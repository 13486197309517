<template>
  <div class="px-3  my-2">
    <ul class="list-unstyled tvm_transport_types">
      <li v-for="(item, index) in transportTypesList" :key="index">
        <div class="mb-2 transport_heading">
          <span class="_theme_font_bold ft-14 px-2"> {{ item.title }}</span>
        </div>
        <!-- <b-form-group :v-slot="{ ariaDescribedby } + index" label="" class="px-2">
          <b-form-radio-group v-model="selected" :options="item.options" :aria-describedby="ariaDescribedby + index"
            value-field="option" text-field="label" stacked />
        </b-form-group> -->
        <div class="px-2">
          <div v-for="option in item.options" :key="option.option">
            <label>
              <input type="radio" :name="'transport-radio-group'" :value="option.option" v-model="selected"
                :aria-describedby="'transport-radio-' + index" />
              {{ option.label }}
            </label>
          </div>
        </div>
      </li>
    </ul>

    <ul v-if="buttons != null && buttons.length" class="list-unstyled service__types__list pt-2">
      <li v-for="(button, index) in buttons" :key="index" class="service__types__list__item">
        <div class="d-flex flex-wrap justify-content-center">
          <button :disabled="!selected || isDisableBtn"
            class="btn bgcolor-primary mb-2 rounded-buttons mx-2 text-white chips__btn"
            @click.once="confirmTypes(button)">
            {{ button.name }}
          </button>
        </div>
      </li>
    </ul>


  </div>
</template>

<script src='./TvmTransportTypes.js'></script>



<style scoped>
.transport_heading {
  padding: 3px 0;
  border-bottom: 1px solid #eee;
}

.tvm_transport_types .form-group {
  margin-bottom: 0 !important;
}

#chat-widget .rounded-buttons.chips__btn {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 6px 12px !important;
  min-width: 56px !important;
  height: 32px !important;
  text-transform: unset !important;
}
</style>
