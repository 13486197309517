/**
 *
 * IMPORTANT:
 * This file is used only in vue3 application
 * changes made here should be made in vue2 also
 * vue3file:wwwroot\js\components\shared\ChatWidget\TvmSuggestions\TvmSuggestions.js
 * for clarification contact Leela
 *
 */
import 'v-slick-carousel/style.css';
import { VSlickCarousel } from 'v-slick-carousel';
// import Slick from 'vue-slick';
import { chatWidgetSuggestionsInteraction } from '../../../Events/TagManagerServices/TaggingEventForPages';
import { useChatSettingsStore } from '../../../store/ChatSettingsStore/ChatSettingsStore';
import { mapWritableState } from 'pinia';
export default {
  data() {
    return {
      buttons: [],
      isDisableChat: false,
      slickOptions: {
        infinite: false,
        groupsToShow: 3,
        slidesPerGroup: 2,
        dots: false,
        ignorePrefersReducedMotion: true,
      },
      themePrimaryColor: window.primaryColor,
      textUsButtonEnable: {},
      search: '',
      specialPositionButtons: [],
      arrowSlotProps: {},
    };
  },
  computed: {
    ...mapWritableState(useChatSettingsStore, ['chatThemeSettings', 'tvmChatActions']),
  },
  props: ['botResponse', 'isWaitingForResponse'],
  mounted() {
    const btns = this.botResponse?.suggestions;
    [...this.buttons] = [...btns];
    [...this.buttons].forEach((item) => {
      item.truncateName = item.name;
    });
    if (this.chatThemeSettings?.primeColor) {
      this.themePrimaryColor = this.chatThemeSettings.primeColor;
    }
    if (this.buttons && this.buttons.length > 5) {
      const longestButtonLength = Math.max(...this.buttons.map((item) => item.name.length));
      if (longestButtonLength === 4) {
        this.reInitSlick();
        this.slickOptions.groupsToShow = 4;
        this.slickOptions.slidesPerGroup = 3;
        // this.slickOptions.groupsToScroll = 4;
      } else if (longestButtonLength > 12) {
        this.reInitSlick();
        let maxLength = 23;
        [...this.buttons].forEach((item) => {
          if (item.name?.length > maxLength) {
            item.truncateName = item.name.substr(0, maxLength).replace(/\s+\S*$/, '...');
          }
        });
        this.slickOptions.groupsToShow = 2;
        this.slickOptions.slidesPerGroup = 3;
        // this.slickOptions.groupsToScroll = 2;
      } else {
        this.reInitSlick();
        this.slickOptions.groupsToShow = 3;
        this.slickOptions.slidesPerGroup = 3;
        // this.slickOptions.groupsToScroll = 3;
      }
      this.specialPositionButtons = this.buttons.filter(
        (item) => item.code === 'text' || item.position === 'top' || item.position === 'bottom'
      );
      this.buttons = this.buttons.filter(
        (item) => !(item.code === 'text' || item.position === 'top' || item.position === 'bottom')
      );
    } else {
      this.specialPositionButtons = this.buttons.filter(
        (item) => item.code === 'text' || item.position === 'top' || item.position === 'bottom'
      );
      this.buttons = this.buttons.filter(
        (item) => !(item.code === 'text' || item.position === 'top' || item.position === 'bottom')
      );
    }

    this.scrollBottom();
  },
  components: {
    // Slick,
    VSlickCarousel,
  },
  methods: {
    optionclick(event, button) {
      if (button.code === 'text') {
        this.$emit('showTextUsOtherForm');
      } else {
        this.tvmChatActions = { ModeOfAction: 'tvm-assistant-suggestion-button' };

        // store.commit(SET_CHAT_ACTIONS, { ModeOfAction: 'tvm-assistant-suggestion-button' });
        let request = {
          UserResponse: button.userResponse ? button.userResponse : button.name,
          Event: button.event,
          EventLabel: button.name,
          SubmitFlow: button.submitFlow,
          SubmitAction: button.submitAction,
          specialObject: button.userResponse ? { TvmComponentRequest: button.name } : null,
        };
        if (request) {
          this.$emit('tvm-suggestions', request);
        }
      }
      chatWidgetSuggestionsInteraction(event, {});
    },
    reInitSlick() {
      if (this.$refs.slick) {
        this.$refs.slick.destroy();
        this.$nextTick(() => {
          this.$refs.slick.create();
        });
      }
    },
    scrollBottom() {
      let container = document.querySelector('#chat__bodyview');
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 800);
      }
    },
    getArrowStyles(direction) {
      const commonStyles = {
        cursor: 'pointer',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: this.themePrimaryColor,
        color: '#fff',
        fontSize: '10px',
        padding: '5px',
        zIndex: 10,
      };
      if (direction === 'left') {
        return {
          ...commonStyles,
          left: '0',
          borderRadius: '0px 3px 3px 0px',
        };
      } else if (direction === 'right') {
        return {
          ...commonStyles,
          right: '0',
          borderRadius: '3px 0px 0px 3px',
        };
      }
      return commonStyles;
    },
  },
  unmounted() {
    this.textUsButtonEnable = {};
  },
  watch: {
    search(val) {
      this.buttons = this.botResponse?.suggestions.filter((item) => {
        return item.name.toUpperCase().includes(val.toUpperCase());
      });
      if (this.buttons?.length > 5) {
        this.reInitSlick();
      }
    },
  },
};
