import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService.js';
// import { mapState } from 'vuex';
export default {
  props: ['botResponse', 'chatThemeSettings'],
  data() {
    return {
      primaryColor: window.primaryColor + '85' || '#eee',
      inventoryInfo: [],
    };
  },
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state?.commonSettings?.chatThemeSettings;
  //     },
  //   }),
  // },
  mounted() {
    if (this.botResponse?.component?.values?.length) {
      this.inventoryInfo = this.botResponse?.component?.values;
    }
    if (this.chatThemeSettings?.borderColor) {
      this.primaryColor = this.chatThemeSettings?.borderColor;
    }
  },
  methods: {
    minimizeChatWidget() {
      ChatWidgetService.closeChatWidgetSession();
      if (sessionStorage.getItem('chatTextModal') !== null) {
        sessionStorage.removeItem('chatTextModal');
      }
    },
  },
};
