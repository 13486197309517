<template>
  <div class="flow_end_items_section">
    <TvmFeatureTiles
      :tiles-data="botResponse.component"
      :is-waiting-for-response="isWaitingForResponse"
      :is-last-index="isLastIndex"
      :chat-theme-settings="chatThemeSettings"
    />
  </div>
</template>
<script>
import TvmFeatureTiles from "./TvmFeatureTiles.vue";
export default {
  components: { TvmFeatureTiles },
  // eslint-disable-next-line vue/require-prop-types
  props: ["isLastIndex", "isWaitingForResponse", "botResponse", "chatThemeSettings"],
  data() {
    return {
      themePrimaryColor: window.primaryColor,
    };
  },
};
</script>
