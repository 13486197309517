<!--
Important:
This COmponent is used only for the Vue3 application
Any modifications made should be done for Vue2 component also
Vue2File: wwwroot\js\WebsitePrimary\components\ResearchModels\PrimaryResearchModels\SimplifyResearchModels.vue
Dont forget to make the changes in both the files
Any clarifications contact Leela
-->
<template>
  <div class="">
    <div
      class="sub-menu-background-fade w-100 h-100 position-fixed"
      style="left: 0px; top: 0px"
      @click.prevent="closeprimaryResearchModels()"
    ></div>
    <div v-if="frameMenuData">
      <div class="simplify-fixed-submenu d-flex px-3 menu-alignment gemini-simplify-fixed-submenu">
        <div class="w-100">
          <div class="text-right ft-24" v-if="isLuxuryTemplate">
            <span class="cursor-pointer close-icon" @click.prevent="closeprimaryResearchModels()">
              <em class="primaryicon-times" style="-webkit-text-stroke: 2px white"></em>
            </span>
          </div>
          <div class="text-right ft-24" v-else-if="isGeminiTemplate">
            <span class="cursor-pointer" @click.prevent="closeprimaryResearchModels()">
              <em class="primaryicon-times menu-icon-color ft-16" />
            </span>
          </div>
          <div
            class="text-right ft-24 color-primary simplify-submenu-close"
            style="-webkit-text-stroke: 2px white"
            v-else
          >
            <span class="cursor-pointer close-icon" @click.prevent="closeprimaryResearchModels()">
              <em class="primaryicon-times bgcolor-white"></em>
            </span>
          </div>
          <div class="">
            <div class="d-flex justify-content-center">
              <a
                class="cursor-pointer text-decoration-none switch-parent position-relative"
                :class="[currentIndex == index ? 'active' : '']"
                @click="frameSimplifyMenuData(index, year)"
                v-for="(year, index) in yearList"
                :key="index"
              >
                <div class="switch" :class="[currentIndex == index ? 'bgcolor-primary' : '']">
                  <span
                    class="color-black _theme_font_bold ft-12 btn-websites button-text"
                    :class="[currentIndex == index ? 'selected text-white' : '']"
                    >{{ year }}</span
                  >
                </div>
              </a>
            </div>
          </div>

          <div class="" v-if="frameMenuData">
            <div class="w-100 d-flex justify-content-center">
              <div
                v-for="(vehicleColumn, index) in frameMenuData"
                :key="index"
                class="vehicle-column"
              >
                <div v-for="(vehicle, index) in vehicleColumn" :key="index">
                  <a
                    :href="baseUrl + vehicle.navigationUrl"
                    class="no-hover vehicle-block"
                    @click="researchModelsSonicEvents(vehicle)"
                  >
                    <div class="vehiclepay bg-white px-4 text-center">
                      <div class="d-flex align-items-center" style="min-height: 100px">
                        <img
                          v-if="useoverrides"
                          class="img-fluid"
                          :src="`${vehicle.imageUrl}&type=png&autocrop=true&angle=${angleValue}&useoverrides=${useoverrides}`"
                          alt="Simplify Research model"
                        />
                        <img
                          v-else
                          class="img-fluid"
                          :src="`${vehicle.imageUrl}&type=png&autocrop=true&angle=${angleValue}`"
                          alt="Simplify Research model"
                        />
                      </div>
                      <div class="">
                        <strong class="color-primary ft-14 model-title"
                          >{{ vehicle.year }} {{ vehicle.model }}</strong
                        >
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript" src="../ResearchModels.js"></script>

<style scoped>
.switch-parent:first-child {
  left: 16px;
}

.switch {
  width: 70px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #eaeaea;
  border: 1px solid #ccc;
  border-radius: 12px;
  text-align: center;
  left: 16px;
}

.active .switch {
  background-color: #fff;
}

.active {
  position: relative;
  z-index: 1;
  border-bottom: 0px;
}

.simplify-fixed-submenu {
  min-width: 15%;
  width: auto !important;
}

.vehiclepay {
  padding: 10px;
  margin: 10px;
  max-width: 180px;
}

.vehicle-block:hover .vehiclepay {
  background-color: #f2f2f2 !important;
  border-radius: 20px;
  transition-duration: 0.5s;
}

.vehicle-block:hover img {
  transform: scale(1.2);
  transition-duration: 0.5s;
}
@media (min-width: 1500px) {
  .menu-alignment {
    align-items: center;
  }
}
.close-icon {
  color: #000 !important;
}
#simplify-side-nav .simplify-submenu-close span em {
  color: #464545 !important;
  background-color: #fff !important;
}
#simplify-side-nav .simplify-submenu-close span em:hover {
  color: #fff !important;
  background-color: #007cc3 !important;
}
</style>
