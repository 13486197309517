/**
 *
 * IMPORTANT:
 * This file is used in the vue3 application
 * Changes made here should be made in the vue2 also
 * file location:wwwroot\js\components\shared\ChatWidget\usertextresponse\usertextresponse.js
 * for clarifications contact leela
 *
 *
 */
import { mapState } from 'pinia';
import { useChatSettingsStore } from '../../../store/ChatSettingsStore/ChatSettingsStore';

export default {
  data() {
    return {
      user: 'You',
      dealerLogo: window.logoImage,
      avatarImg: 'https://bootdey.com/img/Content/avatar/avatar6.png',
      fromuser: 'user',
      touser: 'Virtual Assistant',
    };
  },
  computed: {
    ...mapState(useChatSettingsStore, ['chatThemeSettings']),
  },
  props: {
    userinput: {
      default: '',
      required: false,
    },
    requesttime: {
      default: '',
      required: false,
    },
    index: {
      default: '',
      required: false,
    },
  },
  mounted() {
    this.reloadDisableChat();
    if (this.userinput?.Event !== 'main_menu') {
      window.activeTileNavigateIndex = this.index;
    }
  },
  methods: {
    reloadDisableChat() {
      this.$emit('reloadDisableChat', false);
    },
  },
};
