// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.navigate_inventory_box[data-v-a389f0e2] {
  width: 100%;
  text-align: center;
  margin: 0 10px;
  text-decoration: none;
  border-radius: 6px;
  border: 1px solid #eee;
}
.navigate_to_inventory .text-white[data-v-a389f0e2] {
  color: #fff !important;
}
.chips__btn[data-v-a389f0e2] {
  font-size: 12px;
  font-weight: 400;
  padding: 6px 12px;
  min-width: 56px;
  height: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChatWidget/TvmNavigateToInventory/TvmNavigateToInventory.vue"],"names":[],"mappings":";AAkBA;EACE,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,qBAAqB;EACrB,kBAAkB;EAClB,sBAAsB;AACxB;AAEA;EACE,sBAAsB;AACxB;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;EACf,YAAY;AACd","sourcesContent":["<template>\r\n  <div class=\"navigate_to_inventory mb-4\">\r\n    <div v-for=\"(item, index) in inventoryInfo\" :key=\"index\" class=\"d-flex flex-wrap justify-content-center my-2  pt-2\"\r\n      style=\"clear: both;\">\r\n      <div class=\"navigate_inventory_box\" :style=\"{ borderColor: primaryColor }\">\r\n        <div class=\"\">\r\n          <a class=\"d-block py-2\" :href=\"item.uri\" style=\"color: #0000ee;\" @click=\"minimizeChatWidget()\">{{\r\n            item.linkDesc\r\n            }}</a>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</template>\r\n\r\n<script src=\"./TvmNavigateToInventory.js\"></script>\r\n\r\n<style scoped>\r\n.navigate_inventory_box {\r\n  width: 100%;\r\n  text-align: center;\r\n  margin: 0 10px;\r\n  text-decoration: none;\r\n  border-radius: 6px;\r\n  border: 1px solid #eee;\r\n}\r\n\r\n.navigate_to_inventory .text-white {\r\n  color: #fff !important;\r\n}\r\n\r\n.chips__btn {\r\n  font-size: 12px;\r\n  font-weight: 400;\r\n  padding: 6px 12px;\r\n  min-width: 56px;\r\n  height: 32px;\r\n}\r\n</style>\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
