import axios from 'axios';
// import Slick from 'vue-slick';
import bModal from 'bootstrap-vue/es/components/modal/modal';
import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService';
import TvmPreLoader from '../TvmPreLoader/TvmPreLoader.vue';
// import { mapState } from 'vuex';
import 'v-slick-carousel/style.css';
import { VSlickCarousel } from 'v-slick-carousel';

export default {
  data() {
    return {
      primaryColor: window.primaryColor,
      isLoader: false,
      displayText: 'Please Wait Data is Loading...',
      isDisableChat: false,
      isHeading: false,
      serviceOffersData: [],
      selectedItem: {},
      serviceTypesPayloadUrl: '',
      buttons: [],
      selectedOfferId: '',
      btnDisabled: true,
      selectedItems: [],
      selectedCoupon: '',
      viewDetailsModal: false,
      viewDetailsData: '',
      slickOptions: {
        infinite: false,
        groupsToShow: 3,
        groupsToScroll: 1,
        dots: false,

        // nextArrow: `<em class="primaryicon-right-arrow" style="cursor:pointer;right:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;"></em>`,
        // prevArrow: `<em class="primaryicon-left-arrow" style="cursor:pointer;left:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;"></em>`,
      },
      isDisableBtn: false,
      offerText2: '',
    };
  },
  components: {
    // Slick,
    'b-modal': bModal,
    'tvm-pre-loader': TvmPreLoader,
    VSlickCarousel,
  },
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state?.commonSettings?.chatThemeSettings;
  //     },
  //   }),
  // },
  props: ['botResponse', 'isLastIndex', 'isWaitingForResponse', 'chatThemeSettings'],
  mounted() {
    if (this.chatThemeSettings?.primeColor) {
      this.primaryColor = this.chatThemeSettings.primeColor;
    }
    this.slickOptions.nextArrow = `<em class="primaryicon-right-arrow" style="cursor:pointer;right:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;background:${this.primaryColor} !important;color:#fff !important;"></em>`;
    this.slickOptions.prevArrow = `<em class="primaryicon-left-arrow" style="cursor:pointer;left:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;background:${this.primaryColor} !important;color:#fff !important;"></em>`;
    let payload = this.botResponse?.component?.value;
    this.buttons = this.botResponse?.component?.value?.suggestions;
    this.isDisableChat = payload.disableChat;
    this.selectedOfferId = payload.selectedItem ? payload.selectedItem : '';
    this.isLoader = true;
    axios
      .get(`${payload.offerUri}`)
      .then((response) => {
        let items = response.data;
        if (items?.length) {
          this.isLoader = false;
          items.forEach((value, index) => {
            if (value.offerId === this.selectedOfferId) {
              value.isSelected = true;
              this.selectedCoupon = value.offerText1;
              setTimeout(() => {
                if (this.$refs.slick) {
                  this.$refs.slick.goTo(index);
                }
              }, 500);
            } else {
              value.isSelected = false;
            }
          });
          this.serviceOffersData = items;
          this.scrollBottom();
        } else {
          this.isLoader = false;
          this.serviceOffersData = items;
          this.scrollBottom();
        }
      })
      .catch((error) => {
        console.log(error);
        this.displayText = 'Something Went wrong. Please try again';
        this.isLoader = false;
      });
  },
  methods: {
    trucate(value, length = 20) {
      return value.length <= length ? value : value.substring(0, length) + '...';
    },
    onCouponSelected(serviceType, idx) {
      // for single selection
      if (serviceType) {
        let isSelected = serviceType.isSelected;
        this.serviceOffersData.forEach((value) => {
          value.isSelected = false;
        });
        this.serviceOffersData[idx].isSelected = !isSelected;
        this.selectedCoupon = this.serviceOffersData[idx].isSelected
          ? (this.serviceOffersData[idx].preText + ' ' || '') +
            (this.serviceOffersData[idx].offerText1 + ' ' || '') +
            (this.serviceOffersData[idx].offerText2 || '')
          : '';
        this.offerText2 = this.serviceOffersData[idx].isSelected
          ? (this.serviceOffersData[idx].preText + ' ' || '') +
            (this.serviceOffersData[idx].offerText1 || '')
          : '';
        this.selectedDealerSpecial = this.serviceOffersData[idx].isSelected
          ? this.serviceOffersData[idx]
          : '';
      }
      this.scrollBottom();
    },

    optionclick(button) {
      this.isDisableBtn = true;

      let postObject = {
        eventname: button.event,
        text: button.name,
      };
      if (button.code === 'cnfm') {
        postObject.OfferSelection = {
          OfferId: this.selectedDealerSpecial?.offerId,
          OfferCategoryId: this.selectedDealerSpecial?.offerTypeID,
          OfferDesignId: this.selectedDealerSpecial?.offerDesignId,
        };
      } else {
        this.selectedCoupon = '';
        this.offerText2 = '';
      }

      let userInput = '';
      if (this.selectedCoupon?.length) {
        userInput = `Selected Dealer Special: ${this.selectedCoupon}`;
      }

      ChatWidgetService.postTvmMessage(
        userInput,
        button.event,
        button.submitFlow,
        button.submitAction,
        postObject,
        this.offerText2
      );
      this.scrollBottom();
    },
    viewDetails(val) {
      this.viewDetailsModal = true;
      this.viewDetailsData = val;
    },
    scrollBottom() {
      let container = document.querySelector('#chat__bodyview');
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 500);
      }
    },
    getArrowStyles(direction) {
      const commonStyles = {
        cursor: 'pointer',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: this.primaryColor,
        color: '#fff',
        fontSize: '10px',
        padding: '5px',
        zIndex: 10,
      };
      if (direction === 'left') {
        return {
          ...commonStyles,
          left: '0',
          borderRadius: '0px 3px 3px 0px',
        };
      } else if (direction === 'right') {
        return {
          ...commonStyles,
          right: '0',
          borderRadius: '3px 0px 0px 3px',
        };
      }
      return commonStyles;
    },
  },
};
