<template>
  <div>
    <div v-if="response.errorCount !== 4" class="tvm_api_error_regenerate mx-4 my-3">
      <div class="info_card text-center p-2 pt-3">
        <div>
          <em class="primaryicon-info text-danger ft-22" />
          <h6 class="color-black ft-15 mb-3">There was an error generating response</h6>
          <div class="d-flex flex-wrap justify-content-center mt-3">
            <button title="Regenerate" style="padding: 6px 30px !important"
              class="btn bgcolor-primary mb-2 rounded-buttons mx-1 text-white chips__btn px-4" @click="optionclick()">
              <em class="primaryicon-call-me" /> <span class="mx-1">Retry</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mt-2">
        <small class="ml-3 color-black time__stamp mb-1 d-block">
          <span class="user__icon__box">
            <svg fill="#B3B3B3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z" />
            </svg>
          </span>
          <span v-html="clientName" />
        </small>
        <div class="speech-bubble">
          <p class="ft-14 mb-3">
            Hello there! It seems we've hit a bit of a snag on our end, but no worries, our trusty
            team
            has been
            alerted and is already on the case. Thanks for your patience!
          </p>
        </div>
        <div class="d-flex flex-wrap justify-content-center my-3">
          <button title="Text Us" class="btn bgcolor-primary mb-2 rounded-buttons mx-1 text-white chips__btn px-4"
            @click="textUsOnClick()">
            <span class="mx-1">Text Us</span>
          </button>
          <button title="Main Menu" class="btn bgcolor-primary mb-2 rounded-buttons mx-1 text-white chips__btn px-4"
            @click="mainMenu()">
            <span class="mx-1">Main Menu</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService';

export default {
  props: ['response'],

  data() {
    return {
      clientName: window.clientName || '',
    }
  },

  mounted() {
    this.scrollBottom();
  },

  methods: {
    optionclick() {
      try {
        ChatWidgetService.chatWidgetApi(this.response);
        this.$emit('hideTvmApiErrorRegenerate', true);
      } catch (error) {
        console.log(error)
      }
    },
    textUsOnClick() {
      this.$emit('showTextUsOtherForm');
    },
    mainMenu() {
      this.$emit('mainMenu');
    },
    scrollBottom() {
      let container = document.querySelector('#chat__bodyview');
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 500);
      }
    },
  },
};
</script>
<style scoped>
#chat-widget .rounded-buttons.chips__btn {
  font-size: 11px !important;
  font-weight: 400 !important;
  padding: 6px 12px !important;
  min-width: 56px !important;
  text-transform: unset !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 1900px) {
  #chat-widget .rounded-buttons.chips__btn {
    font-size: 12px !important;
  }
}

.info_card {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 12px;
}

.info_card .primaryicon-call-me {
  vertical-align: middle;
}

.speech-bubble {
  position: relative;
  padding: 10px 20px;
  color: #000;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.095);
  border-left: 0 !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: #f3f3f3;
  margin-bottom: 10px;
  color: #000;
  max-width: 70%;
}

span.user__icon__box svg {
  height: 15px;
  width: 15px;
  vertical-align: text-bottom;
}

.time__stamp {
  font-size: 12px;
}
</style>
