import axios from 'axios';
// import Slick from 'vue-slick';
import bModal from 'bootstrap-vue/es/components/modal/modal';
import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService';
import TvmPreLoader from '../TvmPreLoader/TvmPreLoader.vue';
// import { mapState } from 'vuex';
import 'v-slick-carousel/style.css';
import { VSlickCarousel } from 'v-slick-carousel';

export default {
  data() {
    return {
      primaryColor: window.primaryColor,
      isLoader: false,
      otherService: '',
      customService: '',
      displayText: 'Please Wait Data is Loading...',
      isDisableChat: false,
      ServiceDescriptionModal: false,
      serviceTypesData: [],
      serviceTypesPayloadUrl: '',
      buttons: [],
      btnDisabled: true,
      selectedItems: [],
      commaSeparated: '',
      describeService: "describe what's going on",
      selectedIndex: 0,
      slickOptions: {
        infinite: false,
        groupsToShow: 3,
        groupsToScroll: 2,
        slidesPerGroup: 2,
        dots: false,
        arrows: false,
        // centerPadding: '20px',
        // adaptiveHeight: false,
        // nextArrow: `<em class="primaryicon-right-arrow" style="cursor:pointer;right:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;"></em>`,
        // prevArrow: `<em class="primaryicon-left-arrow" style="cursor:pointer;left:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;"></em>`,
      },
      errorLength: '',
      isDisableBtn: false,
      describeModalService: '',
    };
  },
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state?.commonSettings?.chatThemeSettings;
  //     },
  //   }),
  // },
  components: {
    // Slick,
    'b-modal': bModal,
    'tvm-pre-loader': TvmPreLoader,
    VSlickCarousel,
  },
  props: ['botResponse', 'isLastIndex', 'isWaitingForResponse', 'chatThemeSettings'],
  mounted() {
    if (this.chatThemeSettings?.primeColor) {
      this.primaryColor = this.chatThemeSettings.primeColor;
    }
    // this.slickOptions.nextArrow = `<em class="primaryicon-right-arrow" style="cursor:pointer;right:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;background:${this.primaryColor} !important;color:#fff !important;"></em>`;
    // this.slickOptions.prevArrow = `<em class="primaryicon-left-arrow" style="cursor:pointer;left:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;background:${this.primaryColor} !important;color:#fff !important;"></em>`;
    let payload = this.botResponse?.component?.value;
    this.buttons = this.botResponse?.component?.value?.suggestions;
    this.isLoader = true;
    axios
      .get(
        `${payload.servicesUri}`
        // `https://teamvoffers.com/api/ServiceScheduler/ScheduleServiceTypes?accountId=59823`
      )
      .then((response) => {
        let items = response.data;
        this.isLoader = false;
        items.forEach((value) => {
          value.isSelected = false;
        });

        this.serviceTypesData = JSON.parse(JSON.stringify(items));
        this.scrollBottom();
      })
      .catch((error) => {
        console.log(error);
        this.displayText = 'Something Went wrong. Please try again';
        this.isLoader = false;
      });
  },
  beforeDestroy() {
    this.$root.$off('isWaitingForResponseData');
  },
  methods: {
    trucate(value, length = 15) {
      return value.length <= length ? value : value.substring(0, length) + '...';
    },
    onServiceType(serviceType, index) {
      if (
        serviceType.name.toLowerCase().trim() === this.describeService &&
        !serviceType.isSelected
      ) {
        this.ServiceDescriptionModal = true;
        this.selectedIndex = index;
      }
      serviceType.isSelected = !serviceType.isSelected;
      let selectedItems = this.serviceTypesData.filter(function (item) {
        return item.isSelected === true;
      });

      if (serviceType.name.toLowerCase().trim() === "describe what's going on") {
        this.customService = '';
      }

      this.commaSeparated = this.customService ? this.customService : '';
      selectedItems.forEach((value) => {
        if (value.name.toLowerCase().trim() !== "describe what's going on") {
          this.commaSeparated = (
            this.commaSeparated ? `${this.commaSeparated}, ${value.name}` : value.name
          ).replace('</br>,', '</br>');
        }
      });

      this.btnDisabled = selectedItems.length <= 0;
      this.scrollBottom();
    },

    optionclick(button) {
      this.$root.$emit('isWaitingForResponseData', true);
      this.isDisableBtn = true;
      let selectedItems = this.serviceTypesData.filter(function (item) {
        return item.isSelected === true;
      });

      let selections = selectedItems.map((value) => ({
        Id: value.dealerServiceId,
        Name: value.name,
        Code: value.serviceOpCode,
        AppraisalCode: value.vehicleAppraisalOPTCode,
        Comments:
          value.name?.toLowerCase().trim() === "describe what's going on"
            ? this.describeModalService
            : value.name,
      }));

      let postObject = {
        eventname: button.event,
        text: button.name,
      };
      if (selections) {
        postObject.ServiceSelections = {
          SelectionType: 1,
          Selections: selections,
        };
      }
      ChatWidgetService.postTvmMessage(
        `${
          this.commaSeparated?.split(',').length > 1 ? 'Selected Services' : 'Selected Service'
        } : ${this.commaSeparated.replace('</br>', ',')}`,
        button.event,
        button.submitFlow,
        button.submitAction,
        postObject
      );
      this.scrollBottom();
    },
    addService() {
      if (this.otherService) {
        this.customService = this.otherService + '</br>';
        this.commaSeparated = this.otherService + '</br>' + this.commaSeparated;
        this.describeModalService = this.otherService;
      } else {
        this.serviceTypesData[this.selectedIndex].isSelected = false;
      }
      this.ServiceDescriptionModal = false;
      this.otherService = '';
    },
    scrollBottom() {
      let container = document.querySelector('#chat__bodyview');
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 500);
      }
    },
    getArrowStyles(direction) {
      const commonStyles = {
        cursor: 'pointer',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: this.themePrimaryColor,
        color: '#fff',
        fontSize: '10px',
        padding: '5px',
        zIndex: 10,
      };
      if (direction === 'left') {
        return {
          ...commonStyles,
          left: '0',
          borderRadius: '0px 3px 3px 0px',
        };
      } else if (direction === 'right') {
        return {
          ...commonStyles,
          right: '0',
          borderRadius: '3px 0px 0px 3px',
        };
      }
      return commonStyles;
    },
  },
  watch: {
    otherService(val) {
      if (val.length === 256) {
        this.errorLength = 'Text must be less than 256 characters.';
      } else {
        this.errorLength = '';
      }
    },
  },
};
