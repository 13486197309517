<template>
  <div>
    <div class="assistant_icon">
      <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 39.291 33.469"
        :class="[{ 'animated_icon': isAnimatedIcon }]">
        <g id="Group_8757" data-name="Group 8757" transform="translate(-1550 -239.531)">
          <path id="Path_69832" data-name="Path 69832"
            d="M34.086,15.495a10.555,10.555,0,1,0-18.462,9.261l-.51,5.569a.61.61,0,0,0,.2.47c.2.131.53-.062.53-.062l4.392-2.653A10.555,10.555,0,0,0,34.086,15.495"
            transform="translate(1545.993 237.241)" :fill="primaryColor" stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
            stroke-width="1" />
          <path id="Path_69833" data-name="Path 69833"
            d="M34.121,8.81,34.1,8.775A16.016,16.016,0,0,0,5.622,8.606C2.277,10.009,0,12.782,0,15.976s2.276,5.965,5.619,7.368a2.344,2.344,0,0,0,.652.152c.526.022.888-.376.719-.7A14.567,14.567,0,0,1,9.441,5.912l-.026-.05A14.536,14.536,0,1,1,21.734,30.421a2.126,2.126,0,1,0,.141,1.464A16.037,16.037,0,0,0,34.1,23.241l.059-.119c3.074-1.461,5.129-4.115,5.129-7.146s-2.074-5.708-5.17-7.165"
            transform="translate(1550 239.531)" :fill="headSetColor" />
          <path id="Path_69843" data-name="Path 69843"
            d="M22.129,23.277a1.2,1.2,0,1,1,1.2-1.2,1.2,1.2,0,0,1-1.2,1.2h0Zm4.429,0a1.2,1.2,0,1,1,1.2-1.2,1.2,1.2,0,0,1-1.2,1.2h0Zm4.427,0a1.2,1.2,0,1,1,1.2-1.2,1.2,1.2,0,0,1-1.2,1.2h0Z"
            transform="translate(1543.705 233.251)" :fill="iconDots" stroke="rgba(0,0,0,0)" stroke-miterlimit="10"
            stroke-width="1" />
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex';
export default {
  props: {
    width: {
      type: Number,
      default: 34
    },
    height: {
      type: Number,
      default: 34
    },
    autoSpin: {
      type: Boolean,
      default: false
    },
    spinDuration: {
      type: Number,
      default: null
    },
    themeSettings: {
      type: Object,
      default: null
    },
    chatThemeSettings: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      primaryColor: this.themeSettings?.iconColor || window.primaryColor,
      isAnimatedIcon: false,
      headSetColor: this.themeSettings?.textColor || '#000',
      iconDots: this.themeSettings?.backgroundColor || '#fff',
    }
  },
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state.commonSettings.chatThemeSettings;
  //     },
  //   }),
  // },
  watch: {
    chatThemeSettings: {
      handler(newVal, oldVal) {
        if (newVal?.iconColor && newVal?.launchIconColorForAssistant) {
          this.primaryColor = newVal.iconColor;
          this.headSetColor = newVal?.textColor || '#000';
          this.iconDots = newVal?.backgroundColor || '#fff';
        }
      },
    },
  },
  mounted() {
    if (this.autoSpin && this.spinDuration) {
      console.log(this.spinDuration)
      setInterval(() => {
        this.isAnimatedIcon = !this.isAnimatedIcon;
      }, this.spinDuration);
    }
    if (this.chatThemeSettings?.iconColor && this.chatThemeSettings?.launchIconColorForAssistant) {
      this.primaryColor = this.chatThemeSettings.iconColor;
      this.headSetColor = this.chatThemeSettings?.textColor || '#000';
      this.iconDots = this.chatThemeSettings?.backgroundColor || '#fff';
    }
  },
}
</script>
<style lang="less" scoped>
.assistant_icon svg {
  transform: rotate(-360deg);
  transition: transform 1s ease;
  display: block;

  &:hover {
    transform: rotate(360deg);
    transition: transform 1s ease;
  }
}

.animated_icon {
  animation: rotate 550ms ease;
}

@keyframes rotate {
  0% {
    transform: rotate(-360deg);
    transition: transform 550ms ease;
  }

  100% {
    transform: rotate(360deg);
    transition: transform 550ms ease;
  }
}
</style>
