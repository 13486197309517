import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService';

export default {
  props: ['botResponse', 'isLastIndex', 'componentData'],
  data() {
    return {
      isAuthenticated: window.isauthenticated === 'True',
    };
  },
  mounted() {
    if (this.isLastIndex) {
      let url = new URL(window.location.href);
      let returnUrl = '';
      if (url.pathname.includes('logout') || url.pathname === '/') {
        returnUrl = `?openchatwidget=true&signinsource=assistant`;
      } else if (url.search) {
        returnUrl = `?openchatwidget=true&signinsource=assistant&returnurl=${url.pathname}${url.search}`;
      } else {
        returnUrl = `?openchatwidget=true&signinsource=assistant&returnurl=${url.pathname}`;
      }
      let response = this.componentData?.value?.postLoadData;
      let userPin = this.componentData?.value?.userPin || '';

      if (response) {
        sessionStorage.setItem('postreloadintent', JSON.stringify(response));
      }

      if (!this.isAuthenticated) {
        window.dispatchEvent(new CustomEvent('tvmSignInNavigationLoader', { detail: true }));
        let cookieId = ChatWidgetService.getChatSessionCookie();
        sessionStorage.setItem('oldsid', cookieId);
        window.location.href = '/landing/autologin/' + userPin + returnUrl;
        sessionStorage.setItem('hideTvmHelpModal', true);
      }
    }
  },
};
