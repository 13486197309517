<template>
  <div>
    <div class="pt-3">
      <div v-if="isTextUsEnabled && !isLoadingApi && textUsWidgetPosition === 'top'" class="text_us_option py-0">
        <div class="d-flex align-items-center text_us_card justify-content-center"
          @click.prevent="$emit('showTextUsForm', $event)"
          :style="{ borderColor: chatThemeSettings?.borderColor || '' }">
          <div>
            <TextUsIcon :theme-color="chatThemeSettings?.borderColor" />
          </div>
          <div class="ml-3">
            <span> {{ customizedFlowNameForTextUs }} </span>
          </div>
        </div>
        <div class="text-center or_section">
          <span class="font-weight-light">Or</span>
        </div>
      </div>
    </div>
    <div v-if="serviceButtons && serviceButtons.length" class="fullfill__columns">
      <div :class="evenOrOddClass">
        <div v-for="(item, index) in serviceButtons" :key="index" class="chat__inneritems">
          <div class="custom-payload-con" :style="{ borderColor: chatThemeSettings?.borderColor || '' }">
            <div :title="item.text" class="chat__body__btn cursor-pointer" :disabled="isWaitingForResponse"
              @click="onPayloadButtonClick($event, item)">
              <em
                :class="['primaryicon-' + item.image, item.icon == 'primaryicon-Sales-Special' ? 'customfontsize' : '']"
                :style="{ color: chatThemeSettings?.borderColor || '#878787' }" />
              <p class="chat__body__btn__title">
                {{ item.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isTextUsEnabled && !isLoadingApi && (textUsWidgetPosition === 'bottom' || textUsWidgetPosition === null)"
      class="text_us_option">
      <div class="text-center or_section">
        <span class="font-weight-light">Or</span>
      </div>
      <div class="d-flex align-items-center text_us_card justify-content-center"
        :style="{ borderColor: chatThemeSettings?.borderColor || '' }" @click.prevent="$emit('showTextUsForm', $event)">
        <div>
          <TextUsIcon :theme-color="chatThemeSettings?.borderColor" />
        </div>
        <div class="ml-3">
          <span> {{ customizedFlowNameForTextUs }} </span>
        </div>
      </div>
    </div>
    <div v-if="!isTextUsEnabled || textUsWidgetPosition === 'top'" class="mb-4" />

  </div>
</template>
<script src="./TvmStartUpButtons.js"></script>
<style scoped>
.chat__list {
  width: 100%;
  list-style: none;
  padding: 0 30px;
  margin: 0 0 10px 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.chat__grid .chat__inneritems,
.chat__list .chat__inneritems {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fullfill__columns {
  display: inline-block;
  margin: 0;
  width: 100%;
}

.fullfill__columns .chat__grid {
  width: 100%;
  list-style: none;
  padding: 0 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.chat__grid.last_full_width .chat__inneritems:last-of-type {
  grid-column: 1 / -1;
}

.img-max-height {
  margin-bottom: 4px;
  height: 28px;
}

.chatbot-response .text-white {
  color: #fff !important;
}

.chat__body__btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  border: 0 !important;
  background: none !important;
  outline: 0 !important;
  cursor: pointer;
  height: 100%;
  text-align: center;
}

.icon__cont__box {
  margin-bottom: 5px;
  margin-right: 0;
  border: 0;
  padding: 2px;
  display: flex;
}

.chat__list .chat__inneritems .chat__body__btn {
  flex-direction: row;
  justify-content: flex-start;
}

.chat__list .chat__inneritems .icon__cont__box {
  margin: 0 10px 0 0;
  display: flex;
}

.chat__list .chat__inneritems .icon__cont__box .icon__cont {
  font-size: 18px;
}

.chat__grid .chat__inneritems .icon__cont__box .icon__cont {
  font-size: 18px;
}

.chat__list .chat__inneritems .icon__cont__box .end__chatIcon {
  width: 20px;
  height: auto;
}

.chat__grid .chat__inneritems .icon__cont__box .end__chatIcon {
  width: 20px;
  height: auto;
}

.chat__list .chat__inneritems .icon__cont__box .end__chatIcon path,
.chat__grid .chat__inneritems .icon__cont__box .end__chatIcon path {
  color: var(--color-primary);
  fill: currentColor;
}

.chat__body__btn__title {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  color: #000;
}

.custom-payload-con {
  width: 100%;
  /* border: 1px solid #eee; */
  border-radius: 6px;
  padding: 13px 2px;
  height: 100%;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.09);
}

.chat__list .chat__inneritems .custom-payload-con {
  padding: 10px 15px;
  border-radius: 5px;
}

.chat__body__btn em {
  font-size: 27px;
  margin-bottom: 3px;
}

@media all and (min-width: 1900px) {
  .chat__list .chat__inneritems .custom-payload-con {
    padding: 15px 30px;
    border-radius: 10px;
  }
}

@media all and (min-width: 2500px) {
  .chat__list .chat__inneritems .custom-payload-con {
    padding: 20px 30px;
    border-radius: 12px;
  }

  .chat__body__btn em {
    font-size: 35px;
  }

  .chat__body__btn em.customfontsize {
    font-size: 40px !important;
  }
}

@media all and (min-width: 3800px) {
  .chat__list .chat__inneritems .custom-payload-con {
    padding: 40px 50px;
    border-radius: 15px;
  }

  .chat__body__btn em {
    font-size: 35px;
  }

  .chat__body__btn em.customfontsize {
    font-size: 40px !important;
  }
}

@media all and (min-width: 1900px) {
  #chatBodySec2 .chat__body__btn__title {
    font-size: 13px;
  }

  #chatBodySec2 .chat__grid .chat__inneritems .chat__body__btn__title {
    font-size: 14px;
  }

  .chat__list .chat__inneritems .chat__body__btn {
    min-height: 40px;
  }

  .chat__list .chat__inneritems .icon__cont__box {
    margin: 0 20px 0 0;
  }

  .chat__list .chat__inneritems .icon__cont__box .icon__cont {
    font-size: 24px;
  }

  .chat__list .chat__inneritems .icon__cont__box .end__chatIcon {
    width: 24px;
  }

  #chatBodySec2 .chat__list .chat__inneritems .chat__body__btn__title {
    font-size: 16px;
  }

  .chat__grid .chat__inneritems .icon__cont__box .icon__cont {
    font-size: 22px;
  }

  .chat__grid .chat__inneritems .icon__cont__box .end__chatIcon {
    width: 22px;
  }

  .img-max-height {
    height: 35px;
  }

  .fullfill__columns .chat__grid {
    gap: 15px;
  }
}

@media all and (min-width: 2500px) {
  #chatBodySec2 .chat__body__btn__title {
    font-size: 18px;
  }

  #chatBodySec2 .chat__grid .chat__inneritems .chat__body__btn__title {
    font-size: 20px;
  }

  .chat__grid .chat__inneritems .chat__body__btn {
    min-height: 100px;
  }

  .chat__list .chat__inneritems .chat__body__btn {
    min-height: 50px;
  }

  .chat__list .chat__inneritems .icon__cont__box {
    margin: 0 20px 0 0;
  }

  .chat__list .chat__inneritems .icon__cont__box .icon__cont {
    font-size: 36px;
  }

  .chat__list .chat__inneritems .icon__cont__box .end__chatIcon {
    width: 36px;
  }

  #chatBodySec2 .chat__list .chat__inneritems .chat__body__btn__title {
    font-size: 20px;
  }

  .chat__grid .chat__inneritems .icon__cont__box .icon__cont {
    font-size: 28px;
  }

  .chat__grid .chat__inneritems .icon__cont__box .end__chatIcon {
    width: 28px;
  }

  .chat__body__btn em {
    font-size: 35px;
  }

  .chat__body__btn em.customfontsize {
    font-size: 40px !important;
  }
}

@media all and (min-width: 3800px) {
  #chatBodySec2 .chat__body__btn__title {
    font-size: 24px;
  }

  #chatBodySec2 .chat__grid .chat__inneritems .chat__body__btn__title {
    font-size: 30px;
  }

  .chat__grid .chat__inneritems .chat__body__btn {
    min-height: 160px;
  }

  .chat__list .chat__inneritems .chat__body__btn {
    min-height: 60px;
  }

  .chat__list .chat__inneritems .icon__cont__box {
    margin: 0 30px 0 0;
  }

  .chat__list .chat__inneritems .icon__cont__box .icon__cont {
    font-size: 42px;
  }

  .chat__list .chat__inneritems .icon__cont__box .end__chatIcon {
    width: 42px;
  }

  #chatBodySec2 .chat__list .chat__inneritems .chat__body__btn__title {
    font-size: 30px;
  }

  .chat__grid .chat__inneritems .icon__cont__box {
    margin-bottom: 10px;
  }

  .chat__grid .chat__inneritems .icon__cont__box .icon__cont {
    font-size: 42px;
  }

  .chat__grid .chat__inneritems .icon__cont__box .end__chatIcon {
    width: 42px;
  }

  .chat__body__btn em {
    font-size: 35px;
  }

  .chat__body__btn em.customfontsize {
    font-size: 40px !important;
  }
}

.text_us_option {
  padding: 5px 30px 20px 30px;
}

.text_us_card {
  /* border: 1px solid #eee; */
  border-radius: 5px;
  padding: 13px 10px;
  cursor: pointer;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.09);
}

.text_us_card img {
  height: 34px;
}

.or_section {
  margin-bottom: 5px;
}

.text_us_option span {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.text_us_option .text_us_card span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: block;
}

.text_us_option .or_section span {
  font-size: 13px;
}

div[disabled=true] {
  opacity: 0.6;
  pointer-events: none;
}

@media all and (min-width: 1900px) {
  .text_us_option span {
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }

  .text_us_card img {
    height: 50px;
  }

  .text_us_option {
    padding: 5px 30px 20px 30px;
  }

  .chat__grid .custom-payload-con {
    padding: 15px 2px !important;
  }

  .text_us_card {
    padding: 16px 10px;
  }

  .chat__body__btn em {
    font-size: 27px;
    margin-bottom: 3px;
  }

  .chat__body__btn em.customfontsize {
    font-size: 30px !important;
    margin-bottom: 3px;
  }

}
</style>
