// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#chat-widget .tvm_login_vehicles .rounded-buttons.chips__btn {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 6px 12px !important;
  min-width: 56px !important;
  height: 32px !important;
  text-transform: unset !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChatWidget/TvmLoginVehicles/TvmLoginVehicles.vue"],"names":[],"mappings":";AAuCA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,0BAA0B;EAC1B,uBAAuB;EACvB,gCAAgC;AAClC","sourcesContent":["<template>\r\n  <div class=\"px-3  my-2 tvm_login_vehicles\">\r\n    <div v-if=\"vehicles && vehicles.length\" class=\"d-flex flex-column justify-content-center align-items-center\">\r\n      <button v-for=\"(vehicle, index) in vehicles\" :key=\"index\"\r\n        class=\"btn bgcolor-primary mb-2 rounded-buttons mx-1 text-white chips__btn\" @click.once=\"eachVehicle(vehicle)\">\r\n        {{ vehicle.displayText }}\r\n      </button>\r\n    </div>\r\n  </div>\r\n</template>\r\n<script>\r\nimport { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService.js';\r\n\r\nexport default {\r\n  props: [\"botResponse\"],\r\n  data() {\r\n    return {\r\n      vehicles: [],\r\n      primaryColor: window.primaryColor\r\n    }\r\n  },\r\n  mounted() {\r\n    if (this.botResponse?.component?.value?.vehicles?.length) {\r\n      this.vehicles = this.botResponse?.component?.value?.vehicles;\r\n    }\r\n  },\r\n  beforeDestroy() {\r\n    this.$root.$off('isWaitingForResponseData');\r\n  },\r\n  methods: {\r\n    eachVehicle(vehicle) {\r\n      this.$root.$emit('isWaitingForResponseData', true);\r\n      let specialObject = { TvmComponentRequest: vehicle };\r\n      ChatWidgetService.postTvmMessage(vehicle.displayText?.trim(), null, vehicle?.suggestion?.submitFlow, vehicle?.suggestion?.submitAction, specialObject);\r\n    }\r\n  },\r\n}\r\n</script>\r\n<style>\r\n#chat-widget .tvm_login_vehicles .rounded-buttons.chips__btn {\r\n  font-size: 12px !important;\r\n  font-weight: 400 !important;\r\n  padding: 6px 12px !important;\r\n  min-width: 56px !important;\r\n  height: 32px !important;\r\n  text-transform: unset !important;\r\n}\r\n</style>\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
