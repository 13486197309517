<template>
  <div id="kbb__trade" ref="kbb__trade" class="kbb__block mx-3 my-3">
    <iframe v-if="DisplayGraphic && isMobile" :src="kellyurl" frameborder="0" allowfullscreen
      title="IFrame Trade Valuation" />
    <div v-if="year || make || model" class="kbb-est-con color-primary mb-2 d-flex justify-content-between"
      :style="{ color: primaryColor + '!important' }">
      {{ year }} {{ make }} {{ model }} {{ trim }}
      <div v-if="Downloadable">
        <i class="primaryicon-download cursor-pointer" title="Download" @click="download()" />
      </div>
    </div>
    <div v-if="DisplayGraphic">
      <div v-if="!isMobile">
        <table class="table trade_details_table my-2"
          :style="{ borderColor: chatThemeSettings?.borderColor + '!important' }">
          <!-- <caption class="d-none">
            Vehicle Data Table
          </caption>
          <tr class="d-none">
            <th>&nbsp;</th>
          </tr> -->
          <tbody>
            <tr>
              <td>Estimated Mileage</td>
              <td>{{ estMileage }}</td>
            </tr>
            <tr>
              <td>Condition</td>
              <td class="font-weight-bold _theme_font_bold">{{ condition }}</td>
            </tr>
            <tr v-if="VIN">
              <td>VIN</td>
              <td>{{ VIN }}</td>
            </tr>
            <tr>
              <td>Trade-In Value</td>
              <td>${{ SelectedValue }}</td>
            </tr>
            <tr>
              <td>High</td>
              <td>${{ High }}</td>
            </tr>
            <tr>
              <td>Low</td>
              <td>${{ Low }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="nextQuestion" class="confirm_section">
          <p class="mb-0 ft-13 font-weight-bold">{{ nextQuestion }}</p>
        </div>
      </div>

      <div v-if="isMobile" class="kbb-est-con color-primary" :style="{ color: primaryColor + '!important' }">
        <span class="mr-2">Estimated Mileage : {{ estMileage }}</span>
        <span class="mr-2">Condition : {{ condition }}</span>
      </div>
      <div v-if="VIN && isMobile" class="kbb-est-con color-primary" :style="{ color: primaryColor + '!important' }">
        <span>VIN : {{ VIN }}</span>
      </div>
    </div>
    <div v-if="!DisplayGraphic">
      <table class="bb__table__1" :style="{ borderColor: chatThemeSettings?.borderColor + '!important' }">
        <!-- <caption class="d-none">
          Vehicle Data Table
        </caption> -->
        <tbody>
          <tr>
            <th>Estimated Mileage</th>
            <td>:</td>
            <td>{{ estMileage }}</td>
          </tr>
          <tr>
            <th>Condition</th>
            <td>:</td>
            <td>{{ condition }}</td>
          </tr>
          <tr v-if="VIN">
            <th>VIN</th>
            <td>:</td>
            <td>{{ VIN }}</td>
          </tr>
        </tbody>
      </table>
      <table class="bb__table__2" :style="{ borderColor: chatThemeSettings?.borderColor + '!important' }">
        <!-- <caption class="d-none">
          Selected Condition Table
        </caption> -->
        <tbody>
          <tr class="selected-condition">
            <th>Trade-In Value</th>
            <td>:</td>
            <td>{{ SelectedValue }}</td>
          </tr>
          <tr v-if="High">
            <th>High</th>
            <td>:</td>
            <td>{{ High }}</td>
          </tr>
          <tr v-if="Low">
            <th>Low</th>
            <td>:</td>
            <td>{{ Low }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="isMobile" class="kbb-dis mt-2" v-html="disclaimer" />

    <div v-if="nextQuestion && isMobile" class="confirm_section">
      <p class="mb-0 ft-13 font-weight-bold">{{ nextQuestion }}</p>
    </div>
  </div>
</template>
<script>
// import { mapState } from "vuex";
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["botResponse", "index", "chatThemeSettings"],
  data() {
    return {
      kellyurl: "",
      estMileage: "",
      condition: "",
      disclaimer: "",
      VIN: "",
      year: "",
      make: "",
      model: "",
      trim: "",
      SelectedValue: "",
      High: "",
      Low: "",
      DisplayGraphic: false,
      Downloadable: false,
      isMobile: window.ismobile,
      primaryColor: window.primaryColor,
      nextQuestion: "",
    };
  },
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state?.commonSettings?.chatThemeSettings;
  //     },
  //   }),
  // },
  mounted() {
    if (this.chatThemeSettings?.primeColor) {
      this.primaryColor = this.chatThemeSettings.primeColor;
      this.$nextTick(() => {
        if (document.querySelector(".trade_details_table tr td:first-child")) {
          let firstChildTds = document.querySelectorAll(".trade_details_table tr td:first-child");
          firstChildTds.forEach((item) => {
            item.style.borderColor = this.chatThemeSettings?.borderColor;
          });
        }
      });
    }
    if (this.$refs.kbb__trade && this.$refs.kbb__trade.offsetTop) {
      this.$emit("TvmKbbOffSetValue", this.$refs.kbb__trade.offsetTop);
    }
    if (this.botResponse?.component?.value) {
      let disData = this.botResponse?.component?.value;
      this.kellyurl = disData.uri;
      this.estMileage = disData.estimated_Mileage;
      this.condition = disData.condition;
      this.disclaimer = disData.disclaimer;
      this.VIN = disData.vin;
      this.year = disData.year;
      this.make = disData.make;
      this.model = disData.model;
      this.trim = disData.trim;
      this.High = disData.high;
      this.Low = disData.low;
      this.SelectedValue = disData.selectedValue;
      this.DisplayGraphic = disData.displayGraphic;
      this.Downloadable = disData.downlodable;
    }
    if (this.botResponse?.component?.nextQuestion) {
      this.nextQuestion = this.botResponse?.component?.nextQuestion;
    }
    // loading script for html to image download
    if (!document.getElementById("html2canvasScript")) {
      let scriptTag = document.createElement("script");
      scriptTag.src = "https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js";
      scriptTag.id = "html2canvasScript";
      scriptTag.async = true;
      document.getElementsByTagName("head")[0].appendChild(scriptTag);
    }
    // loading script for html to image download
  },
  methods: {
    download() {
      // eslint-disable-next-line no-undef
      html2canvas(document.getElementById("kbb__trade")).then(function (canvas) {
        let anchorTag = document.createElement("a");
        document.body.appendChild(anchorTag);
        anchorTag.download = "trade_value.jpg";
        anchorTag.href = canvas.toDataURL();
        anchorTag.target = "_blank";
        anchorTag.click();
      });
    },
    viewDetails(val) {
      this.scrollBottom();
      setTimeout(() => {
        this.$root.$emit("OpenSlideInfo", `viewDetails-${val}`);
      }, 500);
    },
    scrollBottom() {
      let container = document.querySelector("#chat__bodyview");
      if (container) {
        setTimeout(() => {
          container.scrollTo(0, this.$refs.kbb__trade.offsetTop + 600);
        }, 500);
      }
    },
  },
};
</script>
<style scoped>
.bb-est-con {
  font-size: 11px;
  font-weight: bold;
  display: flex;
  width: 100%;
  margin: 0 0 5px 0;
}

.bb-dis {
  font-size: 10px;
}

.selected-condition {
  font-weight: bold;
  background: #eee;
}

.bb__table__1 {
  width: 100%;
  border: 1px solid #dbdbdb;
  margin: 0 0 10px 0;
}

.bb__table__2 {
  width: 100%;
  border: 1px solid #dbdbdb;
  margin: 0 0 10px 0;
}

.bb__table__1 th,
.bb__table__1 td,
.bb__table__2 th,
.bb__table__2 td {
  font-size: 12px;
  padding: 5px;
}

.bb__table__1 th,
.bb__table__2 th {
  width: 44%;
}

.bb__table__1 td:nth-of-type(1),
.bb__table__2 td:nth-of-type(1) {
  width: 6%;
}

.bb__table__1 td:nth-of-type(2),
.bb__table__2 td:nth-of-type(2) {
  width: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.kbb-est-con {
  font-size: 11px;
  font-weight: bold;
}

.kbb-dis {
  font-size: 10px;
}

.kbb__block {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.kbb__block iframe {
  width: 100%;
  height: 380px;
  border: none;
  text-align: center;
  margin: 0 auto;
  display: flex;
}

.kbb__block iframe svg {
  width: 100%;
  max-width: 100%;
}

@media all and (min-width: 1366px) {
  .kbb__block iframe {
    height: 380px;
  }

  .kbb-est-con {
    font-size: 11px;
  }

  .kbb-dis {
    font-size: 11px;
  }
}

@media all and (min-width: 1440px) {
  .kbb__block iframe {
    height: 402px;
  }

  .kbb-est-con {
    font-size: 11px;
  }

  .kbb-dis {
    font-size: 11px;
  }
}

@media all and (min-width: 1920px) {
  .kbb__block iframe {
    height: 565px;
  }

  .kbb-est-con {
    font-size: 14px;
  }

  .kbb-dis {
    font-size: 12px;
  }
}

@media all and (min-width: 2560px) {
  .kbb__block iframe {
    height: 740px;
  }

  .kbb-est-con {
    font-size: 14px;
  }

  .kbb-dis {
    font-size: 14px;
  }
}

@media all and (min-width: 3840px) {
  .kbb__block iframe {
    height: 1024px;
  }

  .kbb-est-con {
    font-size: 16px;
  }

  .kbb-dis {
    font-size: 16px;
  }
}

.trade_details_table {
  border: 1px solid #eee !important;
}

.trade_details_table tr td {
  border: 0;
  padding: 8px 20px;
  font-size: 14px;
}

.trade_details_table tr td:first-child {
  border-right: 1px solid #eee;
  max-width: 100px;
}

.confirm_section {
  margin: 15px 0;
  word-break: break-word;
}

@media screen and (min-width: 1900px) {
  .confirm_section .ft-13 {
    font-size: 14px !important;
  }
}
</style>
