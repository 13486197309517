// import { mapState } from 'vuex';
export default {
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state?.commonSettings?.chatThemeSettings;
  //     },
  //   }),
  // },
  props: ['botResponse', 'chatThemeSettings'],
  data() {
    return {
      info: [],
      title: '',
      imageDetails: {},
      primaryColor: window.primaryColor,
    };
  },

  mounted() {
    if (this.chatThemeSettings?.primeColor) {
      this.primaryColor = this.chatThemeSettings.primeColor;
    }
    if (this.botResponse?.component?.value) {
      this.title = this.botResponse?.component?.value?.title;
      this.info = this.botResponse?.component?.value?.info;
      this.imageDetails = this.botResponse?.component?.value?.imageDetails;
    }
  },
};
