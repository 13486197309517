<template>
  <div
    class="position-absolute submenu-navigation"
    :class="[
      submenublackcolor ? (isprimary ? 'primarymenu websitenewsubmenu' : 'websitenewsubmenu') : '',
    ]"
  >
    <div class="w-100">
      <div class="d-flex" style="background: #f5f5f5">
        <div class v-for="menu in menudata">
          <a
            class="px-3 py-2 d-inline-block w-100 submenu-item ft-14 cursor-pointer"
            @click.prevent="onMenuDataClick(menu.groupItemsList)"
            >{{ menu.vehicleTypeName }}</a
          >
        </div>
      </div>

      <div class="d-flex submenu bgcolor-primary" v-if="submenudata">
        <div v-for="submenu in submenudata">
          <div>
            <a
              class="px-3 py-2 ft-14 no-border cursor-pointer text-light nohover"
              @click.prevent="onsubmenuitemclick(submenu)"
            >
              <span>{{ submenu.model_Year }} {{ submenu.model_Name }}</span>
            </a>
          </div>
        </div>
      </div>

      <div class="w-100 bg-white" v-if="vehicleList">
        <div class="w-100">
          <VSlickCarousel ref="slick" v-bind="slickOptions">
            <div v-for="vehicle in vehicleList.vehiclePays" :key="vehicle.trim">
              <div class="vehiclepay text-center">
                <div class="mt-2">
                  <strong>{{ vehicle.trim }} {{ vehicle.styleName }}</strong>
                </div>
                <div class="my-3">
                  <img :src="vehicle.imageHandlerPath" class="img-fluid" alt="Vehicle pay" />
                </div>
                <div class="mb-2">
                  <a
                    :href="(baseUrl + '/vehicledetails/' + vehicle.buildYourOwn).toLowerCase()"
                    class="bgcolor-primary ft-14 rounded-buttons"
                    >Explore</a
                  >
                  <a
                    class="bgcolor-primary mx-1 color-white ft-14 rounded-buttons"
                    :href="
                      (
                        baseUrl +
                        '/inventory/new/' +
                        make +
                        '/' +
                        vehicle.modelName.split(' ')[0] +
                        '/' +
                        vehicle.trim.split(' ')[0]
                      ).toLowerCase()
                    "
                    >View Inventory</a
                  >
                </div>
              </div>
            </div>
          </VSlickCarousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="./HorizontalBuildPriceMenu.js"></script>

<style scoped>
.websitenewsubmenu {
  top: 55px;
  left: 0px;
  width: 100%;
}
.submenudata {
  width: 200px;
  max-height: 500px;
  overflow-y: auto;
}

.submenu-navigation a {
  border-right: 1px solid #e5e5e5;
}

.vehiclepay {
  padding: 9px;
  border: 1px solid #eee;
  width: 90%;
  border-radius: 15px;
  margin: 20px 20px;
}

.vehiclepay img {
  height: 120px;
  margin: auto;
}

.submenu {
  background-color: #e5e5e5;
}

.vehiclepay a {
  color: #fff;
  border-right: none;
  width: auto;
  padding: 5px 20px;
  text-decoration: none;
}

.slick-arrow.previous-link {
  position: absolute;
  top: 50%;
}

.slick-arrow.next-link {
  position: absolute;
  right: 0px;
  top: 50%;
}

.bgcolor-black {
  background-color: #000;
}

.primarymenu.websitenewsubmenu {
  top: 100%;
}

.website-primary-header .horizontalmenu .primarymenu .submenu-item {
  text-decoration: none;
  color: #000;
}

.primarymenu .submenu {
  color: #ffffff;
  height: 35px;
  line-height: 35px;
}
.submenu a {
  text-decoration: none;
  color: #000;
}

.submenu-navigation a.no-border {
  border: none;
}
:deep(.v-slick-arrow.prev) {
  left: 0px !important;
  z-index: 1000;
  border-radius: 50%;
}
:deep(.v-slick-arrow.next) {
  right: 0px !important;
  border-radius: 50%;
}
:deep(.v-slick-arrow.prev:before),
:deep(.v-slick-arrow.next:before) {
  background-color: #5c5c5c !important;
  border-radius: 50%;
}
:deep(.v-slick-arrow.disabled:before) {
  opacity: 0.1;
  background-color: #000000 !important;
  border-radius: 50%;
}
</style>
