<template>
  <div v-if="flowIndicator && processNameIndicator"></div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["flowIndicator", "processNameIndicator"],
  data() {
    return {
      primaryColor: window.primaryColor,
    };
  },
  watch: {
    flowIndicator(val) {
      if (window.tvmFlowSetting?.length) {
        window.tvmFlowSetting.forEach((element) => {
          if (element?.flowName?.toLowerCase() === val?.toLowerCase()) {
            sessionStorage.setItem("activeFlowIndicatorCode", element?.code);
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.process_name_indicator {
  position: absolute;
  top: 1.5%;
  z-index: 99;
  color: #fff;
  border-radius: 20px;
  padding: 6px 20px;
  font-size: 12px;
  display: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  left: 0;
  right: 0;
  max-width: max-content;
  margin: 0 auto;
}

.process_name_indicator.animated_bottom {
  display: block;
  animation: animated_bottom 1s;
  transition: 0.5s;
}

@keyframes animated_bottom {
  0% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0px);
  }
}

.border-full {
  position: absolute;
  height: 1px;
  top: 3vh;
  left: 0;
  display: none;
  transition: width 0.6s linear;
  right: 0;
  margin: 0 auto;
  z-index: 9;
  opacity: 0.5;

  &.animated_border {
    animation: animated_border 5s;
    transition: 0.5s;
    display: block;
  }
}

@keyframes animated_border {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
    display: block;
  }
}

@media (min-width: 1900px) {
  .border-full {
    top: 2.8vh;
  }
}
</style>
