<template>
  <div class="svg_icon">
    <svg :style="{
      fill: isHoverHour ? themePrimaryColor : chatThemeSettings?.borderColor || themeColor,
      stroke: isHoverHour ? themePrimaryColor : chatThemeSettings?.borderColor || themeColor,
    }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px">
      <path
        d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 37.039062 10.990234 A 1.0001 1.0001 0 0 0 36.265625 11.322266 L 26.183594 22.244141 A 3 3 0 0 0 25 22 A 3 3 0 0 0 22 25 A 3 3 0 0 0 25 28 A 3 3 0 0 0 25.5 27.958984 L 29.125 34.486328 A 1.0010694 1.0010694 0 1 0 30.875 33.513672 L 27.246094 26.984375 A 3 3 0 0 0 28 25 A 3 3 0 0 0 27.652344 23.599609 L 37.734375 12.677734 A 1.0001 1.0001 0 0 0 37.039062 10.990234 z" />
    </svg>
  </div>
</template>

<script>
import { useChatSettingsStore } from '../../../store/ChatSettingsStore/ChatSettingsStore';
import { mapWritableState } from 'pinia';

export default {
  props: {
    themeColor: {
      type: String,
      required: false,
      default: '#B3B3B3',
    },
    isHoverHour: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      themePrimaryColor: window.primaryColor,
    }
  },
  computed: {
    ...mapWritableState(useChatSettingsStore, ['chatThemeSettings']),
  },
}
</script>
<style scoped>
.svg_icon svg {
  position: relative;
  bottom: 0px;
  height: 16px;
}

.footer-menu:hover svg {
  bottom: -2px;
  transition: 0.5s;
}

@media (min-width: 1900px) {
  .svg_icon svg {
    height: 21px !important;
    margin-bottom: 2px;
  }
}
</style>
