<template>
  <div id="tvmServiceCoupons">
    <div :id="'Service_Types' + index" class=" w-100 pt-2 service_offers position-relative service__types">
      <div v-if="serviceOffersData && serviceOffersData.length === 0" class="py-4 pb-5">
        <tvm-pre-loader />
      </div>

      <div class="px-3 position-relative">
        <VSlickCarousel v-if="serviceOffersData != null && serviceOffersData.length" ref="slick"
          class="service__types__list" v-bind="slickOptions" @afterChange="handleAfterChange" @init="handleInit">
          <template #nextArrow="arrowSlotProps">
            <em :disabled="arrowSlotProps.disabled" @click="arrowSlotProps.onClick" class="primaryicon-right-arrow"
              :style="getArrowStyles('right')"></em>
          </template>
          <div v-for="(serviceDescText, index) in serviceOffersData" :key="index"
            class="service__types__list__item p-1 text-center">
            <div v-if="componentObj.isButtonEnabled"
              class="service__types__btn__cont color-primary p-1 rounded-box theme-text-color position-relative"
              :class="[serviceDescText.isSelected ? 'selected-box' : '']" :style="{
                border:
                  serviceDescText.isSelected && chatThemeSettings && chatThemeSettings.primeColor
                    ? '2px solid ' + primaryColor
                    : '1px solid ' + (chatThemeSettings?.borderColor || '#eee'),
              }" @click.prevent="onCouponSelected(serviceDescText, index)">
              <div v-if="serviceDescText.isAppleMobile" class="position-absolute appleWallet-position">
                <a class="appleWallet"
                  :href="`${serviceDescText.appleWalletAPIUrl}/${serviceDescText.templateId}/${serviceDescText.serialNumber}`"
                  @click="onAppleWalletClick(serviceDescText.offerId)">
                  <img :id="serviceDescText.offerId" src="/images/addToAppleWallet.svg"
                    :offerid="serviceDescText.offerId" :templateid="serviceDescText.templateId"
                    :serialnumber="serviceDescText.serialNumber" class="appleWalletImgBtn"
                    :alt="serviceDescText.heading" :title="serviceDescText.heading" />
                </a>
              </div>

              <Transition>
                <div v-if="serviceDescText.isSelected" class="custom-check-box">
                  <span class="primaryicon-financing" />
                </div>
              </Transition>

              <a class="service__types__btn">
                <span class="service__types__btn__iconBox mt-3">
                  <img
                    :src="`https://service.secureoffersites.com/images/getlibraryimage?fileNameOrId=${serviceDescText.imageId}&amp;height=60`"
                    class="img-fluid" alt="add To Apple Wallet" />
                </span>
                <div class="card-min-height">
                  <p v-if="serviceDescText.heading" :title="serviceDescText.heading"
                    class="service__types__btn__title text-capitalize text-dark theme-text-color text-ellipsis">
                    <span v-html="serviceDescText.heading" />
                  </p>

                  <p v-if="serviceDescText.amountText"
                    class="service__types__btn__title text-capitalize text-dark theme-text-color">
                    <span>
                      <b class="_theme_font_bold color-black" v-html="trucate(serviceDescText.amountText)" />
                    </span>
                  </p>

                  <p v-if="serviceDescText.description" :title="serviceDescText.description"
                    class="service__types__btn__title text-dark theme-text-color">
                    <span v-if="serviceDescText.description.length > 15">
                      {{ trucate(serviceDescText.description) }}
                    </span>
                    <span v-else>
                      <span v-html="serviceDescText.description" />
                    </span>
                  </p>

                  <div class="text-center py-1" style="line-height: 0.5">
                    <span class="no-hover ft-10 color-black text-underline"
                      @click.stop="viewDetails(serviceDescText)">View
                      Details</span>
                  </div>
                </div>
                <div class="w-100 px-1 d-flex align-items-center justify-content-center">
                  <span
                    class="text-white service__types__btn__title select_btn select_btn rounded-buttons font-weight-bold bgcolor-primary opacity_7"
                    :class="{ 'bgcolor-primary text-white opacity-1': serviceDescText.isSelected }">
                    {{ serviceDescText.isSelected ? "Selected" : "Select" }}
                  </span>
                </div>
              </a>
            </div>
            <div v-else
              class="service__types__btn__cont color-primary p-1 rounded-box theme-text-color position-relative"
              :class="[serviceDescText.isSelected ? 'selected-box' : '']" :style="{
                border:
                  serviceDescText.isSelected && chatThemeSettings && chatThemeSettings.primeColor
                    ? '2px solid ' + primaryColor
                    : '1px solid ' + (chatThemeSettings?.borderColor || '#eee'),
              }">
              <div v-if="serviceDescText.isAppleMobile" class="position-absolute appleWallet-position">
                <a class="appleWallet"
                  :href="`${serviceDescText.appleWalletAPIUrl}/${serviceDescText.templateId}/${serviceDescText.serialNumber}`"
                  @click="onAppleWalletClick(serviceDescText.offerId)">
                  <img :id="serviceDescText.offerId" src="/images/addToAppleWallet.svg"
                    :offerid="serviceDescText.offerId" :templateid="serviceDescText.templateId"
                    :serialnumber="serviceDescText.serialNumber" class="appleWalletImgBtn"
                    :alt="serviceDescText.heading" :title="serviceDescText.heading" />
                </a>
              </div>

              <Transition>
                <div v-if="serviceDescText.isSelected" class="custom-check-box">
                  <span class="primaryicon-financing" />
                </div>
              </Transition>

              <a class="service__types__btn">
                <span class="service__types__btn__iconBox mt-3">
                  <img
                    :src="`https://service.secureoffersites.com/images/getlibraryimage?fileNameOrId=${serviceDescText.imageId}&amp;height=60`"
                    class="img-fluid" alt="addToAppleWallet" />
                </span>
                <div class="card-min-height">
                  <p v-if="serviceDescText.heading" :title="serviceDescText.heading"
                    class="service__types__btn__title text-capitalize text-dark theme-text-color text-ellipsis">
                    <span v-html="serviceDescText.heading" />
                  </p>

                  <p v-if="serviceDescText.amountText"
                    class="service__types__btn__title text-capitalize text-dark theme-text-color">
                    <span>
                      <b class="_theme_font_bold color-black" v-html="trucate(serviceDescText.amountText)" />
                    </span>
                  </p>

                  <p v-if="serviceDescText.description" :title="serviceDescText.description"
                    class="service__types__btn__title text-dark theme-text-color">
                    <span v-if="serviceDescText.description.length > 15">
                      {{ trucate(serviceDescText.description) }}
                    </span>
                    <span v-else>
                      <span v-html="serviceDescText.description" />
                    </span>
                  </p>

                  <div class="text-center py-1" style="line-height: 0.5">
                    <span class="no-hover ft-10 color-black text-underline"
                      @click.stop="viewDetails(serviceDescText)">View
                      Details</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <template #prevArrow="arrowSlotProps">
            <em :disabled="arrowSlotProps.disabled" @click="arrowSlotProps.onClick" class="primaryicon-left-arrow"
              :style="getArrowStyles('left')"></em>
          </template>
        </VSlickCarousel>
      </div>

      <div v-if="serviceOffersData?.length > 3" class="ft-12 page_counter">
        {{ currentSlide }} of {{ serviceOffersData?.length }}
      </div>
    </div>

    <div class="w-100 px-1 d-flex align-items-center flex-wrap justify-content-center">
      <template v-if="buttons != null && buttons.length">
        <div v-for="(button, i) in buttons" :key="i" class="mx-2 mb-3">
          <div v-if="button?.name?.toLowerCase() !== 'menu'"
            class="btn bgcolor-primary rounded-buttons btn-block text-white service__chips__btn px-3"
            :class="{ disabledContent: !isLastIndex || !selectedCoupon || isWaitingForResponse }"
            @click.prevent="optionclick($event, button, selectedCoupon, selectedCouponIndex)">
            {{ button.name }}
          </div>
          <div v-else class="btn bgcolor-primary rounded-buttons btn-block text-white service__chips__btn px-3"
            :class="{ disabledContent: !isLastIndex || selectedCoupon || isWaitingForResponse }"
            @click.once="optionclick($event, button, null, null)">
            {{ button.name }}
          </div>
        </div>
      </template>
    </div>

    <!-- custom modal -->

    <template v-if="viewDetailsModal">
      <div id="viewDetailsModal" class="modal" :style="{ display: viewDetailsModal ? 'block' : 'none' }">
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header align-items-center">
              <h6 class="m-0 _theme_font_bold">Disclosure</h6>
              <div>
                <button type="button" aria-label="Close" class="close" @click.prevent="viewDetailsModal = false">
                  ×
                </button>
              </div>
            </div>
            <div class="p-3 scroll-wrap">
              <p v-if="viewDetailsData.disclaimerText">
                <span v-html="viewDetailsData.disclaimerText" />
              </p>
              <p v-if="viewDetailsData.formattedEndDate" class="m-0">
                - Expires:
                <b class="color-primary theme-text-color">{{ viewDetailsData.formattedEndDate }}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop show" />
    </template>
    <!-- end custom modal -->
  </div>
</template>

<script src="./TvmServiceCoupons.js"></script>

<style scoped>
#tvmServiceCoupons>>>.modal {
  position: absolute !important;
  margin: 10px;
}

#tvmServiceCoupons>>>.modal-body {
  padding: 0px;
}

#tvmServiceCoupons>>>.modal-header {
  padding: 5px;
}

#tvmServiceCoupons>>>.modal-title {
  text-transform: capitalize;
}

#tvmServiceCoupons>>>.modal-backdrop {
  position: absolute !important;
}

.service__types__list .slick-track {
  display: flex !important;
}

.service__types__list .slick-slide {
  height: inherit !important;
}

.card-min-height {
  min-height: 80px;
}

/* .service__types {
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
} */

.service__types__list {
  width: 100%;
  padding: 0 12px;
  margin: 0 0 5px 0;
}

.service__types__list__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.service__types__btn__cont {
  width: 100%;
  border: 1px solid #dfdfdf;
  border-radius: 9px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service__types__btn {
  width: 100%;
  height: 100%;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  border: 0 !important;
  background: none !important;
  outline: 0 !important;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

.ttinfo {
  width: 150px;
  height: auto;
  display: flex;
  position: absolute;
  border: 1px solid red;
  padding: 5px;
}

.service__types__btn__iconBox {
  margin-bottom: 5px;
  margin-right: 0;
  border: 0;
  padding: 2px;
  display: flex;
}

.service__types__btn__title {
  font-size: 10px;
  font-weight: 400;
  margin: 0;
}

.selected-box {
  background-color: #f1f1f1;
  border: 2px solid #f1f1f1;
  transition: background-color 10ms linear;
}

.box-border {
  border: 1px dashed #ccc;
}

.active-border {
  border: 3px solid;
}

.slick-list.draggable {
  height: 100%;
}

.box-bottom {
  bottom: 3px;
  width: 90%;
  left: 14px;
}

#service_offers.service__chips__btn {
  font-size: 9px !important;
  font-weight: 400 !important;
  padding: 2px !important;
  min-width: 56px !important;
}

.chat__subSec .service__chips__btn {
  color: #fff !important;
}

.slick-track {
  display: flex !important;
  align-items: center !important;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  background: #fff;
  color: #000;
  padding: 0.5em 1em;
  border: 1px solid #bbb;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.tooltip.bs-tooltip-bottom .arrow {
  position: relative;
  background: #fff;
  top: 1px;
  width: 16px;
}

.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip.bs-tooltip-bottom .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::after,
.tooltip.bs-tooltip-bottom .arrow::after {
  bottom: 0;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::after,
.tooltip.bs-tooltip-bottom .arrow::after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}

.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip.bs-tooltip-bottom .arrow::before {
  border-color: rgba(187, 187, 187, 0);
  border-bottom-color: #bbb;
  border-width: 9px;
  margin-left: -9px;
}

.list__block {
  display: inline-block;
  width: 100%;
}

.list__data {
  font-size: 0.7em;
  display: block;
  font-weight: bold;
}

.service__types.service_offers::v-deep em.primaryicon-left-arrow.slick-arrow {
  background: #007cc3 !important;
  cursor: pointer;
  right: 0;
  top: 45% !important;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 !important;
  height: 43px !important;
}

.service__types.service_offers::v-deep em.primaryicon-right-arrow.slick-arrow {
  background: #007cc3 !important;
  cursor: pointer;
  right: 0;
  top: 45% !important;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 !important;
  height: 43px !important;
}

.service__types>>>em.primaryicon-left-arrow.slick-disabled,
.service__types>>>em.primaryicon-right-arrow.slick-disabled {
  pointer-events: none;
  opacity: 0;
}

.card-min-height p span {
  color: #343a40 !important;
}

.disabledContent {
  pointer-events: none !important;
  opacity: 0.7 !important;
}

.appleWallet-position {
  left: 0px;
  top: 0px;
  z-index: 9;
}

.appleWallet-position img {
  height: 20px !important;
}

.custom-check-box {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 10px;
  height: 10px;
  background: #000;
  color: #fff;
  line-height: 0;
  display: flex;
  font-size: 8px;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

@media (min-width: 1900px) {
  .service__types__btn__title {
    font-size: 12px;
  }

  .custom-check-box {
    width: 16px;
    height: 16px;
    font-size: 10px;
  }
}

.text-ellipsis {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-underline {
  text-decoration: underline !important;
}

.select_btn {
  padding: 8px 30px;
  border-radius: 30px;
  display: inline-block;
  margin: 20px 0 10px 0;
  min-width: 100px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.service__types::v-deep ul.v-slick-dots {
  display: flex !important;
  list-style: none;
  justify-content: end;
  margin-top: 15px;
  align-items: center;
  margin-right: 57px;
  padding: 0;
  width: calc(100% - 70px);
}

.service__types::v-deep ul.v-slick-dots li {
  margin: 0;
}

.service__types::v-deep ul.v-slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #e1e1e1b8;
  border-radius: 50%;
  /* margin: 0 3px; */
}

#website-mazda-oem #chat-widget .service__types::v-deep ul.slick-dots {
  display: flex !important;
  list-style: none;
  justify-content: end !important;
  margin-top: 20px !important;
  align-items: center;
  margin-right: 57px;
  padding: 0;
  width: calc(100% - 80px);
}

#chat-widget .service__types::v-deep .v-slick-dots li button::before {
  font-size: 0 !important;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  background: #000;
  border-radius: 50%;
}

#website-mazda-oem #chat-widget .page_counter {
  bottom: 4%;
}

.service__types::v-deep ul.slick-dots .slick-active button {
  background-color: #000;
}

.page_counter {
  position: absolute;
  right: 4%;
  bottom: 4%;
  font-weight: bold;
  border-left: 1px solid #e1e1e1d7;
  padding-left: 10px;
  line-height: 1;
}

.modal .scroll-wrap {
  max-height: 50vh;
  overflow-y: auto;
  font-size: 12px;
}

@media (min-width: 1900px) {
  .modal .scroll-wrap {
    font-size: 14px;
  }
}

.opacity_7 {
  opacity: 0.7 !important;
}

.opacity-1 {
  opacity: 1 !important;
}

#chat-widget .v-slick-slider em[disabled=false] {
  display: none !important;
}
</style>
