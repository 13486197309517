<template>
  <div class="in df_message mb-0">
    <div class="mt-2">
      <div class="chat__leftPane mb-1">
        <small class="ml-3 grey-text time__stamp">
          <span class="user__icon__box">
            <svg :fill="chatThemeSettings?.borderColor || '#B3B3B3'" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z" />
            </svg>
          </span>
          <span v-html="clientName" />
        </small>
        <small class="grey-text time__stamp">{{ responseTime }}</small>
      </div>

      <div :id="'fillfilment-' + index" class="chat-body speech-bubble check_fulfillment"
        :class="{ confirmed: slideOutLength }" :style="{ borderColor: chatThemeSettings?.borderColor }">
        <div class="chat-message mb-0">
          <div class="chatbot-response">
            <div v-if="fullFillMentText">
              <span class="inner__bubbleTxt">{{ fullFillMentText }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="clear: both" />

    <!-- color selection component -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'reservevehicle_colorcodes' &&
      isLastIndex
    ">
      <tvm-color-selection :is-waiting-for-response="isWaitingForResponse" :bot-response="responseData.botResponse" />
    </template>
    <!-- end color selection component -->

    <!-- tvm-reserve-your-vehicle-final-view -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'reservevehicle_finalview'
    ">
      <tvm-reserve-your-vehicle-final-view :bot-response="responseData.botResponse" />
    </template>
    <!-- end tvm-reserve-your-vehicle-final-view -->

    <!-- navigate to inventory -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'findavehicle_navigation'
    ">
      <tvm-navigate-to-inventory :chat-theme-settings="chatThemeSettings" :bot-response="responseData.botResponse" />
    </template>
    <!-- end navigate to inventory -->

    <!-- tvm-sell-us-your-car -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'user_confirmation'
    ">
      <tvm-sell-us-your-car :chat-theme-settings="chatThemeSettings" :bot-response="responseData.botResponse" />
    </template>
    <!-- end tvm-sell-us-your-car -->

    <!-- tvm-kbb-trade-value -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm_kbb_trade_value'
    ">
      <tvm-kbb-trade-value :chat-theme-settings="chatThemeSettings" :index="index"
        :bot-response="responseData.botResponse" @TvmKbbOffSetValue="$emit('TvmKbbOffSetValue', $event)" />
    </template>
    <!-- end tvm-kbb-trade-value -->

    <!-- tvm_bb_trade_value -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm_bb_trade_value'
    ">
      <tvm-bb-trade-value :chat-theme-settings="chatThemeSettings" :index="index"
        :bot-response="responseData.botResponse" @TvmKbbOffSetValue="$emit('TvmKbbOffSetValue', $event)" />
    </template>
    <!-- end tvm_bb_trade_value -->

    <!-- tvm_signin_navigation -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm_signin_navigation' &&
      isLastIndex &&
      responseData.isDirect
    ">
      <tvm-sign-in-navigation :bot-response="responseData.botResponse" :is-last-index="isLastIndex"
        :component-data="responseData.botResponse?.component" />
    </template>
    <!-- end tvm_signin_navigation -->

    <!-- tvm-service-coupons -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-servicecoupons'
    ">
      <tvm-service-coupons :chat-theme-settings="chatThemeSettings" :is-waiting-for-response="isWaitingForResponse"
        :count-coupons="responseData.countCoupons" :bot-response="responseData.botResponse" :is-last-index="isLastIndex"
        :index="index" />
    </template>
    <!-- end tvm-service-coupons -->

    <!-- tvm-my-vehicles-->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-my-vehicles' &&
      isLastIndex
    ">
      <tvm-my-vehicles :bot-response="responseData.botResponse" :is-last-index="isLastIndex" />
    </template>
    <!-- end tvm-my-vehicles-->

    <!-- tvm-login-vehicles-->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-login-vehicles' &&
      isLastIndex
    ">
      <tvm-login-vehicles :bot-response="responseData.botResponse" :is-last-index="isLastIndex" />
    </template>
    <!-- end tvm-login-vehicles-->

    <!-- tvm-schedule-service-types -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-scheduleservice-types'
    ">
      <tvm-service-types :chat-theme-settings="chatThemeSettings" :is-waiting-for-response="isWaitingForResponse"
        :bot-response="responseData.botResponse" :is-last-index="isLastIndex" />
    </template>
    <!--end tvm-schedule-service-types -->

    <!-- tvm-service-offers -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-scheduleservice-offers'
    ">
      <tvm-service-offers :chat-theme-settings="chatThemeSettings" :is-waiting-for-response="isWaitingForResponse"
        :bot-response="responseData.botResponse" :is-last-index="isLastIndex" />
    </template>
    <!--end tvm-service-offers -->

    <!-- tvm-dealer-special-offers -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-dealer-specials-offers'
    ">
      <tvm-dealer-special-offers :is-waiting-for-response="isWaitingForResponse"
        :bot-response="responseData.botResponse" :is-last-index="isLastIndex" />
    </template>
    <!--end tvm-dealer-special-offers -->



    <!-- tvm-my-address -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-my-address'
    ">
      <tvm-my-address :chat-theme-settings="chatThemeSettings" :bot-response="responseData.botResponse"
        :is-last-index="isLastIndex" />
    </template>
    <!-- end tvm-my-address -->

    <!-- tvm-start-up-buttons -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-startupbuttons' &&
      responseData.countTileComponent === countTileComponent
    ">
      <tvm-start-up-buttons :chat-theme-settings="chatThemeSettings" :interceptor-name="responseData.interceptorName"
        :is-waiting-for-response="isWaitingForResponse" :bot-response="responseData.botResponse"
        :count-tile-component="responseData.countTileComponent" :is-last-index="isLastIndex"
        @showTextUsForm="$emit('show-text-us-form', $event)" />
    </template>
    <!--end tvm-start-up-buttons -->

    <!-- tvm-multi-select-suggestions -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component && isLastIndex &&
      responseData.botResponse.component.componentName === 'tvm-multi-select-component'
    ">
      <tvm-multi-select-suggestions :chat-theme-settings="chatThemeSettings"
        :is-waiting-for-response="isWaitingForResponse" :bot-response="responseData.botResponse"
        :is-last-index="isLastIndex" />
    </template>
    <!-- end tvm-multi-select-suggestions- -->



    <!-- start tvm-show-color-swatches -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-show-color-swatches' &&
      isLastIndex
    ">
      <tvm-show-color-swatches :is-waiting-for-response="isWaitingForResponse" :bot-response="responseData.botResponse"
        :is-last-index="isLastIndex" />
    </template>
    <!-- end tvm-show-color-swatches -->

    <!-- start tvm-transport-types -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-transport-types' &&
      isLastIndex
    ">
      <tvm-transport-types :is-waiting-for-response="isWaitingForResponse" :bot-response="responseData.botResponse"
        :is-last-index="isLastIndex" />
    </template>
    <!-- end tvm-transport-types -->

    <!-- tvm-webredirect-component -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-webredirect-component'
    ">
      <tvm-webredirect-component :chat-theme-settings="chatThemeSettings" :bot-response="responseData.botResponse"
        :is-direct="responseData.isDirect" />
    </template>
    <!-- end tvm-webredirect-component -->

    <!-- suggestions -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.suggestions &&
      responseData.botResponse.suggestions.length &&
      isLastIndex
    ">
      <tvm-suggestions :interceptor-name="responseData.interceptorName" :is-waiting-for-response="isWaitingForResponse"
        :bot-response="responseData.botResponse" @tvm-suggestions="$emit('tvm-suggestions', $event)"
        @showTextUsOtherForm="$emit('show-text-us-other-form', $event)" />
    </template>
    <!-- end suggestions -->

    <!-- structured suggestions -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-structured-suggestions' &&
      isLastIndex
    ">
      <tvm-structured-suggestions :is-waiting-for-response="isWaitingForResponse"
        :bot-response="responseData.botResponse"
        @tvm-structured-suggestions="$emit('tvm-structured-suggestions', $event)" />
    </template>
    <!-- end structured suggestions -->

    <!-- start tvm-autocomplete-address -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-autocomplete-address' &&
      isLastIndex
    ">
      <tvm-autocomplete-address :search-text="userInputText" :selected-address-value="selectedAutoCompletedValue"
        :bot-response="responseData.botResponse" @selectedInputAddress="$emit('selectedInputAddress', $event)" />
    </template>
    <!-- end tvm-transport-types -->

    <!-- start tvm-ymmt-auto-complete -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-ymmt-auto-complete' &&
      isLastIndex
    ">
      <tvm-ymmt-auto-complete :search-text="userInputText" :selected-ymmt-value="selectedAutoCompletedValue"
        :bot-response="responseData.botResponse" @selectedInputYmmt="$emit('selectedInputYmmt', $event)" />
    </template>
    <!-- end tvm-ymmt-auto-complete -->

    <!-- tvm-page-redirect-component -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-page-redirect-component' &&
      isLastIndex &&
      responseData.isDirect
    ">
      <tvm-page-redirect-component :bot-response="responseData.botResponse" />
    </template>
    <!-- end tvm-page-redirect-component -->

    <!-- start tvm-flow-end-items -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.component &&
      responseData.botResponse.component.componentName === 'tvm-flow-end-items'
    ">
      <tvm-flow-end-items :chat-theme-settings="chatThemeSettings" :is-waiting-for-response="isWaitingForResponse"
        :bot-response="responseData.botResponse" :is-last-index="isLastIndex" />
    </template>
    <!-- end tvm-flow-end-items -->

    <!-- tvm-multi-component -->
    <template v-if="
      responseData.botResponse &&
      responseData.botResponse.multiComponent &&
      responseData.botResponse.multiComponent.length &&
      isLastIndex
    ">
      <div v-for="(item, index) in responseData.botResponse.multiComponent" :key="index">
        <component :is="item.componentName.replaceAll('_', '-')"
          v-if="item.componentName === 'tvm_signin_navigation' && responseData.isDirect"
          :bot-response="responseData.botResponse" :component-data="item" :is-last-index="isLastIndex" />
        <component :is="item.componentName" v-if="item.componentName === 'tvm-user-feedback-prompt'"
          :id="conversationId" :bot-response="responseData.botResponse" :component-data="item"
          :is-last-index="isLastIndex" :feedbacks="itemData.feedbacks" />

        <component :is="item.componentName" v-if="item.componentName === 'tvm-suggestions'"
          :is-waiting-for-response="isWaitingForResponse"
          :bot-response="getTvmSuggestionsResponse(item, responseData.botResponse)"
          @tvm-suggestions="$emit('tvm-suggestions', $event)"
          @showTextUsOtherForm="$emit('show-text-us-other-form', $event)" />
      </div>
    </template>
    <!-- end tvm-multi-component -->



    <!-- end slide-out-panel -->
  </div>
</template>
<script src="./TvmFullFillmentText.js"></script>
<style scoped>
.chat__leftPane {
  min-width: 70%;
}

.chat-body.speech-bubble {
  float: left;
  min-width: 120px;
  min-height: 30px;
  max-width: 76%;
  background: #f3f3f3;
  margin-bottom: 10px;
  color: #000;
}

.time-text {
  font-size: 12px;
}

.speech-bubble {
  position: relative;
  padding: 10px 20px;
  background-color: #fff;
  color: #000;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.095);
  border-left: 0 !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.speech-bubble:before,
.speech-bubble:after {
  content: "\0020";

  position: absolute;
  top: -12px;
  left: 20px;
  z-index: 2;
  width: 0;
  height: 0;
  overflow: hidden;
  border: solid 14px transparent;
  border-top: 0;
  border-bottom-color: #fff;
  display: none;
}

.speech-bubble:before {
  top: -14px;
  z-index: 1;
  border-bottom-color: rgba(0, 0, 0, 0.095);
  display: none;
}

.iframe__noBG {
  background: none;
  border: 0;
  max-width: 100%;
}

.avatar-img {
  width: 230px;
}

.chat-body.text-white {
  color: #fff !important;
}

.chatbot-response .text-white {
  color: #fff !important;
}

.chatbot-response ul {
  display: unset;
  padding: 0;
}

.chatbot-response ul li {
  border: 0;
}

.chatbot-response ul li span {
  font-size: 12px;
}

.fullfill__columns {
  display: inline-block;
  margin: 0;
  width: 100%;
}

ul.chat__grid {
  width: 100%;
  list-style: none;
  padding: 0 30px;
  margin: 0 0 10px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

ul.chat__list {
  width: 100%;
  list-style: none;
  padding: 0 30px;
  margin: 0 0 10px 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

ul.chat__grid .chat__inneritems,
ul.chat__list .chat__inneritems {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fullfill__columns:empty,
ul.chat__grid:empty,
ul.chat__list:empty,
ul.chat__grid .chat__inneritems:empty,
ul.chat__list .chat__inneritems:empty {
  display: none;
}

.fullfill__block {
  display: inline-block;
  margin: 0 0 10px 0;
  width: 100%;
}

.fullfill__block ul.chat__body__boxlist {
  width: 100%;
  list-style: none;
  padding: 0 20px;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.chat__body__boxlist__item:first-child {
  width: 100%;
  display: grid;
  border: 0;
  border-radius: 0;
  padding: 0;
  grid-column: 1/3;
}

.fullfill__block .chat__body__boxlist .chat__body__boxlist__item:first-child {
  width: 100%;
  display: grid;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px;
  grid-column: 1/2;
}

.chat__body__boxlist__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.chat__body__boxlist__item:empty,
.fullfill__block ul.chat__body__boxlist .chat__body__boxlist__item:empty {
  display: none;
}

.chat__body__boxlist__item .custom-payload-con {
  width: 100%;
}

.chat__body__boxlist__item .custom-payload-con:empty,
.fullfill__block .chat__body__boxlist .chat__body__boxlist__item:first-child .custom-payload-con:empty {
  display: none;
}

.chat__inneritems .custom-payload-con:empty {
  display: none;
}

button.chat__body__btn {
  width: 100%;
}

.chat__body__boxlist__item .icon__cont__box {
  margin-bottom: 5px;
  margin-right: 0;
  border: 0;
  padding: 2px;
}

.time__stamp {
  font-size: 10px;
  color: #000;
}

.inner__bubbleTxt {
  font-size: 12px;
}

.chat__body__boxlist__item .custom-payload-con:empty::before {
  display: none;
}

.fullfill__new__block {
  width: 100%;
  padding: 0 20px;
  display: inline-block;
}

.fullfill__new__block:empty {
  display: none;
}

@media all and (min-width: 1900px) {
  .chat-body.speech-bubble {
    width: 100%;
    max-width: 70%;
  }

  .chat__body__boxlist {
    gap: 20px;
  }

  .chat__body__boxlist__item {
    padding: 20px;
  }

  .time__stamp {
    font-size: 12px;
  }

  .inner__bubbleTxt {
    font-size: 14px;
  }

  ul.chat__grid {
    gap: 20px;
    padding: 0 55px;
  }

  ul.chat__grid .chat__grid__item {
    min-height: 100px;
  }
}

@media all and (min-width: 2500px) {
  .chat-body.speech-bubble {
    width: 100%;
    max-width: 70%;
  }

  .chat__body__boxlist {
    gap: 20px;
  }

  .chat__body__boxlist__item {
    padding: 20px;
  }

  .time__stamp {
    font-size: 16px;
  }

  .inner__bubbleTxt {
    font-size: 20px;
  }

  ul.chat__list {
    padding: 0 50px;
    gap: 20px;
  }

  ul.chat__grid .chat__grid__item {
    min-height: 120px;
  }
}

@media all and (min-width: 3800px) {
  .chat-body.speech-bubble {
    width: 100%;
    margin-bottom: 30px;
    max-width: 70%;
  }

  .chat__body__boxlist {
    gap: 20px;
  }

  .chat__body__boxlist__item {
    padding: 20px;
  }

  .time__stamp {
    font-size: 20px;
  }

  .inner__bubbleTxt {
    font-size: 26px;
  }

  ul.chat__list {
    padding: 0 60px;
    gap: 40px;
  }

  ul.chat__grid .chat__grid__item {
    min-height: 150px;
  }
}

.chat__grid.last_full_width .chat__inneritems:last-of-type {
  grid-column: 1 / -1;
}

span.user__icon__box svg {
  height: 15px;
  width: 15px;
  vertical-align: text-bottom;
}

.text_us_option {
  padding: 5px 30px 20px 30px;
}

.text_us_card {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 16px 10px;
  cursor: pointer;
}

.text_us_card img {
  height: 34px;
}

.or_section {
  margin-bottom: 5px;
}

.text_us_option span {
  font-size: 14px;
  font-weight: 400;
}

.text_us_option .or_section span {
  font-size: 13px;
}

@media all and (min-width: 1900px) {
  .text_us_option span {
    font-size: 18px;
    font-weight: 400;
  }

  .text_us_card img {
    height: 40px;
  }

  .text_us_option {
    padding: 5px 55px 20px 55px;
  }

  .chat__grid .custom-payload-con {
    padding: 2px !important;
  }

  .text_us_card {
    padding: 18px 10px;
  }
}
</style>
