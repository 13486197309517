import axios from 'axios';
import eventBus from '../EmitterService/EmitterService';
import moment from 'moment';
import { htmlDecode } from '@/js/services/CommonUtility';
import { DetectUserBrowserWithDevice } from '@/js/services/GetUserAgentDetails';
// import store from '../../store/store';

// import { SET_CHAT_ACTIONS, SET_OLD_TID } from '@/store/StoreConstants/CommonSettingsConstants';
import ChatApi from '@/js/components/shared/ChatWidget/ChatApiInterceptors/ChatApiInterceptors';
// import {
//   fireChatWidgetAgentMessageEvent,
//   fireChatWidgetUserMessageEvent,
// } from '../../Events/TagManagerServices/TaggingEventForPages';
// import { isStellantisAdobeAnalytics } from '@/js/services/AdobeTagManager/Constants/StellantisAdobeTagManagerConstants';
// import { getFormTransactionId } from '@/js/services/Dealer/StellantisService';
import { TvmConstants } from '@/js/components/shared/ChatWidget/TvmConstants';
import { useChatSettingsStore } from '../../store/ChatSettingsStore/ChatSettingsStore';

export class ChatWidgetService {
  constructor() {
    this.key = '';
    this.userInputList = [];
    this.throttlingTimeout = null;
    this.cancelSource = null;
    this.errorCount = 0;
  }
  static chatWidgetApi(request) {
    const date = new Date();
    const requestTime = this.formatAMPM(date);
    window.dispatchEvent(new CustomEvent('tvmLoader', { detail: true }));
    try {
      let postReloadData = sessionStorage.getItem('postreloadintent');
      if (postReloadData && request.Event === 'chat_start') {
        let postReloadDataObject = JSON.parse(postReloadData);
        request.userResponse = postReloadDataObject.buttonName;
        request.SubmitAction = postReloadDataObject.submitAction || '';
      } else if (request.Event === 'chat_start') {
        request.SubmitAction = 'chat_start';
        request.userResponse = 'Start';
      } else {
        // No action needed for other cases
      }

      if (request) request.TID = window.tid;

      if (request.Event === 'main_menu') {
        request.SubmitAction = 'main_menu';
      }
    } catch (err) {
      console.error(`Error on sending message block, Err : ${err}`);
    }

    try {
      let chatSessionCookieId = null;
      let existingCookie = null;
      // let chatSessionCookieData = ChatWidgetService.getChatSessionCookie();
      // let existingCookie = ChatWidgetService.getCookieValueByName('ChatSessionCookie');
      // if (chatSessionCookieData !== null && chatSessionCookieData) {
      //   chatSessionCookieId = chatSessionCookieData;
      // }
      let localChatSessionCookie = localStorage.getItem('localChatSessionCookie');
      if (localChatSessionCookie) {
        existingCookie = JSON.parse(localChatSessionCookie);
        chatSessionCookieId = existingCookie.v;
      }
      let chatSettingsStore = useChatSettingsStore();

      let userDetails = {
        AccountId: window.accountId,
        CampaignId: window.campaignId,
        ConsumerId: window.userDetails?.consumerid || null,
        UserPin: window.userDetails?.userpin,
        Email: this.decodeHtmlEntities(window.userDetails?.email) || '',
        rawCookie: existingCookie ? existingCookie : null,
      };
      request.UserDetails = userDetails;
      request.SessionId = chatSessionCookieId;
      request.IsBot = window.isBot === 'True';
      request.UserDeviceInfo = DetectUserBrowserWithDevice();
      request.RequestedUri = window.location.href;
      request.ModeOfAction =
        chatSettingsStore.tvmChatActions?.ModeOfAction ?? request?.SubmitAction;
      request.OldTid = chatSettingsStore.tvmChatActions?.tvmOldTid || window.tid;
      request.SdSessionId = window.sdSessionId || '';
      request.WebsiteDomain = window.primaryDomain || '';
      // if (isStellantisAdobeAnalytics()) {
      //   request.LeadIdentifier = getFormTransactionId();
      // }
    } catch (e) {
      console.error(`Error on attaching user details to request, Err : ${e}`);
    }
    if (window.isGoogleChatEnabled) {
      let url = window.chatWidgetApiBaseUrl + '/ChatBot/messege';
      if (this.cancelSource) {
        this.cancelSource.cancel(
          `Error:Request canceled due to the user hitting the API multiple times., Request Body: ${JSON.stringify(
            request
          )}`
        );
      }
      this.cancelSource = axios.CancelToken.source();
      let headers = {
        headers: { 'referer-uri': window.location.href },
        cancelToken: this.cancelSource.token,
      };
      // fireChatWidgetUserMessageEvent(request.UserResponse);
      try {
        ChatApi.post(url, request, headers)
          .then((response) => {
            if (response?.data?.responseList?.length) {
              window.dispatchEvent(new CustomEvent('tvmLoader', { detail: false }));
              this.errorCount = 0;
              // store.commit(SET_CHAT_ACTIONS, {});
              // store.commit(SET_OLD_TID, '');
              let chatSettingsStore = useChatSettingsStore();
              chatSettingsStore.tvmChatActions = {};

              let responseData = response.data.responseList;
              responseData.forEach((element, i) => {
                eventBus.$emit('chatResponseDelayLoading', true);
                setTimeout(() => {
                  eventBus.$emit('chatWidgetData', {
                    request: i === 0 ? request : null,
                    // fullfillmentText: response.data.botResponse.queryResult.fulfillmentText,
                    fullfillmentText: element.fulfillmentText,
                    response: element.botResponse.queryResult
                      ? element.botResponse.queryResult.fulfillmentMessages
                      : null,
                    outputContexts: element.botResponse.queryResult
                      ? element.botResponse.queryResult.outputContexts
                      : null,
                    diagnosticInfo: this.getDiagnosticInfo(element.botResponse),
                    requestTime,
                    responseTime: this.formatAMPM(date),
                    responseData: element,
                    responseType: element.responseType,
                    leadIdentifier: request.LeadIdentifier,
                  });
                  // fireChatWidgetAgentMessageEvent(element.fulfillmentText);
                  if (i === responseData.length - 1) {
                    eventBus.$emit('chatResponseDelayLoading', false);
                  }
                }, i * response?.data?.responseDelay || 0);
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.errorCount++;
            request.errorCount = this.errorCount;
            window.dispatchEvent(new CustomEvent('tvmLoader', { detail: false }));
            window.dispatchEvent(new CustomEvent('loadErrorComponent', { detail: request }));
            console.log(this.errorCount);
            if (this.errorCount === 4) {
              this.errorCount = 0;
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  }
  static loadChatWidgetData(sessionId) {
    let url = window.chatWidgetApiBaseUrl + `/ChatBot/messeges?SessionId=${sessionId}`;

    axios
      .get(url)
      .then((response) => {
        eventBus.$emit('getChatWidgetData', response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static async loadChatWithSessionId(sessionId, oldsid) {
    try {
      let url =
        window.chatWidgetApiBaseUrl +
        `/ChatBot/messeges/${window.accountId}?sessionId=${sessionId}&oldSessionId=${oldsid || ''}`;

      let response = await ChatApi.get(url);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }

  static loadChatWidgetDataAfterLogin(sessionId, callback) {
    let url = window.chatWidgetApiBaseUrl + `/ChatBot/messeges?SessionId=${sessionId}`;

    axios
      .get(url)
      .then((response) => {
        eventBus.$emit('getChatWidgetData', response);
        callback(true);
      })
      .catch((error) => {
        callback(false);
        console.log(error);
      });
  }

  static getDiagnosticInfo(data) {
    if (data?.queryResult?.diagnosticInfo) {
      return data.queryResult.diagnosticInfo;
    }
  }
  static setSessionKey(key) {
    this.key = key;
  }
  static getSessionKey() {
    return this.key;
  }
  static formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  static getSessionId(sessiontime) {
    let sessionId = '';
    let userpin = window.userDetails.userpin;
    let accountId = window.accountId;
    if (window.isauthenticated === 'True') {
      let email = this.decodeHtmlEntities(window.userDetails.email);
      sessionId = `auth~${sessiontime}auth~${parseInt(
        window.campaignId
      )}~${email}~${userpin}~${accountId}`;
    } else {
      sessionId = `~${sessiontime}~${parseInt(window.campaignId)}~~~${accountId}`;
    }
    return sessionId;
  }

  static checkForWidgetSession() {
    return !!sessionStorage.getItem('isChatWidgetOpened');
  }
  static setChatWidgetSession() {
    let key = 'isChatWidgetOpened';
    sessionStorage.setItem(key, true);
  }
  static closeChatWidgetSession() {
    let key = 'isChatWidgetOpened';
    sessionStorage.removeItem(key);
  }
  static showLayout(val) {
    let key = 'showLayout';
    sessionStorage.setItem(key, val);
  }
  static closeShowLayout() {
    let key = 'showLayout';
    sessionStorage.removeItem(key);
  }
  static postTvmMessage(
    userResponse,
    event,
    submitFlow,
    submitAction,
    specialObject,
    customUIUserResponse
  ) {
    let fromUser = 'user';
    let toUser = 'Virtual Assistant';
    let consumerId = 0;
    if (window.userDetails && (window.userDetails.firstname || window.userDetails.lastname)) {
      fromUser = `${window.userDetails.firstname} ${window.userDetails.lastname}`;
      consumerId = window.userDetails.consumerid;
    }

    if (window.clientName) {
      toUser = htmlDecode(window.clientName);
    }

    let tvmRequest = {
      // SessionId: sessionId,
      UserResponse: userResponse,
      Event: event,
      FromUser: fromUser,
      ToUser: toUser,
      ConsumerId: consumerId,
      SubmitFlow: submitFlow || null,
      SubmitAction: submitAction || null,
      SpecialObject: specialObject,
      customUIUserResponse: customUIUserResponse,
    };
    if (this.throttlingTimeout) {
      clearTimeout(this.throttlingTimeout);
    }
    this.throttlingTimeout = setTimeout(() => {
      this.chatWidgetApi(tvmRequest);
    }, 500);
  }
  static setAssistantButtonSession() {
    let key = 'isAssistantButtonSession';
    sessionStorage.setItem(key, true);
  }
  static setFlowIndicator(val) {
    let key = 'setFlowIndicator';
    sessionStorage.setItem(key, val);
  }
  static getFlowIndicator() {
    return sessionStorage.getItem('setFlowIndicator');
  }
  static getChatSessionCookie() {
    let rawCookie = ChatWidgetService.getCookieValueByName('ChatSessionCookie');
    let cookieObj = rawCookie ? JSON.parse(rawCookie) : null;
    if (localStorage.getItem('localChatSessionCookie') && !cookieObj) {
      cookieObj = this.getWithExpiry('localChatSessionCookie');
    }
    if (cookieObj?.v && cookieObj?.e) {
      let cookieExpDate = moment.utc(cookieObj.e);
      let currentDate = moment.utc();
      let differenceMinutes = cookieExpDate.diff(currentDate, 'minutes');
      if (differenceMinutes < TvmConstants.bufferTimeMinitues && !cookieObj.isBuffer) {
        this.setCookie(
          moment.utc().add(TvmConstants.bufferTimeMinitues, 'minutes').toDate().toUTCString(),
          cookieObj.v,
          'bufferTimeAdded'
        );
      }
    }

    let chatSessionCookie = cookieObj?.v;
    let isAuthenticated = window.isauthenticated === 'True';
    if (
      !chatSessionCookie ||
      (isAuthenticated && !chatSessionCookie?.startsWith('auth')) ||
      (!isAuthenticated && chatSessionCookie?.startsWith('auth')) ||
      cookieObj?.consumerid !== window?.userDetails?.consumerid
    ) {
      let expirationDate = moment()
        .add(TvmConstants.expireDate, TvmConstants.expireFormat)
        .utc()
        .toDate()
        .toUTCString();
      // here one is day
      let cookieToken = this.setCookie(expirationDate);
      return cookieToken;
    } else {
      return chatSessionCookie;
    }
  }

  static setCookie(expirationDate, newCookie, bufferTime) {
    // let existingCookie = ChatWidgetService.getCookieValueByName('ChatSessionCookie');
    let cookieToken = newCookie || ChatWidgetService.generateSessionCookie();
    let finalCookie = JSON.stringify({
      v: cookieToken,
      e: `${expirationDate}`,
      consumerid: window.userDetails.consumerid,
      isBuffer: bufferTime ? bufferTime : '',
    });

    // if (existingCookie && JSON.stringify(existingCookie) === JSON.stringify(finalCookie)) {
    //   return cookieToken;
    // }

    localStorage.setItem('localChatSessionCookie', finalCookie);

    let cookieValue = `${finalCookie}; expires=${expirationDate}`;

    document.cookie = `ChatSessionCookie = ${cookieValue}; path=/; SameSite=Lax`;
    return cookieToken;
  }

  static generateSessionCookie() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const currentTimeStamp = `${window.isauthenticated === 'True' ? 'auth' : ''}${moment(
      new Date()
    ).format('MMDYYYYhhmmss')}`;
    const charactersLength = characters.length;
    let counter = 0;
    // here fifty is  length of the cookie
    while (counter < 50 - currentTimeStamp.length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    return `${currentTimeStamp}${result}`;
  }

  static getCookieValueByName(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  static updateOldSessionId(oldCookieId, newCookieId) {
    let url = window.chatWidgetApiBaseUrl + `/ChatBot/updateoldsession`;
    let obj = {
      NewSessionId: newCookieId,
      OldSessionId: oldCookieId,
    };
    return axios.post(url, obj);
  }

  static setFlowActionType(val) {
    let key = 'setFlowActionType';
    sessionStorage.setItem(key, val);
  }
  static getFlowActionType() {
    return sessionStorage.getItem('setFlowActionType');
  }
  static async getAssistantSettings() {
    let url = `${window.chatWidgetApiBaseUrl}/ChatBot/assistantsettings/${window.accountId}/${window.campaignId}`;
    let response = await axios.get(url);
    return response.data;
  }
  static async getAssistantMenu() {
    let url = `${window.chatWidgetApiBaseUrl}/ChatBot/getassistantmenu/${window.accountId}/${window.campaignId}`;
    let response = await axios.get(url);
    return response.data;
  }
  static getAutoCompleteAddress(baseUrl, val) {
    let cookieId = this.getChatSessionCookie();
    let accountId = window.accountId;
    let campaignId = window.campaignId;
    let apiUrl = baseUrl
      .replace('{0}', accountId)
      .replace('{1}', campaignId)
      .replace('{2}', val)
      .replace('{3}', cookieId);
    return axios.get(apiUrl);
  }
  static getAutoCompleteYmmt(baseUrl, val) {
    let accountId = window.accountId;
    let campaignId = window.campaignId;
    let apiUrl = baseUrl.replace('{0}', accountId).replace('{1}', campaignId).replace('{2}', val);
    return axios.get(`${window.chatWidgetApiBaseUrl}${apiUrl}`);
  }

  static assistantFlowOpenThrowCTA(flowCode) {
    if (
      window.assistantSettings?.enableChatAssistant &&
      window.assistantSettings?.flows?.length &&
      flowCode
    ) {
      let eachFlowSettings = window.assistantSettings.flows.find((item) => item.code === flowCode);
      if (eachFlowSettings?.websiteButtonEnabled && eachFlowSettings?.isActive) {
        let assistantCode = {};
        assistantCode.assistant = flowCode;
        assistantCode.ActivityTypes = 'AutoWeb';
        if (
          window.assistantSettings.consumer !== null &&
          window.isauthenticated.toLowerCase() === 'true'
        ) {
          assistantCode.consumer = window.assistantSettings.consumer;
        }
        return assistantCode;
      }
    }
  }

  static decodeHtmlEntities(html) {
    try {
      let doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.documentElement.textContent;
    } catch (error) {
      console.log(error);
    }
  }

  static setChatAssistantPrivacyPolicyCookie(cname, cvalue, exdays) {
    try {
      let d = moment().add(exdays, 'd').toDate();
      let expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    } catch (error) {
      console.log(error);
    }
  }

  static submitFeedback(formData) {
    return axios.post(
      `${window.chatWidgetApiBaseUrl}/api/AssistantServices/submit-feedback`,
      formData
    );
  }

  static getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    let cookieExpDate = new Date(item.e).getTime();
    if (now.getTime() > cookieExpDate) {
      localStorage.removeItem(key);
      return null;
    }

    return item;
  }
}
