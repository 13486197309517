/**
 * IMPORTANT:
 * This file is used both in vue3 and vue2
 * DOnt use vue syntax or store concept in this file
 * Any Clarifications contact Leela Directly
 *
 */
export const SUMMARY_PAYMENTDATA = 'paymentData';
export const SUMMARY_PAYMENT = 'payment';
export const SUMMARY_INCENTIVES = 'incentives';
export const SUMMARY_DISCOUNT = 'discount';
export const SUMMARY_SERVICEPROTECTIONPLAN = 'serviceProtectionPlan';
export const SUMMARY_YOURPRICE = 'yourPrice';
export const SUMMARY_SELECTEDTRADE = 'selectedtrade';
export const SUMMARY_SELECTED_VALUES = 'selectedvalues';

//TradeOwnerShip constants
export const TRADE_VALUE_LABEL_OWNED = 'Owned';
export const TRADE_VALUE_LABEL_LEASED = 'Leased';
export const TRADE_VALUE_LABEL_FINANCED = 'Financed';

export const TRADE_OWNERSHIPS = {
  TRADE_VALUE_LABEL_OWNED,
  TRADE_VALUE_LABEL_LEASED,
  TRADE_VALUE_LABEL_FINANCED,
};

//Payment Configuration Data
export const PAYMENT_CONFIGURATION_SELECTED_PAYMENT = 'selectedPayment';
export const PAYMENT_CONFIGURATION_MILEAGE = 'mileage';
export const PAYMENT_CONFIGURATION_DOWN_PAYMENT = 'downPayment';
export const PAYMENT_CONFIGURATION_TRADE_VALUE = 'tradeValue';
export const PAYMENT_CONFIGURATION_TERM = 'term';

export const PAYMENT_CONFIGURATION_DETAILS = {
  [PAYMENT_CONFIGURATION_SELECTED_PAYMENT]: '',
  [PAYMENT_CONFIGURATION_TERM]: '',
  [PAYMENT_CONFIGURATION_TRADE_VALUE]: '',
  [PAYMENT_CONFIGURATION_DOWN_PAYMENT]: '',
  [PAYMENT_CONFIGURATION_MILEAGE]: '',
};

export const EXPRESS_PAYMENT_TAX_STEP_LABEL = 'taxStep';
export const EXPRESS_PAYMENT_TRADE_STEP_LABEL = 'tradeStep';

export const EXPRESS_PAYMENT_STEPS_DETAILS = {
  [EXPRESS_PAYMENT_TAX_STEP_LABEL]: 1,
  [EXPRESS_PAYMENT_TRADE_STEP_LABEL]: 1,
};

export const SUMMARY_DATA_DETAILS = {
  [SUMMARY_PAYMENTDATA]: '',
  [SUMMARY_PAYMENT]: '',
  [SUMMARY_INCENTIVES]: '',
  [SUMMARY_DISCOUNT]: '',
  [SUMMARY_SERVICEPROTECTIONPLAN]: '',
  [SUMMARY_YOURPRICE]: '',
  [SUMMARY_SELECTEDTRADE]: '',
  [SUMMARY_SELECTED_VALUES]: PAYMENT_CONFIGURATION_DETAILS,
};

export const EXPRESS_SECTION_PAYMENT_BOX = 'express payment box';
export const EXPRESS_SECTION_INCENTIVES_BOX = 'express incentives box';
export const EXPRESS_SECTION_TRADE_VALUES_BOX = 'express trade values box';
export const EXPRESS_SECTION_CREDIT_SCORE_BOX = 'express credit score box';
export const EXPRESS_SECTION_TAXES_BOX = 'express taxes box';
export const EXPRESS_SECTION_SERVICE_PROTECTION_BOX = 'express service protection box';
export const EXPRESS_SECTION_SCHEDULE_DELIVERY_BOX = 'express service schedule delivery box';

export const EXPRESS_TRANSACT_TOTAL_SECTIONS = [
  EXPRESS_SECTION_PAYMENT_BOX,
  EXPRESS_SECTION_INCENTIVES_BOX,
  EXPRESS_SECTION_TRADE_VALUES_BOX,
  EXPRESS_SECTION_CREDIT_SCORE_BOX,
  EXPRESS_SECTION_TAXES_BOX,
  EXPRESS_SECTION_SERVICE_PROTECTION_BOX,
];

export const EXPRESS_PAYMENTS_EVENT_FIRE = 'express payments events fire object data';
export const EXPRESS_PAYMENTS_SET_YOUR_PRICE_DATA = 'express payments set your price data';
export const EXPRESS_PAYMENTS_SET_MAIN_TRADE_VALUE = 'express payments set main trade value';

export const EXPRESS_PAYMENTS_SECTION_CALCULATED = '[express payments] isPaymentSectionCalculated';
export const TRANSACT_CURRENT_VIEWPORT = '[express payments] transact current view port';
export const TRANSACT_DEAL_DETAILS = '[express payments] transact Deal Details';

export const TRANSACT_SET_ACTIVE_SECTION = '[express payments] transact active sections set';
export const TRANSACT_GET_ACTIVE_STEPS = '[express payments] transact get active steps';
export const TRANSACT_SET_ACTIVE_SECTION_LOADED =
  '[express payments] transact active sections set loaded';
export const TRANSACT_GET_ACTIVE_SECTION_LOADED =
  '[express payments] transact active sections get loaded';

export const TRANSACT_ACCESSORIES_LIST = '[express payments] transact accessories list';
export const TRANSACT_SELECTED_ACCESSORIES = '[express payments] transact selected accessories';
export const SET_VEHICLES_LIST_ARRAY = '[express payments] get vehicles list array';

export const SET_ACTIVE_PAYMENT_TABS = '[express payments] set active payment tabs';
export const SET_ZIPCODE_MANUAL_STATUS_CHANGE =
  '[express payments] set zip code manual status change';
