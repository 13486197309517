<template>
  <div>
    <div class="value_your_trade_graphic position-relative animate_bottom">
      <div class="custom_legend">
        <span>Appraisal</span>
      </div>
      <div class="tvm_payment_progress">
        <div class="pt-2 px-3">
          <div class="mb-1">
            <span class="min_100">Estimated Mileage</span> :
            <span class="pl-3 font-weight-bold">{{ responseData.estimated_Mileage || "" }}</span>
          </div>
          <div>
            <span class="min_100">Condition</span> :
            <span class="pl-3 font-weight-bold">{{ responseData.condition || "" }}</span>
          </div>
        </div>
        <div class="px-4 pb-3 pt-2 d-flex justify-content-center">
          <div class="position-relative">
            <img
              class="img-fluid progress_bar"
              src="/images/value-my-trade.png"
              alt="value-my-trade"
            />
            <div class="position-absolute ft-12 left_value">
              <span :class="{ 'font-weight-bold': IsSelectedRough }">${{ rough }}</span>
            </div>
            <div class="position-absolute ft-12 middle_value">
              <span :class="{ 'font-weight-bold': IsSelectedAverage }">${{ average }}</span>
            </div>
            <div class="position-absolute ft-12 right_value">
              <span :class="{ 'font-weight-bold': IsSelectedClean }">${{ clean }}</span>
            </div>
          </div>
        </div>
        <div class="px-3 pb-2">
          <div
            v-if="responseData && responseData.disclaimer"
            class="bb-dis mt-2 ft-12"
            v-html="responseData.disclaimer"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    responseData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      rough: "",
      average: "",
      clean: "",
      IsSelectedRough: false,
      IsSelectedAverage: false,
      IsSelectedClean: false,
    };
  },
  // watch:{
  //   responseData:{
  //     handler(newValue, oldValue) {
  //       if (this.responseData?.bbConditions?.length) {
  //         let data = JSON.parse(JSON.stringify(this.responseData?.bbConditions))
  //         data.forEach((item) => {
  //             console.log(item)
  //             switch (item.Condition) {
  //               case "Rough":
  //                 this.rough = item.Value
  //                 break;
  //               case "Average":
  //                 this.average = item.Value
  //                 break;
  //               case "Clean":
  //                 this.clean = item.Value
  //                 break;
  //               default:
  //                 break;
  //             }
  //           })
  //         }
  //     },
  //   deep:true
  //   }
  // },
  mounted() {
    if (this.responseData?.bbConditions) {
      let data = JSON.parse(this.responseData.bbConditions);
      data.forEach((item) => {
        switch (item.Condition) {
          case "Rough":
            this.rough = item.Value;
            this.IsSelectedRough = item.IsSelected;
            break;
          case "Average":
            this.average = item.Value;
            this.IsSelectedAverage = item.IsSelected;
            break;
          case "Clean":
            this.clean = item.Value;
            this.IsSelectedClean = item.IsSelected;
            break;
          default:
            break;
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
// reuseable css
.value_your_trade_card {
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 5px;
}

.custom_legend {
  position: absolute;
  display: inline-block;
  background: #fff;
  z-index: 9;
  font-size: 12px;
  top: -12px;
  left: 30px;
  padding: 0 6px;
  color: #000;
  opacity: 0.8;
}

.card_font_sizes {
  h5 {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    white-space: initial;
    margin: 0;
  }
  p {
    margin-bottom: 3px;
    font-size: 11px;
    white-space: initial;
  }
}
.min-120 {
  min-height: 100px;
}
.min_100 {
  display: inline-block;
  min-width: 150px;
  font-size: 13px;
}

// end reuseable css
.tvm_payment_progress {
  .value_your_trade_card();
  span {
    font-size: 13px;
  }

  .progress_bar {
    height: 45px;
    object-fit: contain;
  }

  .left_value {
    left: -7px;
    top: 35px;
  }

  .middle_value {
    right: 80px;
    top: 42px;
  }

  .right_value {
    right: -7px;
    top: 35px;
  }

  @media screen and (min-width: 1900px) {
    .middle_value {
      right: 100px;
      top: 46px;
    }
  }
}

.animate_bottom {
  animation: animateBottom 2s;
  transition: 0.5s;
}

@keyframes animateBottom {
  0% {
    transform: translateY(50px);
  }

  100% {
    transform: translateY(0);
  }
}
@media (min-width: 1900px) {
  .custom_legend {
    font-size: 13px;
  }
}
</style>
