//Payment Calculator Details
export const GET_PAYMENT_SETTINGS = 'getpaymentsettings';
export const PAYMENT_SETTINGS_SUCCESS = 'paymentsettingssuccess';
export const GET_PAYMENTS = 'getpayments';
export const SET_LOADING = 'setloading';
export const SET_FILTER_LOADING = 'setFilterLoading';
export const SET_MILES_DATA = 'setmiles';
export const GET_MILES_DATA = 'getmiles';
export const SET_PAYMENT_SETTINGS = 'setpaymentsettings';
export const SET_INTERESTED_LEAD_TYPE = 'set interested lead type';
export const EXPRESS_RESET_DATA = 'Express reset data';
export const EXPRESS_SET_INCENTIVES_SELECTED_ZIPCODE = 'Express set incentives selected zip code';
export const GET_ALL_FORD_DIRECT_QUALIFIED_INCENTIVES = 'getallforddirectqualifiedincentives';
export const SET_ALL_FORD_DIRECT_QUALIFIED_INCENTIVES = 'SETallforddirectqualifiedincentives';
export const REBATE_LABEL = 'rebateLabel';
export const DISCOUNT_LABEL = 'discountLabel';
export const FINAL_PRICE_LABEL = 'finalPriceLabel';
export const SET_AIS_REBATES = 'setRebates';

export const SET_LEASE_DATA = '[payment calculator] set lease data';
export const GET_PAGESTATE_DATA = '[payment calculator] Get Page State Data';
export const SET_COMPLETE_PAYMENT_DETAILS = '[payment calculator] set complete payment details';
export const SET_PAYMENT_CONFIGURATION_DATA = '[payment calculator] set payment configuration';
export const GET_CUSTOMIZED_PAYMENTS = '[payment calculator] get customized payments';
export const SET_CASH_TRADE_VALUE = '[payment calculator] set cash trade value';
export const SET_PAGESTATE_KEY = '[payment calculator] set the page state key';
export const PAYMENT_SET_PREVIOUS_SELECTED_VALUES =
  '[payment calculator] set previous selected values';
export const PAYMENT_SET_INTERESTED_COMPONENT_NAME =
  '[payment calculator] interested component name';
export const PAYMENT_SET_CTA_ACTION_COMPONENT_NAME =
  '[payment calculator] cta action component name';
export const SET_LOCKINPAYMENT_TYPE = '[payment calculator] lock in payment type';
export const PAYMENT_SET_CTA_ACTION_COMPONENT_PROPS =
  '[payment calculator] cta action component props';
export const EXPRESS_TRADE_BACK_CLICKED = '[payment calculator] express trade back clicked';

export const SET_STANDARD_FINANCE_VEHICLE_DATA =
  '[payment calculator] set vehicle standard finance data ';
export const GET_STANDARD_FINANCE_VEHICLE_DATA =
  '[payment calculator] get vehicle standard finance data ';

export const SET_CONDITIONAL_REBATE_INCENTIVES = '[Incentives] set conditional rebate incentives';

//GETTERS
export const GET_SELECTED_PAYMENT_DATA = '[payment calculator] get selected payment data';

//FinancePage Details
export const INCREMENT_FINANCING_STEP = 'incrementCurrentStep';
export const DECREMENT_FINANCING_STEP = 'decrementCurrentStep';
export const SET_FINANCING_STEP = 'setCurrentStep';
export const SET_SUCCESS_MESSAGE = 'setsuccessmessage';
export const SET_COAPPLICANT = 'setCoapplicant';
export const SET_FINANCE_DATA = 'setfinancedata';
export const SUBMIT_FINANCE_DATA = 'submitfinancedata';
export const SUBMIT_FINANCE_PARTIAL_DATA = 'submitfinancepartialdata';
export const SET_ERROR_MESSAGE = 'seterrormessage';
export const SET_LOGLEADSAVE_ID = 'setloadleadsaveid';
export const SET_SELECTEDPAYMENTOPTION = 'setselectedpaymentoption';
export const SET_LEASETAB = 'setLeaseTab';
export const SET_FINANCE_TAB = '[payment calculator] set finance tab';
export const SET_CREDIT_FINANCE_DATA = '[payment calculator] set credit finance tab';
export const SET_CREDIT_APPLICANT_ID = '[credit submit] set credit submit application id';
export const SUBMIT_CREDIT_PARTIAL_DATA = '[credit submit] partial data submit';
export const SHOW_FINANCING_TEXT_MODAL = '[text modal] show financing modal ';
export const SUBMIT_CREDIT_R1_DATA = '[credit submit] submitcreditr1data';
export const SET_CREDIT_R1_ADDITIONAL_DATA = '[credit submit] setcreditr1additionaldata';
export const SUBMIT_CBC_CREDIT_DATA = '[credit submit] submitcbcdata';
export const SET_CBC_CREDIT_DATA = '[cbc credit] setcbcdata';
export const SET_FINANCE_E_SIGN_ERROR = '[finance] esign signature error';
export const SET_FINANCE_CBC_RESPONSE = '[finance] finance cbc response';
export const SET_FINANCE_WEB_SERVICE_PAYLOAD = '[finance] set webservice request';
export const CALL_FINANCE_WEB_SERVICE = '[finance] call finance web service';
export const SET_FINANCE_STEP_TWO_VALIDATION_ERROR = '[finance] step two validation error';
export const SET_FINANCE_STEP_ONE_VALIDATION_ERROR = '[finance] step one validation error';
export const SET_LOG_LEAD_DATA = '[finance] log lead data';
export const CONSUMER_EVENT_CALL = '[finance] consumer event call on success';

//Trade Value Details
export const SET_VEHICLE_DATA = 'vehicledata';
export const INCREMENT_TRADE_VALUE_STEP = 'incrementCurrentStep';
export const DECREMENT_TRADE_VALUE_STEP = 'decrementCurrentStep';
export const SET_TRADE_SEARCH_TYPE = 'searchtype';
export const ON_MY_TRADE_BACK_BTN_CLICKED = 'setbackclick';
export const SET_CURRENT_STEP = 'setmytradecurrentstep';
export const SET_VIN_FILTERS = 'setvinfilters';
export const SET_BLACK_BOOK_FILTERS = 'setblackbookfilters';
export const FIRST_CALL = 'firstcall';
export const VALUE_MYTRADE_SUBMIT = 'valuemytradesubmit';
export const CONTACT_INFO = 'value mytrade contact info';
export const GET_BLACKBOOK_DATA = '[trade value] get black book data';
export const SET_CUSTOM_FEES_DATA_MUTATIONS = '[mutations] set custom fees data mutations';
export const SET_STEP_NAME = '[trade value] set current step name';
export const BLACK_BOOK_SELECTED_MAKE = '[trade value] black book selected make';
export const GTP_SKIP = '[trade value] gtp skip click';
export const IS_GTP_PROCESS = '[trade value] gtp process';
export const GTP_ERROR_MESSAGE = '[trade value] gtp error message';
export const SHOW_LOADER = '[trade value] gtp show loader';
export const GTP_VIN_STATUS_MESSAGE = '[trade value] gtp vin status message';
export const GTP_SKIP_TRADE_PAGE = '[trade value] gtp skip trade page only';

//ExpressPaymentCalculator
export const EXPRESS_SET_SUMMARY_DATA = '[express payment] set summary data';
export const EXPRESS_REPLACE_SUMMARY_DATA = '[express payment] replace summary data';
export const EXPRESS_SET_SELECTED_PAYMENT_OPTION = '[express payment] set selected payment option';
export const EXPRESS_SET_LOADING = '[express Payments] set loading';
export const EXPRESS_SET_LEASE_SETTINGS = '[express payments] set payment lease settings';
export const EXPRESS_SET_FINANCE_SETTINGS = '[express payments] set payment finance settings';
export const EXPRESS_SET_CASH_SETTINGS = '[express payments] set payment cash settings';
export const EXPRESS_SET_SERVICE_PROTECTION_PLANS = '[express payments] service protection plans';
export const EXPRESS_SET_LEASE_INCENTIVES = '[express payments] set lease incentives';
export const EXPRESS_SET_FINANCE_INCENTIVES = '[express payments] set finance incentives';
export const EXPRESS_SET_CASH_INCENTIVES = '[express payments] set cash incentives';
export const EXPRESS_SET_INCETIVES_SELECTED_ZIPCODE = '[express payments] set selected incentives';
export const EXPRESS_SET_SHOW_EXPRESS_CHECKOUT_MODAL =
  '[express payments] show express checkout modal';
export const EXPRESS_SET_BUYER_CALL_PERFORMED = '[express payments] buyer call performed';
export const VEHICLE_DETAILS_PAGE = 'Is Vehicle details Page';

export const EXPRESS_SET_CREDIT_SCORES = '[express payments] set credit scores';
export const EXPRESS_SET_BUYER_CREDIT_SCORE_FORM_DETAILS =
  '[express payments] set buyer credit score form details';

export const EXPRESS_SET_SELECTED_SERVICE_PROTECTION_PLAN =
  '[express payments] set selected service protection plan';
export const EXPRESS_SET_PAYMENT = '[express payments] set payment';
export const EXPRESS_SET_PAYMENT_CONFIGURATION = '[express payments] set payment configuration';
export const EXPRESS_SET_PAYMENTS_LOADED = '[express payments] payments loaded';
export const EXPRESS_SET_VEHICLE_DETAILS = '[express payments] set vehicle data';
export const EXPRESS_SET_REGIONS = '[express payments] set regions';
export const EXPRESS_SET_TAXES_ZIP_CODE = '[express payments] set taxes zip code';
export const EXPRESS_SET_TAXES_ZIP_STATE_SELECTED =
  '[express payments] set taxes zip state selected';
export const EXPRESS_SET_TAXES_ZIP_SELECTED = '[express payments] set taxes zip selected';
export const EXPRESS_SET_SELECTED_CREDITSCORE = '[express payments] set selected credit scores';
export const EXPRESS_SET_SELECTED_TRADE_VALUE = '[express payments] set selected trade value';
export const EXPRESS_SET_PAYMENTS_NOT_FOUND_ERROR = '[express payments] payments not found error';
export const EXPRESS_SET_CALCULATE_PAYMENTS_TAB_CHANGED =
  '[express payments] EXPRESS_SET_CALCULATE_PAYMENTS_TAB_CHANGED';
export const EXPRESS_SET_REGIONS_CHANGE = '[express payments] set regions change data';
export const EXPRESS_SET_FIRST_TIME_TRADE_CALL = '[express payments] set first time trade call';
export const EXPRESS_SET_CREDIT_SCORE_FORM_DETAILS =
  '[express payments] set credit score form details';
export const EXPRESS_GET_CREDIT_SCORE_FORM_DETAILS =
    '[express payments] get credit score form details';
export const EXPRESS_GET_PAYMENT_OPTIONS_LOADED = '[express payments] get payment options loaded';
export const EXPRESS_SET_CHECKBOX_CLICKED = '[express payments] checkbox clicked';
export const EXPRESS_SET_EXPRESS_SAVE_OFFER_DETAILS =
  '[express payments] express save offer Details';
export const EXPRESS_SET_EXPRESS_SHOW_MAIL_MODAL = '[express payments] express show mail modal';
export const EXPRESS_SET_SLIDER_VALUE = '[express payments] express set slider value';

//ExpressPayments Schedule Delivery
export const EXPRESS_SET_INCREMENT_SCHEDULE_DELIVERY_STEPS =
  '[express payments] increment set schedule delivery steps';
export const EXPRESS_SET_DECREMENT_SCHEDULE_DELIVERY_STEPS =
  '[express payments] decrement set schedule delivery steps';
export const EXPRESS_SET_RESET_SCHEDULE_DELIVERY_STEPS =
  '[express payments] decrement reset schedule delivery steps';
export const EXPRESS_SET_SCHEDULE_DELIVERY_DATA = '[express payments] set schedule delivery data';

export const EXPRESS_SET_TRANSACT_SECTION_STEP = '[express payments] set transaction section step';
export const EXPRESS_SET_ALL_TRANSACT_SECTION_STEPS =
  '[express payments] set all transaction section steps';

export const EXPRESS_SET_TRANSACT_TRADE_LEASE_OPTIONS = '[express payments] trade lease options';
export const EXPRESS_SET_TRANSACT_OPENED = '[express payments] transact opened';
export const EXPRESS_TRANSACT_SAVE_OFFER_LOG_LEAD_ITEM_ID =
  '[express payments] transact save offer log lead item id';

//DispatchConstants
export const EXPRESS_CALL_PAYMENTS_DATA = '[express payments] call express payments data';
export const EXPRESS_CALL_EXPRESS_PAYMENT_SETTINGS_DATA =
  '[express payments] call express payments settings data';
export const EXPRESS_CALL_TAXES_FEES_REGIONS =
  '[express payments] get express call taxes fees regions';

//Getter Constants
export const EXPRESS_GET_SELECTED_SETTINGS = '[express payments] get selected settings';
export const EXPRESS_GET_ACCESSORIES = '[express payments] get Accessories';
export const EXPRESS_GET_SELECTED_INCENTIVES = '[express payments] get selected incentives';
export const EXPRESS_GET_INCENTIVES = '[express payments] get incentives';
export const EXPRESS_GET_SELECTED_SERVICE_PROTECTION_PLANS =
  '[express payments] get selected service protection plans';
export const EXPRESS_GET_CALCULATED_MONTHLY_PAYMENT =
  '[express payments] get calculated monthly payment';
export const EXPRESS_GET_SELECTED_SUMMARY_DATA = '[express payments] get selected summary data';
export const EXPRESS_SET_CURRENT_VEHICLE_DETAILS = '[express payments] set current vehicle details';
export const EXPRESS_SET_DISCOUNT_INCENTIVES_LOADED =
  '[express payments] set discounts incentives loaded';
export const EXPRESS_SET_STEPS_LIST = '[express payments] set steps list';
export const EXPRESS_SET_SLIDER_SELECTED_TRADE_VALUE =
  '[express payments] set slider selected trade value';
export const EXPRESS_SET_TRADE_SELECTED_OPTION_VALUES =
  '[express payments] set trade selected option values';

export const EXPRESS_SET_SELECTED_INCENTIVES_AMOUNT =
  '[express payments] set selected incentives amount';
export const EXPRESS_SET_APPLIED_OPTION_MODAL_CLOSED =
  '[express payments] set applied option modal';

//Schedule Test Drive Details

export const DECREMENT_SCHEDULE_TEST_DRIVE_STEP = '[schedule test drive] decrement step';
export const INCREMENT_SCHEDULE_TEST_DRIVE_STEP = '[schedule test drive] increment step';
export const SCHEDULE_TEST_DRIVE_CURRENT_COMPONENT = '[schedule test drive] current component';
export const SCHEDULE_TEST_DRIVE_AT_DEALERSHIP = '[schedule test drive] drive at dealership';
export const SCHEDULE_TEST_DRIVE_VEHICLE_DETAILS = '[schedule test drive] vehicle details';
export const SCHEDULE_TEST_DRIVE_SET_LOADING = '[schedule test drive] set loading';
export const SCHEDULE_TEST_DRIVE_SET_ADDRESS_EMPTY = '[schedule test drive] address empty';
export const SCHEDULE_TEST_DRIVE_VEHICLE_AS_PARAM = '[schedule test drive] vehicle param';
export const SCHEDULE_TEST_DRIVE_SETTINGS = '[schedule test drive] settings';
export const SCHEDULE_TEST_DRIVE_GEO_LOCATION = '[schedule test drive] geo location';
export const SCHEDULE_TEST_DRIVE_BOTDOC_DATA = '[schedule test drive] bot doc data';
export const PAYMENT_CALCULATOR_SHOW_MAX_DOWN_PAYMENT_MESSAGE =
  '[payment calculator] show max down payment message';

//payment calculator overlay

export const SET_PAYMENTS_OVERLAY_DATA = '[payment calculator] overlay data';
export const SET_UNLOCK_PRICE_COOKIE = '[payment calculator] set cookie';
export const SET_UNLOCK_PRICE_COOKIE_VIN = '[payment calculator] set cookie vin';
export const SET_TRANSACT_UNLOCK_FORM_MODAL = '[transact unlock form] set navigation';
export const GET_UNLOCK_PRICE_COOKIE = '[payment calculator] get cookie';
export const GET_UNLOCK_PRICE_COOKIE_VIN = '[payment calculator] get cookie vin';
export const SHOW_VDP_CONTACT_US = '[payment calculator] show vdp contact us';
export const VDP_TEXT_ME_QUOTE_FROM_PAYMENT_PANEL = '[payment calculator] text me a quote';
export const REQUEST_VIDEO = '[payment calculator]  request video';
export const SHOW_VDP_CONTACT_US_ZINDEX = '[payment calculator] show vdp contact us zindex';
export const VDP_CONTACT_FROM_TRANSACT = '[payment calculator] vdp contact from transact';
//price discount for cash tab from inventory overlay setting
export const SET_UNLOCK_CASH_PRICE_DISCOUNT = '[payment calculator] cash price payment discount';
export const CASH_PRICE_DISCOUNT = '[payment calculator] cash price payment overlay discount';
//this is to log diffrent leadid,event id when it is from payment panel
export const VDP_CONTACT_FROM_PAYMENT_PANEL = '[payment calculator] vdp contact from payment panel';
export const SET_VDP_PRINT_DETAILS = '[payment calculator] set vdp price details';
export const SET_VDP_PRINT_CFP_DATA = '[payment calculator] set vdp call for price data';

//Sell us your car Details
export const INCREMENT_SELLUSYOURCAR_STEP = 'incrementSellUsCarCurrentStep';
export const DECREMENT_SELLUSYOURCAR_STEP = 'decrementSellUsCarCurrentStep';
export const SET_SELLUSYOURCAR_STEP = 'setSellUsCarCurrentStep';
export const SET_SELLUSYOURCAR_DATA = 'setsellusyourcardata';
export const SELL_US_CAR_VIN = 'selluscarvin';
export const SELL_US_CAR_INSPECTION_SUCCESS_STEP = 'setSellUsCarInspectionSchedule';

export const SELL_US_CAR_INSPECTION_DELIVERY_DATA =
  '[sell us your car] set inspection delivery data';
export const GET_SELLUSYOURCAR_SETTINGS = 'getsellusyourcarsettings';

//check for user login and stepnavigated from step2
export const USER_LOGIN_FIRST_TIME_CALL = 'userloginfirsttimecall';
//check for sell us your car contact lead submitted or not
export const SELL_US_YOUR_CAR_LEAD_SUBMITTED = 'sellUsYourCarLeadSubmitted';

//Transact POpup

export const TRANSACT_POPUP_SHOW_TRANSACT = '[transact popup] show transact';
export const TRANSACT_POPUP_SHOW_TRANSACT_IFRAME = '[transact popup] show transact iframe';
export const SET_IS_TRANSACT = 'set is transact';
export const EXPRESS_SET_TRADE_START_EVENT = '[express payments] transact trade section start';
export const EXPRESS_SET_PAYMENT_START_EVENT = '[express payments] transact payment section start';
export const SET_SOFT_PULL_VEHICLE_PRICE = '[vehicle] vdp soft pull vehicle price';
export const SET_SOFT_PULL_SETTINGS = '[soft pull] set soft pull settings';
//ROUTE1 DATA
export const SET_COAPPLICANT_PREVIOUS_ADDRESS = 'setCoApplicantPreviousAddress';
export const SET_FINANCE_VEHICLE_DATA_BY_VIN = 'setFinanceVehicleDataByVin';
export const SET_CAMPAIGNID_ACCOUNTID_DEALERGROUP =
  '[dealerGroup]CampaignIdandAccountIdForChildSites';

//Vehicle of interest common for value my trade,sell us your car,finace page
export const VALUE_MY_TRADE_LOG_LEAD_PAYLOAD = '[value my trade] log lead payload';
export const VALUE_MY_TRADE_DEALER_EMAIL_PAYLOAD = '[value my trade] dealer email payload';
export const VALUE_MY_TRADE_CLIENT_EMAIL_PAYLOAD = '[value my trade] client email payload';

export const TRADE_APPRAISAL_AND_SCHEDULE_TIME =
  '[value my trade] trade appraisal and schedule time';

export const SET_VEHICLE_INTREST = '[vehicle]set vehicle intrest data';
export const SET_EVENT_DRIVEN_SESSION_COUNT = '[eventdriven]session count';
export const CLOSE_MODAL_POPUP_TO_OPEN_EVENTDRIVEN = '[close popups]for event driven bonus offer';
//Schedule service Time slot specific settings
export const SET_SCHEDULE_SERVICE_TIME_SLOT_SETTINGS =
  '[schedule service] set specific time slot settings';
export const SET_SCHEDULE_SERVICE_TIME_SLOT_BY_SPECIFIC_DATE =
  '[schedule service] set specific time by date';
export const SET_SCHEDULE_SERVICE_TIME_LIST = '[schedule service] set time list';
export const SET_SCHEDULE_SERVICE_SELECTED_TIME = '[schedule service] set selected time';
export const SELECT_SCHEDULE_SERVICE_TIME_ERROR = '[schedule service] select time error';
export const SCHEDULE_SERVICE_SELECTED_DEALER_TRACK_SERVICES =
  '[schedule service] selected dealer track services';
export const SCHEDULE_SERVICE_SELECTED_SERVICE_WRITER =
  '[schedule service] selected dealer track service writer';
export const SCHEDULE_SERVICE_VALIDATION_ERROR_MSG =
  '[schedule service] schedule service error message';
export const SUBMIT_SCHEDULE_SERVICE_DEALER_TRACK =
  '[schedule service] submit schedule service dealer track';
export const SCHEDULE_SERVICE_DEALER_TRACK_RESPONSE =
  '[schedule service]  schedule service dealer track response';
export const SCHEDULE_SERVICE_GENERIC_REQUEST = '[schedule service]  schedule service request';
export const SCHEDULE_DEALER_TRACK_SERVICE_STRING =
  '[schedule service]  schedule dealer track services string';

//selected inventory filter settings
export const GET_FILTERS_SETTINGS = 'getfiltersettings';
export const SET_FILTERS_SETTINGS = 'setfiltersettings';
export const APPLY_FILTERS = 'applyfilters';
export const SET_CURRENTFILTER_URL = 'setcurrentfilterurl';
export const SET_CURRENTFILTER_DROPDOWN_SELECTED = 'setcurrentfilterdropdownselected';
export const INVENTORY_FILTERS_COUNT = 'setFiltersCount';
export const INVENTORY_FILTERS_COUNT_POPUP_MODEL = 'setFiltersCountPopUp';
// Check Availability & Contact-us-vehicle Form Heading And Label
export const SET_FORM_HEADING = 'setformheading';
export const SET_FORM_BUTTON_LABEL = 'setfrombuttonlabel';
export const SET_FORM_ACTION_NAME = 'setfromactionname';

// Check Availability Form Button Color and Font Color
export const SET_FORM_BUTTON_COLOR = 'setformButtonColor';
export const SET_FORM_BUTTON_FONT_COLOR = 'setformButtonFontColor';

//DEALER TRACK
export const DEALER_TRACK_PARTIAL_LEAD = '[finance] dealer track partial lead';
export const DEALER_TRACK_CREATE_FULL_LEAD = '[finance] create dealer track full lead submit';
export const DEALER_TRACK_UPDATE_FULL_LEAD = '[finance] update dealer track full lead submit';
export const DEALER_TRACK_REF_ID = '[finance] dealer track ref id';
export const DEALER_TRACK_RESPONSE = '[finance] dealer track response';
export const DEALER_TRACK_REQUEST = '[finance] dealer track request';
export const DEALER_TRACK_LOG = '[finance] dealer track web service log';
export const E_LEND_DISCLOSURE_DATA = '[finance] e_lend disclosure data';
export const CALL_ELEND_DISCLOSURE_API = '[finance] e_lend disclosure call';

//DRS events
export const SAVE_DRS_PROGRAM_CODES = 'save drs program codes';
export const CLEAR_DRS_PROGRAM_CODES = 'clear drs program codes';

//SmartPath Settings
export const GET_SMARTPATH_UNLOCK_SETTINGS = '[smartpath] get digital garage unlock settings';
export const SET_SMARTPATH_UNLOCK_SETTINGS = '[smartpath] set digital garage unlock settings';
export const SET_SMARTPATH_DEALER_DISCLAIMER_SETTINGS =
  '[smartpath] set dealer disclaimer settings';
export const SET_SMARTPATH_DDOA_DISCLAIMER_SETTINGS = '[smartpath] set DDOA disclaimer settings';

// SRP Model events

export const SET_SHOW_EVN_VIDEO_MODAL = 'setShowEvnVideoModal';
export const SET_VIN_REAL_VIDEO_ID = 'setVinRealVideoId';

export const SET_PAYMENT_FEES_RESULTS = '[payment calculator] set fees results';
export const SET_SHOW_NO_VEHICLE_ERROR = '[schedule test drive] no vehicle error';
export const SET_SDA_INITIATE_FORM_FIRE =
  '[schedule service] set initiateForm fired only first time';
export const SET_SDA_INITIATION_FORM_EVENT_FIRE =
  '[schedule service] set initiateFormEvents fired only first time';
export const SET_PAYMENT_CALCULATOR_INTERACTION_STATUS =
  '[payment calculator] set payment calculator interactions ';
export const SET_SEND_TO_PHONE_VDV = '[payment calculator] vdv send to phone interaction';
export const EXPRESS_SET_SHOW_NAV_LINKS_ON_TRANSACT = 'show navigation links on transact';

export const SET_TRANSACT_PRINT_WINDOW_VARIABLES = '[print] transact print window variables data';
