<template>
  <!-- start appointment_address -->
  <div v-if="responseData && responseData.address" class="appointment_address position-relative p-2 mb-3 animate_bottom">
    <div class="custom_legend">
      <span>{{ responseData.header }}</span>
    </div>
    <div class="row no-gutters align-items-center min-120">
      <div class="col-lg-5 location_icon text-center">
        <i class="primaryicon-map-pin" />
      </div>
      <div class="col-lg-7">
        <div class="mb-1">
          <h5 class="font-weight-bold brake_word"><span>{{ responseData.address }}</span></h5>
        </div>
      </div>
    </div>
  </div>
  <!-- end appointment_address -->
</template>
<script>
export default {
  props: {
    responseData: {
      required: true,
      type: Object,
    }
  },
  data() {
    return {}
  },
}
</script>
<style lang="less" scoped>
// reuseable css
.value_your_trade_card{
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 5px;
}
.custom_legend {
  position: absolute;
  display: inline-block;
  background: #fff;
  z-index: 9;
  font-size: 12px;
  top: -12px;
  left: 30px;
  padding: 0 6px;
  color: #000;
  opacity: 0.8;
}
.card_font_sizes{
  h5 {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    white-space: initial;
    margin: 0;
  }
  p {
    margin-bottom: 3px;
    white-space: initial;
    font-size: 13px;
    text-overflow: ellipsis;
    min-width: 100px;
    overflow: hidden;
  }
}
.min-120{
  min-height: 65px;
}
.ft-c-13{
  font-size: 12px;
}
.max-60{min-width: 60px;display: inline-block}
// end reuseable css
  .appointment_address{
    .value_your_trade_card();
    .card_font_sizes();
    img{
      height: 65px;
      display: block;
      margin: auto;
    }
  }

.brake_word span{
  word-break: break-word;
  display: inline-block;
}
.location_icon i{font-size: 30px; opacity: 0.6;}

.animate_bottom{
  animation: animateBottom 2s;
  transition: 0.5s;
}
@keyframes animateBottom{
  0%{
    transform: translateY(50px);
  }
  100%{
    transform: translateY(0);
  }
}
@media (min-width: 1900px) {
  .appointment_address  {
    h5{
      font-size: 13px;
    }
    p{
      font-size: 12px;
    }
  }
  .custom_legend{
    font-size: 13px;
  }
  .ft-c-13{
    font-size: 13px;
  }
  .location_icon i{font-size: 40px; opacity: 0.6;}
}
@media (min-width: 2500px){
  .appointment_address {
    h5{
      font-size: 16px;
    }
    p{
      font-size: 14px;
    }
  }
  .custom_legend{
    font-size: 16px;
  }
  .ft-c-13{
    font-size: 16px;
  }
  .location_icon i{font-size: 40px; opacity: 0.6;}
}
</style>
