// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tvm_sell_us_your_car .info_card[data-v-eee789e6] {
  border: 1px solid #eee;
  padding: 12px;
  border-radius: 5px;
}
.tvm_sell_us_your_car .info_card b[data-v-eee789e6] {
  font-size: 0.9rem;
}
.tvm_sell_us_your_car .info_card ul li[data-v-eee789e6] {
  font-size: 14px;
}
.tvm_sell_us_your_car .info_card .address_details img[data-v-eee789e6] {
  height: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChatWidget/TvmSellUsYourCar/TvmSellUsYourCar.vue","webpack://./TvmSellUsYourCar.vue"],"names":[],"mappings":"AACA;EAEI,sBAAA;EACA,aAAA;EACA,kBAAA;ACDJ;ADHA;EAOM,iBAAA;ACDN;ADNA;EAYQ,eAAA;ACHR;ADTA;EAkBQ,YAAA;ACNR","sourcesContent":["\n.tvm_sell_us_your_car {\n  .info_card {\n    border: 1px solid #eee;\n    padding: 12px;\n    border-radius: 5px;\n\n    b {\n      font-size: 0.9rem;\n    }\n\n    ul {\n      li {\n        font-size: 14px;\n      }\n    }\n\n    .address_details {\n      img {\n        height: 25px;\n      }\n    }\n  }\n}\n",".tvm_sell_us_your_car .info_card {\n  border: 1px solid #eee;\n  padding: 12px;\n  border-radius: 5px;\n}\n.tvm_sell_us_your_car .info_card b {\n  font-size: 0.9rem;\n}\n.tvm_sell_us_your_car .info_card ul li {\n  font-size: 14px;\n}\n.tvm_sell_us_your_car .info_card .address_details img {\n  height: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
