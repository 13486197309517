<template>
  <div
    :class="[
      desktopsubmenu
        ? 'websiteprimarysubmenu position-absolute submenu-navigation'
        : '',
    ]"
    style="z-index: -1"
  >
    <div
      class="position-fixed w-100 h-100 d-none d-md-inline-block"
      style="z-index: -1"
      @click="closeprimaryResearchModels()"
    />
    <div class="w-100 bg-white">
      <div class="text-center py-3 _theme_font_bold ft-24 choose-model">
        Choose a Model
        <span
          class="close d-none d-md-inline-block mr-5"
          @click="closeprimaryResearchModels()"
        >
          <em class="primaryicon-times ft-15" />
        </span>
      </div>
      <div class="d-flex justify-content-center mb-4 mr-5 pr-3 model-year">
        <a
          v-for="(year, index) in yearList"
          :key="index"
          class="cursor-pointer text-decoration-none position-relative mx-1"
          :class="[currentIndex == index ? 'active' : '']"
          @click="selectYear(index, year, true)"
        >
          <div
            class="switch"
            :class="[currentIndex == index ? 'bgcolor-primary' : '']"
          >
            <span
              class="color-black _theme_font_bold ft-12 btn-websites button-text"
              :class="[currentIndex == index ? 'selected text-white' : '']"
              >{{ year }}</span
            >
          </div>
        </a>
      </div>
      <div
        v-if="
          (menudata && !isMobile) ||
          (menudata && isMobile && filterMenuData && filterMenuData.length)
        "
        class="container"
      >
        <div class="w-100 model-research-slider">
          <VSlickCarousel ref="slick" v-bind="slickOptions">
            <div v-for="(vehicle, index) in filterMenuData" :key="index">
              <div
                class="vehiclepay bg-white border px-4 text-center"
                :class="{ 'cursor-pointer': !isFordOrLincoln }"
                v-on="
                  !isFordOrLincoln
                    ? {
                        click: () =>
                          onExploreBoxClick(
                            $event,
                            vehicle.navigationUrl,
                            vehicle
                          ),
                      }
                    : {}
                "
              >
                <div class="my-3 d-flex vehiclepay-img">
                  <img
                    v-if="useoverrides"
                    class="img-fluid"
                    :src="`${vehicle.imageUrl}&type=jpeg&autocrop=true&angle=${angleValue}&useoverrides=${useoverrides}&width=350&quality=85`"
                    alt="Primary Research Models"
                  />
                  <img
                    v-else
                    class="img-fluid"
                    :src="`${vehicle.imageUrl}&type=jpeg&autocrop=true&angle=${angleValue}`"
                    alt="Primary Research Models"
                  />
                </div>
                <div class="my-3 vehiclepay-title">
                  <span class="ft-14"
                    >{{ vehicle.year }} {{ vehicle.make }}
                    {{ vehicle.model }}</span
                  >
                </div>
                <div class="my-3 research-model-buttons">
                  <a
                    :href="baseUrl + vehicle.navigationUrl.toLowerCase()"
                    class="border no-hover theme_font_bold text-black p-2 px-5 ft-14 rounded-buttons btn-websites button-primary d-inline-block"
                    @click="
                      logTagClickEvents(
                        'Explore',
                        vehicle.navigationUrl,
                        vehicle
                      )
                    "
                  >
                    Explore
                  </a>
                </div>
                <div
                  v-if="enableViewInventoryOnResearchModels"
                  class="my-3 research-model-buttons"
                >
                  <a
                    :href="baseUrl + vehicle.inventoryUrl.toLowerCase()"
                    class="border no-hover theme_font_bold text-black p-2 px-5 ft-14 rounded-buttons btn-websites button-primary d-inline-block"
                    @click="
                      logTagClickEvents(
                        'View Inventory',
                        vehicle.inventoryUrl,
                        vehicle
                      )
                    "
                  >
                    View Inventory
                  </a>
                </div>
                <div
                  v-if="
                    isFordOrLincoln &&
                    enableOem &&
                    !vehicle.hideBuildAndPrice &&
                    !disableBuildAndPrice
                  "
                  class="my-4 research-model-buttons"
                >
                  <a
                    :href="`${getBuildAndPriceUrl(
                      vehicle.buildAndPriceUrl
                    )}${getBuildAndPriceParams(vehicle)}`"
                    class="border no-hover _theme_font_bold text-black p-2 px-5 ft-14 rounded-buttons btn-websites button-tertiary"
                    target="_blank"
                    @click="logBuildAndPriceClickEvents(vehicle)"
                  >
                    Build And Price
                  </a>
                </div>
              </div>
            </div>
          </VSlickCarousel>
        </div>
      </div>
    </div>
  </div>
</template>

  <script type="text/javascript">
import ResearchModels from "../ResearchModels";
export default {
  mixins: [ResearchModels],
  data() {
    return {
      useoverrides: window.digiServiceUseOverrides,
    };
  },
};
</script>

  <style>
.switch-parent:first-child {
  left: 16px;
}
.switch {
  width: 70px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #eaeaea;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-radius: 12px;
  text-align: center;
  left: 16px;
}
.active .switch {
  background-color: #fff;
}

.active {
  position: relative;
  z-index: 1;
}
.websiteprimarysubmenu {
  top: 180px !important;
  left: 0px;
  width: 100%;
  overflow: auto;
  max-height: 70vh;
}

._classic_template .websiteprimarysubmenu {
  top: 157px !important;
}

._website_gemini .websiteprimarysubmenu {
  top: 100px !important;
}
.submenudata {
  width: 200px;
  max-height: 500px;
  overflow-y: auto;
}

.vechicle-menu {
  background-color: rgb(242, 242, 242);
}

.vehiclepay {
  padding: 9px;
  margin: 20px 20px;
  max-width: 300px;
  border-radius: 20px;
}

.vehiclepay img {
  margin: auto;
}

.submenu {
  background-color: #e5e5e5;
}

.vehiclepay a.rounded-buttons {
  color: #000 !important;
  border-color: #000 !important;
  background-color: #fff !important;
}

.slick-arrow.previous-link {
  position: absolute;
  top: 50%;
}

.slick-arrow.next-link {
  position: absolute;
  right: 0px;
  top: 50%;
}

.bgcolor-black {
  background-color: #000;
}

@media (max-width: 768px) {
  .previous-link {
    left: -5px !important;
  }
  .model-research-slider {
    min-height: 240px;
  }

  .model-research-slider .vehiclepay {
    min-height: 240px;
  }
}
.model-research-slider .v-slick-arrow {
  background-color: black;
}
</style>
