<template>
  <!-- my current vehicle -->
  <div class="my_current_vehicle mb-3 animate_bottom">
    <div v-if="vehicleDetails" class="car_info position-relative">
      <div class="custom_legend">
        <span>My Vehicle</span>
      </div>
      <div class="row no-gutters align-items-center min-120">
        <div class="col-lg-5">
          <div class="text-center car_icon">
            <i class="primaryicon-full-car" />
          </div>
        </div>
        <div class="col-lg-7">
          <div class="mb-2">
            <div v-if="!vehicleDetails.vdvUrl">
              <h5 class="font-weight-bold brake_word">
                <span>{{ vehicleDetails.year }}</span> <span>{{ vehicleDetails.make }}</span>
                <span>{{ vehicleDetails.model }}</span> <span>{{ vehicleDetails.trim }}</span>
                <span v-if="vehicleDetails.style">{{ vehicleDetails.style }}</span>
              </h5>
            </div>
            <div v-if="vehicleDetails.vdvUrl">
              <a :href="vehicleDetails.vdvUrl" class="text-decoration-none color-black">
                <h5 class="font-weight-bold brake_word">
                  <span>{{ vehicleDetails.year }}</span> <span>{{ vehicleDetails.make }}</span>
                  <span>{{ vehicleDetails.model }}</span> <span>{{ vehicleDetails.trim }}</span>
                  <span v-if="vehicleDetails.style">{{ vehicleDetails.style }}</span>
                </h5>
              </a>
              <vdv-button :vdv-url="vehicleDetails.vdvUrl" />
            </div>
          </div>
          <div>
            <div class="d-flex flex-wrap justify-content-left">
              <div class="mr-2">
                <p v-if="vehicleDetails.miles" :class="{ flipInX: vehicleDetails.miles }">
                  {{ vehicleDetails.miles }} <span v-if="vehicleDetails.miles">miles</span>
                </p>
              </div>
              <div class="mr-2">
                <p :class="{ flipInX: vehicleDetails.zip }">
                  <span v-if="vehicleDetails.zip">ZIP:</span> {{ vehicleDetails.zip || "" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end my current vehicle -->
</template>
<script>
import TvmVDVIcon from '../../TvmVDVIcon/TvmVDVIcon.vue';

export default {
  components: {
    'vdv-button': TvmVDVIcon
  },
  props: {
    responseData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    vehicleDetails: function () {
      return this.responseData?.vehicleDetails;
    },
  },
};
</script>
<style lang="less" scoped>
// reuseable css
.value_your_trade_card {
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 5px;
}

.custom_legend {
  position: absolute;
  display: inline-block;
  background: #fff;
  z-index: 9;
  font-size: 12px;
  top: -12px;
  left: 30px;
  padding: 0 6px;
  color: #000;
  opacity: 0.8;
}

.card_font_sizes {
  h5 {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    white-space: initial;
    margin: 0;
  }

  p {
    margin-bottom: 3px;
    font-size: 11px;
    white-space: initial;
  }
}

.min-120 {
  min-height: 65px;
}

.brake_word span {
  word-break: break-word;
  display: inline-block;
}

// end reuseable css
.my_current_vehicle {
  .car_info {
    .value_your_trade_card();

    img {
      height: 50px;
      margin: auto;
      display: block;
      object-fit: contain;
    }

    .card_font_sizes();
  }

  & .my_upgrade_vehicle {
    padding: 1px !important;
  }
}

.car_icon i {
  font-size: 30px;
  opacity: 0.6;
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotateX(-5deg);
    transform: perspective(400px) rotateX(-5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotateX(-5deg);
    transform: perspective(400px) rotateX(-5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.animate__flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}

.flipInX {
  animation: flipInX 2s;
}

.animate_bottom {
  animation: animateBottom 2s;
  transition: 0.5s;
}

@keyframes animateBottom {
  0% {
    transform: translateY(50px);
  }

  100% {
    transform: translateY(0);
  }
}

@media (min-width: 1900px) {
  .my_current_vehicle .car_info {
    h5 {
      font-size: 13px;
    }

    p {
      font-size: 12px;
    }
  }

  .custom_legend {
    font-size: 13px;
  }

  .car_icon i {
    font-size: 30px;
  }
}

@media (min-width: 2500px) {
  .my_current_vehicle .car_info {
    h5 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }

  .custom_legend {
    font-size: 16px;
  }

  .car_icon i {
    font-size: 30px;
  }
}
</style>
