<template>
  <div v-if="address && address.length" class="my-3 mx-4">
    <div v-for="(item, index) in address" :key="index">
      <div class="address_card cursor-pointer mb-2 p-2 row no-gutters align-items-center"
        :style="[index === isChecked ? { border: '1px solid' + primaryColor } : { border: '1px solid #eee' }]"
        :class="{ disabledContent: !isLastIndex }" @click.once="eachAddress(item, index)">
        <div class="col-1 text-center">
          <i v-if="index === isChecked" class="primaryicon-tick-icon" :style="{ color: primaryColor }" />
          <i v-else class="primaryicon-map-pin" />
        </div>
        <div class="col">
          <address class="ft-12 mb-0" :style="{ color: primaryColor }">{{ item.shortAddress }}</address>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService';
// import { mapState } from 'vuex';
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['botResponse', 'isLastIndex', 'chatThemeSettings'],
  data() {
    return {
      address: [],
      primaryColor: window.primaryColor,
      isChecked: null,
    }
  },
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state?.commonSettings?.chatThemeSettings;
  //     },
  //   }),
  // },
  mounted() {
    if (this.botResponse?.component?.value?.address?.length) {
      this.address = this.botResponse?.component?.value?.address;
    }
    if (this.chatThemeSettings?.primeColor) {
      this.primaryColor = this.chatThemeSettings.primeColor;
    }
  },
  beforeDestroy() {
    this.$root.$off('isWaitingForResponseData');
  },
  methods: {
    eachAddress(item, index) {
      this.$root.$emit('isWaitingForResponseData', true);
      let specialObject = { TvmComponentRequest: item.id };
      ChatWidgetService.postTvmMessage(item.shortAddress, null, item?.suggestion?.submitFlow, item?.suggestion?.submitAction, specialObject);
      this.isChecked = index;
    }
  },
}
</script>
<style lang="less" scoped>
.address_card {
  border-radius: 5px;
  border: 1px solid #eee;

  &.disabledContent {
    pointer-events: none !important;
    opacity: 0.9 !important;
  }
}
</style>
