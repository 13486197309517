<!--
IMPORTANT:
This file is used only in Vue3 application
Changes made here should be made in vue2 file also
Vue2 File: wwwroot\js\components\Menu\BuildPriceMenu.vue
For any clarifications contact leela directly
-->
<template>
  <div>
    <div class="build-price-menu" v-if="menudata">
      <div class="" v-if="!isMobile">
        <div class="">
          <transition name="slide">
            <ul class="list-unstyled p-0 mobile-submenu w-100">
              <li v-for="(type, index) in menudata" class="submenu-list-item">
                <a
                  @click.prevent="onTypeClick(type.vehicleTypeName)"
                  class="cursor-pointer vehicle-type d-inline-block w-100 bgcolor-primary color-white px-4 py-3"
                  >{{ type.vehicleTypeName }}</a
                >
                <ul class="list-unstyled" v-if="submenuid === type.vehicleTypeName">
                  <li class="" v-for="(groupitemtype, groupitemindex) in type.groupItemsList">
                    <a
                      class="cursor-pointer model-type d-inline-block w-100 hover-color-primary px-4 py-3"
                      style="background-color: rgb(236, 236, 236)"
                      @click.prevent="getVehicleDetails(groupitemtype, groupitemindex)"
                      >{{ groupitemtype.model_Year }} {{ groupitemtype.model_Name }}</a
                    >
                    <div
                      class="img-gallery"
                      v-if="submenuvehicles === groupitemtype.vehicleFullName"
                      style="background-color: #fff"
                    >
                      <div v-if="vehicleList.vehiclePays">
                        <div
                          v-for="item in vehicleList.vehiclePays"
                          class="build-vehicle"
                          style="margin: 10px"
                        >
                          <div class="">
                            <div>
                              <div class="text-center" style="color: #000">
                                <strong>{{ item.trim }} {{ item.styleName }}</strong>
                              </div>
                            </div>
                            <div class="img-width text-center text-center">
                              <img
                                :src="item.imageHandlerPath"
                                class="img-fluid"
                                alt="Vehicle list"
                              />
                            </div>

                            <div
                              class="d-flex text-center inventory-sidemenu-buttons justify-content-center"
                            >
                              <a
                                class="bgcolor-primary mx-1 color-white p-1"
                                v-if="vehicleList.seeInven"
                                style="padding-top: 4px"
                                :href="
                                  (baseUrl + '/inventory/' + vehicleList.seeInven).toLowerCase()
                                "
                                >VIEW INVENTORY</a
                              >
                              <a
                                class="bgcolor-primary mx-1 color-white p-1"
                                :href="
                                  (baseUrl + '/vehicledetails/' + item.buildYourOwn).toLowerCase()
                                "
                                >EXPLORE</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </transition>
        </div>
      </div>

      <!---------------------------------------------------------------------------------- mobile codes--------------------------------------------- -->

      <div class="container-fluid" v-if="isMobile">
        <div class="row bgcolor-primary">
          <div class="col-md-12 text-center fixed-height-vehicle">
            <VSlickCarousel ref="vehicletypeslick" v-bind="vehicletypeslickoptions">
              <span v-for="(type, index) in menudata" class="mt-1">
                <a class="cursor-pointer text-white" @click.prevent="onTypeClick(index)">
                  {{ type.vehicleTypeName }}</a
                >
              </span>
            </VSlickCarousel>
          </div>
        </div>

        <div class="row model-name-mobile fixed-height-vehicle" v-if="grouplist.length">
          <div class="col-12 text-center">
            <VSlickCarousel ref="slickone" v-bind="vehiclemodelslickoptions">
              <span v-for="(item, index) in grouplist" class="mt-1">
                <a
                  class="cursor-pointer color-primary"
                  :class="[
                    'model-type activeModelName',
                    { GroupNameActive: index == isGroupNameIndex },
                  ]"
                  @click.prevent="getVehicleDetails(item, index)"
                  >{{ item.model_Name }}</a
                >
              </span>
            </VSlickCarousel>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 img-gallery">
            <div v-if="vehicleList.vehiclePays">
              <VSlickCarousel ref="slick" v-bind="slickOptions">
                <div v-for="item in vehicleList.vehiclePays" class="build-vehicle">
                  <div class="">
                    <div>
                      <h5 class="text-center">{{ item.modelYear }} {{ item.modelName }}</h5>
                      <h5 class="text-center">{{ item.trim }} {{ item.styleName }}</h5>
                    </div>
                    <div class="img-width text-center ml-2">
                      <img :src="item.imageHandlerPath" class="img-fluid" alt="Vehicle List" />
                    </div>
                    <div class="mb-2 text-center">
                      <a aria-label="Apple"
                        ><i class="fab icon-color text-body fa-apple fa-1x"></i
                      ></a>
                      <a aria-label="Map marker" class="cursor pointer"
                        ><i
                          class="fa icon-color text-body fa-map-marker ml-1 fa-1x"
                          aria-hidden="true"
                        ></i
                      ></a>
                      <a aria-label="Wi-Fi"
                        ><i class="fas icon-color text-body fa-wifi ml-1 fa-1x"></i
                      ></a>
                      <a aria-label="Android"
                        ><i class="fab icon-color text-body fa-android ml-1 fa-1x"></i
                      ></a>
                    </div>
                    <a
                      class="btn-block btn-huge bgcolor-primary"
                      :href="
                        baseUrl +
                        '/inventory/new/' +
                        make +
                        '/' +
                        item.modelName.split(' ')[0] +
                        '/' +
                        item.trim.split(' ')[0].toLowerCase()
                      "
                      >VIEW INVENTORY</a
                    >
                    <a
                      class="btn-block btn-huge bgcolor-primary"
                      :href="baseUrl + '/vehicledetails/' + item.buildYourOwn"
                      >EXPLORE</a
                    >
                  </div>
                </div>
              </VSlickCarousel>
            </div>
          </div>
          <!--group ends-->
        </div>
      </div>
      <!---------------------------------------------------------------------------------- mobie codes--------------------------------------------- -->
    </div>
    <loading-bar v-if="showloading"></loading-bar>
  </div>
</template>

<script>
import "v-slick-carousel/style.css";
import { VSlickCarousel } from "v-slick-carousel";
import axios from "axios";
import LoadingBar from "@/js/components/shared/LoadingBar.vue";
export default {
  components: {
    LoadingBar,
    VSlickCarousel,
  },
  data() {
    return {
      menudata: "",
      showloading: false,
      grouplist: [],
      submenuvehicles: "",
      submenuid: "",
      isActive: false,
      isMobile: false,
      selected: undefined,
      baseUrl: window.baseUrl,
      vehicleList: "",
      make: window.make,
      isGroupNameIndex: undefined,
      vehicletypeslickoptions: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: false,
        prevArrow: `<em class="primaryicon-caret-left ft-24 previous-link slick-mobile-arrows" style="top:9px; left:-15px"></em>`,
        nextArrow: `<em class="primaryicon-caret-right ft-24 next-link slick-mobile-arrows" style="top:9px"></em>`,
      },
      vehiclemodelslickoptions: {
        slidesToShow: 2,
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        prevArrow: `<em class="primaryicon-caret-left ft-24 previous-link slick-mobile-arrows" style="top:9px;left:-15px"></em>`,
        nextArrow: `<em class="primaryicon-caret-right ft-24  next-link slick-mobile-arrows" style="top:9px;"></em>`,
      },
      slickOptions: {
        slidesToShow: 4,
        infinite: false,
        prevArrow: `<em class="primaryicon-caret-left ft-24 previous-link slick-mobile-arrows"></em>`,
        nextArrow: `<em class="primaryicon-caret-right ft-24 next-link slick-mobile-arrows"></em>`,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },

  methods: {
    onTypeClick(submenuid) {
      console.log(submenuid);
      if (this.submenuid === submenuid) {
        this.submenuid = "";
      } else {
        this.submenuid = submenuid;
      }
    },

    getVehicleDetails(vehicleInfo, index) {
      this.isGroupNameIndex = index;
      if (this.submenuvehicles === vehicleInfo.vehicleFullName) {
        this.submenuvehicles = "";
      } else {
        this.submenuvehicles = vehicleInfo.vehicleFullName;
      }

      this.showloading = true;
      this.vehicleList = false;
      axios
        .get(
          `${window.ApiBaseUrl}/VehiclesNewModels/VehicleModelsPaymentOffers?AccountId=${window.accountId}&StyleId=${vehicleInfo.style_ID}
                            &Year=${vehicleInfo.model_Year}&CFModelName=${vehicleInfo.cfModelName}&VehicleType=${vehicleInfo.vehicleTypeName}
                            &ModelName=${vehicleInfo.model_Name}`
        )
        .then((response) => {
          this.vehicleList = response.data;
          this.showloading = false;
        });
    },
  },

  mounted() {
    if (this.menudata === "") {
      this.showloading = true;
      axios
        .get(
          `${window.ApiBaseUrl}/VehiclesNewModels/VehicleModels?themeName=${window.make}&campaignId=${window.campaignId}&accontId=${window.accountId}&showPayments=true`
        )
        .then((response) => {
          this.showloading = false;
          this.menudata = response.data;
        });
    }
  },
};
</script>

<style @scoped>
.horizontal-line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid;
  margin: 1em 0;
  padding: 0;
}

.btn-huge {
  color: #fff;
  text-align: center;
  padding: 4px;
}

.btn-huge:hover {
  color: #fff;
}

.activeModelName {
  padding-bottom: 20px;
  border-bottom: 1px solid;
  line-height: 48px;
}

.vehicle-type {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  color: #fff;
}

.vehicle-type:hover {
  color: #fff;
  text-decoration: none;
}

.model-type {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  color: #000;
  white-space: nowrap;
}

.model-type:hover {
  text-decoration: none;
}

.highlight {
  border: 1px solid;
  padding: 8px;
}

.GroupNameActive {
  border-bottom: 4px solid;
}

.build-price-menu .slick-prev,
.build-price-menu .slick-next {
  background: #ccc;
  color: #fff;
}

.build-price-menu .slick-prev:hover,
.build-price-menu .slick-next:hover,
.build-price-menu .slick-prev:focus,
.build-price-menu .slick-next:focus {
  background: #ccc;
  color: #fff;
}

.previous-link {
  position: absolute;
  cursor: pointer;
  left: -25px;
  top: 50%;
}

.next-link {
  position: absolute;
  cursor: pointer;
  right: -15px;
  top: 50%;
}

.icon-color {
  color: #000;
}

.img-gallery .build-vehicle {
  border: 1px solid #ddd;
  padding: 10px 0px;
  border-radius: 5px;
}

.inventory-sidemenu-buttons a {
  font-size: 10px;
  width: 100px;
}

.img-width {
  width: 80%;
  margin: auto;
}

.slick-slide {
  padding: 4px;
}

.inventory-sidemenu-buttons a:hover {
  color: #fff;
  text-decoration: none;
}
</style>
