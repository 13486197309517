// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.svg_icon svg[data-v-0a49ef56] {
  position: relative;
  bottom: 0px;
  height: 16px;
}
.footer-menu:hover svg[data-v-0a49ef56] {
  bottom: -2px;
  transition: 0.5s;
}
@media (min-width: 1900px) {
.svg_icon svg[data-v-0a49ef56] {
    height: 21px !important;
    margin-bottom: 2px;
}
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChatWidget/ChatIcons/SignInIcon.vue"],"names":[],"mappings":";AAuCA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;AAEA;AACE;IACE,uBAAuB;IACvB,kBAAkB;AACpB;AACF","sourcesContent":["<template>\r\n  <div class=\"svg_icon\">\r\n    <svg :style=\"{ fill: isHoverUser ? themePrimaryColor : chatThemeSettings?.borderColor || themeColor }\"\r\n      xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\">\r\n      <path fill=\"none\" d=\"M0 0h24v24H0z\" />\r\n      <path\r\n        d=\"M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z\" />\r\n    </svg>\r\n  </div>\r\n</template>\r\n\r\n<script>\r\nimport { useChatSettingsStore } from '../../../store/ChatSettingsStore/ChatSettingsStore';\r\nimport { mapWritableState } from 'pinia';\r\n\r\nexport default {\r\n  props: {\r\n    themeColor: {\r\n      type: String,\r\n      required: false,\r\n      default: '#B3B3B3',\r\n    },\r\n    isHoverUser: {\r\n      type: Boolean,\r\n      required: false,\r\n      default: false,\r\n    }\r\n  },\r\n  data() {\r\n    return {\r\n      themePrimaryColor: window.primaryColor,\r\n    }\r\n  },\r\n  computed: {\r\n    ...mapWritableState(useChatSettingsStore, ['chatThemeSettings']),\r\n  },\r\n}\r\n</script>\r\n<style scoped>\r\n.svg_icon svg {\r\n  position: relative;\r\n  bottom: 0px;\r\n  height: 16px;\r\n}\r\n\r\n.footer-menu:hover svg {\r\n  bottom: -2px;\r\n  transition: 0.5s;\r\n}\r\n\r\n@media (min-width: 1900px) {\r\n  .svg_icon svg {\r\n    height: 21px !important;\r\n    margin-bottom: 2px;\r\n  }\r\n}\r\n</style>\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
