import { createApp } from 'vue';
import MainHeader from '@vue3/src/Components/MainHeader/MainHeader';
import CommonComponents from '@vue3/src/Components/CommonComponents/CommonComponents.vue';

import { createPinia } from 'pinia';
import LoadScript from 'vue-plugin-load-script';
import EqualHeightsPlugin from '../../Plugins/EqualHeights/EqualHeightsPlugin';
import VueLazyLoad from 'vue3-lazyload';
import * as Sentry from '@sentry/vue';

const header = {
  data() {
    return {};
  },
  mounted() {},
};

const pinia = createPinia();
const app = createApp(header);

if (window.environment === 'Production') {
  Sentry.init({
    app,
    dsn: window.assistantSentryDns,
    environment: 'Prod',
    trackComponents: true,
    maxValueLength: 8192,
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (error?.message?.match(/UIC93ED1-E854-4807-9CC1-CCE9C220B0UI/i)) {
        return event;
      }
      return null;
    },
  });
}

app.component('main-header', MainHeader);
app.component('common-components', CommonComponents);

app.use(pinia);
app.use(LoadScript);
app.use(EqualHeightsPlugin);
app.use(VueLazyLoad, {
  // options...
});

app.mount('#main-header');
