import { defineStore } from 'pinia';
import {
  PAYMENT_CONFIGURATION_DETAILS,
  SUMMARY_DATA_DETAILS,
} from '../../../../../js/constants/expresspaymentconstants';
import { EXPRESS_GET_SELECTED_SUMMARY_DATA } from '../../../../../store/types';

export const useExpressCheckouStore = defineStore('expresscheckoutstore', {
  state() {
    return {
      showExpressCheckoutModal: false,
      expressPaymentConfiguration: PAYMENT_CONFIGURATION_DETAILS,
      selectedPaymentOption: '',
      selectedAccessories: [],
      summaryData: {
        lease: SUMMARY_DATA_DETAILS,
        finance: SUMMARY_DATA_DETAILS,
        cash: SUMMARY_DATA_DETAILS,
      },
    };
  },
  getters: {
    [EXPRESS_GET_SELECTED_SUMMARY_DATA](state) {
      return state.summaryData[state.selectedPaymentOption];
    },
  },
});
