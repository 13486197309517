/**
 *
 * IMPORTANT:
 * This file is used only in the vue3 application
 * changes made here must be made in vue2 application also
 * file location: wwwroot\js\services\DRSEvents\DRSEventsService.js
 * for any clarifications contact Leela
 *
 */

import { TagManagerContainer } from '../GoogleTagManager/TagManagerContainer';
import { useDrsEventsStore } from '../../store/DRSEventsStore/DRSEventsStore';

export function fireDrsTagManagerEvent(activityName, programCode = '') {
  let tagManagerObject = TagManagerContainer.getDealerObject();
  if (tagManagerObject && typeof tagManagerObject.getDrsEvent === 'function') {
    if (activityName) {
      tagManagerObject.getDrsEvent(activityName, programCode);
    }

    if (programCode) {
      useDrsEventsStore().saveDrsProgramCodes(programCode);
      // store.commit(SAVE_DRS_PROGRAM_CODES, programCode);
      saveDRSActivities(programCode);
    }
  }
}

export function fireDrsCreditApplicationEvent(activityName, programCode = '') {
  const urlParams = new URLSearchParams(window.location.search);
  const drs = urlParams.get('drs');
  if (drs && drs.toLowerCase() === 'true') {
    fireDrsTagManagerEvent(activityName, programCode);
  }
}

export function fireDrsSoftpullOnTransact(programCode = '') {
  if (programCode) {
    saveDrsInStore(programCode);
    // store.commit(SAVE_DRS_PROGRAM_CODES, programCode);
    saveDRSActivities(programCode);
  }
}

export function fireDrsUnlockPriceFormEvent(activityName = '', programCode = '') {
  let tagManagerObject = TagManagerContainer.getDealerObject();
  if (tagManagerObject && typeof tagManagerObject.getDrsEvent === 'function') {
    if (activityName && programCode) {
      tagManagerObject.getDrsEvent(activityName, programCode);
    }

    if (programCode) {
      saveDrsInStore(programCode);
      //store.commit(SAVE_DRS_PROGRAM_CODES, programCode);
      saveDRSActivities(programCode);
    }
  }
}

export function saveDrsInStore(programCode) {
  useDrsEventsStore().saveDrsProgramCodes(programCode);
}

function saveDRSActivities(drsActivity) {
  let cookie = '';

  const value = `; ${document.cookie}`;
  const parts = value.split(`; drsactivitieslist=`);

  if (parts.length === 2) {
    cookie = parts.pop().split(';').shift();
  }
  let activitiesList = [];
  if (cookie) {
    activitiesList = cookie.split('|');
    if (!activitiesList.some((item) => item.toString() === drsActivity.toString())) {
      activitiesList.push(drsActivity);
    }
  } else {
    activitiesList.push(drsActivity);
  }

  let frameCookieData = activitiesList.join('|');
  document.cookie = `drsactivitieslist=${frameCookieData};path=/`;
  document.cookie = `recentdrsactivity=${drsActivity};path=/`;
}
