<template>
  <div>
    <div class="buy_a_vehicle_card animate_bottom position-relative px-2 mb-3">
      <div class="custom_legend d-flex align-items-center">
        <span class="mr-2">Information</span>
        <span class="border_right flex-1 d-block" />
      </div>

      <div class="mt-2">
        <table class="table table-borderless mb-0">
          <!-- <caption class="d-none"></caption>
          <thead class="d-none">
            <th></th>
            <th></th>
          </thead> -->
          <tbody>
            <tr>
              <td>Zip Code</td>
              <td class="font-weight-bold">{{ responseData.zipCode }}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td class="font-weight-bold">{{ responseData.phone }}</td>
            </tr>
            <tr>
              <td>First</td>
              <td class="font-weight-bold">{{ responseData.firstName }}</td>
            </tr>

            <tr>
              <td>Last</td>
              <td class="font-weight-bold">{{ responseData.lastName }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td class="font-weight-bold">{{ responseData.email }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    responseData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      primaryColor: window.primaryColor,
    };
  },
};
</script>
<style lang="less" scoped>
.buy_a_vehicle_card {
  padding: 0 5px;

  .ft-small {
    font-size: 0.75vw;
  }

  .opacity-7 {
    opacity: 0.7;
  }

  .table-borderless>tbody>tr>td,
  .table-borderless>tbody>tr>th,
  .table-borderless>tfoot>tr>td,
  .table-borderless>tfoot>tr>th,
  .table-borderless>thead>tr>td,
  .table-borderless>thead>tr>th {
    border: none;
    padding: 5px;
    font-size: 0.73vw;
    font-weight: 500;
  }

  table.table-borderless tr td {
    max-width: 0;
  }

  table.table-borderless tr td:first-child {
    opacity: 0.6;
  }
}

.border_right {
  display: block;
  width: 100%;
  height: 2px;
  background: #eee;
  position: relative;
  top: 2px;
}

.custom_legend {
  font-size: 0.8vw;
  font-weight: bold;
}

.animate_bottom {
  animation: animateBottom 2s;
  transition: 0.5s;
}

.disclaimer {
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.73vw;
}

@keyframes animateBottom {
  0% {
    transform: translateY(50px);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
