import axios from 'axios';
import 'v-slick-carousel/style.css';
import { VSlickCarousel } from 'v-slick-carousel';
import { useLoader } from '../../../Composables/Loader/useLoader';

export default {
  setup() {
    const { showLoader, hideLoader } = useLoader();
    return {
      showLoader,
      hideLoader,
    };
  },
  props: {
    submenublackcolor: {
      default: false,
    },
    isprimary: {
      default: false,
    },
  },
  data() {
    return {
      useoverrides: window.digiServiceUseOverrides,
      menudata: '',
      submenudata: '',
      baseUrl: window.baseUrl,
      vehicleList: '',
      make: window.make,
      vehicleimage: '',
      isMobile: window.ismobile,
      slickOptions: {
        groupsToShow: 4,
        infinite: false,
        arrows: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              arrows: true,
              groupsToShow: 4,
              groupsToScroll: 1,
              infinite: false,
            },
          },
          {
            breakpoint: 991,
            settings: {
              groupsToShow: 2,
              groupsToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              groupsToShow: 2,
              groupsToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              groupsToShow: 1,
              groupsToScroll: 1,
            },
          },
        ],
      },
    };
  },
  components: {
    VSlickCarousel,
  },

  mounted() {
    this.getVehicleModelsData();
  },
  methods: {
    onMenuDataClick(submenudata) {
      this.submenudata = submenudata;
    },
    onsubmenuitemclick(submenu) {
      this.vehicleList = '';
      axios
        .get(
          `${window.ApiBaseUrl}/VehiclesNewModels/VehicleModelsPaymentOffers?AccountId=${window.accountId}&StyleId=${submenu.style_ID}&Year=${submenu.model_Year}&CFModelName=${submenu.cfModelName}&VehicleType=${submenu.vehicleTypeName}&ModelName=${submenu.model_Name}`
        )
        .then((response) => {
          this.vehicleList = response.data;
        });
    },

    async getVehicleModelsData() {
      this.showLoader('Loading Vehicle Models');
      try {
        const response = await axios.get(
          `${window.ApiBaseUrl}/VehiclesNewModels/VehicleModels?themeName=${window.make}&campaignId=${window.campaignId}&accontId=${window.accountId}&showPayments=true`
        );
        this.menudata = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.hideLoader();
      }
    },

    onVehicleClick(vehicle) {
      this.vehicleimage = vehicle.imageHandlerPath;
    },
    closebuildprice() {
      this.$emit('buildprice');
    },
  },
};
