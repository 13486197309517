<template>
  <!-- my appraisal appointment -->
  <div class="my_appraisal_appointment position-relative p-2 mb-3 animate_bottom">
    <div class="custom_legend">
      <span>{{ responseData.header }}</span>
    </div>
    <div class="row no-gutters align-items-center">
      <div class="col-lg-5">
        <img class="img-fluid" src="/images/chat-widget/Schedule-Service.svg" alt="" />
      </div>
      <div class="col-lg-7">
        <div class="mb-1">
          <h5 class="font-weight-bold"><span>{{ responseData.appointmentDate }}</span></h5>
        </div>
        <div v-if="responseData.service" class="ft-c-13 mb-1 row no-gutters">
          <span class="col-3">Service :</span> <span class="col-9 font-weight-bold">{{ responseData.service }}</span>
        </div>
        <div v-if="responseData.advisorName" class="ft-c-13 mb-1 row no-gutters">
          <span class="col-3">Advisor :</span> <span class="col-9 font-weight-bold">{{ responseData.advisorName
            }}</span>
        </div>
        <div v-if="responseData.appraisal" class="ft-c-13 mb-2">
          <span class="max-60">Appraisal :</span> <span class="font-weight-bold">{{ responseData.appraisal }}</span>
        </div>
        <div>
          <p :title="responseData.firstName + responseData.lastName">{{ responseData.firstName }} {{
            responseData.lastName }}</p>
          <p :title="responseData.email">{{ responseData.email }}</p>
          <p class="m-0">{{ responseData.phoneNumber }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- my appraisal appointment -->
</template>
<script>
export default {
  props: {
    responseData: {
      required: true,
      type: Object,
    }
  },
  data() {
    return {}
  },
}
</script>
<style lang="less" scoped>
// reuseable css
.value_your_trade_card {
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 5px;
}

.custom_legend {
  position: absolute;
  display: inline-block;
  background: #fff;
  z-index: 9;
  font-size: 12px;
  top: -12px;
  left: 30px;
  padding: 0 6px;
  color: #000;
  opacity: 0.8;
}

.card_font_sizes {
  h5 {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    white-space: initial;
    margin: 0;
  }

  p {
    margin-bottom: 3px;
    white-space: initial;
    margin-bottom: 3px;
    font-size: 13px;
    white-space: initial;
    text-overflow: ellipsis;
    min-width: 100px;
    overflow: hidden;
  }
}

.min-120 {
  min-height: 100px;
}

.ft-c-13 {
  font-size: 12px;
}

.max-60 {
  min-width: 60px;
  display: inline-block
}

// end reuseable css
.my_appraisal_appointment {
  .value_your_trade_card();
  .card_font_sizes();

  .success {
    color: green;
  }

  .red {
    color: #FF9800;
  }

  img {
    height: 65px;
    display: block;
    margin: auto;
  }
}

.animate_bottom {
  animation: animateBottom 2s;
  transition: 0.5s;
}

@keyframes animateBottom {
  0% {
    transform: translateY(50px);
  }

  100% {
    transform: translateY(0);
  }
}

@media (min-width: 1900px) {
  .my_appraisal_appointment {
    h5 {
      font-size: 13px;
    }

    p {
      font-size: 12px;
    }
  }

  .custom_legend {
    font-size: 13px;
  }

  .ft-c-13 {
    font-size: 13px;
  }
}

@media (min-width: 2500px) {
  .my_appraisal_appointment {
    h5 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }

  .custom_legend {
    font-size: 16px;
  }

  .ft-c-13 {
    font-size: 16px;
  }
}
</style>
