<template>
  <div>
    <div v-if="!isSubmitted" class="mx-3 p-3 mt-3 mb-4 feedback_widget position-relative">
      <h5 class="font-weight-bold text-center">How would you rate your experience?</h5>
      <div class="feedback-emojis-container">
        <div class="emoji-buttons">
          <button v-for="(emoji, index) in emojis" :key="index" class="emoji-button" @click="activateEmoji(index)"
            @mouseenter="hoverEmoji(index)" @mouseleave="resetHover">
            <div :class="['emoji-wrapper', { 'active-pulse': isActive(index) }]">
              <img :src="currentImage(index)" :alt="emoji.label" class="emoji-image" />
            </div>
            <small :class="{ 'font-weight-bold': isActive(index) }">{{ emoji.label }}</small>
          </button>
        </div>
      </div>
      <div v-if="currentFeedback && currentFeedback.showQuestionier" class="mt-3">
        <div class="form-group">
          <label for="feedback" class="c_font">{{ currentFeedback.question }}</label>
          <textarea id="feedback" v-model="feedback" maxlength="257" placeholder="Write your feedback"
            class="form-control shadow-none form_c_border" name="" rows="3" />
          <div class="d-flex justify-content-between mt-1">
            <small v-if="feedbackError" class="text-danger">{{ feedbackError }}</small>
            <span v-if="feedbackLength" class="badge badge-default bg-white shadow-sm">{{ feedbackLength }}</span>
          </div>
        </div>
        <div class="text-right">
          <button class="btn bgcolor-primary mb-2 rounded-buttons  text-white chips__btn px-4" @click="submit()">
            Submit
          </button>
        </div>
      </div>
      <div v-if="isLoading"
        class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center loading_bar">
        <pre-loader />
      </div>
    </div>
    <div v-if="currentFeedback && currentFeedback.code == 'good' && isSubmitted"
      class="mx-3 p-4 my-3 feedback_widget text-center">
      <em class="primaryicon-tick-icon" />
      <h5 class="mt-3">Happy we could help!</h5>
    </div>
    <div v-if="isSubmitted && currentFeedback && currentFeedback.code !== 'good'"
      class="mx-3 p-4 my-3 feedback_widget text-center">
      <em class="primaryicon-tick-icon" />
      <h5 class="mt-3">Thank you for sharing your valuable feedback!</h5>
    </div>
  </div>
</template>
<script>
import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService.js';
import TvmPreLoader from '../TvmPreLoader/TvmPreLoader.vue';

export default {
  components: {
    'pre-loader': TvmPreLoader
  },
  props: ['botResponse', 'isLastIndex', 'componentData', 'id', 'feedbacks'],
  data() {
    return {
      feedback: '',
      feedbackError: '',
      activeIndex: null,
      hoveredIndex: null,
      isSubmitted: false,
      emojis: [],
      feedbackInfo: [],
      isLoading: false,
      codeMapping: {
        "Poor": "bad",
        "Average": "avg",
        "Excellent": "good"
      },
    };
  },
  computed: {
    currentFeedback() {
      return this.emojis[this.activeIndex];
    },
    feedbackLength() {
      if (this.feedback && this.feedback.length) {
        return this.feedback.length;
      }
      return null
    }
  },
  watch: {
    feedback(val) {
      if (val.length > 256) {
        this.feedbackError = 'Feedback must not exceed 256 characters.';
      } else {
        this.feedbackError = '';
      }
    }
  },
  mounted() {
    if (this.componentData && this.componentData.value && this.componentData.value.lookups && this.componentData.value.lookups.length) {
      let emojisWithImages = [
        {
          label: "Poor",
          default: "/images/chat-widget/poor-default.png",
          hover: "/images/chat-widget/poor-hover.gif",
          active: "/images/chat-widget/poor-active.png",
        },
        {
          label: "Average",
          default: "/images/chat-widget/average-default.png",
          hover: "/images/chat-widget/average-hover.gif",
          active: "/images/chat-widget/average-active.png",
        },
        {
          label: "Excellent",
          default: "/images/chat-widget/good-default.png",
          hover: "/images/chat-widget/good-hover.gif",
          active: "/images/chat-widget/good-active.png",
        },
      ]
      this.emojis = this.componentData.value.lookups;
      this.emojis.forEach(lookup => {
        const matchingEmoji = emojisWithImages.find(emoji => this.codeMapping[emoji.label] === lookup.code);
        if (matchingEmoji) {
          Object.assign(lookup, {
            default: matchingEmoji.default,
            hover: matchingEmoji.hover,
            active: matchingEmoji.active,
            label: matchingEmoji.label
          });
        }
      });
      console.log(this.emojis)
      try {
        if (this.feedbacks?.length) {
          let feedbackId = this.feedbacks[0].feedbackId;
          this.activeIndex = this.emojis.findIndex((item) => {
            return item.feedbackId === feedbackId
          });
          this.isSubmitted = true;
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  methods: {
    currentImage(index) {
      if (this.activeIndex === index) {
        return this.emojis[index].active;
      }
      if (this.hoveredIndex === index) {
        return this.emojis[index].hover;
      }
      return this.emojis[index].default;
    },
    activateEmoji(index) {
      this.activeIndex = index;
      if (this.emojis[index].code === 'good') {
        this.feedbackSubmit();
      }
      this.scrollBottom();
    },
    hoverEmoji(index) {
      this.hoveredIndex = index;
    },
    resetHover() {
      this.hoveredIndex = null;
    },
    isActive(index) {
      return this.activeIndex === index;
    },
    scrollBottom() {
      let container = document.querySelector("#chat__bodyview");
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 200);
      }
    },
    async submit() {
      this.feedbackError = '';
      if (!this.feedback.trim()) {
        this.feedbackError = 'Feedback is required.';
        return;
      }
      if (this.feedback.length > 256) {
        this.feedbackError = 'Feedback must not exceed 256 characters.';
        return;
      }
      this.feedbackSubmit();
    },
    async feedbackSubmit() {
      this.isLoading = true;
      let feedbackObj = {
        ConversationId: this.id || null,
        FeedbackId: null,
        Comments: null,
        SessionId: null,
        TID: window.tid
      }
      let chatSessionCookieData = ChatWidgetService.getChatSessionCookie();
      if (chatSessionCookieData) {
        feedbackObj.SessionId = chatSessionCookieData;
      }
      feedbackObj.FeedbackId = this.currentFeedback.feedbackId;
      feedbackObj.Comments = this.feedback;
      this.feedbackInfo.push(feedbackObj);

      try {
        let postFeedbackInfo = await ChatWidgetService.submitFeedback(this.feedbackInfo);
        console.log(postFeedbackInfo.status)
        if (postFeedbackInfo.status === 204) {
          this.isSubmitted = true;
          this.isLoading = false;
        }
      } catch (error) {
        this.feedbackError = error.message || 'Something went wrong';
        this.isLoading = false;
      }
    }
  },
};
</script>

<style scoped>
.feedback_widget {
  border: 1px solid #eee;
  border-radius: 5px;
  background: #f2f4f6;
}

.loading_bar {
  left: 0;
  top: 0;
  background: #ffffffa3;
}

::v-deep .loading_bar .mb-5 {
  margin-bottom: 0 !important;
}

.c_font {
  font-size: 12px;
}

.feedback_widget h5 {
  font-size: 14px;
}

.feedback_widget em {
  font-size: 30px;
  padding: 10px;
  display: inline-block;
  background: #fff;
  border-radius: 50%;
  animation: pulse 1s 2;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.form_c_border {
  border: 1px solid #eee;
}

.feedback-emojis-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emoji-buttons {
  display: flex;
  justify-content: center;
  column-gap: 10px;
}

.emoji-button {
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
}

.emoji-wrapper {
  padding: 2px;
  border-radius: 50%;
  margin: 5px;
  box-shadow: 0px 2px 4px -1px rgba(175, 182, 201, 0.2);
  transition: 0.1s;
  background: #fff;
}



.emoji-image {
  width: 40px;
  height: 40px;
}

.active-pulse {
  animation: pulse 1s 1;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.emoji-label {
  font-size: 16px;
  color: #333;
}

.emoji-wrapper:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.6);

  }

  70% {
    box-shadow: 0 0 0 15px rgba(0, 123, 255, 0);

  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);

  }
}

#chat-widget .rounded-buttons.chips__btn {
  font-size: 11px !important;
  font-weight: 400 !important;
  padding: 8px 15px !important;
  min-width: 56px !important;
  text-transform: unset !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 1900px) {
  #chat-widget .rounded-buttons.chips__btn {
    font-size: 12px !important;
  }

  .emoji-image {
    width: 50px;
    height: 50px;
  }

  .feedback_widget h5 {
    font-size: 18px;
  }

  .c_font {
    font-size: 14px;
  }
}
</style>
