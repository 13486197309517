<template>
  <div class=" w-100 pt-2 service_offers">
    <div v-if="isLoader" class="py-4 pb-5">
      <tvm-pre-loader />
    </div>
    <div v-if="serviceOffersData != null && serviceOffersData.length === 0 && !isLoader" class="pt-4 pb-0 text-center">
      <h6>Currently there are no coupons available. Please skip and continue.</h6>
    </div>
    <div class="px-3  position-relative">
      <VSlickCarousel v-if="serviceOffersData != null && serviceOffersData.length" ref="slick"
        class="service__types__list" v-bind="slickOptions">
        <template #nextArrow="arrowSlotProps">
          <em :disabled="arrowSlotProps.disabled" @click="arrowSlotProps.onClick" class="primaryicon-right-arrow"
            :style="getArrowStyles('right')"></em>
        </template>
        <div v-for="(serviceDescText, index) in serviceOffersData" :key="index"
          class="service__types__list__item p-1 text-center position-relative">
          <div :class="[{ disabledContent: !isLastIndex }]"
            class="service__types__btn__cont p-1 rounded-box theme-text-color" :style="{
              border: serviceDescText.isSelected
                ? '2px solid ' + primaryColor
                : '1px solid ' + (chatThemeSettings?.borderColor || '#eee'),
            }" :title="serviceDescText.description ? serviceDescText.description : ''"
            @click.prevent="onCouponSelected(serviceDescText, index)">
            <!-- apple wallet -->
            <a class="service__types__btn">
              <span class="service__types__btn__iconBox">
                <img
                  :src="`https://service.secureoffersites.com/images/getlibraryimage?fileNameOrId=${serviceDescText.imageId}&amp;height=60`"
                  class="img-fluid" alt="serviceDescText" />
              </span>
              <div class="card-min-height">
                <p v-if="serviceDescText.heading"
                  class="service__types__btn__title text-capitalize text-dark theme-text-color">
                  <span v-html="serviceDescText.heading" />
                </p>
                <p v-if="serviceDescText.subHeading"
                  class="service__types__btn__title text-capitalize text-dark theme-text-color">
                  <span v-if="serviceDescText.subHeading.length > 15" :title="serviceDescText.subHeading">
                    <span v-html="trucate(serviceDescText.subHeading)" />
                  </span>
                  <span v-else>
                    <span v-html="serviceDescText.subHeading" />
                  </span>
                </p>
                <p v-if="serviceDescText.amountText"
                  class="service__types__btn__title text-capitalize text-dark theme-text-color">
                  <span>
                    <b v-html="trucate(serviceDescText.amountText)" />
                  </span>
                </p>

                <p v-if="serviceDescText.description" class="service__types__btn__title text-dark theme-text-color">
                  <span v-if="serviceDescText.description.length > 15">
                    <span v-html="trucate(serviceDescText.description)" />
                  </span>
                  <span v-else>
                    <span v-html="serviceDescText.description" />
                  </span>
                </p>
              </div>
            </a>
            <!-- apple wallet -->
          </div>
          <div class="text-center py-1 view-details" style="line-height: 0.5">
            <span :style="{ color: primaryColor }" class="ft-10 theme-text-color"
              @click.prevent="viewDetails(serviceDescText)">View Details</span>
          </div>
        </div>
        <template #prevArrow="arrowSlotProps">
          <em :disabled="arrowSlotProps.disabled" @click="arrowSlotProps.onClick" class="primaryicon-left-arrow"
            :style="getArrowStyles('left')"></em>
        </template>
      </VSlickCarousel>
    </div>

    <div class="w-100">
      <div class="mb-2 d-flex flex-wrap align-items-center w-100">
        <div class="min-40">
          <h6 v-if="selectedCoupon" class="list__block pl-2 ft-13 m-0">
            <b> Selected Coupons: </b> <span v-html="selectedCoupon" />
          </h6>
        </div>
      </div>
      <ul v-if="buttons != null && buttons.length"
        class="list-unstyled service__types__list d-flex justify-content-center">
        <li v-for="(button, index) in buttons" :key="index" :class="isLastIndex ? 'mb-4' : 'mb-0'">
          <div class="d-flex flex-wrap justify-content-center">
            <button v-if="isLastIndex"
              class="btn bgcolor-primary mb-2 rounded-buttons mx-2 text-white service__chips__btn"
              :disabled="(selectedCoupon.length == 0 && button.code === 'cnfm') || isDisableBtn"
              @click.once="optionclick(button)">
              {{ button.name }}
            </button>
          </div>
        </li>
      </ul>
    </div>

    <!-- custom modal -->
    <template v-if="viewDetailsModal">
      <div id="viewDetailsModal" class="modal" :style="{ display: viewDetailsModal ? 'block' : 'none' }">
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header align-items-center">
              <h6 class="m-0 _theme_font_bold">Disclosure</h6>
              <div>
                <button type="button" aria-label="Close" class="close" @click.prevent="viewDetailsModal = false">
                  ×
                </button>
              </div>
            </div>

            <div class="p-3 scroll-wrap">
              <p v-if="viewDetailsData.disclaimerText">
                <span v-html="viewDetailsData.disclaimerText" />
              </p>
              <p v-if="viewDetailsData.formattedEndDate" class="m-0">
                - Expires:
                <b class="color-primary theme-text-color">{{ viewDetailsData.formattedEndDate }}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop show" />
    </template>
    <!-- end custom modal -->
  </div>
</template>
<script src="./TvmServiceOffers.js"></script>
<style scoped>
.service__types__list .slick-track {
  display: flex !important;
}

.service__types__list .slick-slide {
  height: inherit !important;
}

.card-min-height {
  min-height: 85px;
}

.service__types {
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.service__types__list {
  width: 100%;
  padding: 0 20px;
  margin: 0 0 5px 0;
}

.service__types__list__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.service__types__btn__cont {
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service__types__btn {
  width: 100%;
  height: 100%;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  border: 0 !important;
  background: none !important;
  outline: 0 !important;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

.ttinfo {
  width: 150px;
  height: auto;
  display: flex;
  position: absolute;
  border: 1px solid red;
  padding: 5px;
}

.service__types__btn__iconBox {
  margin-bottom: 5px;
  margin-right: 0;
  border: 0;
  padding: 2px;
  display: flex;
}

.service__types__btn__title {
  font-size: 10px;
  font-weight: 400;
  margin: 0;
}

.selected-box {
  background-color: #f5f5f5;
  border: 1px solid;
}

.box-border {
  border: 1px solid #ccc;
}

.active-border {
  border: 3px solid;
}

.slick-list.draggable {
  height: 100%;
}

.box-bottom {
  bottom: 3px;
  width: 90%;
  left: 14px;
}

.service__chips__btn {
  font-size: 12px;
  font-weight: 400;
  padding: 6px 12px;
  min-width: 56px;
  height: 32px;
}

.chat__subSec .service__chips__btn {
  color: #fff !important;
}

.slick-track {
  display: flex !important;
  align-items: center !important;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  background: #fff;
  color: #000;
  padding: 0.5em 1em;
  border: 1px solid #bbb;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.tooltip.bs-tooltip-bottom .arrow {
  position: relative;
  background: #fff;
  top: 1px;
  width: 16px;
}

.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip.bs-tooltip-bottom .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::after,
.tooltip.bs-tooltip-bottom .arrow::after {
  bottom: 0;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::after,
.tooltip.bs-tooltip-bottom .arrow::after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}

.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip.bs-tooltip-bottom .arrow::before {
  border-color: rgba(187, 187, 187, 0);
  border-bottom-color: #bbb;
  border-width: 9px;
  margin-left: -9px;
}

.list__block {
  display: inline-block;
  width: 100%;
}

.list__data {
  font-size: 0.7em;
  display: block;
  font-weight: bold;
}

.service__types>>>em.primaryicon-left-arrow.slick-arrow {
  background: #007cc3 !important;
}

.service__types>>>em.primaryicon-right-arrow.slick-arrow {
  background: #007cc3 !important;
}

.service__types>>>em.primaryicon-left-arrow.slick-disabled,
.service__types>>>em.primaryicon-right-arrow.slick-disabled {
  pointer-events: none;
  opacity: 0;
}

.view-details {
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  margin: auto;
}

.service__types>>>.modal {
  position: absolute !important;
  margin: 10px;
}

.service__types>>>.modal-body {
  padding: 0px;
}

.service__types>>>.modal-header {
  padding: 5px;
}

.service__types>>>.modal-title {
  text-transform: capitalize;
}

.service__types>>>.modal-backdrop {
  position: absolute !important;
}

.service__types>>>.modal button:focus {
  box-shadow: none;
  outline: none;
}

.card-min-height p span {
  color: #343a40 !important;
}

.service__types__btn__cont.disabledContent {
  pointer-events: none !important;
  opacity: 0.9 !important;
}

.modal .scroll-wrap {
  max-height: 50vh;
  overflow-y: auto;
  font-size: 12px;
}

@media (min-width: 1900px) {
  .modal .scroll-wrap {
    font-size: 14px;
  }
}

.min-40 {
  min-height: 40px;
  width: 100%;
}

#chat-widget .v-slick-slider em[disabled=false] {
  display: none !important;
}
</style>
