<template>
  <div>
    <div
      v-if="responseData.serviceSpecials && responseData.serviceSpecials.length"
      class="tvm_slide_out_service_special custom_card position-relative animate_bottom py-4 px-"
    >
      <div class="custom_legend">
        <span>My Selected Service Special</span>
      </div>
      <div v-for="(item, index) in responseData.serviceSpecials" :key="index">
        <div class="text-left row no-gutters">
          <div class="col-lg-4 my-auto text-center">
            <div>
              <img class="img-fluid" :src="item.imageUri" alt="" />
            </div>
          </div>
          <div class="col-lg-8">
            <div class="mb-0">
              <p v-if="item.subHeading">{{ item.subHeading || "" }}</p>
              <h3 class="font-weight-bold _theme_font_bold">{{ item.heading || "" }}</h3>
            </div>
            <div>
              <h3 v-if="item.amountText" class="font-weight-bold _theme_font_bold my-1">
                <span v-html="item.amountText" />
              </h3>
              <p v-if="item.description" class="mb-1" :title="item.description">
                <span v-html="truncate(item.description)" />
              </p>
              <div>
                <small
                  v-if="item.disclaimerText"
                  class="m-0 ft-11 d-block"
                  :title="item.disclaimerText"
                >
                  <span>Disclosure:</span>
                  <span v-html="truncate(item.disclaimerText)" />
                </small>
              </div>
            </div>
            <div>
              <p v-if="item.offerText1" class="m-0">{{ item.offerText1 || "" }}</p>
              <p v-if="item.offerText2" class="m-0">{{ item.offerText2 || "" }}</p>
              <p v-if="item.offerText3" class="m-0">{{ item.offerText3 || "" }}</p>
            </div>
          </div>
        </div>
        <div v-if="index !== responseData.serviceSpecials.length - 1" class="border_seperator" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    responseData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      primaryColor: window.primaryColor,
    };
  },
  computed: {
    kbbDetails: function () {
      return this.responseData;
    },
  },
  methods: {
    truncate(value, length = 90) {
      return value.length <= length ? value : value.substring(0, length).replace(/\s+\S*$/, "...");
    },
  },
};
</script>

<style lang="less" scoped>
// reuseable css
.custom_card {
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 5px;
}

.custom_legend {
  position: absolute;
  display: inline-block;
  background: #fff;
  z-index: 9;
  font-size: 12px;
  top: -12px;
  left: 30px;
  padding: 0 6px;
  color: #000;
  opacity: 0.8;
}

.card_font_sizes {
  h3 {
    font-size: 15px;
    font-weight: 600;
    color: #000;
    white-space: initial;
    margin: 0;
  }

  p {
    margin-bottom: 5px;
    font-size: 12px;
    white-space: initial;
  }
}

.min-120 {
  min-height: 120px;
}

.ft-c-25 {
  font-size: 30px;
}

// end reuseable css
.tvm_slide_out_service_special {
  .card_font_sizes();

  table {
    tr {
      td {
        font-size: 10px;
        padding: 2px;
        min-width: 100px;
      }
    }
  }
}

.border_seperator {
  border-bottom: 2px solid #eee;
  margin: 20px 20px;
}

.animate_bottom {
  animation: animateBottom 2s;
  transition: 0.5s;
}

@keyframes animateBottom {
  0% {
    transform: translateY(50px);
  }

  100% {
    transform: translateY(0);
  }
}

@media (min-width: 1900px) {
  .tvm_slide_out_service_special {
    h3 {
      font-size: 20px;
    }

    p {
      font-size: 13px;
    }

    table {
      tr {
        td {
          font-size: 12px;
        }
      }
    }
  }

  .custom_legend {
    font-size: 13px;
  }

  .ft-c-25 {
    font-size: 40px;
  }
}

@media (min-width: 2500px) {
  .tvm_slide_out_service_special {
    h3 {
      font-size: 22px;
    }

    p {
      font-size: 15px;
    }

    table {
      tr {
        td {
          font-size: 13px;
        }
      }
    }
  }

  .custom_legend {
    font-size: 16px;
  }

  .ft-c-25 {
    font-size: 50px;
  }
}
</style>
