<template>
  <div class="dropdown position-relative">
    <ul v-if="suggestions.length" class="suggestions" :style="{ bottom: suggestionBottom }">
      <li v-for="suggestion in suggestions" :key="suggestion.place_id" @click="selectSuggestion(suggestion)">
        {{ suggestion.description }}
      </li>
    </ul>

  </div>
</template>
<script src="./TvmAutoCompleteAddress.js"></script>
<style scoped>
.dropdown {
  width: 100%;
  box-shadow: -1px 0px 5px #bfd3da;
  margin: auto;
  display: block;
  left: 0;
  right: 0;
  border-radius: 0px;
  background: #fff;
}



.dropdown input:focus {
  outline: none;
  box-shadow: none;
}

.suggestions {
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 100%;
  background: #fff;
  width: 100%;
  z-index: 99;
  box-shadow: -3px -3px 5px #bfd3da;
  animation: animated_bottom 0.5s;
  transition: 0.5s;
}

ul.suggestions li {
  list-style: none;
  padding: 8px 20px;
  margin: 0;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s
}

ul.suggestions li:hover {
  background-color: #eee;
}

.dropdown input {
  width: 100%;
  padding: 12px;
  background: #fff;
  border: 0;
  font-size: 15px;
  font-weight: bold;
}

@keyframes animated_bottom {
  0% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0px);
  }
}
</style>
