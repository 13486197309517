<template>
  <div id="black_book" ref="black_book" class="mx-3 my-3">
    <div v-if="year || make || model || style" class="bb-est-con color-primary d-flex justify-content-between ft-14"
      :style="{ color: primaryColor + '!important' }">
      {{ year }} {{ make }} {{ model }} {{ trim }} {{ style }}
      <div v-if="Downloadable">
        <i class="primaryicon-download cursor-pointer" title="Download" @click="download()" />
      </div>
    </div>
    <div v-if="isMobile" class="tvm_payment_progress">
      <div class="px-3 pt-2">
        <table class="bb__table__1" :style="{ borderColor: borderColor + '!important' }">
          <!-- <caption class="d-none">
            Vehicle Data Table
          </caption> -->
          <tbody>
            <tr>
              <th>Estimated Mileage</th>
              <td>:</td>
              <td>{{ estMileage }}</td>
            </tr>
            <tr>
              <th>Condition</th>
              <td>:</td>
              <td>{{ condition }}</td>
            </tr>
            <tr v-if="VIN">
              <th>VIN</th>
              <td>:</td>
              <td>{{ VIN }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pt-2 px-4 pb-4 d-flex justify-content-center">
        <div class="position-relative">
          <img class="img-fluid progress_bar" src="/images/value-my-trade.png" alt="value-my-trade" />
          <div class="position-absolute ft-12 left_value">
            <span>${{ rough }}</span>
          </div>
          <div class="position-absolute ft-12 middle_value">
            <span class="font-weight-bold _theme_font_bold">${{ average }}</span>
          </div>
          <div class="position-absolute ft-12 right_value">
            <span>${{ clean }}</span>
          </div>
        </div>
      </div>
      <div class="px-2 pb-2">
        <div v-if="disclaimer" class="bb-dis mt-2 ft-12" v-html="disclaimer" />
      </div>
    </div>
    <div v-if="nextQuestion && isMobile" class="confirm_section">
      <p class="mb-0 ft-13 font-weight-bold">{{ nextQuestion }}</p>
    </div>
    <div v-if="!isMobile">
      <table class="table trade_details_table my-2" :style="{ borderColor: borderColor + '!important' }">
        <!-- <caption class="d-none">
          Vehicle Data Table
        </caption>
        <tr class="d-none">
          <th>&nbsp;</th>
        </tr> -->
        <tbody>
          <tr>
            <td>Estimated Mileage</td>
            <td>{{ estMileage }}</td>
          </tr>
          <tr>
            <td>Condition</td>
            <td>{{ condition }}</td>
          </tr>
          <tr v-if="VIN">
            <td>VIN</td>
            <td>{{ VIN }}</td>
          </tr>
          <tr>
            <td>Rough</td>
            <td :class="{ 'font-weight-bold _theme_font_bold': IsSelectedRough }">${{ rough }}</td>
          </tr>
          <tr>
            <td>Average</td>
            <td :class="{ 'font-weight-bold _theme_font_bold': IsSelectedAverage }">
              ${{ average }}
            </td>
          </tr>
          <tr>
            <td>Clean</td>
            <td :class="{ 'font-weight-bold _theme_font_bold': IsSelectedClean }">${{ clean }}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="nextQuestion" class="confirm_section">
        <p class="mb-0 ft-13 font-weight-bold">{{ nextQuestion }}</p>
      </div>
    </div>
  </div>
</template>
<script>
// import { mapState } from "vuex";
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["botResponse", "index", "chatThemeSettings"],
  data() {
    return {
      kellyurl: "",
      estMileage: "",
      condition: "",
      disclaimer: "",
      VIN: "",
      year: "",
      make: "",
      model: "",
      trim: "",
      BBConditions: [],
      style: "",
      Downloadable: false,
      rough: "",
      average: "",
      clean: "",
      isMobile: window.ismobile,
      IsSelectedRough: false,
      IsSelectedAverage: false,
      IsSelectedClean: false,
      primaryColor: window.primaryColor,
      nextQuestion: "",
    };
  },
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state?.commonSettings?.chatThemeSettings;
  //     },
  //   }),
  // },
  mounted() {
    if (this.$refs.black_book && this.$refs.black_book.offsetTop) {
      this.$emit("TvmKbbOffSetValue", this.$refs.black_book.offsetTop);
    }
    if (this.botResponse?.component?.value) {
      let disData = this.botResponse.component.value;
      this.kellyurl = disData.uri;
      this.estMileage = disData.estimated_Mileage;
      this.condition = disData.condition;
      this.disclaimer = disData.disclaimer;
      this.VIN = disData.vin;
      this.year = disData.year;
      this.make = disData.make;
      this.model = disData.model;
      this.trim = disData.trim;
      this.style = disData.style;
      this.Downloadable = disData.downlodable;
      if (disData?.bbConditions) {
        this.BBConditions = JSON.parse(disData.bbConditions);
        this.BBConditions.forEach((item) => {
          switch (item.Condition) {
            case "Rough":
              this.rough = item.Value;
              this.IsSelectedRough = item.IsSelected;
              break;
            case "Average":
              this.average = item.Value;
              this.IsSelectedAverage = item.IsSelected;
              break;
            case "Clean":
              this.clean = item.Value;
              this.IsSelectedClean = item.IsSelected;
              break;
            default:
              break;
          }
        });
      }
      // loading script for html to image download
      if (!document.getElementById("html2canvasScript")) {
        let scriptTag = document.createElement("script");
        scriptTag.src =
          "https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js";
        scriptTag.id = "html2canvasScript";
        scriptTag.async = true;
        document.getElementsByTagName("head")[0].appendChild(scriptTag);
      }
      // loading script for html to image download
    }
    if (this.botResponse?.component?.nextQuestion) {
      this.nextQuestion = this.botResponse?.component?.nextQuestion;
    }
    if (this.chatThemeSettings?.primeColor) {
      this.primaryColor = this.chatThemeSettings.primeColor;
      if (document.querySelector(".trade_details_table tr td:first-child")) {
        let firstChildTds = document.querySelectorAll(".trade_details_table tr td:first-child");
        firstChildTds.forEach((item) => {
          item.style.borderColor = this.chatThemeSettings?.borderColor;
        });
      }
    }
  },
  methods: {
    download() {
      // eslint-disable-next-line no-undef
      html2canvas(document.getElementById("black_book")).then(function (canvas) {
        let anchorTag = document.createElement("a");
        document.body.appendChild(anchorTag);
        anchorTag.download = "trade_value.jpg";
        anchorTag.href = canvas.toDataURL();
        anchorTag.target = "_blank";
        anchorTag.click();
      });
    },
    viewDetails(val) {
      this.scrollBottom();
      setTimeout(() => {
        this.$root.$emit("OpenSlideInfo", `viewDetails-${val}`);
      }, 500);
    },
    scrollBottom() {
      let container = document.querySelector("#chat__bodyview");
      if (container) {
        setTimeout(() => {
          container.scrollTo(0, this.$refs.black_book.offsetTop + 600);
        }, 500);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.tvm_payment_progress {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #eee;

  .progress_bar {
    height: 45px;
    object-fit: contain;
  }

  .left_value {
    left: -7px;
    top: 35px;
  }

  .middle_value {
    right: 80px;
    top: 42px;
  }

  .right_value {
    right: -7px;
    top: 35px;
  }

  @media screen and (min-width: 1900px) {
    .middle_value {
      right: 100px;
      top: 46px;
    }

    .confirm_section .ft-13 {
      font-size: 14px !important;
    }
  }
}

.bb-est-con {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  width: 100%;
  margin: 0 0 5px 0;
}

.bb-dis {
  font-size: 12px;
}

.selected-condition {
  font-weight: bold;
  background: #eee;
}

.bb__table__1 {
  width: 100%;
  border: 0;
  margin: 0 0 0px 0;
}

.bb__table__2 {
  width: 100%;
  border: 1px solid #dbdbdb;
  margin: 0 0 10px 0;
}

.confirm_section {
  margin: 15px 0;
  word-break: break-word;
}

.bb__table__1 th,
.bb__table__1 td,
.bb__table__2 th,
.bb__table__2 td {
  font-size: 12px;
  padding: 5px;
}

.bb__table__1 th,
.bb__table__2 th {
  width: 44%;
}

.bb__table__1 td:nth-of-type(1),
.bb__table__2 td:nth-of-type(1) {
  width: 6%;
}

.bb__table__1 td:nth-of-type(2),
.bb__table__2 td:nth-of-type(2) {
  width: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.trade_details_table {
  border: 1px solid #eee !important;
}

.trade_details_table tr td {
  border: 0;
  padding: 8px 20px;
  font-size: 14px;
}

.trade_details_table tr td:first-child {
  border-right: 1px solid #eee;
  max-width: 100px;
}

.view_details {
  position: relative;
  display: inline-block;
}
</style>
