import Modal from '../../Shared/Modal/Modal.vue';
import ContactForm from '../../LeadForms/ContactForm/ContactForm';
import { Events } from '../../../Events/ConsumerEvents/Events';
import CommonUtility from '../../../../../js/services/CommonUtility';
import { thousandSeparator } from '../../../utils/conversion.utils';

export default {
  data() {
    return {
      selectedVehicleIndex: 0,
      useoverrides: window.digiServiceUseOverrides,
      isMobile: window.ismobile,
      contactform: false,
      offerid: '',
      styleid: '',
      vin: '',
      dealerurl: '',
      modalShow: false,
      disclaimer: '',
      classicTemplate: window.masterLayoutName.toLowerCase() === '_website_classic',
    };
  },
  props: ['items'],
  components: {
    ContactForm,
    Modal,
  },
  mounted() {},
  filters: {
  },
  computed: {
    marginBetweenExploreAndInventoryLinks() {
      return !this.items[this.selectedVehicleIndex]?.hideExploreLink ? 'ml-3' : '';
    },
  },
  methods: {
    openContactUs() {
      this.contactform = true;
      let eventModel = {
        ...Events.consumerEventModel,
        consumerEventId: Events.eventsConstants.openContactUsForm,
      };
      Events.logConsumerEvent(eventModel);
    },

    onVehicleSelect(index) {
      this.selectedVehicleIndex = index;
    },
    getDefaultValues(url) {
      return CommonUtility.getVehicleGenericColorAngleLogic(url);
    },
    getUrl(modelName) {
      return modelName.replaceAll(' ', '-');
    },
    closeModal() {
      this.modalShow = false;
      this.disclaimer = '';
    },
    openDisclaimer(text) {
      this.disclaimer = text;
      this.modalShow = true;
    },
    appendMazda(data) {
      if (data.indexOf('MAZDA') === -1) {
        return 'MAZDA' + ' ' + data;
      }
      return data;
    },
    formatNumber(value) {
     return thousandSeparator(value)
    }
  },
};
