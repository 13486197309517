<template>
  <div v-if="linksData && linksData.length">
    <div v-for="(item, index) in linksData" :key="index" :class="item.componentType === 'link' ? 'mb-c-10' : ''">
      <div v-if="item.componentType === 'link' && !item.component.icon">
        <a :href="item.component.uri" class="custom-payload-con"
          :style="{ borderColor: chatThemeSettings?.borderColor }" style="color: #0000ee;"
          @click="navigateLink(item.component.uri)">{{
            item.component.linkDesc }}</a>
      </div>
      <div class="text-center"
        v-if="item.componentType === 'link' && item.component.icon && item.component.icon === 'vdv'">
        <a :href="item.component.uri" class="custom-payload-con "
          :style="{ borderColor: chatThemeSettings?.borderColor }" style="color: #0000ee;" target="_blank">
          <div class="d-flex vdv_link position-relative justify-content-center">
            <span class="vdv_video_icon">
              <img src="/images/playbutton.png" class="img-fluid" alt="vdv video image" />
            </span>
            <span>{{ item.component.linkDesc }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>

import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService';
// import { mapState } from 'vuex';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['linksData', 'chatThemeSettings'],
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state?.commonSettings?.chatThemeSettings;
  //     },
  //   }),
  // },
  methods: {
    navigateLink(val) {
      if (location.hash === '#Transact' && val.includes('#Transact')) {

        location.reload();
      } else {
        ChatWidgetService.closeChatWidgetSession();
        if (sessionStorage.getItem('chatTextModal') !== null) {
          sessionStorage.removeItem('chatTextModal');
        }
      }
    }
  },
}
</script>
<style lang="less" scoped>
.custom-payload-con {
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px;
  display: block;
  font-size: 13px !important;
  text-align: center;
  text-decoration: underline !important;
  color: #0000ee !important;
}

.mb-c-10 {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 1900px) {
  .custom-payload-con {
    font-size: 15px !important;
    padding: 15px;
  }
}

@media (min-width: 2500px) {
  .custom-payload-con {
    font-size: 15px;
    padding: 15px;
  }
}

.vdv_video_icon img {
  filter: invert(1);
  height: 20px;
  width: 20px;
}

.vdv_link span {
  top: 0px;
}
</style>
