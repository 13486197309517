<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43">
      <g id="Text_Icon_-_Home_Screen" data-name="Text Icon - Home Screen" transform="translate(-1608 -442)">
        <g id="Text_Icon_on_Main_Menu" data-name="Text Icon on Main Menu" transform="translate(1608 442)" fill="#fff"
          :stroke="themeColor" stroke-width="1">
          <circle cx="21.5" cy="21.5" r="21.5" stroke="none" />
          <circle cx="21.5" cy="21.5" r="21" fill="none" />
        </g>
        <g id="Group_8670" data-name="Group 8670" transform="translate(1624.054 453.25)">
          <g id="Group_8623" data-name="Group 8623" transform="translate(0.5 0.5)">
            <rect id="Rectangle_4385" data-name="Rectangle 4385" width="10.89" height="19.267" rx="1.862"
              transform="translate(0)" fill="none" :stroke="themeColor" stroke-linecap="round" stroke-linejoin="round"
              stroke-width="1" />
            <line id="Line_923" data-name="Line 923" x2="10.89" transform="translate(0 15.916)" fill="none"
              :stroke="themeColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
            <line id="Line_924" data-name="Line 924" x2="2.513" transform="translate(4.188 1.675)" fill="none"
              :stroke="themeColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1" />
            <line id="Line_925" data-name="Line 925" x2="0.838" transform="translate(5.026 17.591)" fill="none"
              :stroke="themeColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
          </g>
        </g>
      </g>
    </svg>

  </div>
</template>
<script>
export default {
  props: {
    themeColor: {
      type: String,
      required: false,
      default: '#878787',
    }
  }
}
</script>
