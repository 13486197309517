// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assistant_icon svg[data-v-b028dd92] {
  transform: rotate(-360deg);
  transition: transform 1s ease;
  display: block;
}
.assistant_icon svg[data-v-b028dd92]:hover {
  transform: rotate(360deg);
  transition: transform 1s ease;
}
.animated_icon[data-v-b028dd92] {
  animation: rotate-b028dd92 550ms ease;
}
@keyframes rotate-b028dd92 {
0% {
    transform: rotate(-360deg);
    transition: transform 550ms ease;
}
100% {
    transform: rotate(360deg);
    transition: transform 550ms ease;
}
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChatWidget/ChatAssistant/AssistantIcon.vue","webpack://./AssistantIcon.vue"],"names":[],"mappings":"AACA;EACE,0BAAA;EACA,6BAAA;EACA,cAAA;ACAF;ADEE;EACE,yBAAA;EACA,6BAAA;ACAJ;ADIA;EACE,qCAAA;ACFF;ADKA;AACE;IACE,0BAAA;IACA,gCAAA;ACHF;ADMA;IACE,yBAAA;IACA,gCAAA;ACJF;AACF","sourcesContent":["\n.assistant_icon svg {\n  transform: rotate(-360deg);\n  transition: transform 1s ease;\n  display: block;\n\n  &:hover {\n    transform: rotate(360deg);\n    transition: transform 1s ease;\n  }\n}\n\n.animated_icon {\n  animation: rotate 550ms ease;\n}\n\n@keyframes rotate {\n  0% {\n    transform: rotate(-360deg);\n    transition: transform 550ms ease;\n  }\n\n  100% {\n    transform: rotate(360deg);\n    transition: transform 550ms ease;\n  }\n}\n",".assistant_icon svg {\n  transform: rotate(-360deg);\n  transition: transform 1s ease;\n  display: block;\n}\n.assistant_icon svg:hover {\n  transform: rotate(360deg);\n  transition: transform 1s ease;\n}\n.animated_icon {\n  animation: rotate 550ms ease;\n}\n@keyframes rotate {\n  0% {\n    transform: rotate(-360deg);\n    transition: transform 550ms ease;\n  }\n  100% {\n    transform: rotate(360deg);\n    transition: transform 550ms ease;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
