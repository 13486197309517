import axios from 'axios';

import { TagManagerContainer } from '../../Events/GoogleTagManager/TagManagerContainer';
import { LayoutConstants } from '@/js/constants/LayoutConstants';
import {
  getCookieguid,
  buildAndPriceUrl,
  isSatelliteLoaded,
  isFdLincolnDealer,
} from '../../Events/FordDirect/FordCookieService';
import ShiftDigitalContainer from '../../Events/ShiftDigital/ShiftDigitalContainer';
import { fireSonicHeaderNavigationLink } from '../../Events/SonicDataLayer/SonicDataService';
import { TealiumTireThreeTagManagerContainer } from '../../Events/TealiumTireThreeTagging/TealiumTireThreeTagManager';
import {
  TEALIUM_EXPLORE,
  TEALIUM_EXPLORE_CLICK,
} from '@/js/services/TealiumTireThreeTagging/TealiumConstants';
import {
  onCommonCarouselArrowClick,
  onCommonInteractionToggle,
  onCommonLinkLogs,
} from '../../Events/TagManagerServices/TaggingEventForPages';
//import { getSlickDirection } from '../../../../services/SliderService';
import { getMakeValue } from '@/js/services/CommonUtility';
import 'v-slick-carousel/style.css';
import { VSlickCarousel } from 'v-slick-carousel';
import { useLoader } from '../../Composables/Loader/useLoader';

export default {
  props: {
    desktopsubmenu: {
      default: false,
    },
    sidenavsubmenu: {
      default: false,
      required: false,
    },
  },
  data() {
    return {
      useoverrides: window.digiServiceUseOverrides,
      menudata: '',
      filterMenuData: [],
      currentIndex: 0,
      yearList: [],
      baseUrl: window.baseUrl,
      make: window.make,
      angleValue: window.angleValue,
      isMobile: window.ismobile,
      slickOptions: {
        groupsToShow: 6,
        infinite: false,
        arrows: true,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              arrows: true,
              groupsToShow: 6,
              groupsToScroll: 1,
              infinite: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              arrows: true,
              groupsToShow: 4,
              groupsToScroll: 1,
              infinite: false,
            },
          },
          {
            breakpoint: 991,
            settings: {
              groupsToShow: 2,
              groupsToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              groupsToShow: 2,
              groupsToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              groupsToShow: 1,
              groupsToScroll: 1,
            },
          },
        ],
      },
      isSimplifyTemplate: window.masterLayoutName.toLowerCase() === '_website_simplify',
      isLuxuryTemplate:
        window.masterLayoutName.toLowerCase() === '_website_luxury' ||
        window.masterLayoutName.toLowerCase() === '_website_lux_porsche',
      frameMenuData: '',
      showLoading: false,
      isFordOrLincoln: isFdLincolnDealer(window.themeName),
      enableOem: window.enableOEMProgram,
      zip: window.zip,
      postalCode: window.zip ? window.zip.substring(0, 5) : '',
      oemCode: window.oemCode,
      t3Cookie: '',
      isPrimaryTemplate: window.masterLayoutName.toLowerCase() === '_website_primary',
      disableBuildAndPrice: window.disableBuildAndPrice,
      // setMatchHeightData: {
      //   el: ['.vehiclepay-img', '.vehiclepay-title', '.vehiclepay'],
      // },
      // selectedYearToDisplay: window.researchModelDefaultYear ? window.researchModelDefaultYear : '',
      selectedYearToDisplay: '',
      enableViewInventoryOnResearchModels: window.enableViewInventoryOnResearchModels,
    };
  },
  components: {
    //Slick,

    VSlickCarousel,
  },

  async mounted() {
    this.showLoading = true;
    const fdEvents = ShiftDigitalContainer.getShiftDigitalObject();

    let sliderInterval = '';

    sliderInterval = setInterval(() => {
      let element = document.querySelector('.model-research-slider .primaryicon-right-arrow');
      if (element) {
        document
          .querySelector('.model-research-slider .primaryicon-right-arrow')
          .addEventListener('click', this.sliderRightClick);
        document
          .querySelector('.model-research-slider .primaryicon-left-arrow')
          .addEventListener('click', this.sliderLeftClick);
        clearInterval(sliderInterval);
      }
    }, 200);
    if (fdEvents && typeof fdEvents.researchModelStartPageOnload === 'function') {
      await isSatelliteLoaded();
      fdEvents.researchModelStartPageOnload();
    }

    if (this.isFordOrLincoln && this.enableOem) {
      this.t3Cookie = getCookieguid() ? getCookieguid() : '';
    }

    //checking for default research model year
    if (window.researchModelDefaultYear) {
      this.selectedYearToDisplay = +window.researchModelDefaultYear;
    } else {
      let d = new Date().getFullYear();
      this.selectedYearToDisplay = d;
    }

    this.getResearchModels();

    this.loadParagonPrimarySlick();
  },
  methods: {
    async getResearchModels() {
      const { showLoader, hideLoader } = useLoader();
      showLoader('Loading Research Vehicle Models');
      try {
        const response = await axios.get(
          `${window.ApiBaseUrl}/ResearchModel/make-models?make=${getMakeValue(
            window.themeName
          )}&dealerId=${window.accountId}&campaignId=${window.campaignId}`
        );
        this.menudata = response.data;

        this.yearList = this.menudata.map((data) => data.year);

        this.yearList = [...new Set(this.yearList)];

        this.yearList.sort().reverse();

        let i;
        if (this.isSimplifyTemplate || this.isLuxuryTemplate || this.sidenavsubmenu) {
          i = this.getIndexOfYears(this.yearList);

          this.frameSimplifyMenuData(i, this.yearList[i]);
          if (this.isMobile) {
            this.selectYear(i, this.yearList[i], false);
          }
        } else {
          i = this.getIndexOfYears(this.yearList);

          this.selectYear(i, this.yearList[i], false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        hideLoader();
      }
    },

    // returns index of selected year
    getIndexOfYears(years) {
      let indexOfYears = years.indexOf(
        years.find((_) => {
          return +_ === +this.selectedYearToDisplay;
        })
      );

      indexOfYears = indexOfYears === -1 ? 0 : +indexOfYears;

      return indexOfYears;
    },

    researchModelsSonicEvents(data) {
      fireSonicHeaderNavigationLink('Research Models', `${data.year} ${data.model}`);
    },
    sliderRightClick() {
      const fdEvents = ShiftDigitalContainer.getShiftDigitalObject();
      if (fdEvents && typeof fdEvents.researchModelStartPageActionClick === 'function') {
        fdEvents.researchModelStartPageActionClick('model scroll:right');
      }
      onCommonCarouselArrowClick({
        interactionLabel: 'right',
        interactionValue: this.currentSlide,
      });
    },
    onExploreBoxClickLogging(navigationUrl, vehicleDetails) {
      onCommonLinkLogs({ label: vehicleDetails.model, url: navigationUrl });
    },
    sliderLeftClick() {
      const fdEvents = ShiftDigitalContainer.getShiftDigitalObject();
      if (fdEvents && typeof fdEvents.researchModelStartPageActionClick === 'function') {
        fdEvents.researchModelStartPageActionClick('model scroll:left');
      }
      onCommonCarouselArrowClick({
        interactionLabel: 'left',
        interactionValue: this.currentSlide,
      });
    },
    handleBeforeChange(event, slick, currentSlide, nextSlide) {
      // let direction = getSlickDirection({
      //   slick: slick,
      //   currentSlide: currentSlide,
      //   nextSlide: nextSlide,
      // });
      // onCommonCarouselArrowClick({
      //   interactionLabel: direction,
      //   interactionValue: `${nextSlide === 0 ? 1 : nextSlide} / ${slick.slideCount}`,
      // });
    },
    logTagClickEvents(label, url, vehicleDetails) {
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getLinkClickEvent === 'function') {
        tagManager.getLinkClickEvent(label, url);
      }
      let tealium = TealiumTireThreeTagManagerContainer.getDealerObject();
      if (tealium && typeof tealium.tealiumFormCTAAction === 'function') {
        tealium.tealiumFormCTAAction({
          vin: '',
          label: TEALIUM_EXPLORE,
          site_event: TEALIUM_EXPLORE_CLICK,
        });
      }
    },
    onExploreBoxClick(event, navigationUrl, vehicleDetails) {
      if (this.isFordOrLincoln) {
        event.preventDefault();
      } else {
        window.location.href = `${this.baseUrl}${navigationUrl.toLowerCase()}`;
      }
    },
    reInit() {
      // this.$refs.slick.destroy();
      this.$nextTick(() => {
        // this.$refs.slick.create();
      });
      // setTimeout(() => {
      //   document.dispatchEvent(new CustomEvent('matchheight', this.setMatchHeightData));
      // }, 100);
    },

    selectYear(index, year, triggerEvent = false) {
      if (triggerEvent) {
        const fdEvents = ShiftDigitalContainer.getShiftDigitalObject();
        if (fdEvents && typeof fdEvents.researchModelStartPageActionClick === 'function') {
          fdEvents.researchModelStartPageActionClick('year select');
        }
      }

      this.currentIndex = index;
      this.filterMenuData = [];
      if (this.isMobile) {
        setTimeout(() => {
          this.filterMenuData = this.menudata.filter((data) => data.year === year);
        }, 500);
      } else {
        this.filterMenuData = this.menudata.filter((data) => data.year === year);
      }
      this.reInit();
      onCommonInteractionToggle({
        interactionLabel: 'Choose a Model Year',
        interactionValue: year,
      });
    },
    closeprimaryResearchModels() {
      this.$emit('primaryresearchmodels');
    },

    frameSimplifyMenuData(index, year) {
      this.currentIndex = index;
      let actualData = this.menudata.filter((data) => data.year === year);
      this.frameMenuData = [];
      let totalLoops = parseInt(actualData.length / 4) + (actualData.length % 4 ? 1 : 0);
      for (let i = 0; i < totalLoops; i++) {
        this.frameMenuData[i] = actualData.slice(4 * i, 4 * (i + 1));
      }
    },
    loadParagonPrimarySlick() {
      let isParagonprimary = LayoutConstants.isParagonprimaryTemplate();
      if (isParagonprimary) {
        this.slickOptions.slidesToShow = 1;
        this.slickOptions.slidesToScroll = 1;
        this.slickOptions.responsive = [];
        this.slickOptions.adaptiveHeight = false;
      }
    },
    getBuildAndPriceUrl(url) {
      return buildAndPriceUrl(url);
    },
    logBuildAndPriceClickEvents(vehicle) {
      const fdEvents = ShiftDigitalContainer.getShiftDigitalObject();
      if (fdEvents && typeof fdEvents.exitBuildAndPrice === 'function') {
        fdEvents.exitBuildAndPrice({
          year: vehicle.year,
          namePlate: vehicle.make + ' ' + vehicle.model,
        });
      }
    },
    getBuildAndPriceParams(vehicle) {
      if (
        vehicle.year === '2024' &&
        (vehicle.model.toLowerCase().indexOf('mach-e') > 0 ||
          vehicle.model.toLowerCase().indexOf('mache') > 0 ||
          vehicle.model.toLowerCase() === 'f-150 lightning')
      ) {
        return `?dealerCode=${this.oemCode}&intcmp=dc-${this.oemCode}&TertiarySource=${this.t3Cookie}`;
      }
      if (vehicle.buildAndPriceUrl.indexOf('mach-e/build-and-price') > 0) {
        return `?paCode=${this.oemCode}#/model`;
      } else {
        return `/?dcIncentivesURL=${this.baseUrl}&dealerPACode=${this.oemCode}&locale=en&dctype=base&postalCode=${this.postalCode}&intcmp=dc1-${this.oemCode}&TertiarySource=${this.t3Cookie}`;
      }
    },
  },
};
