import axios from 'axios';
// import Slick from 'vue-slick';
import bModal from 'bootstrap-vue/es/components/modal/modal';
import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService';
import {
  chatWidgetAscCommEngagement,
  chatWidgetCouponsSpecialOffer,
  fireSelectedCoupons,
} from '../../../Events/TagManagerServices/TaggingEventForPages';
import TvmPreLoader from '../TvmPreLoader/TvmPreLoader.vue';
import { Events } from '../../../Events/ConsumerEvents/Events';
// import { mapState } from 'vuex';
import 'v-slick-carousel/style.css';
import { VSlickCarousel } from 'v-slick-carousel';
export default {
  data() {
    return {
      primaryColor: window.primaryColor,
      viewDetailsModal: false,
      viewDetailsData: '',
      isLoader: false,
      displayText: 'Please Wait Data is Loading...',
      isDisableChat: false,
      isHeading: false,
      serviceOffersData: [],
      serviceTypesPayloadUrl: '',
      buttons: [],
      btnDisabled: true,
      selectedCoupon: '',
      currentSlide: 1,
      slickOptions: {
        infinite: false,
        groupsToShow: 3,
        groupsToScroll: 1,
        dots: false,
        // centerPadding: "20px",
        // adaptiveHeight: false,
        // nextArrow: `<em class="primaryicon-right-arrow" style="cursor:pointer;right:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;"></em>`,
        // prevArrow: `<em class="primaryicon-left-arrow" style="cursor:pointer;left:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;"></em>`,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              groupsToShow: 3,
              groupsToScroll: 1,
            },
          },
        ],
      },
      selectedCouponIndex: null,
    };
  },
  components: {
    // Slick,
    'b-modal': bModal,
    'tvm-pre-loader': TvmPreLoader,
    VSlickCarousel,
  },
  props: [
    'botResponse',
    'isLastIndex',
    'index',
    'countCoupons',
    'isWaitingForResponse',
    'chatThemeSettings',
  ],
  computed: {
    // ...mapState({
    //   chatThemeSettings: (state) => {
    //     return state?.commonSettings?.chatThemeSettings;
    //   },
    // }),
    componentObj() {
      return this.botResponse?.component?.value;
    },
  },
  mounted() {
    if (this.chatThemeSettings?.primeColor) {
      this.primaryColor = this.chatThemeSettings.primeColor;
    }
    // this.slickOptions.nextArrow = `<em class="primaryicon-right-arrow" style="background:${this.primaryColor} !important;color:#fff !important;"></em>`;
    // this.slickOptions.prevArrow = `<em class="primaryicon-left-arrow" style="background:${this.primaryColor} !important;
    // color:#fff !important;"></em>`;

    let payload = this.botResponse?.component?.value;
    this.buttons = this.botResponse?.component?.value?.suggestions;

    axios
      .get(payload.couponsUri)
      .then((response) => {
        let items = response.data;
        this.serviceTypesPayloadUrl = payload.couponsUri;
        items.forEach((value) => {
          chatWidgetCouponsSpecialOffer(value);
          value.isSelected = false;
        });
        if (items.length) {
          let selectedServiceOffers = JSON.parse(sessionStorage.getItem('serviceOffersData')) || [];
          selectedServiceOffers.forEach((val) => {
            if (val.index === this.countCoupons) {
              let selectedId = val.id;
              items[selectedId].isSelected = true;
              setTimeout(() => {
                if (this.$refs.slick) {
                  this.$refs.slick.goTo(selectedId);
                }
              }, 500);
            }
          });
        }
        this.serviceOffersData = items;
        this.scrollBottom();

        if (items.length > 3) {
          this.slickOptions.dots = true;
        }
      })
      .catch((error) => {
        console.log(error);
        this.displayText = 'Something Went wrong. Please try again';
      });
  },
  beforeDestroy() {
    this.$root.$off('isWaitingForResponseData');
  },
  methods: {
    trucate(value, length = 15) {
      return value.length <= length ? value : value.substring(0, length) + '...';
    },
    optionclick(event, button, offer, idx) {
      this.$root.$emit('isWaitingForResponseData', true);

      let postObject = {
        eventname: button.event,
        text: button.name,
      };
      if (offer?.offerId) {
        let Obj = { id: idx, index: this.countCoupons };
        let selectedServiceOffers = JSON.parse(sessionStorage.getItem('serviceOffersData')) || [];
        selectedServiceOffers.push(Obj);
        sessionStorage.setItem('serviceOffersData', JSON.stringify(selectedServiceOffers));
        postObject.OfferSelection = {
          OfferId: offer.offerId,
          OfferCategoryId: offer.offerTypeID,
          OfferDesignId: offer.offerDesignId,
        };
      }
      ChatWidgetService.postTvmMessage(
        `${button.name} ${offer?.heading ? ':' + offer.heading : ''}`,
        button.event,
        button.submitFlow,
        button.submitAction,
        postObject
      );
      chatWidgetAscCommEngagement(event, {}, false);
    },
    viewDetails(val) {
      this.viewDetailsModal = true;
      this.viewDetailsData = val;
      fireSelectedCoupons(val.heading);
    },
    scrollBottom() {
      let container = document.querySelector('#chat__bodyview');
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 500);
      }
    },
    onAppleWalletClick(offerid, styleid) {
      Events.specialsEvent(Events.eventsConstants.AppleWallet, styleid, '', offerid);
    },
    onCouponSelected(serviceType, idx) {
      let isSelected = serviceType.isSelected;
      this.serviceOffersData.forEach((value) => {
        value.isSelected = false;
      });
      this.serviceOffersData[idx].isSelected = !isSelected;
      this.selectedCoupon = this.serviceOffersData[idx].isSelected
        ? this.serviceOffersData[idx]
        : '';
      this.selectedCouponIndex = this.serviceOffersData[idx].isSelected ? idx : null;
    },
    handleAfterChange(currentSlideGroupIndex) {
      console.log(currentSlideGroupIndex);
      if (window.ismobile) {
        this.currentSlide =
          currentSlideGroupIndex === 0
            ? 2
            : currentSlideGroupIndex + this.slickOptions.groupsToShow;
      } else {
        this.currentSlide =
          currentSlideGroupIndex === 0
            ? 3
            : currentSlideGroupIndex + this.slickOptions.groupsToShow;
      }
    },
    handleInit(event, slick, currentSlide) {
      this.currentSlide = window.ismobile ? 2 : this.slickOptions.groupsToShow;
    },
    getArrowStyles(direction) {
      const commonStyles = {
        cursor: 'pointer',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: this.primaryColor,
        color: '#fff',
        fontSize: '10px',
        padding: '5px',
        zIndex: 10,
      };
      if (direction === 'left') {
        return {
          ...commonStyles,
          left: '0',
          borderRadius: '0px 3px 3px 0px',
        };
      } else if (direction === 'right') {
        return {
          ...commonStyles,
          right: '0',
          borderRadius: '3px 0px 0px 3px',
        };
      }
      return commonStyles;
    },
  },
};
