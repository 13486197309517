import { defineStore } from 'pinia';
export const useDrsEventsStore = defineStore('drseventsstore', {
  state() {
    return {
      mostRecentDrsActivity: '',
      drsProgramCodes: '',
    };
  },

  actions: {
    saveDrsProgramCodes(payload) {
      this.mostRecentDrsActivity = payload;
      try {
        let drsProgramCodesList = [];
        if (this.drsProgramCodes) {
          drsProgramCodesList = this.drsProgramCodes.split('|');
          if (!drsProgramCodesList.some((item) => item === payload)) {
            drsProgramCodesList.push(payload);
          }
        } else {
          drsProgramCodesList.push(payload);
        }

        this.drsProgramCodes = drsProgramCodesList.join('|');
      } catch (e) {
        console.log(e);
      }
    },
  },
});
