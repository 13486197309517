/**
 *
 * IMPORTANT:
 *
 * This file is used both in vue2 and vue3 application
 * Dont use vue syntax and the store concept
 * for clarifications contact Satya
 *
 */

import { STATIC_IMAGE_PATH } from '../../constants/constants';
export const mazdaModels = {
  models: [
    {
      vehicleTypeName: 'SUV',
      groupItemsList: [
        {
          imageHandlerPath:
            STATIC_IMAGE_PATH +
            'oem/mazda/2025-cx30/2025-cx30-turbo-premium-plus-polymetal-gray-profile.png',
          model_Year: 2025,
          model_Name: 'CX-30',
          vehicleTypeName: 'SUV',
          cfModelName: 'CX-30',
          baseMSRP: '25,195',
          inventoryPath: '/inventory/new/mazda/cx-30',
          inventoryPathClassic: '/inventory/new/MAZDA/CX-30',
          exploreLink: '/2025-mazda-cx-30',
          disclaimer: 1,
          disclaimerText: `1 MSRP excludes taxes, title, license fees and $1,455 destination charge (Alaska $1,500). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
        {
          imageHandlerPath: window.baseUrl + '/images/mazdamodels/2022-cx-30.png',
          model_Year: 2024,
          model_Name: 'CX-30',
          vehicleTypeName: 'SUV',
          cfModelName: 'CX-30',
          baseMSRP: '24,995',
          inventoryPath: '/inventory/new/MAZDA/MAZDA-CX-30',
          inventoryPathClassic: '/inventory/new/MAZDA/CX-30',
          exploreLink: '/2024MazdaCX30',
          disclaimer: 2,
          disclaimerText: `2 MSRP excludes taxes, title, license fees and $1,420 destination charge (Alaska $1,465). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
        {
          imageHandlerPath:
            STATIC_IMAGE_PATH + 'oem/mazda/2024-cx5/2024-cx5-TurboSignature-MachineGray.png',
          model_Year: 2025,
          model_Name: 'CX-5',
          vehicleTypeName: 'SUV',
          cfModelName: 'CX-5',
          baseMSRP: '28,770',
          inventoryPath: '/inventory/new/MAZDA/MAZDA-CX-5',
          inventoryPathClassic: '/inventory/new/MAZDA/CX-5',
          exploreLink: '/2025-Mazda-CX-5',
          disclaimer: 2,
          disclaimerText: `2 MSRP excludes taxes, title, license fees and $1,420 destination charge (Alaska $1,465). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
        {
          imageHandlerPath:
            STATIC_IMAGE_PATH + 'oem/mazda/2024-cx5/2024-cx5-TurboSignature-MachineGray.png',
          model_Year: 2024,
          model_Name: 'CX-5',
          vehicleTypeName: 'SUV',
          cfModelName: 'CX-5',
          baseMSRP: '29,300',
          inventoryPath: '/inventory/new/MAZDA/Mazda-CX-5',
          inventoryPathClassic: '/inventory/new/MAZDA/CX-5',
          exploreLink: '/2024-Mazda-CX-5',
          disclaimer: 2,
          disclaimerText: `2 MSRP excludes taxes, title, license fees and $1,420 destination charge (Alaska $1,465). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
        {
          imageHandlerPath: window.baseUrl + '/images/mazdamodels/2023-cx-50.png',
          model_Year: 2025,
          model_Name: 'CX-50',
          vehicleTypeName: 'SUV',
          cfModelName: 'CX-50',
          baseMSRP: '30,500',
          exploreLink: '/2025-Mazda-CX-50',
          inventoryPath: '/inventory/new/MAZDA/CX-50',
          inventoryPathClassic: '/inventory/new/MAZDA/mazda-cx-50',
          disclaimer: 2,
          disclaimerText: `2 MSRP excludes taxes, title, license fees and $1,420 destination charge (Alaska $1,465). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
        {
          imageHandlerPath: window.baseUrl + '/images/mazdamodels/2023-cx-50.png',
          model_Year: 2024,
          model_Name: 'CX-50',
          vehicleTypeName: 'SUV',
          cfModelName: 'CX-50',
          baseMSRP: '30,300',
          exploreLink: '/2024-Mazda-CX-50',
          inventoryPath: '/inventory/new/MAZDA/MAZDA-CX-50',
          inventoryPathClassic: '/inventory/new/MAZDA/mazda-cx-50',
          disclaimer: 2,
          disclaimerText: `2 MSRP excludes taxes, title, license fees and $1,420 destination charge (Alaska $1,465). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
        {
          imageHandlerPath: STATIC_IMAGE_PATH + 'oem/mazda/models/2025-mazda-cx-50-2.5-s-and-2.png',
          model_Year: 2025,
          model_Name: 'CX-50 Hybrid',
          vehicleTypeName: 'SUV',
          cfModelName: 'CX-50 Hybrid',
          baseMSRP: '33,970',
          isVehicleCX_90_PHEV: false,
          exploreLink: '/2025-mazda-cx-50-hybrid',
          hideExploreLink: false,
          inventoryPath: '/inventory/new/mazda/CX-50-hybrid,mazda-cx-50-hev',
          baseText: '',
          disclaimer: 4,
          disclaimerText: `4  MSRP excludes taxes, title, license fees and $1,420 destination charge (Alaska $1,465). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
          specificInventoryModels: 'CX-50-hybrid,mazda-cx-50-hev',
        },
        {
          imageHandlerPath: STATIC_IMAGE_PATH + 'oem/mazda/2024-cx70/2025-cx-70-profile.png',
          model_Year: 2025,
          model_Name: 'CX-70',
          vehicleTypeName: 'SUV',
          cfModelName: 'CX-70',
          baseMSRP: '40,445',
          exploreLink: '/2025-mazda-cx-70',
          inventoryPath: '/inventory/new/mazda/CX-70',
          disclaimer: 4,
          disclaimerText: `4 MSRP excludes taxes, title, license fees and $1,420 destination charge (Alaska $1,465). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
        {
          imageHandlerPath:
            STATIC_IMAGE_PATH + 'oem/mazda/2024-cx90/2024-cx-90-turbo-s-premium-plus-profile.png',
          model_Year: 2025,
          model_Name: 'CX-90',
          vehicleTypeName: 'SUV',
          cfModelName: 'CX-90',
          baseMSRP: '38,045',
          hideInventory: false,
          isVehicleCX_90: false,
          exploreLink: '/2025-mazda-cx-90',
          inventoryPath: '/inventory/new/MAZDA/MAZDA-CX-90',
          inventoryPathClassic: '/inventory/new/MAZDA/CX-90',
          disclaimer: 1,
          disclaimerText: `1 MSRP excludes taxes, title, license fees and $1,455 destination charge (Alaska $1,500). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
        {
          imageHandlerPath:
            STATIC_IMAGE_PATH + 'oem/mazda/2024-cx90/2024-cx-90-turbo-s-premium-plus-profile.png',
          model_Year: 2024,
          model_Name: 'CX-90',
          vehicleTypeName: 'SUV',
          cfModelName: 'CX-90',
          baseMSRP: '37,845',
          hideInventory: false,
          isVehicleCX_90: false,
          exploreLink: '/2024-mazda-cx-90',
          inventoryPath: '/inventory/new/MAZDA/MAZDA-CX-90',
          inventoryPathClassic: '/inventory/new/MAZDA/CX-90',
          disclaimer: 4,
          disclaimerText: `4 MSRP excludes taxes, title, license fees and $1,420 destination charge (Alaska $1,465). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
      ],
    },
    {
      vehicleTypeName: 'Electrified',
      groupItemsList: [
        {
          imageHandlerPath: STATIC_IMAGE_PATH + 'oem/mazda/models/2025-mazda-cx-50-2.5-s-and-2.png',
          model_Year: 2025,
          model_Name: 'CX-50 Hybrid',
          vehicleTypeName: 'Electrified',
          cfModelName: 'CX-50 Hybrid',
          baseMSRP: '33,970',
          isVehicleCX_90_PHEV: false,
          exploreLink: '/2025-mazda-cx-50-hybrid',
          hideExploreLink: false,
          inventoryPath: '/inventory/new/mazda/CX-50-hybrid,mazda-cx-50-hev',
          baseText: '',
          disclaimer: 4,
          disclaimerText: `4  MSRP excludes taxes, title, license fees and $1,420 destination charge (Alaska $1,465). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
          specificInventoryModels: 'CX-50-hybrid,mazda-cx-50-hev',
        },
        {
          imageHandlerPath:
            STATIC_IMAGE_PATH +
            'oem/mazda/2025-plug-in-hybrid/2025-cx70-PHEV-premium-plus-profile.png',
          model_Year: 2025,
          model_Name: 'CX-70 Plug-In Hybrid',
          vehicleTypeName: 'Electrified',
          cfModelName: 'CX-70 Plug-In Hybrid',
          baseMSRP: '54,400',
          isVehicleCX_90_PHEV: false,
          exploreLink: '/2025-mazda-cx-70-plug-in-hybrid',
          inventoryPath: '/inventory/new/mazda/CX-70-PHEV,CX-70-Plug-In-Hybrid',
          baseText: '',
          disclaimer: 4,
          disclaimerText: `4 MSRP excludes taxes, title, license fees and $1,420 destination charge (Alaska $1,465). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details`,
          specificInventoryModels: 'CX-70-PHEV,CX-70-Plug-In-Hybrid',
        },
        {
          imageHandlerPath:
            STATIC_IMAGE_PATH +
            'oem/mazda/2025-plug-in-hybrid/2024-cx90-phev-premiumplus-rhodiumwhite_PHEV.png',
          model_Year: 2025,
          model_Name: 'CX-90 Plug-In Hybrid',
          vehicleTypeName: 'Electrified',
          cfModelName: 'CX-90 Plug-In Hybrid',
          baseMSRP: '49,945',
          hideInventory: false,
          isVehicleCX_90_PHEV: false,
          exploreLink: '/2025-mazda-cx-90-plug-in-hybrid',
          inventoryPath: '/inventory/new/mazda/CX-90-PHEV,CX-90-Plug-In-Hybrid',
          disclaimer: 4,
          disclaimerText: `4 MSRP excludes taxes, title, license fees and $1,420 destination charge (Alaska $1,465). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
          specificInventoryModels: 'CX-90-PHEV,CX-90-Plug-In-Hybrid',
        },
        {
          imageHandlerPath:
            STATIC_IMAGE_PATH +
            'oem/mazda/2024-cx90/2024-cx90-phev-premiumplus-rhodiumwhite_PHEV.png',
          model_Year: 2024,
          model_Name: 'CX-90 Plug-In Hybrid',
          vehicleTypeName: 'Electrified',
          cfModelName: 'CX-90 Plug-In Hybrid',
          baseMSRP: '49,945',
          hideInventory: false,
          isVehicleCX_90_PHEV: false,
          exploreLink: '/2024-mazda-cx-90-phev',
          inventoryPath: '/inventory/new/mazda/CX-90-PHEV,CX-90-Plug-In-Hybrid',
          disclaimer: 4,
          disclaimerText: `4 MSRP excludes taxes, title, license fees and $1,420 destination charge (Alaska $1,465). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
          specificInventoryModels: 'CX-90-PHEV,CX-90-Plug-In-Hybrid',
        },
      ],
    },
    {
      vehicleTypeName: 'Sedan',
      groupItemsList: [
        {
          imageHandlerPath:
            STATIC_IMAGE_PATH + 'oem/mazda/2024-m3s/2024-m3-carbon-edition-polymetal-gray01.png',
          model_Year: 2025,
          model_Name: 'MAZDA3 Sedan',
          vehicleTypeName: 'Sedan',
          cfModelName: 'Mazda3 Sedan',
          baseMSRP: '24,150',
          inventoryPath: '/inventory/new/MAZDA/MAZDA3-Sedan',
          exploreLink: '/2025-Mazda3-Sedan',
          disclaimer: 1,
          disclaimerText: `1 MSRP excludes tax, title, license fees and $1,185 destination charge (Alaska $1,230). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
        {
          imageHandlerPath:
            STATIC_IMAGE_PATH + 'oem/mazda/2024-m3s/2024-m3-carbon-edition-polymetal-gray01.png',
          model_Year: 2024,
          model_Name: 'MAZDA3 Sedan',
          vehicleTypeName: 'Sedan',
          cfModelName: 'Mazda3 Sedan',
          baseMSRP: '24,170',
          inventoryPath: '/inventory/new/MAZDA/MAZDA3-Sedan',
          exploreLink: '/2024Mazda3Sedan',
          disclaimer: 1,
          disclaimerText: `1 MSRP excludes tax, title, license fees and $1,185 destination charge (Alaska $1,230). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
        {
          imageHandlerPath: window.baseUrl + '/images/mazdamodels/2024-Mazda-M3h.png',
          model_Year: 2025,
          model_Name: 'MAZDA3 Hatchback',
          vehicleTypeName: 'Sedan',
          cfModelName: 'Mazda3 Hatchback',
          baseMSRP: '25,150',
          inventoryPath: '/inventory/new/MAZDA/MAZDA3-Hatchback',
          exploreLink: '/2025-Mazda3-Hatchback',
          disclaimer: 1,
          disclaimerText: `1 MSRP excludes tax, title, license fees and $1,185 destination charge (Alaska $1,230). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
        {
          imageHandlerPath: window.baseUrl + '/images/mazdamodels/2024-Mazda-M3h.png',
          model_Year: 2024,
          model_Name: 'MAZDA3 Hatchback',
          vehicleTypeName: 'Sedan',
          cfModelName: 'Mazda3 Hatchback',
          baseMSRP: '25,690',
          inventoryPath: '/inventory/new/MAZDA/MAZDA3-Hatchback',
          exploreLink: '/2024Mazda3Hatchback',
          disclaimer: 4,
          disclaimerText: `4 MSRP excludes tax, title, license fees and $1,185 destination charge (Alaska $1,230). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
      ],
    },
    {
      vehicleTypeName: 'Convertible',
      groupItemsList: [
        {
          imageHandlerPath:
            STATIC_IMAGE_PATH + 'oem/mazda/2025-mx5-miata/mx-5-st-gt-touring-profile.png',
          model_Year: 2025,
          model_Name: 'MX-5 Miata',
          vehicleTypeName: 'Convertible',
          cfModelName: 'MX-5 Miata',
          baseMSRP: '29,530',
          inventoryPath: '/inventory/new/mazda/MX-5-Miata',
          exploreLink: '/2025-Mazda-MX-5-Miata',
          disclaimer: 1,
          disclaimerText: `1 MSRP excludes taxes, title, license fees and $1,185 destination charge (Alaska $1,230). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
        {
          imageHandlerPath:
            STATIC_IMAGE_PATH + 'oem/mazda/2024-mx5-miata-rf/2024-mx-5-st-gt-touring-profile2.png',
          model_Year: 2024,
          model_Name: 'MX-5 Miata',
          vehicleTypeName: 'Convertible',
          cfModelName: 'MX-5 Miata',
          baseMSRP: '28,985',
          inventoryPath: '/inventory/new/mazda/MX-5-Miata',
          exploreLink: '/2024-Mazda-MX-5-Miata',
          disclaimer: 1,
          disclaimerText: `1 MSRP excludes taxes, title, license fees and $1,185 destination charge (Alaska $1,230). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
        {
          imageHandlerPath:
            STATIC_IMAGE_PATH + 'oem/mazda/2025-mx5-miata-rf/2025-mx-5-rf-gt-soulred-profile.png',
          model_Year: 2025,
          model_Name: 'MX-5 Miata Rf',
          vehicleTypeName: 'Convertible',
          cfModelName: 'MX-5 Miata Rf',
          baseMSRP: '37,550',
          inventoryPath: '/inventory/new/mazda/MX-5-Miata-Rf',
          exploreLink: '/2025-Mazda-MX-5-Miata-RF',
          disclaimer: 1,
          disclaimerText: `1 MSRP excludes taxes, title, license fees and $1,185 destination charge (Alaska $1,230). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
        {
          imageHandlerPath:
            STATIC_IMAGE_PATH + 'oem/mazda/2024-mx5-miata-rf/2024-mx-5-rf-gt-soulred-profile1.png',
          model_Year: 2024,
          model_Name: 'MX-5 Miata Rf',
          vehicleTypeName: 'Convertible',
          cfModelName: 'MX-5 Miata Rf',
          baseMSRP: '37,010',
          inventoryPath: '/inventory/new/mazda/MX-5-Miata-Rf',
          exploreLink: '/2024-Mazda-MX-5-Miata-RF',
          disclaimer: 1,
          disclaimerText: `1 MSRP excludes taxes, title, license fees and $1,185 destination charge (Alaska $1,230). Vehicle shown may be priced higher. Actual dealer price will vary. See dealer for complete details.`,
        },
      ],
    },
  ],
  evmodels: [],
};
export const vehicleModels = {
  SUV: [
    {
      vehicles: {
        make: 'MAZDA',
        model: 'Mazda CX-30',
        year: 2022,
        photoURLs: window.baseUrl + '/images/mazdamodels/2020-cx-30.png',
        url: '/inventory/new/mazda/mazda-cx-30',
      },
    },
    {
      vehicles: {
        make: 'MAZDA',
        model: 'Mazda CX-5',
        year: 2021,
        photoURLs: window.baseUrl + '/images/mazdamodels/2021-cx-5.png',
        url: '/inventory/new/mazda/mazda-cx-5',
      },
    },
    {
      vehicles: {
        make: 'MAZDA',
        model: 'Mazda CX-50',
        year: 2023,
        photoURLs: window.baseUrl + '/images/mazdamodels/2023-cx-50.png',
        url: '/inventory/new/mazda/mazda-cx-50',
      },
    },
    {
      vehicles: {
        make: 'MAZDA',
        model: 'Mazda CX-50 Hybrid',
        photoURLs: STATIC_IMAGE_PATH + 'oem/mazda/models/2025-mazda-cx-50-hybrid.png',
        url: '/inventory/new/mazda/CX-50-hybrid,mazda-cx-50-hev',
      },
    },
    {
      vehicles: {
        make: 'MAZDA',
        model: 'MAZDA CX-70',
        photoURLs:
          'https://prod.cdn.secureoffersites.com/images/oem/mazda/2024-cx70/2025-cx-70-profile.png',
        url: '/inventory/new/mazda/CX-70',
      },
    },
    {
      vehicles: {
        make: 'MAZDA',
        model: 'Mazda CX-90',
        year: 2024,
        photoURLs:
          'https://prod.cdn.secureoffersites.com/images/oem/mazda/2024-cx90/navigationjellybeansideprofile.png',
        url: 'inventory/new/MAZDA/cx-90',
      },
    },
  ],
  Sedan: [
    {
      vehicles: {
        make: 'MAZDA',
        model: 'Mazda3 Sedan',
        year: 2022,
        photoURLs: window.baseUrl + '/images/mazdamodels/2022-mazda3-sedan.png',
        url: '/inventory/new/mazda/mazda3-sedan',
      },
    },
    {
      vehicles: {
        make: 'MAZDA',
        model: 'Mazda3 Hatchback',
        year: 2021,
        photoURLs: window.baseUrl + '/images/mazdamodels/2022-m3h.png',
        url: '/inventory/new/mazda/mazda3-hatchback',
      },
    },
  ],
  Convertible: [
    {
      vehicles: {
        make: 'MAZDA',
        model: 'Mazda mx-5 Miata',
        year: 2024,
        photoURLs:
          'https://prod.cdn.secureoffersites.com/images/oem/mazda/2025-mx5-miata/mx-5-st-gt-touring-profile.png',
        url: '/inventory/new/mazda/mazda-mx-5-miata',
      },
    },
    {
      vehicles: {
        make: 'MAZDA',
        model: 'Mazda mx-5 Miata Rf',
        year: 2025,
        photoURLs:
          'https://prod.cdn.secureoffersites.com/images/oem/mazda/2025-mx5-miata-rf/2025-mx-5-rf-gt-soulred-profile.png',
        url: '/inventory/new/mazda/mazda-mx-5-miata-rf',
      },
    },
  ],
  Hybrid: [
    {
      vehicles: {
        make: 'MAZDA',
        model: 'MAZDA CX-70 Plug-In Hybrid',
        photoURLs:
          'https://prod.cdn.secureoffersites.com/images/oem/mazda/2025-plug-in-hybrid/2025-cx70-PHEV-premium-plus-profile.png',
        url: 'inventory/new/mazda/CX-70-PHEV,CX-70-Plug-In-Hybrid',
      },
    },
    {
      vehicles: {
        make: 'MAZDA',
        model: 'MAZDA CX-90 Plug-In Hybrid',
        year: 2022,
        photoURLs:
          'https://prod.cdn.secureoffersites.com/images/oem/mazda/2025-plug-in-hybrid/2024-cx90-phev-premiumplus-rhodiumwhite_PHEV.png',
        url: 'inventory/new/mazda/CX-90-PHEV,CX-90-Plug-In-Hybrid',
      },
    },
    {
      vehicles: {
        make: 'MAZDA',
        model: 'MAZDA CX-50 Hybrid',
        photoURLs: STATIC_IMAGE_PATH + 'oem/mazda/models/2025-mazda-cx-50-hybrid.png',
        url: '/inventory/new/mazda/CX-50-hybrid,mazda-cx-50-hev',
      },
    },
  ],
};
