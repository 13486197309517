<template>
  <div>
    <div class="tvm_reser_vehicle px-3 text-center my-2">
      <div v-if="imgUrl" class="mb-5">
        <img class="img-fluid" :src="imgUrl" alt="" />
      </div>

      <div v-if="!vdvUrl">
        <h6 v-if="vehicleName" class="mb-3 font-weight-bold">{{ vehicleName }}</h6>
      </div>

      <div v-if="vdvUrl">
        <a :href="vdvUrl" class="text-decoration-none color-black">
          <h6 v-if="vehicleName" class="mb-3 font-weight-bold">{{ vehicleName }}</h6>
        </a>
        <div class="d-flex justify-content-center ">
          <vdv-button :vdv-url="vdvUrl" />
        </div>
      </div>


      <div v-if="colorInfo && colorInfo !== null" class="d-flex align-items-center justify-content-center mb-2">
        <span v-if="colorInfo.colorCode" class="selected_color d-block"
          :style="{ background: '#' + colorInfo.colorCode }" />
        <span v-if="colorInfo.colorDescription" class="ml-2 d-block">{{ colorInfo.colorDescription }}</span>
      </div>
      <div v-if="nextQuestion" class="confirm_section">
        <p class="mb-0 ft-14 font-weight-bold"> {{ nextQuestion }}</p>
      </div>
    </div>
  </div>
</template>
<script src="./TvmReserveYourVehicleFinalView.js"></script>
<style scoped>
.selected_color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.tvm_reser_vehicle img {
  max-height: 140px;
  object-fit: contain;
}

.confirm_section {
  margin: 15px 0;
  word-break: break-word;
  text-align: left;
}
</style>
