// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address_card[data-v-3aadd738] {
  border-radius: 5px;
  border: 1px solid #eee;
}
.address_card.disabledContent[data-v-3aadd738] {
  pointer-events: none !important;
  opacity: 0.9 !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChatWidget/TvmMyAddress/TvmMyAddress.vue","webpack://./TvmMyAddress.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,sBAAA;ACAF;ADEE;EACE,+BAAA;EACA,uBAAA;ACAJ","sourcesContent":["\n.address_card {\n  border-radius: 5px;\n  border: 1px solid #eee;\n\n  &.disabledContent {\n    pointer-events: none !important;\n    opacity: 0.9 !important;\n  }\n}\n",".address_card {\n  border-radius: 5px;\n  border: 1px solid #eee;\n}\n.address_card.disabledContent {\n  pointer-events: none !important;\n  opacity: 0.9 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
