import { mapWritableState } from 'pinia';
import { useLeadFormsStore } from '../../store/LeadForms/LeadFormsStore';
import { logGoogleBingEvent } from '../../Services/Shared/GoogleBingEventsService';
import { useMapDirectionsStore } from '../../store/MapDirections/MapDirectionsStore';
import { Events } from '../../Events/ConsumerEvents/Events';
import {
  fireCTAClickEvents,
  fireGetDirectionsCTAEvent,
  fireSocialMediaInteractionEvent,
  fireTagMenuNavigationEvent,
  onCommonLinkLogs,
} from '../../Events/TagManagerServices/TaggingEventForPages';
import { useRegistrationStore } from '../../store/Registration/RegistrationStore';

import LanguageChangeIcon from '../../Components/LanguageChange/LanguageChange.vue';
import { fireSonicHeaderNavigationLink } from '../../Events/SonicDataLayer/SonicDataService';
import ResearchModelsContainer from '../../Components/ResearchModels/ResearchModelsContainer/ResearchModelsContainer.vue';
import LeadFormLink from '../LeadFormLink/LeadFormLink/LeadFormLink.vue';
import CompareMenu from '../../../../js/components/compare/CompareMenu.vue';
import { TagManagerContainer } from '../../Events/GoogleTagManager/TagManagerContainer';
import ShiftDigitalService from '../../Events/ShiftDigital/ShiftDigitalService';
import VerticalBuildPriceMenu from '../BuildPriceMenu/VerticalBuildPriceMenu/VerticalBuildPriceMenu.vue';
import {
  APOLLO_GENERIC_CTA,
  APOLLO_PARTS_CONV,
  APOLLO_SALES_CONV,
  APOLLO_SERVICE_CONV,
  DATE_DAYS,
} from '../../../../js/constants/constants';
import { useIframeModalStore } from '../../store/IframeModalStore/IframeModalStore';
import { fireTrackingCallClick } from '../../Events/TrackerServices/TrackerServices';
import { autoPopulateChatContactDetails, invocaLogic } from '../../../../js/services/CommonUtility';
import { LayoutConstants } from '../../../../js/constants/LayoutConstants';
import ShiftDigitalContainer from '../../Events/ShiftDigital/ShiftDigitalContainer';
import eventBus from '../../Services/EmitterService/EmitterService';
import {
  fireTagCTAInteraction,
  fireAllPageLoadEvents,
} from '../../Events/TagManagerServices/TaggingEventForPages';
import { useSearchMenuStore } from '../../store/Shared/SearchMenuStore/SearchMenuStore';
import { useBuildPriceMenuStore } from '../../store/BuildPriceMenuStore/BuildPriceMenuStore';
import HorizontalBuildPriceMenu from '../BuildPriceMenu/HorizontalBuildPriceMenu/HorizontalBuildPriceMenu.vue';
import UtmUrlService from '../../../../js/services/UtmUrlService';
import { SHIFT_DIGITAL_GET_DIRECTIONS_EVENT } from '../../../../js/services/ShiftDigital/ShiftDigitalConstants';
import FormOpening from '../../../../vue3/src/Services/FormServices/FormOpening';
import {
  CALL_UNITE_OBJECT,
  SALES_PHONE_LABEL_UNITE_DIGITAL,
  SERVICE_PHONE_LABEL_UNITE_DIGITAL,
} from '../../../../js/services/UniteDigital/UniteDigitalConstants';
import ModelSelection from '../ModelSelection/ModelSelection.vue';
import { shouldShowTextPermissionsPopUp } from '../../../../js/services/TextPermissionsPopUpUtils';
import { useTextModalStore } from '../../store/Shared/TextModalStore/TextModalStore';
import { useBuyNowModalStore } from '../../store/Shared/BuyNowModalStore/BuyNowModalStore';
import ChatWidget from '../ChatWidget/ChatWidget/ChatWidget.vue';
import axios from 'axios';

export default {
  template: '#main-template-header',
  components: {
    LanguageChangeIcon,
    ResearchModelsContainer,
    LeadFormLink,
    CompareMenu,
    VerticalBuildPriceMenu,
    HorizontalBuildPriceMenu,
    ModelSelection,
    ChatWidget,
  },
  setup() {},

  data() {
    return {
      shiftDigital: '',
      openLogin: false,
      showappraisal: false,
      showtextusform: false,
      shownotification: false,
      showiframelogin: false,
      showContactForm: false,
      showLeadForm: false,
      showleadform: false,
      notificationmessage: '',
      notificationtype: '',
      selectedMenuItem: '',
      tempMenuId: undefined,
      showBuildPriceMenu: false,
      leadformcomponent: '',
      showbonusoffer: false,
      compareMenu: false,
      showGarageSubmenu: false,
      showDirections: false,
      switchValue: '',
      showResearchModels: false,
      showPrimaryResearchModels: false,
      isMitsubishi: window.make.toLowerCase() === 'mitsubishi',
      enableINVOCA: window.enableINVOCA,
      mazdaSubNavCurrentIndex: undefined,
      showDesktopSubMenu: false,

      showsubmenu: false,

      allhoursGetDirections: false,
      mapiconGetDirections: false,
      showMenu: false,
      showHamburgerIcon: false,
      menuTransparent: true,
      menuStyleId: window.menuStyleId,
      isTransparentMenu: false,

      showCallButtonsModal: false,
      locationModelType: '',
      textChatCTAButtonLabel: '',
      showIframeUrl: false,
      showVehicleSearch: false,
      textUsTheme: {
        'background-color': window.textUsButtonColor,
        color: `${window.textUsFontColor} !important`,
        border: `${window.textUsFontColor ? '0px' : '1px solid #D9D9D9'} !important`,
      },
      showModelSelectionMenu: false,
      activeUrl: window.location.pathname,
      ScheduleServiceForm: false,
      showCallButtons: false,
      isBmwMake: window.make.toLowerCase() === 'bmw',

      //mega menu
      showMenuSection: false,
      selectedLevelOneMenu: '',
      showHeaderDealerHours: false,
      menuDefaultComponent: '',
      menuHasSettings: true,
      menuSectionSettings: [],
      showOthersMenuDropDown: false,

      //mazda oem data variables
      isMazdaOem: window.enableOEMProgram,
      isMobile: window.ismobile,
      subMenus: ['Shop online Inventory', 'Find Parts', 'Buy Accessories'],
      showPartsAccessories: false,
      showSubMenuBackDrop: false,
      timeout: null,
      menuClick: false,
      dealerDataForFlowersGroup: '',
    };
  },
  computed: {
    ...mapWritableState(useMapDirectionsStore, [
      'showAllHoursInDirections',
      'directionsClickedFromAllHours',
      'showMapDirectionsModal',
      'showMapIconInDirections',
    ]),
    ...mapWritableState(useLeadFormsStore, [
      'showRequestQuoteModal',
      'showScheduleServiceModal',
      'showValueYourTradeModal',
      'showTestDriveModal',
      'showBonusOfferModal',
      'showGetFinanceModal',
      'showContactServiceModal',
      'showAccessoryModal',
      'showPrecisionTradeValuePopup',
      'showServiceAppraisalPopup',
      'showPrecissionBonusOfferPopup',
      'showTextPermissionOverlay',
    ]),
    ...mapWritableState(useRegistrationStore, [
      'showRegistrationModal',
      'showSignInModal',
      'showSignOutModal',
      'showLocationModal',
      'locationModalPageName',
    ]),
    ...mapWritableState(useIframeModalStore, ['iframeUrl', 'showIframeModal']),
    ...mapWritableState(useSearchMenuStore, ['showSearchMenu']),
    ...mapWritableState(useBuildPriceMenuStore, ['showHorizontalBuildPriceMenu']),
    ...mapWritableState(useTextModalStore, ['showCookieDisclaimer']),
    ...mapWritableState(useBuyNowModalStore, ['showBuyNowPopUp', 'buyNowPopType']),
  },
  mounted() {
    if (this.isMobile) {
      this.showSubMenu = false;
    }
    UtmUrlService.saveUtmUrl();
    const hashvalue = window.location.hash;
    let url = window.location.href;
    let index = url.indexOf('?');
    let textUs = url.substr(index);
    if (index !== -1) {
      if (textUs.toLowerCase() === '?textus=service' || textUs.toLowerCase() === '?textus=sales') {
        this.openLogin = false;
        this.showtextusform = true;
        this.showSearchMenu = false;
        this.showiframelogin = false;
      }
    }
    if (hashvalue) {
      if (hashvalue.toLowerCase() === '#getdirections') {
        this.onDirectionsClick();
      }
    }
    if (
      window.location.pathname.toLowerCase().indexOf('/landing/autologin') > -1 &&
      hashvalue.toLowerCase() === '#value' &&
      window.isauthenticated === 'True'
    ) {
      window.location = '/shopperwizard/mytradevalue';
    }

    if (window.location.pathname === '/login') {
      this.onShowSignInModal();
    }
    if (
      window.location.pathname &&
      (window.location.pathname.toLowerCase() === '/register' ||
        window.location.pathname.toLowerCase() === '/signin')
    ) {
      this.showRegistrationModal = true;
    } else if (
      window.showPhoneNumberLoginOnEntry &&
      window.isauthenticated === 'False' &&
      !this.showSignOutModal &&
      (window.location.pathname.toLowerCase() === '/' ||
        window.location.pathname.toLowerCase() === '/landing')
    ) {
      this.showRegistrationModal = true;
    } else if (
      window.enablePINLoginEntry === 'True' &&
      window.isauthenticated === 'False' &&
      !this.showSignOutModal &&
      (window.location.pathname.toLowerCase() === '/' ||
        window.location.pathname.toLowerCase() === '/landing')
    ) {
      if (!sessionStorage.closeAutoLogin) this.onShowSignInModal();
    }

    eventBus.$on('shownotificationmessage', (data) => {
      this.notificationmessage = data.ActivationMessage;
      this.notificationtype = data.MessageType;
      this.showiframelogin = false;
      setTimeout(() => {
        this.shownotification = true;
      }, 400);
    });
    this.loadIframeOverride();
    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
    this.showSignOutModalEvent();
    fireTagCTAInteraction();
    this.iframeMarginOverride();
    this.changeFooterTextLabel();
    this.getEVMenuLabelForKia();
    // don't remove below one
    window.previousHeight = window.innerHeight;
    //gemini header code
    if (document.querySelector('#gemini-menu')) {
      document.querySelector('#gemini-menu').className = '';
    }
    if (document.querySelector('#geminimenuoverlay')) {
      document.querySelector('#geminimenuoverlay').className = '';
    }
    if (
      this.menuStyleId.toString() === '8' ||
      this.menuStyleId.toString() === '9' ||
      this.menuStyleId.toString() === '10'
    ) {
      this.isTransparentMenu = window.isPageLanding;
    }
    let textChatCTAButtonLabel;
    if (window.isTextUsEnabled && window.isGoogleChatEnabled) {
      textChatCTAButtonLabel = 'Text or Chat';
    } else if (window.isTextUsEnabled) {
      textChatCTAButtonLabel = 'Text Us';
    } else if (window.isGoogleChatEnabled) {
      textChatCTAButtonLabel = 'Chat';
    } else {
      textChatCTAButtonLabel = '';
    }
    this.textChatCTAButtonLabel = window.textUsCTAButtonLabel || textChatCTAButtonLabel;

    //geminin header code ends
    if (window.isGoogleChatEnabled) {
      autoPopulateChatContactDetails();
    }

    fireAllPageLoadEvents();
    //this.getAllMenuSectionSettings();

    // mazda header code
    if (document.querySelector('#searchmenu')) {
      document.querySelector('#searchmenu').className = '';
    }
    if (document.querySelector('#textmenu')) {
      document.querySelector('#textmenu').className = '';
    }

    if (document.querySelector('#iframeloginid')) {
      document.querySelector('#iframeloginid').className = '';
    }
    this.removeSubMenuInitialLoadDorpDown();
    this.checkForPrecisionComponent();
    this.showTextPermissionOverlay = shouldShowTextPermissionsPopUp();
    this.enableAndDisableCookieDisclaimer();
    if (window.accountId === '70003' || window.accountId === '69775') {
      this.getDealerHoursForGroupSite();
    }
  },
  methods: {
    onSocialMediaClick(socialMedia, url, event) {
      if (window.openSocialLinksInLightBox && event) {
        console.log(event);
        event && event.preventDefault();
        this.showIframeModal = true;
        this.iframeUrl = url;
      }

      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getSocialMediaEvent === 'function') {
        tagManager.getSocialMediaEvent(socialMedia, url);
      }

      logGoogleBingEvent(APOLLO_GENERIC_CTA, 'Click', socialMedia, 1, socialMedia + ' ' + 'Page');
      fireSocialMediaInteractionEvent(socialMedia, url);
    },
    onBuildPriceMenuClick(menuName = '') {
      fireSonicHeaderNavigationLink(menuName);
      if (menuName) {
        this.logGoogleEvent(menuName);
      }
      this.selectedMenuItem = menuName;
      this.showBuildPriceMenu = !this.showBuildPriceMenu;

      setTimeout(function () {
        if (document.getElementById('build-and-price')) {
          document.getElementById('build-and-price').style.display = 'block';
        }
      }, 200);
      this.showContactForm = false;
      this.showResearchModels = false;
      this.showPrimaryResearchModels = false;
      this.headerMenuInteraction({
        element_text: menuName,
        element_type: 'header',
        element_subtype: 'sidebar_menu',
        element_action: 'slideout',
      });
    },

    onPrimaryResearchModelsClick(
      subMenuName = '',
      menuName = '',
      subName = '',
      mainMenuName = '',
      sidemenu = ''
    ) {
      fireSonicHeaderNavigationLink(subMenuName, menuName);
      this.headerSubMenuClick(name, subName);
      this.showSubMenuBackDrop = false;
      if (menuName) {
        this.logGoogleEvent(menuName);
      }
      this.selectedMenuItem = menuName;
      this.showPrimaryResearchModels = !this.showPrimaryResearchModels;
      this.showContactForm = false;
      this.showBuildPriceMenu = false;
      this.showResearchModels = false;
      // mazda header code
      if (this.showPrimaryResearchModels) {
        this.showSubMenuBackDrop = true;
      }
      this.headerMenuInteraction({
        element_text: subMenuName || menuName,
        element_type: 'header',
        element_subtype: 'sidebar_menu',
        element_action: 'slideout',
      });
      if (!sidemenu) {
        this.showMenu = !this.showMenu;
        this.showsubmenu = !this.showsubmenu;
      }
    },
    headerMenuInteraction(menuEventObj) {
      fireTagMenuNavigationEvent(menuEventObj);
    },
    onSignInModalOpen() {
      this.onShowSignInModal();
    },
    /**
     * Opens signin modal as iframe
     */
    onShowSignInModal() {
      this.showSignInModal = true;
      this.mazdaSubNavCurrentIndex = undefined;
    },

    onOpenLogin() {
      this.openLogin = !this.openLogin;
      if (this.openLogin) {
        let eventModel = {
          ...Events.consumerEventModel,
          consumerEventId: Events.eventsConstants.LoginClick,
        };
        Events.logConsumerEvent(eventModel);
      }
    },
    /**
     * SIgnin button click event
     */
    headerSignInClick() {
      this.signInConsumerEvent(Events.eventsConstants.HeaderSignInClick);
      fireCTAClickEvents('Sign In', { label: 'Sign In' });
      this.showRegistrationModal = true;
    },

    openRegistrationModal() {
      this.showRegistrationModal = true;
    },

    signInConsumerEvent(eventId) {
      let eventModel = {
        ...Events.consumerEventModel,
        consumerEventId: eventId,
      };
      Events.logConsumerEvent(eventModel);
    },
    /**
     * Opens the direactions modal map with hours
     */
    allHoursClick() {
      this.showAllHoursInDirections = true;
      this.directionsClickedFromAllHours = true;
      this.showMapIconInDirections = false;
      this.showMapDirectionsModal = true;
    },
    /**
     * Opens the Leadform like Request a QUote
     * @param {*} name
     * @param {*} mainName
     * @param {*} subName
     */
    onLeadFormOpen(name, mainName = '', subName = '') {
      if (name === 'RequestQuote') {
        this.showRequestQuoteModal = true;
      } else if (name === 'ValueYourTrade') {
        this.showValueYourTradeModal = true;
      } else if (name === 'TestDrive') {
        this.showTestDriveModal = true;
      } else if (name === 'ScheduleService') {
        this.showScheduleServiceModal = true;
      } else if (name === 'GetFinance') {
        this.showGetFinanceModal = true;
      } else if (name === 'BonusOffer') {
        this.showBonusOfferModal = true;
      } else if (name === 'ContactUsService') {
        this.showContactServiceModal = true;
      } else if (name === 'PartsandAccessories') {
        this.showAccessoryModal = true;
      }

      if (subName === '') {
        fireSonicHeaderNavigationLink(mainName);
      } else {
        fireSonicHeaderNavigationLink(mainName, subName);
      }

      if (name) {
        this.logGoogleEvent(name);
      }
      this.headerMenuInteraction({
        element_text: subName || mainName,
        element_type: 'header',
        element_subtype: 'sidebar_menu',
        element_action: 'popup',
      });
    },
    onLeadFormOpenMurgado(name, mainName = '', subName = '') {
      if (name === 'RequestQuote') {
        this.showRequestQuoteModal = true;
      } else if (name === 'ValueYourTrade') {
        this.showValueYourTradeModal = true;
      } else if (name === 'TestDrive') {
        this.showTestDriveModal = true;
      } else if (name === 'ScheduleService') {
        this.showScheduleServiceModal = true;
      } else if (name === 'GetFinance') {
        this.showGetFinanceModal = true;
      } else if (name === 'BonusOffer') {
        this.showBonusOfferModal = true;
      } else if (name === 'ContactUsService') {
        this.showContactServiceModal = true;
      } else if (name === 'PartsandAccessories') {
        this.showAccessoryModal = true;
      }
      if (subName === '') {
        fireSonicHeaderNavigationLink(mainName);
      } else {
        fireSonicHeaderNavigationLink(mainName, subName);
      }
      if (name) {
        this.logGoogleEvent(name);
      }
      this.headerMenuInteraction({
        element_text: subName || mainName,
        element_type: 'header',
        element_subtype: 'sidebar_menu',
        element_action: 'popup',
      });
    },
    /**
     * Direct redirect main menu and submenu link event
     * @param {*} menuName
     * @param {*} subMenuName
     */
    onSimplifySonicHeader(menuName = '', subMenuName = '') {
      if (subMenuName === '') {
        fireSonicHeaderNavigationLink(menuName);
      } else {
        fireSonicHeaderNavigationLink(menuName, subMenuName);
      }
      this.headerMenuInteraction({
        element_text: subMenuName || menuName,
        element_type: 'header',
        element_subtype: 'sidebar_menu',
      });
    },

    headerMenuNavigationEvent(linkText, linkUrl, menuName = '') {
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getMenuEventClick === 'function') {
        tagManager.getMenuEventClick(linkText, linkUrl, menuName);
      }
      if (linkUrl?.toLowerCase().includes('scheduleservice') && this.shiftDigital) {
        ShiftDigitalService.scheduleServiceClick(this.shiftDigital);
      }
    },

    logGoogleEvent(menuName) {
      logGoogleBingEvent('landing page', 'menu_click', menuName.toLowerCase(), 1, 'menu click');
    },
    /**
     * Added as an click event binding for the main menu link which has submenu
     * @param {} menuId
     * @param {*} menuName
     */
    onContactFormClick(menuId, menuName) {
      console.log('main maenu click');
      fireSonicHeaderNavigationLink(menuName);
      if (window.menuEvent !== 'click') {
        return;
      }
      if (menuName) {
        this.logGoogleEvent(menuName);
      }
      this.selectedMenuItem = menuName;
      if (menuId === this.tempMenuId) {
        this.tempMenuId = menuId;
        this.showContactForm = !this.showContactForm;
      } else {
        this.showContactForm = true;
        this.tempMenuId = menuId;
      }

      // mazda header code
      //to show or hide menu backdrop
      if (menuId && this.showContactForm && menuId === this.tempMenuId) {
        this.showSubMenuBackDrop = true;
      } else {
        this.showSubMenuBackDrop = false;
      }
      setTimeout(() => {
        this.removeSubmenuInitialload();
      }, 200);

      setTimeout(function () {
        if (document.getElementById('simplify-submenu')) {
          document.getElementById('simplify-submenu').style.display = 'block';
        }
        if (document.getElementById('primary-submenu')) {
          document.getElementById('primary-submenu').style.display = 'block';
        }
      }, 200);

      // let menuEventObj = {
      //   element_text: menuName,
      //   element_type: 'header',
      //   element_subtype: 'sidebar_menu',
      //   element_action: 'dropdown',
      // };

      //fireTagMenuNavigationEvent(menuEventObj);

      this.showBuildPriceMenu = false;
      this.showPrimaryResearchModels = false;
      this.showModelSelectionMenu = false;
      this.headerMenuInteraction({
        element_text: menuName,
        element_type: 'header',
        element_subtype: 'sidebar_menu',
        element_action: 'dropdown',
      });
    },

    /**
     * Main menu linkevent which has submenu
     * @param {*} menuId
     * @param {*} menuName
     */

    onMouseOver(menuId, menuName = '') {
      if (window.menuEvent !== 'mouse-over') {
        return;
      }
      this.selectedMenuItem = menuName;
      this.showContactForm = true;
      this.tempMenuId = menuId;
      setTimeout(function () {
        if (document.getElementById('simplify-submenu')) {
          document.getElementById('simplify-submenu').style.display = 'block';
        }
        if (document.getElementById('primary-submenu')) {
          document.getElementById('primary-submenu').style.display = 'block';
        }
      }, 200);
      // mazda header code
      //to show or hide menu backdrop
      if (menuId && this.showContactForm && menuId === this.tempMenuId) {
        this.showSubMenuBackDrop = true;
      } else {
        this.showSubMenuBackDrop = false;
      }
      setTimeout(() => {
        this.removeSubmenuInitialload();
      }, 200);

      this.showBuildPriceMenu = false;
      this.showPrimaryResearchModels = false;
      this.showModelSelectionMenu = false;
      if (this.timeout) clearTimeout(this.timeout);

      this.headerMenuInteraction({
        element_text: menuName,
        element_type: 'header',
        element_subtype: 'sidebar_menu',
        element_action: 'dropdown',
      });

      this.timeout = setTimeout(() => {
        this.headerMenuInteraction({
          element_text: menuName,
          element_type: 'header',
          element_subtype: 'nav_tab',
          element_action: 'dropdown',
        });
      }, 200);
    },

    onMouseLeave() {
      if (window.menuEvent !== 'mouse-over') {
        return;
      }
      this.selectedMenuItem = '';
      this.showContactForm = false;
      this.tempMenuId = '';
      this.showSubMenuBackDrop = false;
    },

    subMenuClose() {
      this.selectedMenuItem = '';
      this.showContactForm = false;
      this.tempMenuId = '';
      this.showsubmenu = false;
    },

    openParagonPopup(type, mainName = '', subName = '') {
      this.headerSubMenuClick(mainName, subName);
      this.buyNowPopType = 'used';
      if (type === 'paragongroupinventorynewpopup') {
        this.buyNowPopType = 'new';
      }
      this.showBuyNowPopUp = true;
      this.headerMenuInteraction({
        element_text: subName || mainName,
        element_type: 'header',
        element_subtype: 'nav_tab',
        element_action: 'popup',
      });
    },

    onProfileClick() {
      this.showiframelogin = true;
      this.showGarageSubmenu = false;
    },

    onSearchClick() {
      this.showSearchMenu = true;
      let eventModel = {
        ...Events.consumerEventModel,
        consumerEventId: Events.eventsConstants.Searchiconclick,
      };
      Events.logConsumerEvent(eventModel);
    },

    onContactSalesClick(phoneNumber, textname, pagename) {
      fireTrackingCallClick(phoneNumber, textname, 'sideNavigation');
      // mazda header code
      if (textname.indexOf('Sales') !== -1) {
        FormOpening.viewPage(SALES_PHONE_LABEL_UNITE_DIGITAL, CALL_UNITE_OBJECT, 'select', {
          category: 'call me',
        });
      } else {
        FormOpening.viewPage(SERVICE_PHONE_LABEL_UNITE_DIGITAL, CALL_UNITE_OBJECT, 'select', {
          category: 'call me',
        });
      }
      if (this.enableINVOCA === true) {
        invocaLogic();
      }
      this.logEvent(Events.eventsConstants.Clickedtocall, textname);
      if (this.enableINVOCA === true) {
        invocaLogic();
      }
      if (this.shiftDigital) {
        ShiftDigitalService.SubmitCallEvent(this.shiftDigital, textname);
      }
    },
    onTextUsClick() {
      window.dispatchEvent(new CustomEvent('chatwidgetloader', { detail: true }));
    },

    logEvent(eventid, name) {
      let eventmodel = {
        ...Events.consumerEventModel,
        consumerEventId: eventid,
        shopperWizardButtonText: name,
      };
      Events.logConsumerEvent(eventmodel);
      let department = name?.toLowerCase();

      if (department.indexOf('parts') !== -1) {
        this.callClick(APOLLO_PARTS_CONV, 'parts');
      } else if (department.indexOf('service') !== -1) {
        this.callClick(APOLLO_SERVICE_CONV, 'service');
      } else {
        this.callClick(APOLLO_SALES_CONV, 'sales');
      }
    },
    callClick(event, department) {
      logGoogleBingEvent(
        event,
        'call',
        'contact_us_' + department,
        1,
        'contact us' + department + 'phone number click'
      );
    },

    onResearchModelsClick(menuName = '') {
      if (menuName) {
        this.logGoogleEvent(menuName);
      }
      this.selectedMenuItem = menuName;
      this.showResearchModels = !this.showResearchModels;
      this.showContactForm = false;
      this.showBuildPriceMenu = false;
      this.showPrimaryResearchModels = false;

      if (this.showResearchModels) {
        this.showSubMenuBackDrop = true;
      }
      this.headerMenuInteraction({
        element_text: menuName,
        element_type: 'header',
        element_subtype: 'nav_tab',
        element_action: 'slideout',
      });
    },

    onDirectionsClick() {
      let eventModel = {
        ...Events.consumerEventModel,
        consumerEventId: Events.eventsConstants.opengetdirectionForm,
      };
      Events.logConsumerEvent(eventModel);
      logGoogleBingEvent(
        'Landing',
        'Button click',
        'Get Directions',
        1,
        'Lead Form Thank You Get Directions'
      );
      fireGetDirectionsCTAEvent();
      window.location.href = 'get-directions';
    },
    showGarage() {
      this.showGarageSubmenu = !this.showGarageSubmenu;
    },
    onCloseClick() {
      this.openLogin = false;
      this.showtextusform = false;
      this.showSearchMenu = false;
      this.showiframelogin = false;
    },
    closeNotification() {
      this.shownotification = false;
    },
    loadIframeOverride() {
      if (this.isInIframe()) {
        setTimeout(() => {
          if (document.getElementById('simplify-side-nav') !== null && !this.showHeader()) {
            document.getElementById('simplify-side-nav').style.display = 'none';
          }

          if (document.getElementById('website-simplify-footer') !== null && !this.showFooter()) {
            document.getElementById('website-simplify-footer').style.display = 'none';
          }
          if (document.getElementById('newspecials-title') !== null) {
            document.getElementById('newspecials-title').style.display = 'none';
          }
          if (document.getElementById('newspecial-container') !== null) {
            document.getElementById('newspecial-container').style.display = 'none';
          }

          if (document.getElementById('newspecials-heading') !== null) {
            document.getElementById('newspecials-heading').style.display = 'none';
          }
          if (document.getElementById('simplify-right-section') !== null && !this.showHeader()) {
            document.getElementById('simplify-right-section').style.cssText = 'width:100%;left:0';
          }
          // mazda header code
          if (document.getElementById('website-mazda-oem-header') !== null && !this.showHeader()) {
            document.getElementById('website-mazda-oem-header').style.display = 'none';
          }
          if (document.getElementById('website-mazda-oem-footer') !== null && !this.showFooter()) {
            document.getElementById('website-mazda-oem-footer').style.display = 'none';
          }
          if (document.getElementById('website-primary-header') !== null && !this.showHeader()) {
            document.getElementById('website-primary-header').style.display = 'none';
          }
          if (document.getElementById('website-primary-footer') !== null && !this.showFooter()) {
            document.getElementById('website-primary-footer').style.display = 'none';
          }
          if (document.getElementById('primary-new-body') !== null && !this.showHeader()) {
            document.getElementById('primary-new-body').style.margin = '0';
          }
        }, 200);
      }
    },
    isInIframe() {
      return window.location !== window.parent.location;
    },
    menuInteraction(event, mainMenuName, mainMenuUrl, subMenuName = '', subMenuUrl = '') {
      this.headerMenuInteraction({
        element_text: subMenuName || mainMenuName,
        element_type: 'header',
        element_subtype: 'sidebar_menu',
        element_action: 'redirect',
      });
    },
    redirectMenuInteraction(event, mainMenuName, mainMenuUrl, subMenuName = '', subMenuUrl = '') {
      this.headerMenuInteraction({
        element_text: subMenuName || mainMenuName,
        element_type: 'header',
        element_subtype: 'sidebar_menu',
        element_action: 'redirect',
      });
    },
    hideDropDownOnClick() {
      // used to close subnav dropdowns
      this.mazdaSubNavCurrentIndex = undefined;
    },
    activeState(event) {
      return event === this.activeUrl;
    },
    showSubMenuList(index) {
      if (index === this.mazdaSubNavCurrentIndex) {
        this.mazdaSubNavCurrentIndex = undefined;
      } else {
        this.mazdaSubNavCurrentIndex = index;
      }
    },
    bindEvent(mainMenuName, mainMenuLink, subMenuName = '', subMenuLink = '') {
      fireSonicHeaderNavigationLink(mainMenuName, subMenuName);
    },
    onHeaderClick(menu = '') {
      this.mazdaSubNavCurrentIndex = undefined; //this is only for mazda sub nav closing
      if (menu === 'gemini') {
        this.$root.$emit('hideChatWidget');
        if (this.showPrimaryResearchModels || this.showModelSelectionMenu) {
          this.showPrimaryResearchModels = false;
          this.showsubmenu = false;
          this.showMenu = false;
          this.showModelSelectionMenu = false;
        }
        setTimeout(() => {
          invocaLogic();
        }, 1000);
        onCommonLinkLogs({ label: 'header Menu click', url: window.location.href });
      }

      if (this.showsubmenu) {
        this.subMenuClose();
      } else {
        this.showMenu = !this.showMenu;
      }
    },
    showHeader() {
      let paramsString = window.location.search ? window.location.search.toLocaleLowerCase() : '';
      let searchParams = new URLSearchParams(paramsString);
      return searchParams.has('showheader') && searchParams.get('showheader') === 'true';
    },
    showFooter() {
      let paramsString = window.location.search ? window.location.search.toLocaleLowerCase() : '';
      let searchParams = new URLSearchParams(paramsString);
      return searchParams.has('showfooter') && searchParams.get('showfooter') === 'true';
    },
    onHeaderPhoneClick(type) {
      this.mazdaSubNavCurrentIndex = undefined;
      if (window.isDealerGroup && !window.showDealerGroupNumber) {
        this.showLocationModal = true;
        this.locationModelType = type;
      } else {
        this.showCallButtonsModal = true;
      }
      onCommonLinkLogs({ label: 'Contact Us Icon' });
    },
    changeFooterTextLabel() {
      if (window.isTextUsEnabled && window.isGoogleChatEnabled) {
        if (
          document.querySelectorAll('.text-white.icons span') &&
          window.textUsMobileCTAButtonLabel
        ) {
          document.querySelectorAll('.text-white.icons span').forEach((item) => {
            if (item.innerText === 'Text') {
              item.innerText = window.textUsMobileCTAButtonLabel;
            }
          });
        }
      }
      if (window.isGoogleChatEnabled) {
        if (
          sessionStorage.getItem('chatTextModal') &&
          sessionStorage.getItem('chatTextModal') !== null
        ) {
          let chatTextModal = JSON.parse(sessionStorage.getItem('chatTextModal'));
          if (
            chatTextModal &&
            document.querySelector('#mobileTextUsLabel') &&
            window.performance &&
            window.performance.navigation.type !== window.performance.navigation.TYPE_BACK_FORWARD
          ) {
            setTimeout(() => {
              document.querySelector('#mobileTextUsLabel').click();
            }, 100);
          }
        }
      }
    },
    isEvVehicleText(text = '') {
      return text && text.toLowerCase() === 'ev/hybrid' ? 'hybrid-menu' : '';
    },
    hideVehicleSearch() {
      this.showVehicleSearch = false;
    },
    getHoursLabelValue(label) {
      return label ? label.replaceAll(':00', '') : '';
    },
    getEVMenuLabelForKia() {
      const themeName = window?.themeName?.toLowerCase();
      const headerColor = window?.headerColor;

      // Ensure the theme is "kia"
      if (!themeName?.includes('kia')) return;

      // Determine menu item class based on layout
      let searchItem = 'h-menu-item'; // Default class
      if (LayoutConstants.isSimplifyTemplate()) {
        searchItem = 'menu-item';
      } else if (LayoutConstants.isLuxTemplate()) {
        searchItem = 'side-nav-menu-item';
      } else if (LayoutConstants.isClassicTemplate()) {
        searchItem = 'classic-menu-items';
      }

      // Fetch menu items
      const evMenuItems = document.getElementsByClassName(searchItem);

      // Iterate through menu items and style "EV/Hybrid"
      Array.from(evMenuItems).forEach((item) => {
        const text = item?.textContent?.trim()?.toLowerCase();
        if (text === 'ev/hybrid') {
          const span = item.querySelector('span');
          if (!span) return;

          // Apply color based on header color
          const colorMap = {
            1: '#238705', // Green
            2: '#fff', // White
            3: '#fff', // White
          };
          const color = colorMap[headerColor];
          if (color) span.style.setProperty('color', color, 'important');
        }
      });
    },
    onModelSelectionMenuClick(menuName = '', name = '', submenuName = '', headerType = '') {
      fireSonicHeaderNavigationLink(name, submenuName);
      this.showSubMenuBackDrop = false;

      if (menuName) {
        this.logGoogleEvent(menuName);
      }
      this.selectedMenuItem = menuName;
      this.showModelSelectionMenu = !this.showModelSelectionMenu;
      this.showContactForm = false;
      this.showResearchModels = false;
      this.showPrimaryResearchModels = false;
      if (this.showModelSelectionMenu) {
        this.showSubMenuBackDrop = true;
      }
      if (headerType === 'gemini') {
        this.showSubMenuBackDrop = false;
        this.showMenu = false;
      }
      this.headerMenuInteraction({
        element_text: submenuName || name,
        element_type: 'header',
        element_subtype: 'nav_tab',
        element_action: 'slideout',
      });
    },
    iframeMarginOverride() {
      if (window.location !== window.parent.location) {
        setTimeout(() => {
          try {
            document.querySelector('body').classList.add('iframeClass');
          } catch (e) {
            console.log(e);
          }
        }, 200);
      }
    },
    showSignOutModalEvent() {
      const showSignBack = localStorage.getItem('showSignBack');
      if (showSignBack === 'true' && window.isauthenticated === 'False') {
        localStorage.setItem('showSignBack', 'false');
        this.showSignOutModal = true;
      }
    },
    onLogoClick() {
      onCommonLinkLogs({ label: 'Logo', url: 'Home' });
    },
    onCommonLinkLogs(params) {
      onCommonLinkLogs(params);
    },
    onLogOutClick() {
      onCommonLinkLogs({ label: 'Logout', url: '/logout' });
      localStorage.setItem('showSignBack', 'true');
      window.location.href = '/logout';
    },
    onCallIconClick(phoneNumber, department) {
      fireTrackingCallClick(phoneNumber, department);
    },
    onDirectionsLinkClickForEvent() {
      fireGetDirectionsCTAEvent();
    },
    mobileHeaderSignInClick() {
      this.signInConsumerEvent(Events.eventsConstants.MobileHeaderSignInClick);
      fireCTAClickEvents('Sign In', { label: 'Sign In' });
      this.showRegistrationModal = true;
    },
    headerSubMenuClick(mainName = '', subName = '') {
      if (subName === '') {
        fireSonicHeaderNavigationLink(mainName);
      } else {
        fireSonicHeaderNavigationLink(mainName, subName);
      }
    },
    removeSubMenuInitialLoadDorpDown() {
      let subMenuOverlay = document.querySelectorAll('.mazda-submenu-opening.d-lg-none'); // initial hiding of submenu on pageload
      if (subMenuOverlay.length) {
        subMenuOverlay.forEach((item) => {
          item.classList.remove('d-lg-none');
        });
      }
    },
    removeSubmenuInitialload() {
      let subMenuOverlay = document.querySelectorAll('.moem-submenu-opening.d-none'); // initial hiding of submenu on pageload
      if (subMenuOverlay.length) {
        subMenuOverlay.forEach((item) => {
          item.classList.remove('d-none');
        });
      }
    },
    onDealerGroupContactClick(pageName = 'contactUs') {
      this.locationModalPageName = pageName;
      this.showLocationModal = true;
    },
    openDirections() {
      this.showDirections = true;
      if (this.shiftDigital) {
        this.shiftDigital.submitEvent(SHIFT_DIGITAL_GET_DIRECTIONS_EVENT);
      }
    },
    logTagClickEvents(label, url) {
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getLinkClickEvent === 'function') {
        tagManager.getLinkClickEvent(label, url);
      }
    },
    closeSubMenuBackDrop() {
      this.showSubMenuBackDrop = false;
      this.showBuildPriceMenu = false;
      this.showPrimaryResearchModels = false;
      this.showModelSelectionMenu = false;
      this.showContactForm = false;
    },
    checkForPrecisionComponent() {
      if (window.isauthenticated === 'True') {
        this.showPrecisionTradeValuePopup = !!window.showPrecisionTradeValuePopup;
        this.showServiceAppraisalPopup = !!window.showServiceAppraisalPopup;
        this.showPrecissionBonusOfferPopup = !!(
          window.showPrecisionBonusOfferPopup && window.isBonusOfferExists
        );
      }
    },
    espanolClick() {
      const espanol = document.getElementById('google_translate_element');
      if (espanol) espanol.click();
    },
    enableAndDisableCookieDisclaimer() {
      if (window.showCookieDisclaimer) {
        let cookieValues = document.cookie;
        if (cookieValues.indexOf('cookiedisclaimer=yes') === -1) {
          this.showCookieDisclaimer = true;
        }
      }
    },

    /**mega menu JS code starts from here */
    onHeaderHoursClick() {
      this.showHeaderDealerHours = true;
    },
    hamburgerClick(event = null) {
      this.showMenuSection = !this.showMenuSection;
      setTimeout(() => {
        invocaLogic();
      }, 1000);
      onCommonLinkLogs({ label: 'header Menu click', url: window.location.href });

      //on opening menu select the first item by default
      if (this.showMenuSection) {
        setTimeout(() => {
          this.autoSelectLevelOneMenu();
        }, 100);
      }
      this.blockPageScroll();
    },
    blockPageScroll() {
      if (this.showMenuSection) {
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
      } else {
        document.getElementsByTagName('html')[0].style.overflow = '';
      }
    },
    autoSelectLevelOneMenu() {
      try {
        const firstLevelOneMenuItem = document.querySelector(
          '.level-one-menu-item[data-levelOneMenuId]'
        );
        if (firstLevelOneMenuItem) {
          const firstMenuId = firstLevelOneMenuItem.getAttribute('data-levelOneMenuId');
          this.onLevelOneMenuClick({ menuId: firstMenuId, hasSubMenu: true });
        }
      } catch (err) {
        console.log(err);
      }
    },
    /** menuTrackingEvents: menu related tracking events will be in this function
     *
     */
    menuResearchModelClick(menuOptions = {}) {
      const options = this.getMenuOptions(menuOptions);
      this.menuTrackingEvents(options);
      this.showOthersMenuDropDown = false;
      this.showMenuSection = true;

      if (this.selectedLevelOneMenu !== options.menuId) {
        this.clearMenuDynamicSection();
        this.selectedLevelOneMenu = +options.menuId;
        this.menuDefaultComponent = 'MenuResearchModels';
        this.menuHasSettings = true;
      }

      this.blockPageScroll();
    },
    onLevelOneMenuClick(menuOptions = {}) {
      const options = this.getMenuOptions(menuOptions);
      this.menuTrackingEvents(options);
      this.showOthersMenuDropDown = false;

      if (this.selectedLevelOneMenu !== options.menuId) {
        this.clearMenuDynamicSection();
        this.menuHasSettings = this.findMenuIdHasSettings(options.menuId);
        this.showMenuSection = menuOptions.hasSubMenu || this.menuHasSettings;
        this.selectedLevelOneMenu = +options.menuId;
      }

      // Prevent default behavior if menu has settings
      if (this.menuHasSettings) {
        options.event?.preventDefault();
      }
      this.blockPageScroll();
    },
    onLevelTwoMenuClick(menuOptions = {}) {
      this.menuTrackingEvents(this.getMenuOptions(menuOptions));
    },
    onLevelThreeMenuClick(menuOptions = {}) {
      this.menuTrackingEvents(this.getMenuOptions(menuOptions));
    },
    clearMenuDynamicSection() {
      this.menuDefaultComponent = '';
      this.menuHasSettings = false;
    },
    findMenuIdHasSettings(menuId) {
      return this.menuSectionSettings?.some((item) => item.menuId === +menuId) || false;
    },
    async getAllMenuSectionSettings() {
      if (window.subMenuTypeMegaMenu) {
        try {
          const response = await Axios.get(
            `${window.ApiBaseUrl}/Menu/getmenusectionsettings/${window.campaignId}`
          );

          if (response?.data) {
            this.menuSectionSettings = response.data;
            //store.commit(SET_MENU_SECTION_SETTINGS, response.data);
          } else {
            console.warn('No data returned from the API.');
          }
        } catch (error) {
          console.error('Error fetching menu section settings:', error);
        }
      }
    },

    levelOneMenuDropDown() {
      this.showOthersMenuDropDown = true;
    },
    closeLevelOneMenuDropDown() {
      this.showOthersMenuDropDown = false;
    },

    /** functions for horizontal design type mega menu
     * hLevelOneClick: used for horizontal level one menu click with no submenu
     * hLevelOneWtSubMenuClick: used for horizontal level one menu click with submenu
     * showOthersMenuDropDown: used for float design type mega menu
     * hResearchModelsClick: used for horizontal research models menu click
     * findMenuIdHasSettings: used to check menuId has any dynamic components configured in Admin
     */
    hLevelOneClick(menuOptions = {}) {
      if (menuOptions.menuId && this.findMenuIdHasSettings(menuOptions.menuId)) {
        this.showMenuSection = true;
      } else {
        this.showMenuSection = false;
      }
      this.showOthersMenuDropDown = false;
      this.onLevelOneMenuClick(menuOptions);
      this.blockPageScroll();
    },
    hLevelOneWtSubMenuClick(menuOptions = {}) {
      this.showMenuSection = true;
      this.showOthersMenuDropDown = false;
      this.onLevelOneMenuClick(menuOptions);
      this.blockPageScroll();
    },
    hResearchModelsClick(menuOptions = {}) {
      this.showMenuSection = true;
      this.showOthersMenuDropDown = false;
      this.menuResearchModelClick(menuOptions);
      this.blockPageScroll();
    },
    async menuTrackingEvents(menuOptions = {}) {
      const { event, menuLevel, menuName, menuLink, subMenuName, subMenuLink, subSubMenuName, subSubMenuLink } = this.getMenuOptions(menuOptions);
      if(menuLevel === "submenu") {
        await fireSonicHeaderNavigationLink(menuName, subMenuName);
        this.headerMenuInteraction({
          element_text: subMenuName,
          element_type: 'header',
          element_subtype: 'nav_tab',
          element_action: 'redirect',
          menu_details: {
            mainMenuName: menuName,
            mainMenuUrl: menuLink,
            subMenuName: subMenuName,
            subMenuUrl: subMenuLink,
          },
        });
      }
      else if(menuLevel === "sub submenu") {
        await fireSonicHeaderNavigationLink(subMenuName, subSubMenuName);
        this.headerMenuInteraction({
          element_text: subSubMenuName,
          element_type: 'header',
          element_subtype: 'nav_tab',
          element_action: 'redirect',
          menu_details: {
            mainMenuName: subMenuName,
            mainMenuUrl: subMenuLink,
            subMenuName: subSubMenuName,
            subMenuUrl: subSubMenuLink,
          },
        });
      }
      else {
        await fireSonicHeaderNavigationLink(menuName);
        this.headerMenuInteraction({
          element_text: menuName,
          element_type: 'header',
          element_subtype: 'nav_tab',
          element_action: 'dropdown',
        });
      }
      logGoogleBingEvent('landing page', 'menu_click', menuName?.toLowerCase(), 1, 'menu click');
      let tagManager = TagManagerContainer.getDealerObject();
      if (tagManager && typeof tagManager.getMenuEventClick === 'function') {
        tagManager.getMenuEventClick(menuName, menuLink, menuName);
      }
      if (menuLink?.toLowerCase().includes('scheduleservice') && this.shiftDigital) {
        ShiftDigitalService.scheduleServiceClick(this.shiftDigital);
      }
    },
    onClickBackgroundWrapper(event=null) {
      this.closeMegaMenuSection(event);
    },
    closeMegaMenuSection(event=null) {
      this.showMenuSection = false;
      this.selectedLevelOneMenu = "";
      this.clearMenuDynamicSection();
      this.blockPageScroll();
    },
    getMenuOptions(menuOptions = {}) {
      const defaultOptions = {
        event: null,
        menuLevel: "",
        hasSubMenu: false,
        menuName: '',
        menuId: null,
        menuLink: '',
        menuText: '',
      };

      return { ...defaultOptions, ...menuOptions };
    },

    /**new functions currently using in utility + nav menu,
     * Temporary workaround to support both utility navigation and mega menu integration.
     */

    onClickMenuItemHasSubmenu(menuOptions = {}) {
      const options = this.getMenuOptions(menuOptions);
      if (window.subMenuTypeMegaMenu) {
        this.onLevelOneMenuClick(options);
      } else {
        this.onContactFormClick(options.menuId, options.menuName);
      }
    },
    onClickMenuItemHasNoSubmenu(menuOptions = {}) {
      const options = this.getMenuOptions(menuOptions);
      if (window.subMenuTypeMegaMenu) {
        this.onLevelOneMenuClick(options);
      } else {
        this.logGoogleEvent(options.menuName, options.menuName, options.menuLink);
        this.redirectMenuInteraction(options.event, options.menuName, options.menuLink);
      }
    },
    onClickResearchModels(menuOptions = {}) {
      const options = this.getMenuOptions(menuOptions);
      if (window.subMenuTypeMegaMenu) {
        this.menuResearchModelClick(options);
      } else {
        this.onPrimaryResearchModelsClick(options.menuName);
      }
    },
    onHeaderMenuClick() {
      if (this.showContactForm) {
        this.showContactForm = false;
      }
    },
    getDealerHoursForGroupSite() {
      axios
        .get(
          `${window.ApiBaseUrl}/DealerGroup/GetAllDealerDetails?campaignid=${window.campaignId}&nocache=true`
        )
        .then((response) => {
          if (response?.data) {
            this.dealerDataForFlowersGroup = response.data;
            this.getCurrentDayOpenHours();
          }
        });
    },
    getCurrentDayOpenHours() {
      this.dealerDataForFlowersGroup.forEach((dealerHours, index) => {
        if (dealerHours?.businessHours) {
          let salesTime = dealerHours.businessHours.find(
            (t) => t.department.toLowerCase() === 'sales department'
          );
          dealerHours.displayMainTime = this.getCurrentDayDisplayTime(
            salesTime || dealerHours.businessHours[0]
          );
        }
      });
    },
    getCurrentDayDisplayTime(hourObject) {
      let today = DATE_DAYS.map((t) => t.toLowerCase())[new Date().getDay()];
      return hourObject[today];
    },
  },
};
