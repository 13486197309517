<template>
  <div class="svg_icon">
    <svg :style="{ fill: isHoverUser ? themePrimaryColor : chatThemeSettings?.borderColor || themeColor }"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z" />
    </svg>
  </div>
</template>

<script>
import { useChatSettingsStore } from '../../../store/ChatSettingsStore/ChatSettingsStore';
import { mapWritableState } from 'pinia';

export default {
  props: {
    themeColor: {
      type: String,
      required: false,
      default: '#B3B3B3',
    },
    isHoverUser: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      themePrimaryColor: window.primaryColor,
    }
  },
  computed: {
    ...mapWritableState(useChatSettingsStore, ['chatThemeSettings']),
  },
}
</script>
<style scoped>
.svg_icon svg {
  position: relative;
  bottom: 0px;
  height: 16px;
}

.footer-menu:hover svg {
  bottom: -2px;
  transition: 0.5s;
}

@media (min-width: 1900px) {
  .svg_icon svg {
    height: 21px !important;
    margin-bottom: 2px;
  }
}
</style>
