import 'v-slick-carousel/style.css';
import { VSlickCarousel } from 'v-slick-carousel';
import Modal from '../Shared/Modal/Modal.vue';
import { mazdaModels } from '../../../../js/WebsiteMazda/components/mazdamodelsconstants';
import ModelSelectionVehicle from './ModelSelectionVehicle/ModelSelectionVehicle.vue';
export default {
  props: {
    submenublackcolor: {
      default: false,
    },
  },
  data() {
    return {
      useoverrides: window.digiServiceUseOverrides,
      modalShow: true,
      selectedModel: 'all',
      modelFilters: {},
      hoveredVehicle: '',
      menudata: '',
      evmenudata: {},
      submenudata: '',
      baseUrl: window.baseUrl,
      vehicleList: '',
      make: window.make,
      vehicleimage: '',
      isMobile: window.ismobile,
      slickOptions: {
        slidesToShow: 2,
        infinite: false,
        prevArrow: `<em class="primaryicon-caret-left ft-24 previous-link slick-mobile-arrows" style="position: absolute; top: 50%; z-index: 99; font-size: 24px;"></em>`,
        nextArrow: `<em class="primaryicon-caret-right ft-24 next-link slick-mobile-arrows" style="position: absolute; top: 50%; z-index: 99; right: 0px;font-size: 24px;"></em>`,
        responsive: [
          {
            breakpoint: 1441,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      showMazdaEvModel:
        window.accountId === '60898' || (window.state && window.state.toLowerCase() === 'ca'),
      classicTemplate: window.masterLayoutName.toLowerCase() === '_website_classic',
    };
  },
  components: {
    VSlickCarousel,
    Modal,
    ModelSelectionVehicle,
  },
  watch: {
    modalShow() {
      if (!this.modalShow) {
        this.$emit('hidemodal', this.modalShow);
      }
    },
  },
  mounted() {
    let staticData = mazdaModels;
    this.menudata = [...staticData.models];

    this.evmenudata = [...staticData.evmodels];
    console.log(mazdaModels);
    this.formatVehicles(this.menudata);

    this.evmenudata = this.formatVehicles(this.evmenudata, true);
  },
  methods: {
    onContainerLeave() {
      this.hoveredVehicle = 'all';
    },
    onModelMouseEnter(key) {
      this.hoveredVehicle = key;
    },
    onModelMouseLeave() {
      this.hoveredVehicle = '';
    },
    onModelSelect(vehicleTypeName) {
      this.selectedModel = vehicleTypeName;
    },
    addIntoModelFilter(vehicle) {
      console.log('vehicle');
      console.log(vehicle);
      switch (vehicle.vehicleTypeName) {
        case 'SUV':
          this.modelFilters['SUV'] = 'Crossovers & SUVs';
          break;
        case 'Electrified':
          this.modelFilters['Electrified'] = 'Electrified';
          break;
        case 'Sedan':
          this.modelFilters['Sedan'] = 'SEDANS & HATCHBACKS';
          break;
        case 'Convertible':
          this.modelFilters['Convertible'] = 'Sports cars';
          break;

        default:
          this.modelFilters[vehicle.vehicleTypeName] = vehicle.vehicleTypeName;
      }
      console.log(this.modelFilters);
    },
    formatVehicles(vehicles, returnMethod) {
      let menuData = [...vehicles];

      try {
        for (let i = 0; i < vehicles.length; i++) {
          if (!returnMethod) this.addIntoModelFilter(vehicles[i]);
          let items = {};
          for (let vehicle of vehicles[i].groupItemsList) {
            let vehicleName = vehicle.cfModelName;
            if (vehicleName in items) {
              items[vehicleName].push(vehicle);
            } else {
              items[vehicleName] = [vehicle];
            }
          }
          menuData[i].groupItemsList = items;
        }
      } catch (e) {
        console.log(e);
      }

      if (!returnMethod) {
        this.menudata = menuData;
      } else {
        return menuData;
      }
    },
    closeModal() {
      this.modalShow = false;
      document.getElementById('paragon-websiteprimary-footer').style.display = 'block';
    },
    closeModelSelectionMenu() {
      this.$emit('buildprice');
    },
    checkModelToLoad(submenuItem) {
      console.log(submenuItem);
      if(!submenuItem) {
        return false;
      }
      return !(
        submenuItem.length > 0 &&
        this.selectedModel === 'all' &&
        submenuItem[0].cfModelName === 'CX-50 Hybrid' &&
        submenuItem[0].vehicleTypeName === 'SUV'
      );
    },
  }
};
