<template>
  <div class="tvm_sell_us_your_car mx-3 my-3">
    <div class="info_card" :style="{ borderColor: chatThemeSettings?.borderColor }">
      <div>
        <b v-if="title" class="mb-3 d-block">{{ title }}</b>
        <ul v-if="info && info.length" class="my-2 px-3">
          <li v-for="(item, index) in info" :key="index" class="my-2">{{ item.value }}</li>
        </ul>
        <div v-if="imageDetails && imageDetails.code === 'maps'" class="d-flex address_details align-items-center">
          <div>
            <img class="img-fluid" :src="imageDetails.imageUri" alt="" />
          </div>
          <div>
            <a target="_blank" :href="imageDetails.navigationUri" class="ft-14" style="color: #0000ee;">{{
              imageDetails.title }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./TvmSellUsYourCar.js"></script>
<style lang="less" scoped>
.tvm_sell_us_your_car {
  .info_card {
    border: 1px solid #eee;
    padding: 12px;
    border-radius: 5px;

    b {
      font-size: 0.9rem;
    }

    ul {
      li {
        font-size: 14px;
      }
    }

    .address_details {
      img {
        height: 25px;
      }
    }
  }
}
</style>
