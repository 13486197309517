export const convertNumberToPrice = (value) => {
  if (typeof value === 'number') {
    value = value.toString();
  }
  let checkComma = value && value.indexOf(',') > -1;
  if (checkComma) {
    return value;
  }
  value = parseFloat(value);
  if (typeof value !== 'number') {
    return value;
  }
  return value.toLocaleString();
};

export const conventStringToNumber = (value) => {
  if (typeof value === 'number') {
    return value;
  }
  let convertedValue = value;
  if (value && value[0] === '$') {
    convertedValue = value.substr(1, value.length);
  }
  if (convertedValue) {
    convertedValue = convertedValue.split(',').join('');
    return +convertedValue;
  }
};

export const getDollarPrice = (showPlusAndMinus, price) => {
  let value = price;
  const currencyFormat = convertNumberToPrice(Math.abs(price));
  if (showPlusAndMinus) {
    if (value > 0) {
      value = `+$${currencyFormat}`;
    } else if (value < 0) {
      value = `-$${currencyFormat}`;
    } else {
      // No action needed for other cases.
    }
  } else {
    value = `$${currencyFormat}`;
  }
  return value;
};

export const thousandSeparator = (value) => {
  try {
    let checkComma = value.indexOf(',') > -1;
    if (checkComma) {
      return value;
    }
    value = parseFloat(value);
  } catch {
    console.log('thousand separator error');
  }

  if (typeof value !== 'number') {
    return value;
  }
  return value.toLocaleString();
};
