/**
 * IMPORTANT...
 * THis file is used only in Vue2 application.
 * Any changes made on this file should be made on vue3 also
 * Vue3 FIle: vue3/src/Events/ShiftDigital/ShiftDigitalVehicles/NissanShiftDigital.js
 *
 * Dont use any vue specific syntax code or store in this file
 * Any Clarifications contact Leela Directly
 *
 */

import { DecodeHtml } from '../../DecodeHtml';

export default class NissanShiftDigital {
  vehicleDetails = '';

  prepareSDLayer() {
    let zipCode = window.zip;
    let zip = '';
    if (zipCode) {
      zip = zipCode.split('-')[0];
    }
    this.sdLayer = {
      pageType: '',
      websiteTier: 'Tier 3',
      language: 'en',
      pageBrand: window.themeName,
      sessionId: window.tid,
      dealerName: DecodeHtml.decodeHtml(window.clientName),
      siteTechnologyVersion: '2.2',
      dealerCity: window.city,
      dealerZipCode: zip,
      dealerState: window.state,
      trafficType: 'Typed/Bookmarked',
    };
  }

  sendPageView() {
    try {
      window.sdDataLayer = { ...this.sdLayer, ...this.layer };
      sd('send', 'pageview');
    } catch (error) {
      console.log(error);
    }
  }
}
