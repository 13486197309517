// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../vue3/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../vue3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.signInNavigationModalLoader {
  position: absolute;
  background: rgba(240, 242, 250, .768627451);
  height: 100%;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}
.signInNavigationModalLoader .c_border_r {
  border-radius: 11px;
}
`, "",{"version":3,"sources":["webpack://./../js/components/shared/ChatWidget/TvmLoaderModal/TvmLoaderModal.vue"],"names":[],"mappings":";AA0BA;EACE,kBAAkB;EAClB,2CAA2C;EAC3C,YAAY;EACZ,WAAW;EACX,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,MAAM;AACR;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["<template>\r\n  <div>\r\n    <div class=\"signInNavigationModalLoader\">\r\n      <div class=\"d-flex align-items-center justify-content-center flex-column  bg-white  p-4 shadow-sm c_border_r\">\r\n        <assistant-icon :width=\"40\" :height=\"40\" :auto-spin=\"true\" :spin-duration=\"1000\" />\r\n        <p class=\"my-2\">{{ message }}</p>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</template>\r\n<script>\r\nimport AssistantIcon from '../ChatAssistant/AssistantIcon.vue';\r\n\r\nexport default {\r\n  components: {\r\n    \"assistant-icon\": AssistantIcon,\r\n  },\r\n  props: {\r\n    message: {\r\n      type: String,\r\n      default: null,\r\n    },\r\n  },\r\n}\r\n</script>\r\n<style>\r\n.signInNavigationModalLoader {\r\n  position: absolute;\r\n  background: rgba(240, 242, 250, .768627451);\r\n  height: 100%;\r\n  width: 100%;\r\n  z-index: 99;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  top: 0;\r\n}\r\n\r\n.signInNavigationModalLoader .c_border_r {\r\n  border-radius: 11px;\r\n}\r\n</style>\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
