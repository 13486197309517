import BFormRadioGroup from 'bootstrap-vue/es/components/form-radio/form-radio-group';
import BFormRadio from 'bootstrap-vue/es/components/form-radio/form-radio';
import BFormGroup from 'bootstrap-vue/es/components/form-group/form-group';
import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService';

export default {
  props: ['botResponse', 'isWaitingForResponse', 'isLastIndex'],
  data() {
    return {
      transportTypesList: [],
      selected: '',
      ariaDescribedby: 'ariaDescribedby',
      selectedTypeObj: {},
      buttons: [],
      isDisableBtn: false,
    };
  },
  mounted() {
    this.transportTypesList = this.botResponse?.component?.value?.transportTypes;
    this.buttons = this.botResponse?.component?.value?.suggestions;
  },
  components: {
    BFormRadioGroup,
    BFormRadio,
    BFormGroup,
  },
  methods: {
    confirmTypes(button) {
      this.$root.$emit('isWaitingForResponseData', true);
      this.isDisableBtn = true;
      let specialObject = { TvmComponentRequest: this.selectedTypeObj };
      ChatWidgetService.postTvmMessage(
        this.selectedTypeObj.label,
        null,
        button.submitFlow,
        button.submitAction,
        specialObject
      );
      this.scrollBottom();
    },
    scrollBottom() {
      let container = document.querySelector('#chat__bodyview');
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 800);
      }
    },
  },
  watch: {
    selected(val) {
      const selectedOptionObject = this.transportTypesList
        .flatMap((item) => item.options)
        .find((option) => option.option === val);
      this.selectedTypeObj = selectedOptionObject;
    },
  },
};
