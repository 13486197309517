/**
 *
 * IMPORTANT:
 * This file is used both in vue2 and vue3 application
 * DOnt use the vue syntax and the store concept
 * for any clarifications contact Leela
 *
 */

// HONDA DRS EVENT CODES

export const HONDA_DRS_PAYMENT_CALCULATOR_FORM_LEAD_DESKTOP = 90800;
export const HONDA_DRS_PAYMENT_CALCULATOR_FORM_LEAD_MOBILE = 90801;
export const HONDA_DRS_TRADE_IN_FORM_LEAD_DESKTOP = 90802;
export const HONDA_DRS_TRADE_IN_FORM_LEAD_MOBILE = 90803;
export const HONDA_DRS_CREDIT_APPLICATION_FORM_LEAD_DESKTOP = 90806;
export const HONDA_DRS_CREDIT_APPLICATION_FORM_LEAD_MOBILE = 90807;
export const HONDA_DRS_CREDIT_APPLICATION_SOFT_PULL_SHOWROOM_FORM_LEAD_DESKTOP = 90804;
export const HONDA_DRS_CREDIT_APPLICATION_SOFT_PULL_SHOWROOM_FORM_LEAD_MOBILE = 90805;

export const HONDA_DRS_UNLOCK_PRICE_FORM_LEAD_DESKTOP = 90812;

//ACURA DRS EVENT CODES
export const ACURA_DRS_PAYMENT_CALCULATOR_FORM_LEAD_DESKTOP = 95036;
export const ACURA_DRS_PAYMENT_CALCULATOR_FORM_LEAD_MOBILE = 95037;
export const ACURA_DRS_TRADE_IN_FORM_LEAD_DESKTOP = 95038;
export const ACURA_DRS_TRADE_IN_FORM_LEAD_MOBILE = 95039;
export const ACURA_DRS_CREDIT_APPLICATION_FORM_LEAD_DESKTOP = 95042;
export const ACURA_DRS_CREDIT_APPLICATION_FORM_LEAD_MOBILE = 95043;
export const ACURA_DRS_CREDIT_APPLICATION_SOFT_PULL_SHOWROOM_FORM_LEAD_DESKTOP = 95040;
export const ACURA_DRS_CREDIT_APPLICATION_SOFT_PULL_SHOWROOM_FORM_LEAD_MOBILE = 95041;
export const ACURA_DRS_UNLOCK_PRICE_FORM_LEAD_DESKTOP = 95048;
export const ACURA_DRS_UNLOCK_PRICE_FORM_LEAD_MOBILE = 95049;

export function DRS_PAYMENT_CALCULATOR_FORM_LEAD() {
  const theme = window.themeName ? window.themeName.toLowerCase() : '';

  if (theme === 'honda') {
    return window.ismobile
      ? HONDA_DRS_PAYMENT_CALCULATOR_FORM_LEAD_MOBILE
      : HONDA_DRS_PAYMENT_CALCULATOR_FORM_LEAD_DESKTOP;
  }

  if (theme === 'acura') {
    return window.ismobile
      ? ACURA_DRS_PAYMENT_CALCULATOR_FORM_LEAD_MOBILE
      : ACURA_DRS_PAYMENT_CALCULATOR_FORM_LEAD_DESKTOP;
  }
}

export function DRS_TRADE_IN_FORM_LEAD() {
  const theme = window.themeName ? window.themeName.toLowerCase() : '';

  if (theme === 'honda') {
    return window.ismobile
      ? HONDA_DRS_TRADE_IN_FORM_LEAD_MOBILE
      : HONDA_DRS_TRADE_IN_FORM_LEAD_DESKTOP;
  }

  if (theme === 'acura') {
    return window.ismobile
      ? ACURA_DRS_TRADE_IN_FORM_LEAD_MOBILE
      : ACURA_DRS_TRADE_IN_FORM_LEAD_DESKTOP;
  }
}

export function DRS_CREDIT_APPLICATION_FORM_LEAD() {
  const theme = window.themeName ? window.themeName.toLowerCase() : '';

  if (theme === 'honda') {
    return window.ismobile
      ? HONDA_DRS_CREDIT_APPLICATION_FORM_LEAD_MOBILE
      : HONDA_DRS_CREDIT_APPLICATION_FORM_LEAD_DESKTOP;
  }

  if (theme === 'acura') {
    return window.ismobile
      ? ACURA_DRS_CREDIT_APPLICATION_FORM_LEAD_MOBILE
      : ACURA_DRS_CREDIT_APPLICATION_FORM_LEAD_DESKTOP;
  }
}

export function DRS_CREDIT_APPLICATION_SOFT_PULL_SHOWROOM_FORM_LEAD() {
  const theme = window.themeName ? window.themeName.toLowerCase() : '';

  if (theme === 'honda') {
    return window.ismobile
      ? HONDA_DRS_CREDIT_APPLICATION_SOFT_PULL_SHOWROOM_FORM_LEAD_MOBILE
      : HONDA_DRS_CREDIT_APPLICATION_SOFT_PULL_SHOWROOM_FORM_LEAD_DESKTOP;
  }

  if (theme === 'acura') {
    return window.ismobile
      ? ACURA_DRS_CREDIT_APPLICATION_SOFT_PULL_SHOWROOM_FORM_LEAD_MOBILE
      : ACURA_DRS_CREDIT_APPLICATION_SOFT_PULL_SHOWROOM_FORM_LEAD_DESKTOP;
  }
}

export function DRS_UNLOCK_PRICE_FORM_LEAD() {
  const theme = window.themeName ? window.themeName.toLowerCase() : '';

  if (theme === 'acura') {
    return window.ismobile
      ? ACURA_DRS_UNLOCK_PRICE_FORM_LEAD_MOBILE
      : ACURA_DRS_UNLOCK_PRICE_FORM_LEAD_DESKTOP;
  }
  if (theme === 'honda') {
    return HONDA_DRS_UNLOCK_PRICE_FORM_LEAD_DESKTOP;
  }
}
