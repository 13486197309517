import axios from 'axios';
import { Validations } from '../../../Services/Validations/Validations';
import { Events } from '../../../Events/ConsumerEvents/Events';
import ShowDisclaimer from '@/js/components/leadforms/ShowDisclaimer.vue';

import { TagManagerContainer } from '../../../Events/GoogleTagManager/TagManagerContainer';
import {
  DWP_Sales_Text_90501,
  DWP_Service_Text_90601,
  DWP_Service_TextContact,
  DWP_Sales_TextContact,
} from '@/js/services/GoogleTagManager/GoogleTagConstants';
import UtmUrlService from '@/js/services/UtmUrlService';
import DealerGroupService from '@/js/services/DealerGroupService';
import ShiftDigitalContainer from '../../../Events/ShiftDigital/ShiftDigitalContainer';
import {
  SHIFT_DIGITAL_GENERAL_CONTACT,
  SHIFT_DIGITAL_OTHER,
  SHIFT_DIGITAL_SERVICE,
} from '../../../../../js/services/ShiftDigital/ShiftDigitalConstants';
import ShiftDigitalService from '../../../Events/ShiftDigital/ShiftDigitalService';
import FormOpening from '../../../Services/FormServices/FormOpening';
import {
  OPEN_TEXT_LABEL_UNITE_DIGITAL,
  SUBMIT_TEXT_LABEL_UNITE_DIGITAL,
  TEXT_UNITE_OBJECT,
} from '../../../../../js/services/UniteDigital/UniteDigitalConstants';

import { LogLeadApi } from '../../../Services/LogLeadApiService/LogLeadApiService';
import IntegrateWithText from '../IntegrateWithText/IntegrateWithText.vue';

import { EXPRESS_GET_SELECTED_SUMMARY_DATA } from '../../../../../store/types';
import ShiftTcpaDisclaimerCheckbox from '../../../Components/Shared/ShiftTcpaDisclaimerCheckbox/ShiftTcpaDisclaimerCheckbox.vue';
import { fireSonicChatIconClick } from '../../../Events/SonicDataLayer/SonicDataService';
import {
  chatWidgetAscCommEngagement,
  fireTagChatSalesForm,
  fireTagChatServiceForm,
  fireTagChatSubmit,
  chatWidgetInputElementFocus,
  onCommonLinkLogs,
} from '../../../Events/TagManagerServices/TaggingEventForPages';

import OtherOptionTextForm from '../OtherOptionTextForm/OtherOptionTextForm.vue';

import {
  isAcuraJDPA,
  isGenesisJDPA,
  isHondaAutoData,
} from '@/js/services/GoogleTagManager/TagManagerConstants';
import { fireDrsTagManagerEvent } from '../../../Events/DRSEvents/DRSEventsService';

import { DRS_PAYMENT_CALCULATOR_FORM_LEAD } from '@/js/services/DRSEvents/DRSEventsConstants';
import { DecodeHtml } from '@/js/services/DecodeHtml';
import TcpaDisclaimer from '@/js/components/leadforms/TcpaDisclaimer.vue';

import { LocalStorageService } from '@/js/components/shared/Services/LocalStorageService';
import LeadFormSpamCheck from '@/js/services/LeadFormSpamCheck';
import { mapState } from 'pinia';
import { useExpressCheckouStore } from '../../../store/ExpressCheckout/ExpressCheckoutStore/ExpressCheckoutStore';
import { useChatSettingsStore } from '../../../store/ChatSettingsStore/ChatSettingsStore';

export default {
  components: {
    ShowDisclaimer,
    IntegrateWithText,
    ShiftTcpaDisclaimerCheckbox,
    TcpaDisclaimer,
    OtherOptionTextForm,
  },
  props: {
    referFrom: {
      type: String,
      required: false,
      default: '',
    },
    openserviceprop: {
      type: Boolean,
      required: false,
      default: false,
    },
    opentransactprop: {
      type: Boolean,
      required: false,
      default: false,
    },
    opengeneralprop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      zipDetails: {},
      setTextAsLeadForm: window.setTextAsLeadForm,
      firstName: '',
      lastName: '',
      shiftDigital: '',
      channellistready: false,
      isLoader: false,
      displayText: 'Please Wait Data is Loading...',
      tvm_AccountId: '',
      stageurl: atob(window.portalUrl) + 'offersites',
      textuschannellistdata: [],
      salesfirstdata: '',
      servicefirstdata: '',
      transactfirstdata: '',
      generalfirstdata: '',
      islistformactive: false,
      salesOptions: [],
      serviceOptions: [],
      transactOptions: [],
      generalOptions: [],
      listforminitialdisplay: false,
      isthankyou: false,
      opentheservice: false,
      openthesales: false,
      openthetransact: false,
      openthegeneral: false,
      leadTypeid: '',
      touchpointAccountId: '',
      textRequestPhoneNumberId: '',
      dealerPhoneNumber: '',
      isChannelListActive: false,
      isSalesActive: false,
      isServiceActive: false,
      isTransactActive: false,
      isGeneralActive: false,
      senderror: false,
      integrateWithText: window.integrateWithText,
      textdetails: {
        vTextarea: '',
        vRadio: '',
        vPhonenumber: '',
        vEmailAddress: '',
        Zip: '',
      },
      errordetails: {
        phoneerror: false,
        firstnameerror: false,
        lastnameerror: false,
        textArea: false,
        ziperror: false,
        emailerror: false,
      },
      sendtodealerdata: {
        dealerPhoneNumber: '',
        consumerPhoneNumber: '',
        message: '',
        accountId: '',
        locationId: '',
        leadTypeId: '',
      },
      loadShiftSpecialFields: window.showShiftSpecialFields,
      showOtherTextForm: false,
      isOtherTextOnlyActive: false,
      enableOtherTextUs: false,
      tagManager: '',
      footerDisclaimer: '',
      isGenesisJDPAEnabled: isGenesisJDPA(true),
      tcpChecked: window.enableTCPAConsent ? false : null,
      showZipCodeField: window.showZipCodeField,
      showEmailField: window.showEmailField,
      make: window.make?.toLowerCase(),
    };
  },

  computed: {
    ...mapState(useExpressCheckouStore, {
      showExpressCheckoutModal: 'showExpressCheckoutModal',
      paymentConfiguration: 'expressPaymentConfiguration',
      selectedAccessories: 'selectedAccessories',
      summaryData: EXPRESS_GET_SELECTED_SUMMARY_DATA,
    }),
    ...mapState(useChatSettingsStore, ['chatThemeSettings']),
  },
  watch: {
    firstName(val) {
      if (this.setTextAsLeadForm) {
        let validations = new Validations();
        this.errordetails.firstnameerror = validations.checkNameHasSpecialCharacters(val);
        if (!this.errordetails.firstnameerror) {
          this.errordetails.firstnameerror = validations.checkNameLimit(
            this.firstName,
            2,
            'First Name'
          );
        }
      }
    },
    lastName(val) {
      if (this.setTextAsLeadForm) {
        let validations = new Validations();
        this.errordetails.lastnameerror = validations.checkNameHasSpecialCharacters(val);
        if (!this.errordetails.lastnameerror) {
          this.errordetails.lastnameerror = validations.checkNameLimit(
            this.lastName,
            2,
            'Last Name'
          );
        }
      }
    },
    'textdetails.vTextarea': {
      handler(val) {
        let validations = new Validations();
        this.errordetails.textArea = validations.checkEmpty(val);
      },
    },
    'textdetails.vPhonenumber': {
      handler(val) {
        let validations = new Validations();
        this.errordetails.phoneerror = validations.checkPhone(val);
      },
    },
    'textdetails.vEmailAddress': {
      handler(val) {
        let validations = new Validations();
        if (this.showEmailField) {
          this.errordetails.emailerror = validations.checkEmail(val);
        }
      },
    },
    'textdetails.Zip': {
      handler(val) {
        let validations = new Validations();
        if (this.showZipCodeField) {
          this.errordetails.ziperror = validations.checkZip(val);
        }
      },
    },
  },
  mounted() {
    if (
      window.themeName?.toLowerCase() === 'genesis' ||
      window.themeName?.toLowerCase() === 'infiniti'
    ) {
      this.showZipCodeField = true;
      this.showEmailField = true;
    }
    if (window.makeZipCodeMandatory) {
      this.showZipCodeField = true;
    }

    if (window.isauthenticated.toLowerCase() === 'true') {
      let userDetails = window.userDetails;
      this.firstName = userDetails.firstname || '';
      this.lastName = userDetails.lastname || '';
      this.textdetails.vPhonenumber = userDetails.phonenumber || '';
      if (this.showZipCodeField) {
        this.textdetails.Zip = userDetails.zip || '';
      }
    }
    // this.$root.$on('textUsButtonsNavigation', () => {
    //   this.isChannelListActive = true;
    //   this.islistformactive = false;
    //   this.showOtherTextForm = false;
    // });
    window.addEventListener('textUsButtonsNavigation', (val) => {
      if (val) {
        this.isChannelListActive = true;
        this.islistformactive = false;
      }
    });
    this.opentheservice = this.openserviceprop;
    this.openthesales = this.opensalesprop;
    this.openthetransact = this.opentransactprop;
    this.openthegeneral = this.opengeneralprop;

    console.log(window.textusParameter);
    if (window.textusParameter === 'sales') {
      this.opentheservice = false;
      this.openthesales = true;
      this.openthetransact = false;
      this.openthegeneral = false;
    } else if (window.textusParameter === 'service') {
      this.openthesales = false;
      this.opentheservice = true;
      this.openthetransact = false;
      this.openthegeneral = false;
    } else if (window.textusParameter === 'transact') {
      this.openthesales = false;
      this.opentheservice = false;
      this.openthetransact = true;
      this.openthegeneral = false;
    } else if (window.textusParameter === 'general') {
      this.openthesales = false;
      this.opentheservice = false;
      this.openthetransact = false;
      this.openthegeneral = true;
    } else {
      //No action needed for other cases
    }

    FormOpening.openForm(OPEN_TEXT_LABEL_UNITE_DIGITAL, TEXT_UNITE_OBJECT);

    window.textusParameter = '';

    this.isChannelListActive = false;
    this.isSalesActive = false;
    this.isTransactActive = false;
    this.isServiceActive = false;
    this.isGeneralActive = false;
    this.channellistready = true;
    this.isLoader = true;

    this.islistformactive = false;
    this.listforminitialdisplay = false;
    this.isthankyou = false;

    if (typeof window.accountId !== 'undefined') {
      this.tvm_AccountId = window.accountId;
    } else {
      this.tvm_AccountId = this.tvm_getParams('chatwidget.js')['accountid'];
    }
    if (window.isDealerGroup && window.childAccountId && window.childAccountId !== '0') {
      this.tvm_AccountId = window.childAccountId;
    }

    this.zipDetails = { ...LocalStorageService.zipDetails() };

    let eventmodel = {
      ...Events.consumerEventModel,
      consumerEventId: Events.eventsConstants.opentextusForm,
      styleId: this.styleid,
      vin: this.vin,
    };
    Events.logConsumerEvent(eventmodel);

    axios
      .get(`${this.stageurl}/service/textus/get?accountId=${this.tvm_AccountId}`)
      .then((response) => {
        this.textuschannellistdata = response.data;
        this.isChannelListActive = true;
        this.channellistready = false;
        this.isLoader = false;

        if (this.textuschannellistdata && this.textuschannellistdata.length === 0) {
          this.OtherTextForm();
          this.isOtherTextOnlyActive = true;
          setTimeout(() => {
            let tilesAvailable = JSON.parse(sessionStorage.getItem('tilesAvailable'));
            if (document.querySelector('#textUsFormEnabled') && !tilesAvailable) {
              document.querySelector('#textUsFormEnabled i').style.display = 'none';
            }
          }, 500);
        }

        for (let value of this.textuschannellistdata) {
          if (value.LeadTypeId === 1) {
            if (this.salesfirstdata === '') {
              this.salesfirstdata = value;
            }
          } else if (value.LeadTypeId === 2) {
            if (this.servicefirstdata === '') {
              this.servicefirstdata = value;
            }
          } else if (value.LeadTypeId === 3) {
            if (this.generalfirstdata === '') {
              this.generalfirstdata = value;
            }
          } else if (value.LeadTypeId === 4) {
            if (this.transactfirstdata === '') {
              this.transactfirstdata = value;
            }
          } else {
            //No action needed for other cases
          }
        }

        if (window.textusparameter === 'sales') {
          if (this.salesfirstdata !== '') {
            this.openSales();
          } else {
            this.$emit('closepopup');
          }
        } else if (window.textusparameter === 'service') {
          if (this.servicefirstdata !== '') {
            this.openService();
          } else {
            this.$emit('closepopup');
          }
        } else if (window.textusparameter === 'general') {
          if (this.generalfirstdata !== '') {
            this.openGeneral();
          } else {
            this.$emit('closepopup');
          }
        } else if (window.textusparameter === 'transact') {
          if (this.servicefirstdata !== '') {
            this.openTransact();
          } else {
            this.$emit('closepopup');
          }
        } else {
          //No action needed for other cases
        }

        if (this.opentheservice) {
          this.openService();
        } else if (this.openthesales) {
          this.openSales();
        } else if (this.openthegeneral) {
          this.openGeneral();
        } else if (this.openthetransact) {
          if (this.transactfirstdata === '') {
            this.opentheservice = this.openthetransact = false;
            this.openthesales = true;
            this.openSales();
          } else {
            this.openTransact();
          }
        } else {
          //No action needed for other cases
        }
      })
      .catch((error) => {
        console.log(error);
        this.displayText = 'Something Went wrong. Please try again';
      });

    this.getAssistantSettings();

    this.tagManager = TagManagerContainer.getDealerObject();
    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
    if (window.tCPADisclaimer) {
      this.footerDisclaimer = DecodeHtml.decodeHtml(window.tCPADisclaimer);
    }
  },

  methods: {
    checkGooglePresent(data) {
      let datatolowercase = data.toLowerCase();
      return datatolowercase.indexOf('google') !== -1;
    },
    onFormFocus(event) {
      const isFormField = event.target.matches('input, select, textarea');
      if (isFormField) {
        const elementTitle = event.target.getAttribute('name');
        chatWidgetAscCommEngagement({
          department: this.isServiceActive ? 'Service' : 'Sales',
          elementTitle,
        });
      }
    },
    tvm_getParams(script_name) {
      let v_scripts = document.getElementsByTagName('script');
      for (let value of v_scripts) {
        if (value.src.toLowerCase().indexOf(script_name) > -1) {
          let v_pa = value.src.split('?').pop().split('&');
          let v_p = {};
          for (let innerValue of v_pa) {
            let kv = innerValue.split('=');
            v_p[kv[0].toLowerCase()] = kv[1];
          }
          return v_p;
        }
      }
      return {};
    },
    ontcpChecked($event) {
      this.tcpChecked = $event;
    },
    channellistclick(
      leadTypeId,
      touchpointAccountId,
      textRequestPhoneNumberId,
      phoneNumber,
      textName = '',
      shouldFireEvent = false
    ) {
      if (shouldFireEvent) {
        chatWidgetAscCommEngagement({
          department: this.isServiceActive ? 'Service' : 'Sales',
          elementTitle: textName,
        });
      }
      fireSonicChatIconClick(textName);
      this.$root.$emit('textUs');
      if (leadTypeId === 1) {
        this.openSales();
      } else if (leadTypeId === 2) {
        this.openService();
      } else if (leadTypeId === 3) {
        this.openGeneral();
      } else {
        this.openTransact();
      }

      this.isChannelListActive = false;
      this.textdetails.vTextarea = '';
      this.textdetails.vRadio = '';
      this.textdetails.Zip = '';

      if (window.isauthenticated.toLowerCase() === 'false') {
        this.textdetails.vPhonenumber = '';
        this.textdetails.Zip = '';
      }

      this.islistformactive = true;
      this.listforminitialdisplay = true;
      this.clearErrorData();
      setTimeout(function () {
        let element = document.getElementById('sendbtn');
        element.setAttribute('data-leadTypeid', leadTypeId);
        element.setAttribute('data-touchpointAccountId', touchpointAccountId);
        element.setAttribute('data-textRequestPhoneNumberId', textRequestPhoneNumberId);
        element.setAttribute('data-dealerPhoneNumber', phoneNumber);
      }, 1000);
      setTimeout(function () {
        try {
          let formParentElement = document.querySelectorAll('.text_us_event_listener');
          formParentElement.forEach((element) => {
            element.addEventListener('focus', function (event) {
              let element = event.target.tagName;
              if (element.toLowerCase() === 'input' || element.toLowerCase() === 'textarea') {
                chatWidgetInputElementFocus(event, false);
              }
            });
          });
        } catch (e) {
          console.log(e);
        }
      }, 1000);
      onCommonLinkLogs({ label: textName });
    },
    clearErrorData() {
      setTimeout(() => {
        !this.textdetails.vTextarea && (this.errordetails.textArea = false);
        !this.firstName && (this.errordetails.firstnameerror = false);
        !this.lastName && (this.errordetails.lastnameerror = false);
        if (window.isauthenticated.toLowerCase() === 'false') {
          !this.textdetails.vPhonenumber && (this.errordetails.phoneerror = false);
          !this.textdetails.Zip && (this.errordetails.ziperror = false);
        }
      }, 100);
    },
    openService() {
      axios
        .get(
          `${this.stageurl}/service/textus/GetTextUsSettings?accountId=${this.tvm_AccountId}&leadtypeid=2`
        )
        .then((response) => {
          console.log(response.data);
          this.serviceOptions = response.data;
          this.isChannelListActive = false;
          let textmessage = this.textdetails.vTextarea;
          this.islistformactive = true;
          this.listforminitialdisplay = true;
          this.isSalesActive = false;
          this.isTransactActive = false;
          this.isGeneralActive = false;
          this.isServiceActive = true;
          this.trackFormOnLoadEvents('service', textmessage);
        });
    },
    openTransact() {
      axios
        .get(
          `${this.stageurl}/service/textus/GetTextUsSettings?accountId=${this.tvm_AccountId}&leadtypeid=1`
        )
        .then((response) => {
          console.log(response.data);
          this.transactOpitions = response.data;
          this.isChannelListActive = false;
          let textmessage = this.textdetails.vTextarea;
          this.islistformactive = true;
          this.listforminitialdisplay = true;
          this.isSalesActive = false;
          this.isGeneralActive = false;
          this.isTransactActive = true;
          this.isServiceActive = false;
          this.trackFormOnLoadEvents('transact', textmessage);
          setTimeout(() => {
            if (this.transactfirstdata) {
              this.channellistclick(
                4,
                this.transactfirstdata.TouchpointAccountId,
                this.transactfirstdata.TextRequestPhoneNumberId,
                this.transactfirstdata.PhoneNumber
              );
            }
            this.transactfirstdata = '';
          }, 1000);
        });
    },

    openSales() {
      axios
        .get(
          `${this.stageurl}/service/textus/GetTextUsSettings?accountId=${this.tvm_AccountId}&leadtypeid=1`
        )
        .then((response) => {
          this.salesOptions = response.data;
          this.isChannelListActive = false;
          let textmessage = this.textdetails.vTextarea;
          this.isSalesActive = true;
          this.listforminitialdisplay = true;
          this.islistformactive = true;
          this.isTransactActive = false;
          this.isServiceActive = false;
          this.isGeneralActive = false;
          this.trackFormOnLoadEvents('sales', textmessage);
        });
    },
    openGeneral() {
      axios
        .get(
          `${this.stageurl}/service/textus/GetTextUsSettings?accountId=${this.tvm_AccountId}&leadtypeid=3`
        )
        .then((response) => {
          this.generalOptions = response.data;
          this.isChannelListActive = false;
          let textmessage = this.textdetails.vTextarea;

          this.isSalesActive = false;
          this.isTransactActive = false;
          this.isServiceActive = false;
          this.isGeneralActive = true;
          this.listforminitialdisplay = true;
          this.islistformactive = true;

          this.trackFormOnLoadEvents('general', textmessage);
        });
    },

    async sendtext() {
      let shiftDigitalEvent = ShiftDigitalContainer.getShiftDigitalObject();
      let validations = new Validations();
      this.errordetails.textArea = validations.checkEmpty(this.textdetails.vTextarea);
      if (this.showZipCodeField) {
        this.errordetails.ziperror = validations.checkZip(this.textdetails.Zip);
      }

      if (this.showEmailField) {
        if (!this.errordetails.emailerror) {
          this.errordetails.emailerror = validations.checkEmail(this.textdetails.vEmailAddress);
        }
      }
      //first name last name validation
      if (this.setTextAsLeadForm) {
        this.errordetails.firstnameerror = validations.checkNameHasSpecialCharacters(
          this.firstName
        );
        this.errordetails.lastnameerror = validations.checkNameHasSpecialCharacters(this.lastName);

        if (!this.errordetails.firstnameerror) {
          this.errordetails.firstnameerror = validations.checkNameLimit(
            this.firstName,
            2,
            'First Name'
          );
        }

        if (!this.errordetails.lastnameerror) {
          this.errordetails.lastnameerror = validations.checkNameLimit(
            this.lastName,
            2,
            'Last Name'
          );
        }
      }

      if (!this.errordetails.phoneerror) {
        this.errordetails.phoneerror = validations.checkEmpty(this.textdetails.vPhonenumber);
      }

      if (this.loadShiftSpecialFields) {
        validations.checkShiftTcpaDisclaimer(this.textdetails, this.errordetails);
      }
      let textmessage =
        'I am interested in:' +
        ' ' +
        this.textdetails.vRadio +
        '\n' +
        this.textdetails.vTextarea +
        '$$$' +
        this.textdetails.vPhonenumber;
      let userPin = window.userpin;
      let userdetails = window.userDetails;
      let enableTextusLeadToCRM = window.enableTextusLeadToCRM;
      let leadapidetails = {};

      leadapidetails['TCPConsent'] = window.enableTCPAConsent ? false : null;

      if (window.autoToggleTCPA === true) {
        this.tcpChecked = true;
      }

      if (this.tcpChecked) {
        leadapidetails['TCPConsent'] = window.enableTCPAConsent ? false : null;
      }

      let eventmodel = {
        ...Events.consumerEventModel,
        consumerEventId: Events.eventsConstants.textusSendButton,
        textUsType: '',
        textusMessage: textmessage,
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: parseInt(this.textdetails.vPhonenumber),
      };
      if (validations.checkError(this.errordetails)) {
        this.errordetails.phoneerror = validations.checkPhone(this.textdetails.vPhonenumber);
        if (validations.checkError(this.errordetails)) {
          let element = document.getElementById('sendbtn');
          this.sendtodealerdata.dealerPhoneNumber = parseInt(
            element.getAttribute('data-dealerPhoneNumber')
          );
          if (this.textdetails.vPhonenumber) {
            this.textdetails.vPhonenumber = this.textdetails.vPhonenumber.match(/\d/g).join('');
          }
          this.sendtodealerdata.consumerPhoneNumber = parseInt(this.textdetails.vPhonenumber);
          this.sendtodealerdata.message = this.textdetails.vTextarea;
          this.sendtodealerdata.accountId = parseInt(
            element.getAttribute('data-touchpointAccountId')
          );
          this.sendtodealerdata.locationId = parseInt(
            element.getAttribute('data-textRequestPhoneNumberId')
          );
          this.sendtodealerdata.leadTypeId = parseInt(element.getAttribute('data-leadTypeid'));

          if (element.getAttribute('data-dealerPhoneNumber') === null) {
            if (window.textusparameter === 'sales') {
              this.sendtodealerdata.dealerPhoneNumber = +this.salesfirstdata.PhoneNumber;
              this.sendtodealerdata.accountId = +this.tvm_AccountId;
              this.sendtodealerdata.locationId = +this.salesfirstdata.TextRequestPhoneNumberId;
              this.sendtodealerdata.leadTypeId = 1;
            } else {
              if (this.openthesales) {
                this.sendtodealerdata.dealerPhoneNumber = +this.salesfirstdata.PhoneNumber;
                this.sendtodealerdata.accountId = +this.tvm_AccountId;
                this.sendtodealerdata.locationId = +this.salesfirstdata.TextRequestPhoneNumberId;
                this.sendtodealerdata.leadTypeId = 1;
              } else if (this.opentheservice) {
                this.sendtodealerdata.dealerPhoneNumber = +this.servicefirstdata.PhoneNumber;
                this.sendtodealerdata.accountId = +this.tvm_AccountId;
                this.sendtodealerdata.locationId = +this.servicefirstdata.TextRequestPhoneNumberId;
                this.sendtodealerdata.leadTypeId = 2;
              } else if (this.openthegeneral) {
                this.sendtodealerdata.dealerPhoneNumber = +this.generalfirstdata.PhoneNumber;
                this.sendtodealerdata.accountId = +this.tvm_AccountId;
                this.sendtodealerdata.locationId = +this.generalfirstdata.TextRequestPhoneNumberId;
                this.sendtodealerdata.leadTypeId = 3;
              } else {
                this.sendtodealerdata.dealerPhoneNumber = +this.servicefirstdata.PhoneNumber;
                this.sendtodealerdata.accountId = +this.tvm_AccountId;
                this.sendtodealerdata.locationId = +this.servicefirstdata.TextRequestPhoneNumberId;
                this.sendtodealerdata.leadTypeId = 4;
              }
            }

            if (this.isServiceActive) {
              this.sendtodealerdata.leadTypeId = 2;
            } else if (this.isSalesActive) {
              this.sendtodealerdata.leadTypeId = 1;
            } else if (this.isGeneralActive) {
              this.sendtodealerdata.leadTypeId = 3;
            } else {
              this.sendtodealerdata.leadTypeId = 4;
            }
          }

          if ((userPin && userPin !== null) || this.setTextAsLeadForm) {
            leadapidetails = {
              FirstName: userdetails.firstname,
              LastName: userdetails.lastname,
              EmailAddress: userdetails.email,
              PhoneNumber: parseInt(this.textdetails.vPhonenumber),
              Message: textmessage,
              vin: window.vin ? window.vin : '',
              styleId: window.styleId ? +window.styleId : 0,
            };
          }
          leadapidetails.FirstName = this.firstName ? this.firstName : userdetails.firstname;
          leadapidetails.LastName = this.lastName ? this.lastName : userdetails.lastname;

          if (this.showZipCodeField) {
            leadapidetails.Zip = this.textdetails.Zip
              ? this.textdetails.Zip
              : userdetails?.zip || '';
          }
          if (this.showEmailField) {
            leadapidetails.EmailAddress = this.textdetails.vEmailAddress
              ? this.textdetails.vEmailAddress
              : userdetails
              ? userdetails.email
              : '';
          }

          if (this.sendtodealerdata.leadTypeId === 1) {
            if (typeof window.accountId !== 'undefined') {
              window.googleBingCustomEvent(
                'Text App',
                'Text Sales',
                'Send Text',
                1,
                'text us on sales submit'
              );
              if (!userPin && userPin !== null) {
                eventmodel.textUsType = Events.eventsConstants.texttypesales;
                // Events.logConsumerEvent(eventmodel);
              }

              leadapidetails['LeadTypeID'] = 24;
              leadapidetails['LeadTypeName'] = 'text-us-sales';
              leadapidetails['websiteUrlLeadSubmitted'] = window.location.href;
              leadapidetails['DealerID'] = window.accountId;
              leadapidetails['campaignID'] = window.campaignId;
              leadapidetails['SessionId'] = window.tid;
            }
          } else if (this.sendtodealerdata.leadTypeId === 2) {
            if (typeof window.accountId !== 'undefined') {
              window.googleBingCustomEvent(
                'Text App',
                'Text Service',
                'Send Text',
                1,
                'text us on service submit'
              );

              if (!userPin && userPin !== null) {
                eventmodel.textUsType = Events.eventsConstants.texttypeservice;
                // Events.logConsumerEvent(eventmodel);
              }
              leadapidetails['websiteUrlLeadSubmitted'] = window.location.href;
              leadapidetails['LeadTypeID'] = 25;
              leadapidetails['LeadTypeName'] = 'text-us-service';
              leadapidetails['DealerID'] = window.accountId;
              leadapidetails['campaignID'] = window.campaignId;
              leadapidetails['SessionId'] = window.tid;
            }
          } else if (this.sendtodealerdata.leadTypeId === 3) {
            if (typeof window.accountId !== 'undefined') {
              window.googleBingCustomEvent(
                'Text App',
                'Text General',
                'Send Text',
                1,
                'text us on General submit'
              );

              if (!userPin && userPin !== null) {
                eventmodel.textUsType = 'general';
                // Events.logConsumerEvent(eventmodel);
              }
              leadapidetails['websiteUrlLeadSubmitted'] = window.location.href;
              leadapidetails['LeadTypeID'] = 237;
              leadapidetails['LeadTypeName'] = 'text-us-general';
              leadapidetails['DealerID'] = window.accountId;
              leadapidetails['campaignID'] = window.campaignId;
              leadapidetails['SessionId'] = window.tid;
            }
          } else {
            if (typeof window.accountId !== 'undefined') {
              window.googleBingCustomEvent(
                'Text App',
                'Text Transact',
                'Send Text',
                1,
                'text us on transact submit'
              );

              if (!userPin && userPin !== null) {
                eventmodel.textUsType = 'Transact';
                // Events.logConsumerEvent(eventmodel);
              }

              leadapidetails['websiteUrlLeadSubmitted'] = window.location.href;
              leadapidetails['LeadTypeID'] = 31;
              leadapidetails['Payment'] = this.summaryData.paymentData || '';
              leadapidetails['Accessories'] = this.selectedAccessories || '';
              leadapidetails['LeadTypeName'] = 'text-us-transact';
              leadapidetails['DealerID'] = window.accountId;
              leadapidetails['campaignID'] = window.campaignId;
              leadapidetails['SessionId'] = window.tid;
            }
          }
          console.log(this.sendtodealerdata);
          if ((userPin && userPin !== null && enableTextusLeadToCRM) || this.setTextAsLeadForm) {
            this.trackFormSubmitevents(leadapidetails.LeadTypeID);

            leadapidetails = {
              ...leadapidetails,
              ...UtmUrlService.getUrl(),
              ...{ sessionId: window.tid },
            };

            leadapidetails['dealergroupid'] = DealerGroupService.getDgId();

            leadapidetails['VDPAccountId'] = window.vdpAccountId ? +window.vdpAccountId : 0;

            if (this.loadShiftSpecialFields) {
              leadapidetails['ShiftTCPAConsent'] = this.textdetails.ShiftTCPAConsent;
              leadapidetails['ShiftTCPADisclaimer'] = this.textdetails.ShiftTCPADisclaimer;
            }
            if (this.tcpChecked) {
              leadapidetails['TCPConsent'] = true;
            }
            if (
              !LeadFormSpamCheck.checkLeadIsValid('', leadapidetails.LeadTypeID, leadapidetails)
            ) {
              this.listforminitialdisplay = false;
              this.isthankyou = true;
              return false;
            }

            if (this.showExpressCheckoutModal && (isAcuraJDPA() || isHondaAutoData())) {
              fireDrsTagManagerEvent('', DRS_PAYMENT_CALCULATOR_FORM_LEAD());
            }

            try {
              let response = await LogLeadApi(
                leadapidetails,
                undefined,
                undefined,
                undefined,
                true
              );
              if (this.shiftDigital) {
                if (shiftDigitalEvent && typeof shiftDigitalEvent.chatLeadEvent === 'function') {
                  shiftDigitalEvent.chatLeadEvent(response.data);
                }
                if (
                  !this.referFrom &&
                  this.shiftDigital &&
                  typeof this.shiftDigital.vehicleTextUsFormSubmit === 'function'
                ) {
                  this.shiftDigital.vehicleTextUsFormSubmit();
                }

                if (
                  this.referFrom &&
                  typeof this.shiftDigital.newSpecialsTextUsFormOnSubmit === 'function'
                ) {
                  let type = this.referFrom === 'preowned' ? 'pre-owned' : this.referFrom;
                  this.shiftDigital.newSpecialsTextUsFormOnSubmit(type);
                }
                if (leadapidetails['LeadTypeID'] === 25) {
                  this.shiftDigital.submitFormEvent(response.data, SHIFT_DIGITAL_SERVICE);
                } else {
                  this.shiftDigital.submitFormEvent(
                    response.data,
                    this.make === 'genesis' ? SHIFT_DIGITAL_GENERAL_CONTACT : SHIFT_DIGITAL_OTHER
                  );
                }
              }

              eventmodel.logLeadItemId = response.data;
              if (this.sendtodealerdata.leadTypeId === 1) {
                eventmodel.textUsType = Events.eventsConstants.texttypesales;
              } else if (this.sendtodealerdata.leadTypeId === 2) {
                eventmodel.textUsType = Events.eventsConstants.texttypeservice;
              } else if (this.sendtodealerdata.leadTypeId === 3) {
                eventmodel.textUsType = 'general';
              } else {
                eventmodel.textUsType = 'Transact';
              }
              if (typeof window.accountId !== 'undefined') {
                Events.logConsumerEvent(eventmodel);
              }

              FormOpening.submitForm(SUBMIT_TEXT_LABEL_UNITE_DIGITAL, '', TEXT_UNITE_OBJECT);
              console.log('Log Lead is submitted for the loggedin users');

              if (this.showExpressCheckoutModal && isAcuraJDPA()) {
                fireDrsTagManagerEvent('', DRS_PAYMENT_CALCULATOR_FORM_LEAD());
              }
            } catch (e) {
              console.log(e);
            }
          }

          let sendtodealerurl = `${this.stageurl}/service/textus/sendtodealer`;
          fireTagChatSubmit(this.isServiceActive ? 'Service' : 'Sales', {
            product_name: 'Apollo Text',
          });

          if (this.isServiceActive) {
            fireTagChatServiceForm({ product_name: 'Apollo Text' });
          } else {
            fireTagChatSalesForm({ product_name: 'Apollo Text' });
          }
          axios
            .post(sendtodealerurl, this.sendtodealerdata, {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then((response) => {
              if (response.status === 200) {
                this.listforminitialdisplay = false;
                this.isthankyou = true;
              }
            })
            .catch((error) => {
              console.log('check error' + error);
              this.senderror = true;
              this.listforminitialdisplay = false;
            });
        }
      }
    },
    backbtnclick() {
      this.opentheservice = false;
      this.isChannelListActive = true;
      this.openserviceprop = false;
      this.isServiceActive = false;
      this.isSalesActive = false;
      this.isGeneralActive = false;
      this.islistformactive = false;
      this.listforminitialdisplay = false;
      this.isthankyou = false;
      this.errordetails.phoneerror = false;
      this.textdetails.vTextarea = '';
      this.textdetails.vRadio = '';
      this.textdetails.vPhonenumber = '';
    },

    isNumberKey(evt) {
      evt = evt || window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    OtherTextForm() {
      this.isChannelListActive = false;
      this.showOtherTextForm = true;
      this.islistformactive = false;
      this.$root.$emit('textUs');
      onCommonLinkLogs({ label: 'Text Us' });
    },
    getAssistantSettings() {
      let url = `${window.chatWidgetApiBaseUrl}/ChatBot/assistantsettings/${window.accountId}/${window.campaignId}`;
      axios
        .get(url)
        .then((response) => {
          if (response && response.data) {
            let res = response.data;
            this.enableOtherTextUs = res.enableOtherInText ? res.enableOtherInText : false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    trackFormSubmitevents(leadTypeId) {
      if (this.tagManager && typeof this.tagManager.getFormSubmit === 'function') {
        let formValue = [];
        switch (leadTypeId) {
          case 24:
          case 235:
            formValue = isGenesisJDPA() ? [...DWP_Sales_TextContact] : [...DWP_Sales_Text_90501];
            break;
          case 25:
            formValue = isGenesisJDPA()
              ? [...DWP_Service_TextContact]
              : [...DWP_Service_Text_90601];
            break;
          default:
            formValue = [...DWP_Sales_Text_90501];
        }
        this.tagManager.getFormSubmit(formValue[0], formValue[1]);
      }
    },
    trackFormOnLoadEvents(formType, textmessage = '') {
      if (formType === 'sales') {
        if (this.shiftDigital) {
          this.shiftDigital.initiateForm(
            this.make === 'genesis' ? SHIFT_DIGITAL_GENERAL_CONTACT : SHIFT_DIGITAL_OTHER
          );
          ShiftDigitalService.initiateFormEvents(
            this.shiftDigital,
            make === 'genesis' ? SHIFT_DIGITAL_GENERAL_CONTACT : SHIFT_DIGITAL_OTHER
          );
        }

        if (typeof window.accountId !== 'undefined') {
          let eventmodel = {
            ...Events.consumerEventModel,
            consumerEventId: Events.eventsConstants.textusSalesButton,
            textUsType: Events.eventsConstants.texttypesales,
            textusMessage: textmessage,
          };
          Events.logConsumerEvent(eventmodel);
          window.googleBingCustomEvent('Text App', 'Text Sales', 'Click', 1, 'text us sales click');
        }
        FormOpening.eventTagging(
          {
            LeadTypeID: 24,
            LeadTypeName: 'text-us-sales',
          },
          undefined,
          true
        );
      } else if (formType === 'service') {
        if (this.shiftDigital) {
          this.shiftDigital.initiateForm(SHIFT_DIGITAL_SERVICE);
          ShiftDigitalService.initiateFormEvents(this.shiftDigital, SHIFT_DIGITAL_SERVICE);
        }

        if (typeof window.accountId !== 'undefined') {
          let eventmodel = {
            ...Events.consumerEventModel,
            consumerEventId: Events.eventsConstants.textusServiceButton,
            textUsType: Events.eventsConstants.texttypeservice,
            textusMessage: textmessage,
          };
          Events.logConsumerEvent(eventmodel);
          window.googleBingCustomEvent(
            'Text App',
            'Text Service',
            'Click',
            1,
            'text us service click'
          );
        }
      } else if (formType === 'general') {
        if (this.shiftDigital) {
          this.shiftDigital.initiateForm(
            this.make === 'genesis' ? SHIFT_DIGITAL_GENERAL_CONTACT : SHIFT_DIGITAL_OTHER
          );
          ShiftDigitalService.initiateFormEvents(
            this.shiftDigital,
            this.make === 'genesis' ? SHIFT_DIGITAL_GENERAL_CONTACT : SHIFT_DIGITAL_OTHER
          );
        }

        if (typeof window.accountId !== 'undefined') {
          let eventmodel = {
            ...Events.consumerEventModel,
            consumerEventId: Events.eventsConstants.textUsGeneralButton,
            textUsType: 'general',
            textusMessage: textmessage,
          };
          Events.logConsumerEvent(eventmodel);
          window.googleBingCustomEvent(
            'Text App',
            'Text General',
            'Click',
            1,
            'text us General click'
          );
        }
        FormOpening.eventTagging(
          {
            LeadTypeID: 237,
            LeadTypeName: 'text-us-general',
          },
          undefined,
          true
        );
      } else if (formType === 'transact') {
        if (this.shiftDigital) {
          this.shiftDigital.initiateForm(SHIFT_DIGITAL_OTHER);
          try {
            ShiftDigitalService.initiateFormEvents(this.shiftDigital, SHIFT_DIGITAL_OTHER);
          } catch (e) {
            console.log(e);
          }
        }

        if (typeof window.accountId !== 'undefined') {
          let eventmodel = {
            ...Events.consumerEventModel,
            consumerEventId: Events.eventsConstants.textusTransactButton,
            textUsType: 'transact',
            textusMessage: textmessage,
            downpayment: this.paymentConfiguration.downPayment,
            paymentTerm: this.paymentConfiguration.term,
            milesPerYear: this.paymentConfiguration.mileage,
            paymentType: this.paymentConfiguration.selectedPayment,
            tradeIn: this.summaryData.paymentData.TradeEquity,
            MSRP: parseFloat(this.summaryData.paymentData.MSRP.replace(/[$,]/g, '')),
            payment: this.summaryData.paymentData.Payment.replace(/[$,]/g, ''),
            incentives: this.summaryData.paymentData.IncentiveCash,
            sellingPrice: parseFloat(
              this.summaryData.paymentData.PurchasePrice.replace(/[$,]/g, '')
            ),
            paymentDueAtSigning: this.summaryData.paymentData.DueAtSigning.replace(/[$,]/g, ''),
            discount: this.summaryData.paymentData.Discount,
          };
          Events.logConsumerEvent(eventmodel);
          window.googleBingCustomEvent(
            'Text App',
            'Text Transact',
            'Click',
            1,
            'text us transact click'
          );
        }
        FormOpening.eventTagging(
          {
            LeadTypeID: 31,
            LeadTypeName: 'text-us-transact',
          },
          undefined,
          true
        );
      } else {
        //No action needed for other cases
      }
    },
  },
};
