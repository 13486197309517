<template>
  <div>
    <div class="buy_a_vehicle_card animate_bottom position-relative p-2 mb-3">
      <div class="custom_legend d-flex align-items-center">
        <span class="mr-2">Your New Vehicle</span>
        <span class="border_right flex-1 d-block" />
      </div>
      <div class="py-2">
        <img class="img-fluid" :src="responseData.imageUrl" alt="" />
      </div>
      <div class="text-center">
        <span class="d-block ft-small font-weight-bold">{{ responseData.type }}</span>
        <div v-if="!responseData.vdvUrl">
          <h5 class="my-1">
            {{ responseData.year }} {{ responseData.make }} {{ responseData.model }}
            {{ responseData.trim }}
          </h5>
        </div>
        <div v-if="responseData.vdvUrl">
          <a :href="responseData.vdvUrl" class="text-decoration-none color-black">
            <h5 class="my-1">
              {{ responseData.year }} {{ responseData.make }} {{ responseData.model }}
              {{ responseData.trim }}
            </h5>
          </a>
          <div class="d-flex justify-content-center ">
            <vdv-button :vdv-url="responseData.vdvUrl" />
          </div>
        </div>
        <div class="mt-2 ft-small mx-auto">
          <div v-if="responseData.exteriorColorCode" class="row mb-1">
            <span class="opacity-7 col-6 text-right">Exterior Color</span>
            <span class="font-weight-bold"><small class="color_selector"
                :style="{ background: '#' + responseData.exteriorColorCode }" />{{ responseData.exteriorColor }}</span>
          </div>
          <div v-if="responseData.interiorColorCode || responseData.int_Color_Generic" class="row mb-1">
            <span class="col-6 opacity-7 text-right">Interior Color</span>
            <span class="font-weight-bold"><small class="color_selector" :style="{
              background: responseData.interiorColorCode
                ? '#'
                : '' + (responseData.interiorColorCode || responseData.int_Color_Generic),
            }" />{{ responseData.interiorColor }}</span>
          </div>
          <div v-if="responseData.tradeOption" class="row">
            <span class="col-6 opacity-7 text-right"> Trade</span>
            <span class="font-weight-bold"> {{ responseData.tradeOption }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TvmVDVIcon from '../../TvmVDVIcon/TvmVDVIcon.vue';

export default {
  components: {
    'vdv-button': TvmVDVIcon
  },
  props: {
    responseData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      primaryColor: window.primaryColor,
    };
  },
};
</script>
<style lang="less" scoped>
.buy_a_vehicle_card {
  padding: 0 5px;

  img {
    height: 200px;
    display: block;
    margin: auto;
  }

  h5 {
    font-size: 0.8vw;
    font-weight: 600;
  }

  .ft-small {
    font-size: 0.75vw;
  }

  .opacity-7 {
    opacity: 0.7;
  }

  .color_selector {
    width: 0.7vw;
    height: 0.7vw;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: -1px;
    vertical-align: text-bottom;
  }

  .table-borderless>tbody>tr>td,
  .table-borderless>tbody>tr>th,
  .table-borderless>tfoot>tr>td,
  .table-borderless>tfoot>tr>th,
  .table-borderless>thead>tr>td,
  .table-borderless>thead>tr>th {
    border: none;
    padding: 5px;
    font-size: 0.73vw;
    font-weight: 500;
  }

  table.table-borderless tr td {
    max-width: 0;
  }

  table.table-borderless tr td:first-child {
    opacity: 0.6;
  }
}

.border_right {
  display: block;
  width: 100%;
  height: 2px;
  background: #eee;
  position: relative;
  top: 2px;
}

.custom_legend {
  font-size: 0.8vw;
  font-weight: bold;
}

.animate_bottom {
  animation: animateBottom 2s;
  transition: 0.5s;
}

.disclaimer {
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.73vw;
}

@keyframes animateBottom {
  0% {
    transform: translateY(50px);
  }

  100% {
    transform: translateY(0);
  }
}

.max-auto-width {
  max-width: fit-content;
}

.max-100 {
  max-width: 100px;
}
</style>
