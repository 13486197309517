import axios from 'axios';
import { Validations } from '../../../Services/Validations/Validations';
import { Events } from '../../../Events/ConsumerEvents/Events';
import ShowDisclaimer from '@/js/components/leadforms/ShowDisclaimer.vue';
import { TagManagerContainer } from '../../../Events/GoogleTagManager/TagManagerContainer';
import {
  DWP_Sales_Text_90501,
  DWP_Service_Text_90601,
} from '@/js/services/GoogleTagManager/GoogleTagConstants';
import UtmUrlService from '@/js/services/UtmUrlService';

import DealerGroupService from '@/js/services/DealerGroupService';
import ShiftDigitalContainer from '../../../Events/ShiftDigital/ShiftDigitalContainer';
import {
  SHIFT_DIGITAL_OTHER,
  SHIFT_DIGITAL_SERVICE,
} from '@/js/services/ShiftDigital/ShiftDigitalConstants';
import ShiftDigitalService from '../../../Events/ShiftDigital/ShiftDigitalService';
import FormOpening from '../../../Services/FormServices/FormOpening';
import {
  OPEN_TEXT_LABEL_UNITE_DIGITAL,
  SUBMIT_TEXT_LABEL_UNITE_DIGITAL,
  TEXT_UNITE_OBJECT,
} from '@/js/services/UniteDigital/UniteDigitalConstants';

import { LogLeadApi } from '../../../Services/LogLeadApiService/LogLeadApiService';
import { EXPRESS_GET_SELECTED_SUMMARY_DATA } from '../../../../../store/types';
import ShiftTcpaDisclaimerCheckbox from '../../Shared/ShiftTcpaDisclaimerCheckbox/ShiftTcpaDisclaimerCheckbox.vue';
import { fireSonicChatIconClick } from '../../../Events/SonicDataLayer/SonicDataService';
import {
  fireTagChatSalesAppt,
  fireTagChatSalesForm,
  fireTagChatServiceAppt,
  fireTagChatServiceForm,
  fireTagChatSubmit,
} from '../../../Events/TagManagerServices/TaggingEventForPages';

import {
  isAcuraJDPA,
  isGenesisJDPA,
  isHondaAutoData,
} from '@/js/services/GoogleTagManager/TagManagerConstants';
import { fireDrsTagManagerEvent } from '../../../Events/DRSEvents/DRSEventsService';
import { DRS_PAYMENT_CALCULATOR_FORM_LEAD } from '@/js/services/DRSEvents/DRSEventsConstants';

import TcpaDisclaimer from '@/js/components/leadforms/TcpaDisclaimer.vue';
import { LocalStorageService } from '@/js/components/shared/Services/LocalStorageService';
import { mapState } from 'pinia';
import { useExpressCheckouStore } from '../../../store/ExpressCheckout/ExpressCheckoutStore/ExpressCheckoutStore';

export default {
  components: {
    ShowDisclaimer,
    ShiftTcpaDisclaimerCheckbox,
    TcpaDisclaimer,
  },
  props: {
    referFrom: {
      type: String,
      required: false,
      default: '',
    },
    openserviceprop: {
      type: Boolean,
      required: false,
      default: false,
    },
    opensalesprop: {
      type: Boolean,
      required: false,
      default: false,
    },
    opentransactprop: {
      type: Boolean,
      required: false,
      default: false,
    },
    disclaimer: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      zipDetails: {},
      setTextAsLeadForm: window.setTextAsLeadForm,
      firstName: '',
      lastName: '',
      shiftDigital: '',
      channellistready: false,
      isLoader: false,
      displayText: 'Please Wait Data is Loading...',
      tvm_AccountId: '',
      stageurl: atob(window.portalUrl) + 'offersites',
      // stageurl: "https://dev.teamvelocityportal.com/OfferSites",
      textuschannellistdata: [],
      salesfirstdata: '',
      servicefirstdata: '',
      transactfirstdata: '',
      islistformactive: false,
      salesOptions: [],
      serviceOptions: [],
      transactOptions: [],
      listforminitialdisplay: false,
      isthankyou: false,
      opentheservice: false,
      openthesales: false,
      openthetransact: false,
      leadTypeid: '',
      touchpointAccountId: '',
      textRequestPhoneNumberId: '',
      dealerPhoneNumber: '',
      isChannelListActive: false,
      isSalesActive: false,
      isServiceActive: false,
      isTransactActive: false,
      senderror: false,
      integrateWithText: window.integrateWithText,
      textdetails: {
        vTextarea: '',
        vRadio: '',
        vPhonenumber: '',
        vEmailAddress: '',
        Zip: '',
      },
      errordetails: {
        phoneerror: false,
        firstnameerror: false,
        lastnameerror: false,
        textArea: false,
        ziperror: false,
        emailerror: false,
      },
      sendtodealerdata: {
        dealerPhoneNumber: '',
        consumerPhoneNumber: '',
        message: '',
        accountId: '',
        locationId: '',
        leadTypeId: '',
      },
      loadShiftSpecialFields: window.showShiftSpecialFields,
      hideTextForm: true,
      themePrimaryColor: window.primaryColor,
      isGenesisJDPAEnabled: isGenesisJDPA(true),
      tcpChecked: window.enableTCPAConsent ? false : null,
      showZipCodeField: window.showZipCodeField,
      showEmailField: window.showEmailField,
    };
  },

  computed: {
    ...mapState(useExpressCheckouStore, {
      showExpressCheckoutModal: 'showExpressCheckoutModal',
      paymentConfiguration: 'expressPaymentConfiguration',
      summaryData: EXPRESS_GET_SELECTED_SUMMARY_DATA,
    }),
  },
  watch: {
    firstName(val) {
      let validations = new Validations();
      this.errordetails.firstnameerror = validations.checkNameHasSpecialCharacters(val);
      if (!this.errordetails.firstnameerror) {
        this.errordetails.firstnameerror = validations.checkNameLimit(
          this.firstName,
          2,
          'First Name'
        );
      }
    },
    lastName(val) {
      let validations = new Validations();
      this.errordetails.lastnameerror = validations.checkNameHasSpecialCharacters(val);
      if (!this.errordetails.lastnameerror) {
        this.errordetails.lastnameerror = validations.checkNameLimit(this.lastName, 2, 'Last Name');
      }
    },
    'textdetails.vTextarea': {
      handler(val) {
        let validations = new Validations();
        if (!this.textdetails.vRadio) {
          this.errordetails.textArea = validations.checkEmpty(val);
        } else {
          this.errordetails.textArea = '';
        }
      },
    },
    'textdetails.vPhonenumber': {
      handler(val) {
        let validations = new Validations();
        this.errordetails.phoneerror = validations.checkPhone(val);
      },
    },
    'textdetails.vEmailAddress': {
      handler(val) {
        let validations = new Validations();
        if (this.showEmailField) {
          this.errordetails.emailerror = validations.checkEmail(val);
        }
      },
    },
    'textdetails.Zip': {
      handler(val) {
        let validations = new Validations();
        if (this.showZipCodeField) {
          this.errordetails.ziperror = validations.checkZip(val);
        }
      },
    },
  },
  mounted() {
    this.zipDetails = { ...LocalStorageService.zipDetails() };
    if (
      window.themeName?.toLowerCase() === 'genesis' ||
      window.themeName?.toLowerCase() === 'infiniti'
    ) {
      this.showZipCodeField = true;
      this.showEmailField = true;
    }
    if (window.makeZipCodeMandatory) {
      this.showZipCodeField = true;
    }

    if (window.isauthenticated === 'True') {
      let userDetails = window.userDetails;
      this.firstName = userDetails.firstname || '';
      this.lastName = userDetails.lastname || '';
      this.textdetails.vPhonenumber = userDetails.phonenumber || '';
      if (this.showZipCodeField) {
        this.textdetails.vPhonenumber = userDetails.zip || '';
      }
    }
    // this.$root.$on('textUsButtonsNavigation', () => {
    //   this.isChannelListActive = true;
    //   this.islistformactive = false;
    // });

    window.addEventListener('textUsButtonsNavigation', (val) => {
      if (val) {
        this.isChannelListActive = true;
        this.islistformactive = false;
      }
    });

    this.opentheservice = this.openserviceprop;
    this.openthesales = this.opensalesprop;
    this.openthetransact = this.opentransactprop;

    console.log(window.textusParameter);
    if (window.textusParameter === 'sales') {
      this.opentheservice = false;
      this.openthesales = true;
      this.openthetransact = false;
    } else if (window.textusParameter === 'service') {
      this.openthesales = false;
      this.opentheservice = true;
      this.openthetransact = false;
    } else if (window.textusParameter === 'transact') {
      this.openthesales = false;
      this.opentheservice = false;
      this.openthetransact = true;
    } else {
      // No action needed for other cases
    }

    FormOpening.openForm(OPEN_TEXT_LABEL_UNITE_DIGITAL, TEXT_UNITE_OBJECT);

    window.textusParameter = '';

    this.isChannelListActive = false;
    this.isSalesActive = false;
    this.isTransactActive = false;
    this.isServiceActive = false;
    this.channellistready = true;
    this.isLoader = true;

    this.islistformactive = false;
    this.listforminitialdisplay = false;
    this.isthankyou = false;

    if (typeof window.accountId !== 'undefined') {
      this.tvm_AccountId = window.accountId;
    } else {
      this.tvm_AccountId = this.tvm_getParams('chatwidget.js')['accountid'];
    }
    if (window.isDealerGroup && window.childAccountId && window.childAccountId !== '0') {
      this.tvm_AccountId = window.childAccountId;
    }

    //log consumer event when opending test drive from inventory vdp
    let eventmodel = {
      ...Events.consumerEventModel,
      consumerEventId: Events.eventsConstants.opentextusForm,
      styleId: this.styleid,
      vin: this.vin,
    };
    Events.logConsumerEvent(eventmodel);
    axios
      .get(`${this.stageurl}/service/textus/get?accountId=${this.tvm_AccountId}`)
      .then((response) => {
        this.textuschannellistdata = response.data;
        this.isChannelListActive = true;
        this.channellistready = false;
        this.isLoader = false;

        for (let data of this.textuschannellistdata) {
          if (data.LeadTypeId === 1) {
            if (this.salesfirstdata === '') {
              this.salesfirstdata = data;
            }
          } else if (data.LeadTypeId === 2) {
            if (this.servicefirstdata === '') {
              this.servicefirstdata = data;
            }
          } else if (data.LeadTypeId === 4) {
            if (this.transactfirstdata === '') {
              this.transactfirstdata = data;
            }
          } else {
            // No action needed for other cases
          }
        }

        if (window.textusparameter === 'sales') {
          if (this.salesfirstdata !== '') {
            this.openSales();
          } else {
            this.$emit('closepopup');
          }
        } else if (window.textusparameter === 'service') {
          if (this.servicefirstdata !== '') {
            this.openService();
          } else {
            this.$emit('closepopup');
          }
        } else if (window.textusparameter === 'transact') {
          if (this.servicefirstdata !== '') {
            this.openTransact();
          } else {
            this.$emit('closepopup');
          }
        } else {
          // No action needed for other cases
        }

        if (this.opentheservice) {
          this.openService();
        } else if (this.openthesales) {
          this.openSales();
        } else if (this.openthetransact) {
          if (this.transactfirstdata === '') {
            this.opentheservice = this.openthetransact = false;
            this.openthesales = true;
            this.openSales();
          } else {
            this.openTransact();
          }
        } else {
          // No action needed for other cases
        }
      })
      .catch((error) => {
        console.log(error);
        this.displayText = 'Something Went wrong. Please try again';
      });
  },

  methods: {
    checkGooglePresent(data) {
      let datatolowercase = data.toLowerCase();
      return datatolowercase.indexOf('google') !== -1;
    },
    tvm_getParams(script_name) {
      let v_scripts = document.getElementsByTagName('script');
      for (let script of v_scripts) {
        if (script.src.toLowerCase().indexOf(script_name) > -1) {
          let v_pa = script.src.split('?').pop().split('&');
          let v_p = {};
          for (let val of v_pa) {
            let kv = val.split('=');
            v_p[kv[0].toLowerCase()] = kv[1];
          }
          return v_p;
        }
      }
      return {};
    },
    channellistclick(
      leadTypeId,
      touchpointAccountId,
      textRequestPhoneNumberId,
      phoneNumber,
      textName = ''
    ) {
      fireSonicChatIconClick(textName);
      this.$root.$emit('textUs');
      if (leadTypeId === 1) {
        this.openSales();
      } else if (leadTypeId === 2) {
        this.openService();
      } else {
        this.openTransact();
      }

      this.isChannelListActive = false;
      this.textdetails.vTextarea = '';
      this.textdetails.vRadio = '';
      this.textdetails.vPhonenumber = '';
      this.textdetails.Zip = '';

      this.islistformactive = true;
      this.listforminitialdisplay = true;

      setTimeout(function () {
        let element = document.getElementById('sendbtn');
        element.setAttribute('data-leadTypeid', leadTypeId);
        element.setAttribute('data-touchpointAccountId', touchpointAccountId);
        element.setAttribute('data-textRequestPhoneNumberId', textRequestPhoneNumberId);
        element.setAttribute('data-dealerPhoneNumber', phoneNumber);
      }, 1000);
    },
    clearErrorData() {
      setTimeout(() => {
        !this.textdetails.vTextarea && (this.errordetails.textArea = false);
        !this.firstName && (this.errordetails.firstnameerror = false);
        !this.lastName && (this.errordetails.lastnameerror = false);
        !this.textdetails.vPhonenumber && (this.errordetails.phoneerror = false);
        !this.textdetails.Zip && (this.errordetails.ziperror = false);
      }, 100);
    },

    openService() {
      this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
      fireTagChatServiceForm();

      axios
        .get(
          `${this.stageurl}/service/textus/GetTextUsSettings?accountId=${this.tvm_AccountId}&leadtypeid=2`
        )
        .then((response) => {
          console.log(response.data);
          this.serviceOptions = response.data;
          this.isChannelListActive = false;
          let textmessage = this.textdetails.vTextarea;
          this.islistformactive = true;
          this.listforminitialdisplay = true;

          this.isSalesActive = false;
          this.isTransactActive = false;
          this.isServiceActive = true;

          if (this.shiftDigital) {
            this.shiftDigital.initiateForm(SHIFT_DIGITAL_SERVICE);
            ShiftDigitalService.initiateFormEvents(this.shiftDigital, SHIFT_DIGITAL_SERVICE);
          }

          if (typeof window.accountId !== 'undefined') {
            let eventmodel = {
              ...Events.consumerEventModel,
              consumerEventId: Events.eventsConstants.textusServiceButton,
              textUsType: Events.eventsConstants.texttypeservice,
              textusMessage: textmessage,
            };
            Events.logConsumerEvent(eventmodel);
            window.googleBingCustomEvent(
              'Text App',
              'Text Service',
              'Click',
              1,
              'text us service click'
            );
          }
        });
    },
    openTransact() {
      this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();

      axios
        .get(
          `${this.stageurl}/service/textus/GetTextUsSettings?accountId=${this.tvm_AccountId}&leadtypeid=1`
        )
        .then((response) => {
          console.log(response.data);
          this.transactOpitions = response.data;
          this.isChannelListActive = false;
          let textmessage = this.textdetails.vTextarea;
          this.islistformactive = true;
          this.listforminitialdisplay = true;

          this.isSalesActive = false;
          this.isTransactActive = true;
          this.isServiceActive = false;

          if (this.shiftDigital) {
            this.shiftDigital.initiateForm(SHIFT_DIGITAL_OTHER);
            try {
              ShiftDigitalService.initiateFormEvents(this.shiftDigital, SHIFT_DIGITAL_OTHER);
            } catch (e) {
              console.log(e);
            }
          }

          if (typeof window.accountId !== 'undefined') {
            let eventmodel = {
              ...Events.consumerEventModel,
              consumerEventId: Events.eventsConstants.textusTransactButton,
              textUsType: 'transact',
              textusMessage: textmessage,
              downpayment: this.paymentConfiguration.downPayment,
              paymentTerm: this.paymentConfiguration.term,
              milesPerYear: this.paymentConfiguration.mileage,
              paymentType: this.paymentConfiguration.selectedPayment,
              tradeIn: this.summaryData.paymentData.TradeEquity,
              MSRP: parseFloat(this.summaryData.paymentData.MSRP.replace(/[$,]/g, '')),
              payment: this.summaryData.paymentData.Payment.replace(/[$,]/g, ''),
              incentives: this.summaryData.paymentData.IncentiveCash,
              sellingPrice: parseFloat(
                this.summaryData.paymentData.PurchasePrice.replace(/[$,]/g, '')
              ),
              paymentDueAtSigning: this.summaryData.paymentData.DueAtSigning.replace(/[$,]/g, ''),
              discount: this.summaryData.paymentData.Discount,
            };
            Events.logConsumerEvent(eventmodel);
            window.googleBingCustomEvent(
              'Text App',
              'Text Transact',
              'Click',
              1,
              'text us transact click'
            );
          }

          setTimeout(() => {
            if (this.transactfirstdata) {
              this.channellistclick(
                4,
                this.transactfirstdata.TouchpointAccountId,
                this.transactfirstdata.TextRequestPhoneNumberId,
                this.transactfirstdata.PhoneNumber
              );
            }
            this.transactfirstdata = '';
          }, 1000);
        });
    },

    openSales() {
      this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
      fireTagChatSalesForm();

      axios
        .get(
          `${this.stageurl}/service/textus/GetTextUsSettings?accountId=${this.tvm_AccountId}&leadtypeid=1`
        )
        .then((response) => {
          this.salesOptions = response.data;
          this.isChannelListActive = false;
          let textmessage = this.textdetails.vTextarea;

          this.isSalesActive = true;
          this.listforminitialdisplay = true;
          this.islistformactive = true;

          this.isTransactActive = false;
          this.isServiceActive = false;
          if (this.shiftDigital) {
            this.shiftDigital.initiateForm(SHIFT_DIGITAL_OTHER);
            ShiftDigitalService.initiateFormEvents(this.shiftDigital, SHIFT_DIGITAL_OTHER);
          }

          if (typeof window.accountId !== 'undefined') {
            let eventmodel = {
              ...Events.consumerEventModel,
              consumerEventId: Events.eventsConstants.textusSalesButton,
              textUsType: Events.eventsConstants.texttypesales,
              textusMessage: textmessage,
            };
            Events.logConsumerEvent(eventmodel);
            window.googleBingCustomEvent(
              'Text App',
              'Text Sales',
              'Click',
              1,
              'text us sales click'
            );
          }
        });
    },

    async sendtext() {
      let shiftDigitalEvent = ShiftDigitalContainer.getShiftDigitalObject();
      let validations = new Validations();
      if (!this.textdetails.vRadio) {
        this.errordetails.textArea = validations.checkEmpty(this.textdetails.vTextarea);
      } else {
        this.errordetails.textArea = '';
      }
      if (this.showZipCodeField) {
        this.errordetails.ziperror = validations.checkZip(this.textdetails.Zip);
      }

      //first name last name validation

      this.errordetails.firstnameerror = validations.checkNameHasSpecialCharacters(this.firstName);
      this.errordetails.lastnameerror = validations.checkNameHasSpecialCharacters(this.lastName);

      if (!this.errordetails.firstnameerror) {
        this.errordetails.firstnameerror = validations.checkNameLimit(
          this.firstName,
          2,
          'First Name'
        );
      }
      if (this.showEmailField) {
        if (!this.errordetails.emailerror) {
          this.errordetails.emailerror = validations.checkEmail(this.textdetails.vEmailAddress);
        }
      }

      if (!this.errordetails.lastnameerror) {
        this.errordetails.lastnameerror = validations.checkNameLimit(this.lastName, 2, 'Last Name');
      }

      if (!this.errordetails.phoneerror) {
        this.errordetails.phoneerror = validations.checkEmpty(this.textdetails.vPhonenumber);
      }

      if (this.loadShiftSpecialFields) {
        //shift bmw ccpa and tcpa disclaimer checkboxes
        validations.checkShiftTcpaDisclaimer(this.textdetails, this.errordetails);
      }

      ///ends
      // let loc_userguid =
      //   typeof window.userguid === 'undefined' ? '' : window.userguid
      // let loc_isBot = typeof window.IsBot === 'undefined' ? false : window.IsBot
      let textmessage =
        'I am interested in:' +
        ' ' +
        this.textdetails.vRadio +
        '\n' +
        this.textdetails.vTextarea +
        '$$$' +
        this.textdetails.vPhonenumber;
      let userPin = window.userpin;
      let userdetails = window.userDetails;
      let enableTextusLeadToCRM = window.enableTextusLeadToCRM;
      let leadapidetails = {};

      leadapidetails['TCPConsent'] = window.enableTCPAConsent ? false : null;

      if (window.autoToggleTCPA === true) {
        this.tcpChecked = true;
      }

      if (this.tcpChecked) {
        leadapidetails['TCPConsent'] = window.enableTCPAConsent ? false : null;
      }

      if (shiftDigitalEvent && typeof shiftDigitalEvent.chatMessageEvent === 'function') {
        shiftDigitalEvent.chatMessageEvent(textmessage);
      }
      let eventmodel = {
        ...Events.consumerEventModel,
        consumerEventId: Events.eventsConstants.textusSendButton,
        textUsType: '',
        textusMessage: textmessage,
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: parseInt(this.textdetails.vPhonenumber),
      };

      if (validations.checkError(this.errordetails)) {
        this.errordetails.phoneerror = validations.checkPhone(this.textdetails.vPhonenumber);
        if (validations.checkError(this.errordetails)) {
          let element = document.getElementById('sendbtn');
          this.sendtodealerdata.dealerPhoneNumber = parseInt(
            element.getAttribute('data-dealerPhoneNumber')
          );
          if (this.textdetails.vPhonenumber) {
            this.textdetails.vPhonenumber = this.textdetails.vPhonenumber.match(/\d/g).join('');
          }
          this.sendtodealerdata.consumerPhoneNumber = parseInt(this.textdetails.vPhonenumber);
          this.sendtodealerdata.message = this.textdetails.vTextarea;
          this.sendtodealerdata.accountId = parseInt(
            element.getAttribute('data-touchpointAccountId')
          );
          this.sendtodealerdata.locationId = parseInt(
            element.getAttribute('data-textRequestPhoneNumberId')
          );
          this.sendtodealerdata.leadTypeId = parseInt(element.getAttribute('data-leadTypeid'));

          if (element.getAttribute('data-dealerPhoneNumber') === null) {
            if (window.textusparameter === 'sales') {
              this.sendtodealerdata.dealerPhoneNumber = +this.salesfirstdata.PhoneNumber;
              this.sendtodealerdata.accountId = +this.tvm_AccountId;
              this.sendtodealerdata.locationId = +this.salesfirstdata.TextRequestPhoneNumberId;
              this.sendtodealerdata.leadTypeId = 1;
            } else {
              if (this.openthesales) {
                this.sendtodealerdata.dealerPhoneNumber = +this.salesfirstdata.PhoneNumber;
                this.sendtodealerdata.accountId = +this.tvm_AccountId;
                this.sendtodealerdata.locationId = +this.salesfirstdata.TextRequestPhoneNumberId;
                this.sendtodealerdata.leadTypeId = 1;
              } else if (this.opentheservice) {
                this.sendtodealerdata.dealerPhoneNumber = +this.servicefirstdata.PhoneNumber;
                this.sendtodealerdata.accountId = +this.tvm_AccountId;
                this.sendtodealerdata.locationId = +this.servicefirstdata.TextRequestPhoneNumberId;
                this.sendtodealerdata.leadTypeId = 2;
              } else {
                this.sendtodealerdata.dealerPhoneNumber = +this.servicefirstdata.PhoneNumber;
                this.sendtodealerdata.accountId = +this.tvm_AccountId;
                this.sendtodealerdata.locationId = +this.servicefirstdata.TextRequestPhoneNumberId;
                this.sendtodealerdata.leadTypeId = 4;
              }
            }

            if (this.isServiceActive) {
              this.sendtodealerdata.leadTypeId = 2;
            } else if (this.isSalesActive) {
              this.sendtodealerdata.leadTypeId = 1;
            } else {
              this.sendtodealerdata.leadTypeId = 4;
            }
          }

          if ((userPin && userPin !== null) || this.setTextAsLeadForm) {
            leadapidetails = {
              FirstName: userdetails.firstname,
              LastName: userdetails.lastname,
              EmailAddress: userdetails.email,
              PhoneNumber: parseInt(this.textdetails.vPhonenumber),
              Message: textmessage,
              vin: window.vin ? window.vin : '',
              styleId: window.styleId ? +window.styleId : 0,
            };
          }
          //firstName last Name
          leadapidetails.FirstName = this.firstName ? this.firstName : userdetails.firstname;
          leadapidetails.LastName = this.lastName ? this.lastName : userdetails.lastname;
          //ends
          if (this.showZipCodeField) {
            leadapidetails.Zip = this.textdetails?.Zip || userdetails?.zip || '';
          }

          if (this.sendtodealerdata.leadTypeId === 1) {
            if (typeof window.accountId !== 'undefined') {
              window.googleBingCustomEvent(
                'Text App',
                'Text Sales',
                'Send Text',
                1,
                'text us on sales submit'
              );
              if (!userPin && userPin !== null) {
                eventmodel.textUsType = Events.eventsConstants.texttypesales;
                // Events.logConsumerEvent(eventmodel);
              }

              leadapidetails['LeadTypeID'] = 24;
              leadapidetails['LeadTypeName'] = 'text-us-sales';
              leadapidetails['DealerID'] = window.accountId;
              leadapidetails['campaignID'] = window.campaignId;
              leadapidetails['SessionId'] = window.tid;
            }
          } else if (this.sendtodealerdata.leadTypeId === 2) {
            if (typeof window.accountId !== 'undefined') {
              window.googleBingCustomEvent(
                'Text App',
                'Text Service',
                'Send Text',
                1,
                'text us on service submit'
              );

              if (!userPin && userPin !== null) {
                eventmodel.textUsType = Events.eventsConstants.texttypeservice;
                // Events.logConsumerEvent(eventmodel);
              }
              leadapidetails['websiteUrlLeadSubmitted'] = window.location.href;
              leadapidetails['LeadTypeID'] = 25;
              leadapidetails['LeadTypeName'] = 'text-us-service';
              leadapidetails['DealerID'] = window.accountId;
              leadapidetails['campaignID'] = window.campaignId;
              leadapidetails['SessionId'] = window.tid;
            }
          } else {
            if (typeof window.accountId !== 'undefined') {
              window.googleBingCustomEvent(
                'Text App',
                'Text Transact',
                'Send Text',
                1,
                'text us on transact submit'
              );

              if (!userPin && userPin !== null) {
                eventmodel.textUsType = 'General';
                // Events.logConsumerEvent(eventmodel);
              }

              leadapidetails['websiteUrlLeadSubmitted'] = window.location.href;
              leadapidetails['LeadTypeID'] = 237;
              leadapidetails['Payment'] = this.summaryData?.paymentData
                ? this.summaryData.paymentData
                : {};
              leadapidetails['LeadTypeName'] = 'text-us-general';
              leadapidetails['DealerID'] = window.accountId;
              leadapidetails['campaignID'] = window.campaignId;
              leadapidetails['SessionId'] = window.tid;
            }
          }
          console.log(this.sendtodealerdata);
          if ((userPin && userPin !== null && enableTextusLeadToCRM) || this.setTextAsLeadForm) {
            let tagManager = TagManagerContainer.getDealerObject();
            if (tagManager && typeof tagManager.getFormSubmit === 'function') {
              if (leadapidetails['LeadTypeID'] === 25) {
                tagManager.getFormSubmit(DWP_Service_Text_90601[0], DWP_Service_Text_90601[1]);
              } else if (typeof tagManager.getFormSubmit === 'function') {
                tagManager.getFormSubmit(DWP_Sales_Text_90501[0], DWP_Sales_Text_90501[1]);
              } else {
                //No action needed for other cases
              }
            }

            leadapidetails = {
              ...leadapidetails,
              ...UtmUrlService.getUrl(),
              ...{ sessionId: window.tid },
            };

            leadapidetails['dealergroupid'] = DealerGroupService.getDgId();

            leadapidetails['VDPAccountId'] = window.vdpAccountId ? +window.vdpAccountId : 0;

            if (this.loadShiftSpecialFields) {
              leadapidetails['ShiftTCPAConsent'] = this.textdetails.ShiftTCPAConsent;
              leadapidetails['ShiftTCPADisclaimer'] = this.textdetails.ShiftTCPADisclaimer;
            }
            if (this.tcpChecked) {
              leadapidetails['TCPConsent'] = true;
            }
            if (this.showExpressCheckoutModal && (isAcuraJDPA() || isHondaAutoData())) {
              fireDrsTagManagerEvent('', DRS_PAYMENT_CALCULATOR_FORM_LEAD());
            }

            try {
              let response = await LogLeadApi(leadapidetails);
              if (this.shiftDigital) {
                if (
                  !this.referFrom &&
                  this.shiftDigital &&
                  typeof this.shiftDigital.vehicleTextUsFormSubmit === 'function'
                ) {
                  this.shiftDigital.vehicleTextUsFormSubmit();
                }

                if (
                  this.referFrom &&
                  typeof this.shiftDigital.newSpecialsTextUsFormOnSubmit === 'function'
                ) {
                  let type = this.referFrom === 'preowned' ? 'pre-owned' : this.referFrom;
                  this.shiftDigital.newSpecialsTextUsFormOnSubmit(type);
                }
                if (leadapidetails['LeadTypeID'] === 25) {
                  this.shiftDigital.submitFormEvent(response.data, SHIFT_DIGITAL_SERVICE);
                } else {
                  this.shiftDigital.submitFormEvent(response.data, SHIFT_DIGITAL_OTHER);
                }
              }

              eventmodel.logLeadItemId = response.data;
              if (shiftDigitalEvent && typeof shiftDigitalEvent.chatLeadEvent === 'function') {
                shiftDigitalEvent.chatLeadEvent(response.data);
              }
              if (this.sendtodealerdata.leadTypeId === 1) {
                eventmodel.textUsType = Events.eventsConstants.texttypesales;
              } else if (this.sendtodealerdata.leadTypeId === 2) {
                eventmodel.textUsType = Events.eventsConstants.texttypeservice;
              } else {
                eventmodel.textUsType = 'Transact';
              }
              if (typeof window.accountId !== 'undefined') {
                Events.logConsumerEvent(eventmodel);
              }

              FormOpening.submitForm(SUBMIT_TEXT_LABEL_UNITE_DIGITAL, '', TEXT_UNITE_OBJECT);
              console.log('Log Lead is submitted for the loggedin users');
            } catch (e) {
              console.log(e);
            }
          }

          let sendtodealerurl = `${this.stageurl}/service/textus/sendtodealer`;
          fireTagChatSubmit(this.isServiceActive ? 'Service' : 'Sales');
          if (this.isServiceActive) {
            fireTagChatSalesAppt();
          } else {
            fireTagChatServiceAppt();
          }
          axios
            .post(sendtodealerurl, this.sendtodealerdata, {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then((response) => {
              if (response.status === 200) {
                this.listforminitialdisplay = false;
                this.isthankyou = true;
                this.hideTextForm = false;
              }
            })
            .catch((error) => {
              console.log('check error' + error);
              this.senderror = true;
              this.listforminitialdisplay = false;
              this.hideTextForm = false;
            });
        }
      }
    },
    backbtnclick() {
      this.opentheservice = false;
      this.isChannelListActive = true;
      this.openserviceprop = false;
      this.isServiceActive = false;
      this.isSalesActive = false;
      this.islistformactive = false;
      this.listforminitialdisplay = false;
      this.isthankyou = false;
      this.errordetails.phoneerror = false;
      this.textdetails.vTextarea = '';
      this.textdetails.vRadio = '';
      this.textdetails.vPhonenumber = '';
    },

    isNumberKey(evt) {
      evt = evt || window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    ontcpChecked($event) {
      this.tcpChecked = $event;
    },
  },
};
