import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService';
export default {
  data() {
    return {
      // searchText: '',
      suggestions: [],
      debounceTimeout: null,
      isCheckSelectedAddress: false,
      suggestionBottom: '',
    };
  },
  props: {
    searchText: {
      required: true,
      default: null,
    },
    selectedAddressValue: {
      required: true,
      default: {},
    },
    botResponse: {
      required: true,
      type: Object,
    },
  },
  methods: {
    debouncedSearch() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(this.autocompleteSearch, 300);
    },
    autocompleteSearch() {
      if (this.searchText === '') {
        this.suggestions = [];
        return this.suggestions;
      }
      ChatWidgetService.getAutoCompleteAddress(this.autoCompleteUri, this.searchText)
        .then((response) => {
          this.suggestions = response.data;
        })
        .catch((error) => {
          console.error('Error fetching autocomplete suggestions: ', error);
        });
    },
    selectSuggestion(suggestion) {
      this.$emit('selectedInputAddress', suggestion);
      this.suggestions = [];
      this.isCheckSelectedAddress = true;
      this.$nextTick(() => {
        this.scrollBottom();
      });
    },
    scrollBottom() {
      let container = document.querySelector('#chat__bodyview');
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 100);
      }
    },
  },
  watch: {
    searchText(val) {
      if (val === '') {
        this.suggestions = [];
        return; // Exit early if searchText is empty
      }
      // Check if selectedAddressValue description is not available
      if (!this.selectedAddressValue?.description) {
        this.debouncedSearch();
      }
    },
    suggestions(val) {
      if (val?.length && document.querySelector('#isTileNavigate')) {
        this.suggestionBottom = '-' + document.querySelector('#isTileNavigate').style.height;
      } else {
        this.suggestionBottom = '100%';
      }
    },
  },
  computed: {
    autoCompleteUri() {
      return this.botResponse?.component?.value?.autoCompleteUri;
    },
  },
};
