// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.vdv_video_icon img {
  filter: invert(1);
  height: 20px;
  width: 20px;
}
.vdv_video_icon span {
  top: 0px;
}
.vdv_link {
  gap: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChatWidget/TvmVDVIcon/TvmVDVIcon.vue"],"names":[],"mappings":";AAkBA;EACE,iBAAiB;EACjB,YAAY;EACZ,WAAW;AACb;AAEA;EACE,QAAQ;AACV;AAEA;EACE,QAAQ;AACV","sourcesContent":["<template>\r\n  <div v-if=\"vdvUrl\" class=\"m-0 mt-1  vdv_video_icon\">\r\n    <a :href=\"vdvUrl\" target=\"_blank\" class=\"d-flex  gap-1 text-decoration-none vdv_link\">\r\n      <div>\r\n        <img src=\"/images/playbutton.png\" class=\"img-fluid\" alt=\"vdv video image\" />\r\n      </div>\r\n      <div>\r\n        <span class=\"ft-12 position-relative color-black\">Play Video</span>\r\n      </div>\r\n    </a>\r\n  </div>\r\n</template>\r\n<script>\r\nexport default {\r\n  props: [\"vdvUrl\"]\r\n}\r\n</script>\r\n<style>\r\n.vdv_video_icon img {\r\n  filter: invert(1);\r\n  height: 20px;\r\n  width: 20px;\r\n}\r\n\r\n.vdv_video_icon span {\r\n  top: 0px;\r\n}\r\n\r\n.vdv_link {\r\n  gap: 4px;\r\n}\r\n</style>\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
