import { callFacebookConversionApi } from '@/js/services/FaceBookConversionApiService';
function googleCustomEvent(e, o, t, n, a) {
  (e = e ? e.toLowerCase() : ''), (o = o ? o.toLowerCase() : ''), (t = t ? t.toLowerCase() : '');
  let i = 'Google Custom Event logged successfully..';
  (a = a.toLowerCase()) && (i = i + '--------' + a + '-----------'), console.info(i);
  let g = n || 0;
  if (typeof ga === 'undefined') {
    ga = window.parent.ga;
  }
  return 'undefined' == typeof ga
    ? (console.warn('No google analytics keys'), !1)
    : (ga('send', {
        hitType: 'event',
        eventCategory: e,
        eventAction: o,
        eventLabel: t,
        eventValue: g,
      }),
      void 0 !== ga.getByName &&
        ga.getByName('dealer') &&
        ga('dealer.send', {
          hitType: 'event',
          eventCategory: e,
          eventAction: o,
          eventLabel: t,
          eventValue: g,
        }),
      void 0 !== ga.getByName &&
        ga.getByName('dealer2') &&
        ga('dealer2.send', {
          hitType: 'event',
          eventCategory: e,
          eventAction: o,
          eventLabel: t,
          eventValue: g,
        }),
      void 0 !== ga.getByName &&
        ga.getByName('dealer3') &&
        ga('dealer3.send', {
          hitType: 'event',
          eventCategory: e,
          eventAction: o,
          eventLabel: t,
          eventValue: g,
        }),
      void (
        void 0 !== ga.getByName &&
        ga.getByName('digitalAnalyticsTracker') &&
        ga('digitalAnalyticsTracker.send', {
          hitType: 'event',
          eventCategory: e,
          eventAction: o,
          eventLabel: t,
          eventValue: g,
        })
      ));
}
function bingCustomEvent(e, o, t, n, a) {
  (e = e ? e.toLowerCase() : ''), (o = o ? o.toLowerCase() : ''), (t = t ? t.toLowerCase() : '');
  let i = 'Bing Custom Event logged successfully..';
  (a = a.toLowerCase()) && (i = i + '--------' + a + '-----------'), console.info(i);
  let g = n || 0;
  if (typeof window.uetq === 'undefined') {
    window.parent.uetq.push({ ec: e, ea: o, el: t, ev: g });
  } else {
    window.uetq.push({ ec: e, ea: o, el: t, ev: g });
  }
}
export function logGoogleBingEvent(e, o, t, n, a, userData = '') {
  console.log('google bing event');
  let eventName = `${e} - ${o} - ${t}`;
  callFacebookConversionApi(eventName, userData);
  if (o) {
    googleCustomEvent(e, o, t, n, a), bingCustomEvent(e, o, t, n, a);
  }
}
