<template>
  <div v-if="vdvUrl" class="m-0 mt-1  vdv_video_icon">
    <a :href="vdvUrl" target="_blank" class="d-flex  gap-1 text-decoration-none vdv_link">
      <div>
        <img src="/images/playbutton.png" class="img-fluid" alt="vdv video image" />
      </div>
      <div>
        <span class="ft-12 position-relative color-black">Play Video</span>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  props: ["vdvUrl"]
}
</script>
<style>
.vdv_video_icon img {
  filter: invert(1);
  height: 20px;
  width: 20px;
}

.vdv_video_icon span {
  top: 0px;
}

.vdv_link {
  gap: 4px;
}
</style>
