// import { SET_CHAT_ACTIONS } from '../../../../../store/StoreConstants/CommonSettingsConstants.js';
// import store from '../../../../../store/store.js';
import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService.js';
import { chatWidgetCtaInteraction } from '../../../Events/TagManagerServices/TaggingEventForPages.js';
// import { mapState } from 'vuex';
import TextUsIcon from '../ChatIcons/TextUsIcon.vue';
import { RECENT_VDP_VIN_KEY } from '../../../../../../wwwroot/js/constants/constants.js';
import { useChatSettingsStore } from '../../../store/ChatSettingsStore/ChatSettingsStore.js';
export default {
  data() {
    return {
      disableButton: false,
      sessiontime: new Date().getTime(),
      fromuser: 'user',
      touser: 'Virtual Assistant',
      serviceButtons: [],
      evenOrOddClass: null,
      isTextUsEnabled: false,
      assistantMenuResponse: {
        component: {
          buttons: [],
        },
      },
      isLoadingApi: true,
      textUsWidgetPosition: null,
      customizedFlowNameForTextUs: null,
    };
  },

  props: {
    botResponse: {
      required: false,
    },
    isWaitingForResponse: {
      required: false,
    },
    interceptorName: {
      required: false,
      default: null,
    },
    chatThemeSettings: {
      type: Object,
      required: false,
    },
  },
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state.commonSettings.chatThemeSettings;
  //     },
  //   }),
  // },
  async mounted() {
    this.setUserName();
    this.assistantMenuGridChecker(this.botResponse);
    await new Promise((resolve) => setTimeout(resolve, 500));
    const data = await ChatWidgetService.getAssistantMenu();
    if (data?.flowButtons?.length) {
      this.assistantMenuResponse.component.buttons = data.flowButtons;
      this.assistantMenuGridChecker(this.assistantMenuResponse);
      this.scrollBottom();
    }
    this.isTextUsEnabled = window.isEnableTextAssistant;
    this.textUsWidgetPosition = window.assistantSettings?.textUsTilePosition?.toLowerCase() || null;
    this.customizedFlowNameForTextUs =
      window.assistantSettings?.customizedFlowNameForTextUs || 'Text Us';
    this.isLoadingApi = false;
  },
  beforeDestroy() {
    this.$root.$off('navigateTextForm');
  },
  methods: {
    onPayloadButtonClick(event, data) {
      this.setUserName();
      if (document.getElementById('MessageBox')) {
        document.getElementById('MessageBox').focus();
      }
      if (data?.text === 'text') {
        this.$root.$emit('navigateTextForm', true);
      } else {
        // store.commit(SET_CHAT_ACTIONS, { ModeOfAction: 'tvm-assistant-tiles' });
        let useChatSettings = useChatSettingsStore();
        useChatSettings.tvmChatActions = { ModeOfAction: 'tvm-assistant-tiles' };
        // this.$root.$emit('isWaitingForResponseData', true);
        ChatWidgetService.setFlowIndicator(data.text);
        ChatWidgetService.setFlowIndicator(data.submitAction);
        let recentVin = localStorage.getItem(RECENT_VDP_VIN_KEY);
        if (
          data.submitAction === 'tvm_ScheduleTestDrive' &&
          recentVin &&
          this.interceptorName === 'sendvdpvin'
        ) {
          data.vin = recentVin;
          data.interceptorName = this.interceptorName;
        }
        let specialObject = { TvmComponentRequest: data };
        console.log(data);
        ChatWidgetService.postTvmMessage(
          data.text,
          null,
          data.submitFlow,
          data.submitAction,
          specialObject
        );
        chatWidgetCtaInteraction({ event: event, element: data.text, isTextChat: true });
      }
    },
    setUserName() {
      let _myVars = this;
      if (window.userDetails && (window.userDetails.firstname || window.userDetails.lastname)) {
        _myVars.fromuser = `${window.userDetails.firstname} ${window.userDetails.lastname}`;
      }

      if (window.clientName) {
        this.touser = window.clientName;
      }
    },
    assistantMenuGridChecker(res) {
      if (res?.component?.buttons?.length) {
        this.serviceButtons = res?.component?.buttons;
        this.evenOrOddClass = 'chat__list';
        try {
          let gridChecker = res?.component?.buttons.filter((item) => item.type === 'button');
          this.evenOrOddClass =
            gridChecker.length % 2 === 0 ? 'chat__grid' : 'chat__grid last_full_width';
        } catch (e) {
          console.log('Error on evaluating class');
        }
      }
    },
    scrollBottom() {
      let container = document.querySelector('#chat__bodyview');
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 100);
      }
    },
  },
  components: {
    TextUsIcon,
  },
};
