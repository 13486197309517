<template>
  <div>
    <div class="value_your_trade_graphic position-relative animate_bottom">
      <div class="custom_legend">
        <span>Appraisal</span>
      </div>
      <div class="iframe_box">
        <iframe :src="kbbDetails.uri" frameborder="0" title="IFrame Trade Valuation" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    responseData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    kbbDetails: function () {
      return this.responseData;
    },
  },
};
</script>

<style lang="less" scoped>
// reuseable css
.value_your_trade_card {
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 1px;
}
.custom_legend {
  position: absolute;
  display: inline-block;
  background: #fff;
  z-index: 9;
  font-size: 12px;
  top: -12px;
  left: 30px;
  padding: 0 6px;
  color: #000;
  opacity: 0.8;
}
.card_font_sizes {
  h5 {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    white-space: initial;
  }
  p {
    margin-bottom: 5px;
    font-size: 11px;
    white-space: initial;
  }
}
.min-120 {
  min-height: 120px;
}
// end reuseable css
.value_your_trade_graphic {
  .value_your_trade_card();
  .iframe_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0 0 0;
    height: 400px;
  }
  iframe {
    height: 100%;
    display: block;
    margin: auto;
  }
}
.animate_bottom {
  animation: animateBottom 2s;
  transition: 0.5s;
}
@keyframes animateBottom {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}
@media (min-width: 1900px) {
  .custom_legend {
    font-size: 13px;
  }
}
</style>
