<template>
  <div>
    <div class="waiting-message p-2 mb-5">
      <div class="chat_loader">
        <div class="loader-inner ball-pulse-sync">
          <div class="bgcolor-primary theme-bg-color" />
          <div class="bgcolor-primary theme-bg-color" />
          <div class="bgcolor-primary theme-bg-color" />
          <div class="bgcolor-primary theme-bg-color" />
          <div class="bgcolor-primary theme-bg-color" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.scrollBottom();
  },

  methods: {
    scrollBottom() {
      let container = document.querySelector('#chat__bodyview');
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 500);
      }
    },
  },
}
</script>
<style scoped>
.chat_loader {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 24px;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes ball-pulse-sync {
  20% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  40% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  60% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  80% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes ball-pulse-sync {
  20% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  40% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  60% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  80% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.ball-pulse-sync>div:nth-child(1) {
  -webkit-animation: ball-pulse-sync 1s -0.36s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.36s infinite ease-in-out;
}

.ball-pulse-sync>div:nth-child(2) {
  -webkit-animation: ball-pulse-sync 1s -0.27s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.27s infinite ease-in-out;
}

.ball-pulse-sync>div:nth-child(3) {
  -webkit-animation: ball-pulse-sync 1s -0.18s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.18s infinite ease-in-out;
}

.ball-pulse-sync>div:nth-child(4) {
  -webkit-animation: ball-pulse-sync 1s -0.09s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.09s infinite ease-in-out;
}

.ball-pulse-sync>div:nth-child(5) {
  -webkit-animation: ball-pulse-sync 1s 0s infinite ease-in-out;
  animation: ball-pulse-sync 1s 0s infinite ease-in-out;
}

.ball-pulse-sync>div {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin: 0px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}
</style>
