<template>
  <div>
    <div v-if="serviceButtons && serviceButtons.length">
      <TvmFlowEndGeneralComponents :components-data="serviceButtons" />
    </div>
    <div class="mx-3 my-2 mb-3">
      <div v-if="serviceButtons && serviceButtons.length" class="fullfill__columns"
        :class="{ disabledContent: !isLastIndex }">
        <div :class="evenOrOddClass">
          <template v-for="(item, index) in serviceButtons">
            <div v-if="item.componentType === 'tile'" :key="index" class="chat__inneritems">
              <div class="custom-payload-con" :style="[
                { color: chatThemeSettings?.borderColor || '#878787' },
                { borderColor: chatThemeSettings?.borderColor },
              ]">
                <div :title="item.component.text" class="chat__body__btn cursor-pointer"
                  :disabled="isWaitingForResponse" @click.once="onPayloadButtonClick($event, item.component)">
                  <em :class="'primaryicon-' + item.component.image" />
                  <p class="chat__body__btn__title">
                    {{ item.component.text }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="serviceButtons && serviceButtons.length" class="tvm_flow_end_links">
        <TvmFlowEndLink :links-data="serviceButtons" />
      </div>
    </div>
  </div>
</template>

<script>
import { ChatWidgetService } from "../../../Services/ChatWidgetService/ChatWidgetService";
import TvmFlowEndLink from "./TvmFlowEndLink.vue";
import TvmFlowEndGeneralComponents from "./TvmFlowEndGeneralComponents.vue";
import { useChatSettingsStore } from "../../../store/ChatSettingsStore/ChatSettingsStore";
// import { mapState } from "vuex";
// import store from "../../../../../store/store";
// import { SET_CHAT_ACTIONS } from "../../../../../store/StoreConstants/CommonSettingsConstants";
export default {
  components: {
    TvmFlowEndLink,
    TvmFlowEndGeneralComponents,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ["tilesData", "isWaitingForResponse", "isLastIndex", "chatThemeSettings"],
  data() {
    return {
      serviceButtons: [],
      evenOrOddClass: null,
    };
  },
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state.commonSettings.chatThemeSettings;
  //     },
  //   }),
  // },
  mounted() {
    if (this.tilesData?.components?.length) {
      this.serviceButtons = this.tilesData?.components;
      this.evenOrOddClass = "chat__list";
      try {
        let gridChecker = this.tilesData?.components.filter(
          (item) => item.componentName === "tvm-flow-end-tile"
        );
        this.evenOrOddClass =
          gridChecker.length % 2 === 0 ? "chat__grid" : "chat__grid last_full_width";
      } catch (e) {
        console.log("Error on evaluating class");
      }
    }
  },
  beforeDestroy() {
    this.$root.$off("isWaitingForResponseData");
  },
  methods: {
    onPayloadButtonClick(event, data) {
      if (document.getElementById("MessageBox")) {
        document.getElementById("MessageBox").focus();
      }
      // store.commit(SET_CHAT_ACTIONS, { ModeOfAction: "tvm-assistant-flow-end-tiles" });
      const useChatStore = useChatSettingsStore();
      useChatStore.tvmChatActions = { ModeOfAction: "tvm-assistant-flow-end-tiles" };
      // this.$root.$emit("isWaitingForResponseData", true);
      ChatWidgetService.setFlowIndicator(data.text);
      let specialObject = { TvmComponentRequest: data };
      ChatWidgetService.postTvmMessage(
        data.text,
        null,
        data.submitFlow,
        data.submitAction,
        specialObject
      );
    },
  },
};
</script>

<style scoped>
.tvm_flow_end_links {
  margin-top: 10px;
}

.chat__list {
  width: 100%;
  list-style: none;
  padding: 0 30px;
  margin: 0 0 10px 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.chat__grid .chat__inneritems,
.chat__list .chat__inneritems {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fullfill__columns {
  display: inline-block;
  margin: 0;
  width: 100%;
}

.fullfill__columns .chat__grid {
  width: 100%;
  list-style: none;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 13px;
}

.chat__grid.last_full_width .chat__inneritems:last-of-type {
  grid-column: 1 / -1;
}

.img-max-height {
  margin-bottom: 4px;
  height: 28px;
}

.chatbot-response .text-white {
  color: #fff !important;
}

.chat__body__btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  border: 0 !important;
  background: none !important;
  outline: 0 !important;
  cursor: pointer;
}

.icon__cont__box {
  margin-bottom: 5px;
  margin-right: 0;
  border: 0;
  padding: 2px;
  display: flex;
}

.chat__list .chat__inneritems .chat__body__btn {
  flex-direction: row;
  justify-content: flex-start;
}

.chat__list .chat__inneritems .icon__cont__box {
  margin: 0 10px 0 0;
  display: flex;
}

.chat__list .chat__inneritems .icon__cont__box .icon__cont {
  font-size: 18px;
}

.chat__grid .chat__inneritems .icon__cont__box .icon__cont {
  font-size: 18px;
}

.chat__list .chat__inneritems .icon__cont__box .end__chatIcon {
  width: 20px;
  height: auto;
}

.chat__grid .chat__inneritems .icon__cont__box .end__chatIcon {
  width: 20px;
  height: auto;
}

.chat__list .chat__inneritems .icon__cont__box .end__chatIcon path,
.chat__grid .chat__inneritems .icon__cont__box .end__chatIcon path {
  color: var(--color-primary);
  fill: currentColor;
}

.chat__body__btn__title {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  color: #000;
}

.custom-payload-con {
  width: 100%;
  /* border: 1px solid #eee; */
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.09);
}

.chat__list .chat__inneritems .custom-payload-con {
  padding: 10px 15px;
  border-radius: 5px;
}

.custom-payload-con em {
  font-size: 27px;
  margin-bottom: 3px;
}

@media all and (min-width: 1900px) {
  .chat__list .chat__inneritems .custom-payload-con {
    padding: 15px 30px;
    border-radius: 10px;
  }

  .custom-payload-con em {
    font-size: 27px;
    margin-bottom: 3px;
  }
}

@media all and (min-width: 2500px) {
  .chat__list .chat__inneritems .custom-payload-con {
    padding: 20px 30px;
    border-radius: 12px;
  }

  .custom-payload-con em {
    font-size: 35px;
  }
}

@media all and (min-width: 3800px) {
  .chat__list .chat__inneritems .custom-payload-con {
    padding: 40px 50px;
    border-radius: 15px;
  }

  .custom-payload-con em {
    font-size: 35px;
  }
}

@media all and (min-width: 1900px) {
  #chatBodySec2 .chat__body__btn__title {
    font-size: 14px;
  }

  #chatBodySec2 .chat__grid .chat__inneritems .chat__body__btn__title {
    font-size: 14px;
  }

  .chat__grid .chat__inneritems .chat__body__btn {
    min-height: 72px;
  }

  .chat__list .chat__inneritems .chat__body__btn {
    min-height: 40px;
  }

  .chat__list .chat__inneritems .icon__cont__box {
    margin: 0 20px 0 0;
  }

  .chat__list .chat__inneritems .icon__cont__box .icon__cont {
    font-size: 24px;
  }

  .chat__list .chat__inneritems .icon__cont__box .end__chatIcon {
    width: 24px;
  }

  #chatBodySec2 .chat__list .chat__inneritems .chat__body__btn__title {
    font-size: 16px;
  }

  .chat__grid .chat__inneritems .icon__cont__box .icon__cont {
    font-size: 22px;
  }

  .chat__grid .chat__inneritems .icon__cont__box .end__chatIcon {
    width: 22px;
  }

  .img-max-height {
    height: 35px;
  }
}

@media all and (min-width: 2500px) {
  #chatBodySec2 .chat__body__btn__title {
    font-size: 18px;
  }

  #chatBodySec2 .chat__grid .chat__inneritems .chat__body__btn__title {
    font-size: 20px;
  }

  .chat__grid .chat__inneritems .chat__body__btn {
    min-height: 100px;
  }

  .chat__list .chat__inneritems .chat__body__btn {
    min-height: 50px;
  }

  .chat__list .chat__inneritems .icon__cont__box {
    margin: 0 20px 0 0;
  }

  .chat__list .chat__inneritems .icon__cont__box .icon__cont {
    font-size: 36px;
  }

  .chat__list .chat__inneritems .icon__cont__box .end__chatIcon {
    width: 36px;
  }

  #chatBodySec2 .chat__list .chat__inneritems .chat__body__btn__title {
    font-size: 20px;
  }

  .chat__grid .chat__inneritems .icon__cont__box .icon__cont {
    font-size: 28px;
  }

  .chat__grid .chat__inneritems .icon__cont__box .end__chatIcon {
    width: 28px;
  }
}

@media all and (min-width: 3800px) {
  #chatBodySec2 .chat__body__btn__title {
    font-size: 24px;
  }

  #chatBodySec2 .chat__grid .chat__inneritems .chat__body__btn__title {
    font-size: 30px;
  }

  .chat__grid .chat__inneritems .chat__body__btn {
    min-height: 160px;
  }

  .chat__list .chat__inneritems .chat__body__btn {
    min-height: 60px;
  }

  .chat__list .chat__inneritems .icon__cont__box {
    margin: 0 30px 0 0;
  }

  .chat__list .chat__inneritems .icon__cont__box .icon__cont {
    font-size: 42px;
  }

  .chat__list .chat__inneritems .icon__cont__box .end__chatIcon {
    width: 42px;
  }

  #chatBodySec2 .chat__list .chat__inneritems .chat__body__btn__title {
    font-size: 30px;
  }

  .chat__grid .chat__inneritems .icon__cont__box {
    margin-bottom: 10px;
  }

  .chat__grid .chat__inneritems .icon__cont__box .icon__cont {
    font-size: 42px;
  }

  .chat__grid .chat__inneritems .icon__cont__box .end__chatIcon {
    width: 42px;
  }
}

div[disabled=true] {
  opacity: 0.6;
  pointer-events: none;
}

.disabledContent {
  pointer-events: none !important;
  opacity: 0.6 !important;
}
</style>
