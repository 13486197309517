 import { Validations } from '../../../Services/Validations/Validations';
import ShowDisclaimer from '../../../../../js/components/leadforms/ShowDisclaimer.vue';
import FormSubmitMessage from '../../Shared/FormSubmitMessage/FormSubmitMessage.vue';
import { frameFaceBookUserDetailsWithEventModel } from '../../../../../js/services/FaceBookConversionApiService';
import { LogLeadApi } from '../../../Services/LogLeadApiService/LogLeadApiService';
import LeadFormSpamCheck from '../../../../../js/services/LeadFormSpamCheck';
import ShiftDigitalService from '../../../Events/ShiftDigital/ShiftDigitalService';
import { SHIFT_DIGITAL_GENERAL_CONTACT } from '../../../../../js/services/ShiftDigital/ShiftDigitalConstants';
import ShiftDigitalContainer from '../../../Events/ShiftDigital/ShiftDigitalContainer';
import DealerGroupService from '../../../../../js/services/DealerGroupService';
import UtmUrlService from '../../../../../js/services/UtmUrlService';
import { TagManagerContainer } from '../../../Events/GoogleTagManager/TagManagerContainer';
import { DWP_Sales_Contact_90507 } from '../../../../../js/services/GoogleTagManager/GoogleTagConstants';
import { Events } from '../../../Events/ConsumerEvents/Events';

export default {
  data() {
    return {
      shiftDigital: '',
      modalShow: false,
      make: window.make,
      name: window.clientName,
      isCommentsMandatory: window.isCommentsMandatory,
      success: false,
      contactTitle: '',
      initialDisplay: true,
      contactdetails: {
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        PhoneNumber: '',
        Message: '',
        Zip: '',
      },
      errordetails: {
        firstnameerror: false,
        lastnameerror: false,
        emailerror: false,
        phoneerror: false,
        commentserror: false,
      },
      disableSubmit: false,
    };
  },
  props: {
    offerid: {
      required: false,
    },
    styleid: {
      required: false,
      default: '',
    },
    vin: {
      required: false,
      default: '',
    },
    details: {
      default: '',
      required: false,
    },
    ignoretagging: {
      default: false,
      require: false,
    },
  },
  mounted() {
    if (this.details !== '') {
      this.contactdetails = JSON.parse(this.details);
    }

    this.shiftDigital = ShiftDigitalContainer.getShiftDigitalObject();
    if (this.shiftDigital && !this.ignoretagging) {
      this.shiftDigital.setVin(this.vin);
      this.shiftDigital.initiateForm(SHIFT_DIGITAL_GENERAL_CONTACT);
      ShiftDigitalService.initiateFormEvents(this.shiftDigital, SHIFT_DIGITAL_GENERAL_CONTACT);
    }

    this.contactTitle = 'Contact ' + this.name;
    if (window.userDetails) {
      this.contactdetails.FirstName = window.userDetails.firstname;
      this.contactdetails.LastName = window.userDetails.lastname;
      this.contactdetails.EmailAddress = window.userDetails.email;
      this.contactdetails.PhoneNumber = window.userDetails.phonenumber;
      this.contactdetails.Zip = window.userDetails.zip;
    }
  },
  components: {
    ShowDisclaimer,
    'form-submit-message': FormSubmitMessage,
  },
  methods: {
    getLeadTypeId() {
      return 2;
    },
    async submitContact() {
      let validations = new Validations();

      this.errordetails.firstnameerror = validations.checkNameHasSpecialCharacters(
        this.contactdetails.FirstName
      );
      this.errordetails.lastnameerror = validations.checkNameHasSpecialCharacters(
        this.contactdetails.LastName
      );

      if (!this.errordetails.firstnameerror) {
        this.errordetails.firstnameerror = validations.checkNameLimit(
          this.contactdetails.FirstName,
          2,
          'First Name'
        );
      }

      if (!this.errordetails.lastnameerror) {
        this.errordetails.lastnameerror = validations.checkNameLimit(
          this.contactdetails.LastName,
          2,
          'Last Name'
        );
      }

      this.errordetails.emailerror = validations.checkEmail(this.contactdetails.EmailAddress);
      this.errordetails.phoneerror = validations.checkPhone(this.contactdetails.PhoneNumber);
      this.errordetails.commentserror = false;
      this.errordetails.commentserror = validations.checkCommentsMandatory(
        this.contactdetails.Message
      );
      if (validations.checkError(this.errordetails)) {
        this.disableSubmit = true;

        this.contactdetails['websiteUrlLeadSubmitted'] = window.location.href;
        this.contactdetails['OfferID'] = this.offerid;
        this.contactdetails['LeadTypeID'] = this.getLeadTypeId();
        this.contactdetails['LeadTypeName'] = 'contact-dealer';
        this.contactdetails['StyleID'] = this.styleid;
        this.contactdetails['Vin'] = this.vin;
        this.contactdetails['DealerID'] = window.accountId ? window.accountId : '';
        this.contactdetails['campaignID'] = window.campaignId ? window.campaignId : '';

        let tagManager = TagManagerContainer.getDealerObject();
        if (tagManager && typeof tagManager.getFormSubmit === 'function') {
          tagManager.getFormSubmit(DWP_Sales_Contact_90507[0], DWP_Sales_Contact_90507[1]);
        }

        this.contactdetails = {
          ...this.contactdetails,
          ...UtmUrlService.getUrl(),
          ...{ sessionId: window.tid },
        };
        this.contactdetails['dealergroupid'] = DealerGroupService.getDgId();

        this.contactdetails['VDPAccountId'] = window.vdpAccountId ? +window.vdpAccountId : 0;

        if (
          !LeadFormSpamCheck.checkLeadIsValid(
            this.contactdetails.EmailAddress,
            2,
            this.contactdetails
          )
        ) {
          this.success = true;
          this.initialDisplay = false;
          return false;
        }
        try {
          let response = await LogLeadApi(this.contactdetails);
          this.disableSubmit = false;
          this.success = true;
          this.initialDisplay = false;

          if (this.shiftDigital) {
            this.shiftDigital.submitFormEvent(response.data, SHIFT_DIGITAL_GENERAL_CONTACT);
          }

          //log consumer event
          let eventmodel = {
            ...Events.consumerEventModel,
            consumerEventId: Events.eventsConstants.submitaSalesLead,
            logLeadItemId: response.data,
            offerId: this.offerid,
            styleId: this.styleid,
            vin: this.vin,
            emailAddress: this.contactdetails.EmailAddress,
            firstName: this.contactdetails.FirstName,
            lastName: this.contactdetails.LastName,
            phoneNumber: this.contactdetails.PhoneNumber,
          };
          Events.logConsumerEvent(eventmodel);

          logGoogleBingEvent(
            'form',
            'Submit',
            'leadform_sales',
            1,
            'Lead form sales submitted for contact us',
            frameFaceBookUserDetailsWithEventModel(eventmodel)
          );
        } catch (e) {
          console.log(e);
          this.disableSubmit = false;
        }
      }
    },
    isNumberKey(evt) {
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    removeSpecialCharacter(event) {
      let validations = new Validations();
      return validations.isSpecialCharacter(event);
    },
  },
};
