<!--
IMPORTANT:
This file is used both in vue2 and vue3 application
Dont use vue syntax and store concept in this file
FOr clarifications contact Leela
-->
<template>
  <div>
    <div class="img-gallery" style="background-color: #fff">
      <div v-for="vehicle in vehicleList">
        <div class="vehicle-image">
          <div class="text-center">
            <strong class="text-center d-block" style="color: rgb(0, 0, 0)"
              >{{ vehicle.model_Year }} {{ vehicle.cfModelName }}</strong
            >
          </div>
          <div class="text-center">
            <a :href="`${baseUrl}/compare/${vehicle.model_Group_Name||vehicle.navigation_Model_Name||vehicle.model_Name}`">
              <div class="text-center img-width">
                <img :src="vehicle.image_URL" alt="Compare menu" class="img-fluid" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <loading-bar v-if="showLoading"></loading-bar>
  </div>
</template>
<script>
import axios from "axios";
import LoadingBar from "../shared/LoadingBar.vue";
export default {
  data() {
    return {
      vehicleList: [],
      showLoading: false,
      baseUrl: window.baseUrl,
    };
  },
  methods: {
    getVehicles() {
      if (this.vehicleList.length === 0) {
        this.showLoading = true;
        axios.get(`${window.ApiBaseUrl}/Compare/${window.campaignId}`).then((response) => {
          this.vehicleList = response.data;
          this.showLoading = false;
        });
      }
    },
  },
  components: {
    LoadingBar,
  },
  mounted() {
    this.getVehicles();
  },
};
</script>
<style @scooed>
.vehicle-image {
  padding: 10px 0px;
  border-radius: 5px;
  margin: 10px;
}
</style>
