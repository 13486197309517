// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tvm_color_swatches .color_selector[data-v-0b6ef691] {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  margin: 5px;
  border: 4px double #ddd;
}
.tvm_color_swatches .color_selector.selected_color[data-v-0b6ef691] {
  border: 6px double #ddd;
  transition: border 0.5s;
}
@keyframes animated_bottom-0b6ef691 {
0% {
    transform: translateY(30px);
}
100% {
    transform: translateY(0px);
}
}
.slide-up-enter-active[data-v-0b6ef691] {
  animation: animated_bottom-0b6ef691 1s;
  transition: 0.5s;
}
#chat-widget .rounded-buttons.chips__btn[data-v-0b6ef691] {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 6px 12px !important;
  min-width: 56px !important;
  height: 32px !important;
  text-transform: unset !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChatWidget/TvmColorSwatches/TvmColorSwatches.vue","webpack://./TvmColorSwatches.vue"],"names":[],"mappings":"AACA;EAEI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,uBAAA;ACDJ;ADGI;EACE,uBAAA;EACA,uBAAA;ACDN;ADMA;AACE;IACE,2BAAA;ACJF;ADOA;IACE,0BAAA;ACLF;AACF;ADQA;EACE,sCAAA;EACA,gBAAA;ACNF;ADSA;EACE,0BAAA;EACA,2BAAA;EACA,4BAAA;EACA,0BAAA;EACA,uBAAA;EACA,gCAAA;ACPF","sourcesContent":["\n.tvm_color_swatches {\n  .color_selector {\n    width: 30px;\n    height: 30px;\n    border-radius: 50%;\n    cursor: pointer;\n    margin: 5px;\n    border: 4px double #ddd;\n\n    &.selected_color {\n      border: 6px double #ddd;\n      transition: border 0.5s;\n    }\n  }\n}\n\n@keyframes animated_bottom {\n  0% {\n    transform: translateY(30px);\n  }\n\n  100% {\n    transform: translateY(0px);\n  }\n}\n\n.slide-up-enter-active {\n  animation: animated_bottom 1s;\n  transition: 0.5s;\n}\n\n#chat-widget .rounded-buttons.chips__btn {\n  font-size: 12px !important;\n  font-weight: 400 !important;\n  padding: 6px 12px !important;\n  min-width: 56px !important;\n  height: 32px !important;\n  text-transform: unset !important;\n}\n",".tvm_color_swatches .color_selector {\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  cursor: pointer;\n  margin: 5px;\n  border: 4px double #ddd;\n}\n.tvm_color_swatches .color_selector.selected_color {\n  border: 6px double #ddd;\n  transition: border 0.5s;\n}\n@keyframes animated_bottom {\n  0% {\n    transform: translateY(30px);\n  }\n  100% {\n    transform: translateY(0px);\n  }\n}\n.slide-up-enter-active {\n  animation: animated_bottom 1s;\n  transition: 0.5s;\n}\n#chat-widget .rounded-buttons.chips__btn {\n  font-size: 12px !important;\n  font-weight: 400 !important;\n  padding: 6px 12px !important;\n  min-width: 56px !important;\n  height: 32px !important;\n  text-transform: unset !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
