import PrimaryResearchModels from '../PrimaryResearchModels/PrimaryResearchModels.vue';
import SimplifyResearchModels from '../SimplifyResearchModels/SimplifyResearchModels.vue';
import BmwResearchModels from '../BmwResearchModels/BmwResearchModels.vue';

import { LayoutConstants } from '../../../../../js/constants/LayoutConstants';

export default {
  data() {
    return {
      componentName: '',
    };
  },
  props: {
    desktopsubmenu: {
      default: false,
    },
    sidenavsubmenu: {
      default: false,
      required: false,
    },
  },
  mounted() {
    if (window.masterLayoutName.toLowerCase() === LayoutConstants.WEBSITE_BMW) {
      this.componentName = 'BmwResearchModels';
    } else if (window.masterLayoutName.toLowerCase() === LayoutConstants.WEBSITE_SIMPLIFY) {
      this.componentName = 'SimplifyResearchModels';
    } else {
      this.componentName = 'PrimaryResearchModels';
    }
  },
  components: {
    PrimaryResearchModels,
    SimplifyResearchModels,
    BmwResearchModels,
  },
  methods: {
    closeprimaryResearchModels() {
      this.$emit('primaryresearchmodels');
    },
  },
};
