// import Slick from 'vue-slick';
import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService.js';
import bPopover from 'bootstrap-vue/es/components/popover/popover';
import 'v-slick-carousel/style.css';
import { VSlickCarousel } from 'v-slick-carousel';
export default {
  components: {
    // Slick,
    bPopover,
    VSlickCarousel,
  },
  props: ['botResponse', 'isWaitingForResponse'],
  data() {
    return {
      buttons: [],
      isDisableChat: false,
      slickOptions: {
        infinite: false,
        slidesPerGroup: 3,
        groupsToShow: 4,
        dots: false,
        // adaptiveHeight: false,
        // nextArrow: `<em class="primaryicon-right-arrow bgcolor-primary" style="cursor:pointer;right:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;"></em>`,
        // prevArrow: `<em class="primaryicon-left-arrow bgcolor-primary" style="cursor:pointer;left:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;"></em>`,
      },
      isMobile: window.ismobile,
      primaryColor: window.primaryColor,
    };
  },
  mounted() {
    this.buttons = this.botResponse?.component?.values;
  },
  beforeDestroy() {
    this.$root.$off('isWaitingForResponseData');
  },
  methods: {
    optionclick(button) {
      this.$root.$emit('isWaitingForResponseData', true);
      let specialObject = { TvmComponentRequest: button };
      ChatWidgetService.postTvmMessage(
        button.colorDescription,
        null,
        this.botResponse.component.submitFlow,
        this.botResponse.component.submitAction,
        specialObject
      );
    },
    reInitSlick() {
      if (this.$refs.slick) {
        this.$refs.slick.destroy();
        this.$nextTick(() => {
          this.$refs.slick.create();
        });
      }
    },
    getArrowStyles(direction) {
      const commonStyles = {
        cursor: 'pointer',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: this.primaryColor,
        color: '#fff',
        fontSize: '10px',
        padding: '5px',
        zIndex: 10,
      };
      if (direction === 'left') {
        return {
          ...commonStyles,
          left: '0',
          borderRadius: '0px 3px 3px 0px',
        };
      } else if (direction === 'right') {
        return {
          ...commonStyles,
          right: '0',
          borderRadius: '3px 0px 0px 3px',
        };
      }
      return commonStyles;
    },
  },
};
