<!--
IMPORTANT:
FIle used only in the vue3 application
changes made here should be made in vue2 also
file location: wwwroot\js\components\shared\LeadFormComponents\ShiftTcpaDisclaimerCheckbox.vue
for clarifications contact Leela
-->
<template>
  <div class="my-2">
    <div class="bgcolor-primary-custom-checkbox mt-3">
      <div class="d-flex p-1 position-relative" :class="[{ error: errorobj.tcpaConsentError }]">
        <input :id="dynamicElement + 'tcpa-disclaimer'" type="checkbox" class="primarybg-input-hide"
          name="tcpa disclaimer" :checked="tcpaConsentValue" @change="updateCheckBox($event)" />
        <span class="mr-2 d-flex">
          <em class="primarybg-checked bgcolor-primary" />
          <em class="primarybg-unchecked" />
        </span>
        <label :for="dynamicElement + 'tcpa-disclaimer'" class="mb-0 ft-12 grey-text">
          {{ tcpaDisclaimerText }}</label>
      </div>
    </div>
  </div>
</template>

<script setup>
import { isGenesisJDPA } from "@/js/services/GoogleTagManager/TagManagerConstants";
import { SHIFT_SPECIAL_DISCLAIMERS } from "@/js/constants/constants";
import { onMounted, ref } from "vue";

const formData = defineModel("formdata");
const props = defineProps({
  errorobj: {
    type: Object,
    required: false,
    default: () => ({}),
  },
});

const tcpaConsentValue = ref(false);
const tcpaDisclaimerText =
  ref(`By checking here, I direct ${window.clientName} to send me text messages to market or advertise
       products, goods, or services. These text messages may be transmitted through autodialed calls or robotext.
        By checking the checkbox and clicking submit, I confirm that I am the current owner/subscriber of the mobile number
         provided or that the current owner/subscribed of this mobile phone number authorized me to provide this number to the dealer.
          I understand that my consent is not required as a condition of purchase and that I can revoke my consent at any time.
           My carrier wireless and text message fees may apply. I will contact the dealer directly to provide reasonable notice
            if I no longer wish to receive automated calls or texts.*`);

onMounted(() => {
  setTimeout(() => {
    if (isGenesisJDPA(true)) {
      this.tcpaDisclaimerText = SHIFT_SPECIAL_DISCLAIMERS.GENESIS_JDPA_DISCLAIMER;
    }

    formData.value.ShiftTCPAConsent = false;
    formData.value.ShiftTCPADisclaimer = tcpaDisclaimerText.value;
  }, 200);
});

function updateCheckBox(event) {
  if (event.target.checked) {
    tcpaConsentValue.value = true;
    formData.value.ShiftTCPAConsent = true;
  } else {
    tcpaConsentValue.value = false;
    formData.value.ShiftTCPAConsent = false;
  }
}
</script>

<style scoped>
.error {
  border: 1px solid red;
  background-color: #fcefef;
}
</style>
