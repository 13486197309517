<template>
  <!-- my upgrade vehicle -->
  <div class="my_current_vehicle mb-3 my_upgrade_vehicle p-0 animate_bottom">
    <div class="car_info position-relative">
      <div class="custom_legend">
        <span>My Upgrade Vehicle</span>
      </div>
      <div class="row no-gutters align-items-center min-120">
        <div class="col-lg-5">
          <div v-if="vehicleDetails.isImageAvailable">
            <img class="img-fluid upgrade_vehicle_img" :src="vehicleDetails.imageUri" alt="" />
          </div>
          <div v-else class="text-center car_icon">
            <i class="primaryicon-full-car" />
          </div>
        </div>
        <div class="col-lg-7">
          <div v-if="!vehicleDetails.vdvUrl">
            <h5 class="font-weight-bold brake_word">
              <span>{{ vehicleDetails.year }}</span> <span>{{ vehicleDetails.make }}</span>
              <span>{{ vehicleDetails.model }}</span> <span>{{ vehicleDetails.trim }}</span>
              <span>{{ vehicleDetails.style }}</span>
            </h5>
          </div>
          <div v-if="vehicleDetails.vdvUrl">
            <a :href="vehicleDetails.vdvUrl" class="text-decoration-none color-black">
              <h5 class="font-weight-bold brake_word">
                <span>{{ vehicleDetails.year }}</span> <span>{{ vehicleDetails.make }}</span>
                <span>{{ vehicleDetails.model }}</span> <span>{{ vehicleDetails.trim }}</span>
                <span>{{ vehicleDetails.style }}</span>
              </h5>
            </a>
            <vdv-button :vdv-url="vehicleDetails.vdvUrl" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end my upgrade vehicle -->
</template>
<script>
import TvmVDVIcon from '../../TvmVDVIcon/TvmVDVIcon.vue';

export default {
  components: {
    "vdv-button": TvmVDVIcon
  },
  props: {
    responseData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    vehicleDetails: function () {
      return this.responseData?.vehicleDetails;
    },
  },
};
</script>
<style lang="less" scoped>
// reuseable css
.value_your_trade_card {
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 5px;
}

.custom_legend {
  position: absolute;
  display: inline-block;
  background: #fff;
  z-index: 9;
  font-size: 12px;
  top: -12px;
  left: 30px;
  padding: 0 6px;
  color: #000;
  opacity: 0.8;
}

.card_font_sizes {
  h5 {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    white-space: initial;
    margin: 0;
  }

  p {
    margin-bottom: 3px;
    font-size: 11px;
    white-space: initial;
  }
}

.min-120 {
  min-height: 80px;
}

.brake_word span {
  word-break: break-word;
  display: inline-block;
}

// end reuseable css
.my_current_vehicle {
  .car_info {
    .value_your_trade_card();

    img {
      height: 60px;
      margin: auto;
      display: block;
      object-fit: contain;
    }

    .card_font_sizes();
  }
}

.car_icon i {
  font-size: 30px;
  opacity: 0.6;
}

.animate_bottom {
  animation: animateBottom 2s;
  transition: 0.5s;
}

@keyframes animateBottom {
  0% {
    transform: translateY(50px);
  }

  100% {
    transform: translateY(0);
  }
}

@media (min-width: 1900px) {
  .my_current_vehicle .car_info {
    h5 {
      font-size: 13px;
    }

    p {
      font-size: 12px;
    }
  }

  .custom_legend {
    font-size: 13px;
  }
}

@media (min-width: 2500px) {
  .my_current_vehicle .car_info {
    h5 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }

  .custom_legend {
    font-size: 16px;
  }
}
</style>
