<!--
IMPORTANT:
This file is used only in the vue3 application
Changes made here must be made in vue2 also
file locations: wwwroot\js\components\shared\ChatWidget\DealerWorkingHours\DealerWorkingHours.vue
for clarifications contact Leela

-->

<template>
  <div class="dealer-hours w-100 px-4">
    <div v-if="isWaitingForResponse" class="waiting-message">
      <div class="chat_loader">
        <div class="loader-inner ball-pulse-sync">
          <div class="bgcolor-primary theme-bg-color" />
          <div class="bgcolor-primary theme-bg-color" />
          <div class="bgcolor-primary theme-bg-color" />
          <div class="bgcolor-primary theme-bg-color" />
          <div class="bgcolor-primary theme-bg-color" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12" v-if="!isCollisionPage">
        <div v-if="salesdata && !isServiceOnly">
          <hr />
        </div>
        <div v-for="(salesdata, index) of businessdata" :key="index">
          <div class="details-block">
            <div class="text-center">
              <h5 v-if="salesdata.department.toLowerCase() == 'quick lane'" class="ft-14 _theme_font_bold color-black">
                {{ salesdata.department }} Hours
              </h5>
              <h5 v-else class="ft-14 _theme_font_bold color-black">
                {{ salesdata.department.replace("Department", "Hours") }}
              </h5>
            </div>
            <div>
              <div class="d-flex justify-content-between"
                :class="currentDayIs === 'sunday' ? 'font-weight-bold ft-16' : ''">
                <div>Sunday</div>
                <div v-if="salesdata.sundayLabel !== null && salesdata.sundayLabel !== ''">
                  {{ salesdata.sundayLabel }}
                </div>
                <div v-else>
                  {{ salesdata.sunday }}
                </div>
              </div>
              <div class="d-flex justify-content-between"
                :class="currentDayIs === 'monday' ? 'font-weight-bold ft-16' : ''">
                <div>Monday</div>
                <div v-if="salesdata.mondayLabel !== null && salesdata.mondayLabel !== ''">
                  {{ salesdata.mondayLabel }}
                </div>
                <div v-else>
                  {{ salesdata.monday }}
                </div>
              </div>
              <div class="d-flex justify-content-between"
                :class="currentDayIs === 'tuesday' ? 'font-weight-bold ft-16' : ''">
                <div>Tuesday</div>
                <div v-if="salesdata.tuesdayLabel !== null && salesdata.tuesdayLabel !== ''">
                  {{ salesdata.tuesdayLabel }}
                </div>
                <div v-else>
                  {{ salesdata.tuesday }}
                </div>
              </div>
              <div class="d-flex justify-content-between"
                :class="currentDayIs === 'wednesday' ? 'font-weight-bold ft-16' : ''">
                <div>Wednesday</div>
                <div v-if="salesdata.wednesdayLabel !== null && salesdata.wednesdayLabel !== ''">
                  {{ salesdata.wednesdayLabel }}
                </div>
                <div v-else>
                  {{ salesdata.wednesday }}
                </div>
              </div>
              <div class="d-flex justify-content-between"
                :class="currentDayIs === 'thursday' ? 'font-weight-bold ft-16' : ''">
                <div>Thursday</div>
                <div v-if="salesdata.thursdayLabel !== null && salesdata.thursdayLabel !== ''">
                  {{ salesdata.thursdayLabel }}
                </div>
                <div v-else>
                  {{ salesdata.thursday }}
                </div>
              </div>
              <div class="d-flex justify-content-between"
                :class="currentDayIs === 'friday' ? 'font-weight-bold ft-16' : ''">
                <div>Friday</div>
                <div v-if="salesdata.fridayLabel !== null && salesdata.fridayLabel !== ''">
                  {{ salesdata.fridayLabel }}
                </div>
                <div v-else>
                  {{ salesdata.friday }}
                </div>
              </div>
              <div class="d-flex justify-content-between"
                :class="currentDayIs === 'saturday' ? 'font-weight-bold ft-16' : ''">
                <div>Saturday</div>
                <div v-if="salesdata.saturdayLabel !== null && salesdata.saturdayLabel !== ''">
                  {{ salesdata.saturdayLabel }}
                </div>
                <div v-else>
                  {{ salesdata.saturday }}
                </div>
              </div>
            </div>
            <p class="ft-12 mt-3" v-if="salesdata.description">
              {{ salesdata.description }}
            </p>
          </div>
          <div if="index < businessdata.length -2">
            <hr />
          </div>
        </div>

        <div v-if="holidayHours && holidayHours.length" class="details-block">
          <div class="text-center">
            <h5 class="ft-14 _theme_font_bold color-black">Holiday Hours</h5>
          </div>
          <div v-for="(holiday, index) of holidayHours" :key="index">
            <div class="d-flex justify-content-between align-items-center">
              <div>{{ holiday.holidayName }}</div>

              <div v-if="holiday.isFullDay" class="text-capitalize">
                {{ holiday.holidayMonth }}
                {{ holiday.holidayDay }}{{ getDaySufixLogic(holiday.holidayDay) }}
              </div>

              <div v-else class="text-capitalize text-right">
                {{ holiday.holidayMonth }}
                {{ holiday.holidayDay }}{{ getDaySufixLogic(holiday.holidayDay) }} <br />
                {{ holiday.fromTime }} - {{ holiday.toTime }}
              </div>
            </div>
            <template v-if="holiday.closureDepartments">
              <div class="d-flex justify-content-end">
                <div class="text-muted">
                  {{ getclosureDepartments(holiday.closureDepartments) }}: Closed
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-else class="col-md-12">
        <div v-for="(salesdata, index) of businessdata" :key="index">
          <div class="details-block" style="margin: 0 0 24px 0; padding: 20px; border: 1px solid #ccc">
            <div class="text-center dealer-icon">
              <h5 v-if="salesdata.department.toLowerCase() == 'quick lane'" class="ft-14 _theme_font_bold color-black"
                style="color: #000">
                {{ salesdata.department }} Hours
              </h5>
              <h5 v-else class="ft-14 _theme_font_bold color-black" style="color: #000">
                {{ salesdata.department.replace("Department", "Hours") }}
              </h5>
            </div>
            <div>
              <div class="d-flex justify-content-between"
                :class="currentDayIs === 'sunday' ? 'font-weight-bold ft-16' : ''">
                <div>Sunday:</div>
                <div v-if="salesdata.sundayLabel !== null && salesdata.sundayLabel !== ''">
                  {{ salesdata.sundayLabel }}
                </div>
                <div v-else>
                  {{ salesdata.sunday }}
                </div>
              </div>
              <div class="d-flex justify-content-between"
                :class="currentDayIs === 'monday' ? 'font-weight-bold ft-16' : ''">
                <div>Monday:</div>
                <div v-if="salesdata.mondayLabel !== null && salesdata.mondayLabel !== ''">
                  {{ salesdata.mondayLabel }}
                </div>
                <div v-else>
                  {{ salesdata.monday }}
                </div>
              </div>
              <div class="d-flex justify-content-between"
                :class="currentDayIs === 'tuesday' ? 'font-weight-bold ft-16' : ''">
                <div>Tuesday:</div>
                <div v-if="salesdata.tuesdayLabel !== null && salesdata.tuesdayLabel !== ''">
                  {{ salesdata.tuesdayLabel }}
                </div>
                <div v-else>
                  {{ salesdata.tuesday }}
                </div>
              </div>
              <div class="d-flex justify-content-between"
                :class="currentDayIs === 'wednesday' ? 'font-weight-bold ft-16' : ''">
                <div>Wednesday:</div>
                <div v-if="salesdata.wednesdayLabel !== null && salesdata.wednesdayLabel !== ''">
                  {{ salesdata.wednesdayLabel }}
                </div>
                <div v-else>
                  {{ salesdata.wednesday }}
                </div>
              </div>
              <div class="d-flex justify-content-between"
                :class="currentDayIs === 'thursday' ? 'font-weight-bold ft-16' : ''">
                <div>Thursday:</div>
                <div v-if="salesdata.thursdayLabel !== null && salesdata.thursdayLabel !== ''">
                  {{ salesdata.thursdayLabel }}
                </div>
                <div v-else>
                  {{ salesdata.thursday }}
                </div>
              </div>
              <div class="d-flex justify-content-between"
                :class="currentDayIs === 'friday' ? 'font-weight-bold ft-16' : ''">
                <div>Friday:</div>
                <div v-if="salesdata.fridayLabel !== null && salesdata.fridayLabel !== ''">
                  {{ salesdata.fridayLabel }}
                </div>
                <div v-else>
                  {{ salesdata.friday }}
                </div>
              </div>
              <div class="d-flex justify-content-between"
                :class="currentDayIs === 'saturday' ? 'font-weight-bold ft-16' : ''">
                <div>Saturday:</div>
                <div v-if="salesdata.saturdayLabel !== null && salesdata.saturdayLabel !== ''">
                  {{ salesdata.saturdayLabel }}
                </div>
                <div v-else>
                  {{ salesdata.saturday }}
                </div>
              </div>
            </div>
            <p class="ft-12 mt-3" v-if="salesdata.description">
              {{ salesdata.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DealerHours from '../../DealerHours/DealerHours/DealerHours.js';
export default {
  mixins: [DealerHours],
};
</script>

<style>
.dealer-hours {
  font-size: 13px;
  color: #212529;
}

.chat_loader {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 24px;
  align-items: center;
  justify-content: center;
  animation: none;
  border: none;
  margin: 0;
  padding: 0;
}

@-webkit-keyframes ball-pulse-sync {
  20% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  40% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  60% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  80% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes ball-pulse-sync {
  20% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  40% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  60% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  80% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.ball-pulse-sync>div:nth-child(1) {
  -webkit-animation: ball-pulse-sync 1s -0.36s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.36s infinite ease-in-out;
}

.ball-pulse-sync>div:nth-child(2) {
  -webkit-animation: ball-pulse-sync 1s -0.27s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.27s infinite ease-in-out;
}

.ball-pulse-sync>div:nth-child(3) {
  -webkit-animation: ball-pulse-sync 1s -0.18s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.18s infinite ease-in-out;
}

.ball-pulse-sync>div:nth-child(4) {
  -webkit-animation: ball-pulse-sync 1s -0.09s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.09s infinite ease-in-out;
}

.ball-pulse-sync>div:nth-child(5) {
  -webkit-animation: ball-pulse-sync 1s 0s infinite ease-in-out;
  animation: ball-pulse-sync 1s 0s infinite ease-in-out;
}

.ball-pulse-sync>div {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin: 0px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}
</style>
