// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-payload-con[data-v-311a992d] {
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px;
  display: block;
  font-size: 13px !important;
  text-align: center;
  text-decoration: underline !important;
  color: #0000ee !important;
}
.mb-c-10[data-v-311a992d] {
  margin-bottom: 10px;
}
.mb-c-10[data-v-311a992d]:last-child {
  margin-bottom: 0 !important;
}
@media (min-width: 1900px) {
.custom-payload-con[data-v-311a992d] {
    font-size: 14px !important;
    padding: 10px;
}
}
@media (min-width: 2500px) {
.custom-payload-con[data-v-311a992d] {
    font-size: 14px;
    padding: 10px;
}
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChatWidget/TvmWebIframeRedirect/TvmWebIframeRedirect.vue","webpack://./TvmWebIframeRedirect.vue"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,cAAA;EACA,0BAAA;EACA,kBAAA;EACA,qCAAA;EACA,yBAAA;ACAF;ADGA;EACE,mBAAA;ACDF;ADGE;EACE,2BAAA;ACDJ;ADKA;AACE;IACE,0BAAA;IACA,aAAA;ACHF;AACF;ADMA;AACE;IACE,eAAA;IACA,aAAA;ACJF;AACF","sourcesContent":["\n.custom-payload-con {\n  width: 100%;\n  border: 1px solid #eee;\n  border-radius: 5px;\n  padding: 10px;\n  display: block;\n  font-size: 13px !important;\n  text-align: center;\n  text-decoration: underline !important;\n  color: #0000ee !important;\n}\n\n.mb-c-10 {\n  margin-bottom: 10px;\n\n  &:last-child {\n    margin-bottom: 0 !important;\n  }\n}\n\n@media (min-width: 1900px) {\n  .custom-payload-con {\n    font-size: 14px !important;\n    padding: 10px;\n  }\n}\n\n@media (min-width: 2500px) {\n  .custom-payload-con {\n    font-size: 14px;\n    padding: 10px;\n  }\n}\n",".custom-payload-con {\n  width: 100%;\n  border: 1px solid #eee;\n  border-radius: 5px;\n  padding: 10px;\n  display: block;\n  font-size: 13px !important;\n  text-align: center;\n  text-decoration: underline !important;\n  color: #0000ee !important;\n}\n.mb-c-10 {\n  margin-bottom: 10px;\n}\n.mb-c-10:last-child {\n  margin-bottom: 0 !important;\n}\n@media (min-width: 1900px) {\n  .custom-payload-con {\n    font-size: 14px !important;\n    padding: 10px;\n  }\n}\n@media (min-width: 2500px) {\n  .custom-payload-con {\n    font-size: 14px;\n    padding: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
