import axios from 'axios';
// import Slick from 'vue-slick';
import bModal from 'bootstrap-vue/es/components/modal/modal';
import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService';
import TvmPreLoader from '../TvmPreLoader/TvmPreLoader.vue';
// import { mapState } from 'vuex';
import 'v-slick-carousel/style.css';
import { VSlickCarousel } from 'v-slick-carousel';

export default {
  data() {
    return {
      primaryColor: window.primaryColor,
      isLoader: false,
      displayText: 'Please Wait Data is Loading...',
      isDisableChat: false,
      isHeading: false,
      serviceOffersData: [],
      selectedItem: {},
      serviceTypesPayloadUrl: '',
      buttons: [],
      selectedOfferId: '',
      btnDisabled: true,
      selectedItems: [],
      selectedCoupon: '',
      viewDetailsModal: false,
      viewDetailsData: '',
      IsMultiSelect: false,
      slickOptions: {
        infinite: false,
        groupsToShow: 3,
        groupsToScroll: 1,
        dots: false,
        slidesPerGroup: 1,
        // centerPadding: '20px',
        // adaptiveHeight: false,
        // nextArrow: `<em class="primaryicon-right-arrow" style="cursor:pointer;right:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;"></em>`,
        // prevArrow: `<em class="primaryicon-left-arrow" style="cursor:pointer;left:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;"></em>`,
      },
      isDisableBtn: false,
    };
  },
  components: {
    // Slick,
    'b-modal': bModal,
    'tvm-pre-loader': TvmPreLoader,
    VSlickCarousel,
  },
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state?.commonSettings?.chatThemeSettings;
  //     },
  //   }),
  // },
  props: ['botResponse', 'isLastIndex', 'isWaitingForResponse', 'chatThemeSettings'],
  mounted() {
    if (this.chatThemeSettings?.primeColor) {
      this.primaryColor = this.chatThemeSettings.primeColor;
    }
    // this.slickOptions.nextArrow = `<em class="primaryicon-right-arrow" style="cursor:pointer;right:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;background:${this.primaryColor} !important;color:#fff !important;"></em>`;
    // this.slickOptions.prevArrow = `<em class="primaryicon-left-arrow" style="cursor:pointer;left:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;background:${this.primaryColor} !important;color:#fff !important;"></em>`;
    let payload = this.botResponse?.component?.value;
    this.buttons = this.botResponse?.component?.value?.suggestions;
    this.isDisableChat = payload.disableChat;
    this.IsMultiSelect = payload.isMultiSelect;
    this.selectedOfferId = payload.selectedItem ? payload.selectedItem : '';
    this.isLoader = true;
    axios
      .get(`${payload.offerUri}`)
      .then((response) => {
        let items = response.data;
        if (items?.length) {
          this.isLoader = false;
          items.forEach((value, index) => {
            if (value.offerId === this.selectedOfferId) {
              value.isSelected = true;
              this.selectedCoupon = value.heading;
              setTimeout(() => {
                if (this.$refs.slick) {
                  this.$refs.slick.goTo(index);
                }
              }, 500);
            } else {
              value.isSelected = false;
            }
          });
          this.serviceOffersData = items;
          this.scrollBottom();
        } else {
          this.isLoader = false;
          this.serviceOffersData = items;
          this.scrollBottom();
        }
      })
      .catch((error) => {
        console.log(error);
        this.displayText = 'Something Went wrong. Please try again';
        this.isLoader = false;
      });
  },
  beforeDestroy() {
    this.$root.$off('isWaitingForResponseData');
  },
  methods: {
    trucate(value, length = 15) {
      return value.length <= length ? value : value.substring(0, length) + '...';
    },
    onCouponSelected(serviceType, idx) {
      // for single selection
      if (!this.IsMultiSelect) {
        let isSelected = serviceType.isSelected;
        this.serviceOffersData.forEach((value) => {
          value.isSelected = false;
        });
        this.serviceOffersData[idx].isSelected = !isSelected;
        this.selectedCoupon = this.serviceOffersData[idx].isSelected
          ? this.serviceOffersData[idx].heading || this.serviceOffersData[idx].offerId
          : '';
      }
      // for multiple selection
      if (this.IsMultiSelect) {
        serviceType.isSelected = !serviceType.isSelected;
        let selectedItems = this.serviceOffersData.filter(function (item) {
          return item.isSelected === true;
        });
        this.selectedCoupon = '';
        selectedItems.forEach((value) => {
          this.selectedCoupon = this.selectedCoupon
            ? `${this.selectedCoupon}, ${value.heading || value.offerId}`
            : value.heading || value.offerId;
        });
      }
      this.scrollBottom();
    },
    optionclick(button) {
      this.$root.$emit('isWaitingForResponseData', true);
      this.isDisableBtn = true;
      let selectedItems = this.serviceOffersData.filter(function (item) {
        return item.isSelected === true;
      });
      let seletions = selectedItems.map((value) => ({
        Id: value.offerId,
        Name: value.heading,
      }));
      let postObject = {
        eventname: button.event,
        text: button.name,
      };
      if (button.code === 'cnfm') {
        postObject.ServiceSelections = {
          SelectionType: 2,
          Selections: seletions,
        };
      } else {
        this.selectedCoupon = '';
      }

      let userInput = '';
      if (this.selectedCoupon?.length) {
        userInput = `Selected Coupons:${this.selectedCoupon}`;
      } else {
        userInput = `Skip`;
      }

      ChatWidgetService.postTvmMessage(
        userInput,
        button.event,
        button.submitFlow,
        button.submitAction,
        postObject
      );
      this.scrollBottom();
    },
    viewDetails(val) {
      this.viewDetailsModal = true;
      this.viewDetailsData = val;
    },
    scrollBottom() {
      let container = document.querySelector('#chat__bodyview');
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 500);
      }
    },
    getArrowStyles(direction) {
      const commonStyles = {
        cursor: 'pointer',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: this.primaryColor,
        color: '#fff',
        fontSize: '10px',
        padding: '5px',
        zIndex: 10,
      };
      if (direction === 'left') {
        return {
          ...commonStyles,
          left: '0',
          borderRadius: '0px 3px 3px 0px',
        };
      } else if (direction === 'right') {
        return {
          ...commonStyles,
          right: '0',
          borderRadius: '3px 0px 0px 3px',
        };
      }
      return commonStyles;
    },
  },
};
