<template>
  <div class="tvm_color_swatches mt-3">
    <div v-if="colorsList && colorsList.length">
      <transition name="slide-up">
        <h5 v-show="selectedColorName.colorDescription" :key="selectedColorName.colorDescription"
          class="text-center font-weight-bold ft-13">
          {{ selectedColorName.colorDescription }}
        </h5>
      </transition>
      <div class="d-flex flex-wrap align-items-center justify-content-center my-3">
        <div v-for="(item, index) in colorsList" :key="index">
          <div :id="'item' + index" class="color_selector" :class="index === selectedIndex ? 'selected_color' : ''"
            :style="{
              background:
                (isEnableExtGenericColors ? item.colorDescription : '#' + item.colorCode) +
                '!important',
            }" @click="selectedColor(item, index)" />
          <b-popover :ref="'item' + index" :target="'item' + index" triggers="hover focus" placement="top">
            <span class="ft-12"> {{ item.colorDescription }}</span>
          </b-popover>
        </div>
      </div>
      <ul v-if="buttons != null && buttons.length" class="list-unstyled service__types__list pt-2">
        <li v-for="(button, index) in buttons" :key="index" class="service__types__list__item">
          <div class="d-flex flex-wrap justify-content-center">
            <button :disabled="!selectedColorName.colorDescription"
              class="btn bgcolor-primary mb-2 rounded-buttons mx-2 text-white chips__btn"
              @click.once="colorConfirmation(button)">
              {{ button.name }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { ChatWidgetService } from "../../../Services/ChatWidgetService/ChatWidgetService";
import bPopover from "bootstrap-vue/es/components/popover/popover";

export default {
  components: {
    bPopover,
  },
  props: ["botResponse", "isWaitingForResponse", "isLastIndex"],
  data() {
    return {
      selectedColorName: {},
      selectedIndex: "",
      colorsList: [],
      buttons: [],
    };
  },
  computed: {
    isEnableExtGenericColors() {
      return this.botResponse?.component?.value?.enableExtGenericColors;
    },
  },
  mounted() {
    this.colorsList = this.botResponse?.component?.value?.colorCodeDto;
    this.buttons = this.botResponse?.component?.value?.suggestions;
    this.scrollBottom();
  },
  beforeDestroy() {
    this.$root.$off("isWaitingForResponseData");
  },
  methods: {
    selectedColor(val, index) {
      this.selectedColorName = val;
      this.selectedIndex = index;
      this.scrollBottom();
    },
    colorConfirmation(button) {
      this.$root.$emit("isWaitingForResponseData", true);
      let specialObject = { TvmComponentRequest: button };
      ChatWidgetService.postTvmMessage(
        this.selectedColorName.colorDescription,
        null,
        button.submitFlow,
        button.submitAction,
        specialObject
      );
      this.scrollBottom();
    },
    scrollBottom() {
      let container = document.querySelector("#chat__bodyview");
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 800);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.tvm_color_swatches {
  .color_selector {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    margin: 5px;
    border: 4px double #ddd;

    &.selected_color {
      border: 6px double #ddd;
      transition: border 0.5s;
    }
  }
}

@keyframes animated_bottom {
  0% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0px);
  }
}

.slide-up-enter-active {
  animation: animated_bottom 1s;
  transition: 0.5s;
}

#chat-widget .rounded-buttons.chips__btn {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 6px 12px !important;
  min-width: 56px !important;
  height: 32px !important;
  text-transform: unset !important;
}
</style>
