<template>
  <div v-if="linkInfo && linkInfo.uri" class="m-3">
    <a :href="linkInfo.uri" class="custom-payload-con" :style="{ borderColor: chatThemeSettings?.borderColor }"
      style="color: #0000ee" @click="navigateLink(linkInfo.uri)">{{ linkInfo.message }}</a>
  </div>
</template>
<script>
import { ChatWidgetService } from "../../../Services/ChatWidgetService/ChatWidgetService.js";
// import { mapState } from "vuex";

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["botResponse", "isDirect", "chatThemeSettings"],
  data() {
    return {
      linkInfo: {},
    }
  },
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state?.commonSettings?.chatThemeSettings;
  //     },
  //   }),
  // },
  mounted() {
    if (this.botResponse?.component?.value?.uri) {
      this.linkInfo = this.botResponse.component.value;
      if (this.isDirect) {
        window.location.href = `${this.botResponse?.component?.value?.uri}`;
        sessionStorage.setItem("slideOutClose", true);
      }
    }
  },
  methods: {
    navigateLink(val) {
      if (location.hash === "#Transact" && val.includes("#Transact")) {
        location.reload();
      } else {
        ChatWidgetService.closeChatWidgetSession();
        if (sessionStorage.getItem("chatTextModal") !== null) {
          sessionStorage.removeItem("chatTextModal");
        }
      }
    },
  },

};
</script>
<style lang="less" scoped>
.custom-payload-con {
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px;
  display: block;
  font-size: 13px !important;
  text-align: center;
  text-decoration: underline !important;
  color: #0000ee !important;
}

.mb-c-10 {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 1900px) {
  .custom-payload-con {
    font-size: 14px !important;
    padding: 10px;
  }
}

@media (min-width: 2500px) {
  .custom-payload-con {
    font-size: 14px;
    padding: 10px;
  }
}
</style>
