<!--
/**
 *
 * IMPORTANT:
 * This file is used only in vue3 application
 * changes made here should be made in vue2 also
 * vue2file:wwwroot\js\components\shared\ChatWidget\TvmSuggestions\TvmSuggestions.vue
 * for clarification contact Leela
 *
 */
-->
<template>
  <div class="service__types w-100 my-2">
    <div v-if="specialPositionButtons && specialPositionButtons.length"
      class="d-flex flex-wrap justify-content-center mb-2">
      <div v-for="(chip, index) in specialPositionButtons">
        <button v-if="chip.position === 'top' || (chip.code === 'text' && chip.position !== 'bottom')" :key="index"
          :title="chip.name" style="padding: 6px 30px !important" :disabled="isWaitingForResponse"
          class="btn bgcolor-primary mb-2 rounded-buttons mx-1 text-white chips__btn px-4"
          @click="optionclick($event, chip)">
          {{ chip.name }}
        </button>
      </div>
    </div>

    <template v-if="
      buttons != null &&
      buttons.length > 5 &&
      (botResponse.suggType === null || !botResponse.suggType)
    ">
      <div class="service__types__list px-3 position-relative">
        <VSlickCarousel ref="slickCarousel" class="" v-bind="slickOptions">
          <template #nextArrow="arrowSlotProps">
            <em :disabled="arrowSlotProps.disabled" @click="arrowSlotProps.onClick" class="primaryicon-right-arrow"
              :style="getArrowStyles('right')"></em>
          </template>
          <div v-for="(chip, index) in buttons" :key="index" class="service__types__list__item p-1 text-center">
            <div :title="chip.name" :disabled="isWaitingForResponse"
              class="btn bgcolor-primary btn-block w-100 rounded-buttons p-1 text-white chips__btn"
              @click="optionclick($event, chip)">
              {{ chip.truncateName }}
            </div>
          </div>
          <template #prevArrow="arrowSlotProps">
            <em :disabled="arrowSlotProps.disabled" @click="arrowSlotProps.onClick" class="primaryicon-left-arrow"
              :style="getArrowStyles('left')"></em>
          </template>
        </VSlickCarousel>
      </div>

    </template>
    <div v-else class="d-flex flex-wrap justify-content-center">
      <template v-for="(chip, index) in buttons">
        <div v-if="chip.suggestionButtonType === 'button'" :key="index" :title="chip.name"
          :disabled="isWaitingForResponse" class="btn bgcolor-primary mb-2 rounded-buttons mx-2 text-white chips__btn"
          @click="optionclick($event, chip)">
          {{ chip.name }}
        </div>
        <a v-if="chip.suggestionButtonType === 'button-link'" :key="index" :href="chip.inventoryURI"
          :disabled="isWaitingForResponse" class="btn bgcolor-primary mb-2 rounded-buttons mx-2 text-white chips__btn">
          {{ chip.name }}
        </a>
      </template>
    </div>
    <div v-if="specialPositionButtons && specialPositionButtons.length"
      class="d-flex flex-wrap justify-content-center my-3">
      <template v-for="(chip, index) in specialPositionButtons">
        <button v-if="chip.position === 'bottom'" :key="index" :title="chip.name" :disabled="isWaitingForResponse"
          style="padding: 6px 30px !important"
          class="btn bgcolor-primary mb-2 rounded-buttons mx-1 text-white chips__btn px-4"
          @click="optionclick($event, chip)">
          {{ chip.name }}
        </button>
      </template>
    </div>
  </div>
</template>

<script src="./TvmSuggestions.js"></script>

<style scoped>
#chat-widget .rounded-buttons.chips__btn {
  font-size: 11px !important;
  font-weight: 400 !important;
  padding: 6px 12px !important;
  min-width: 56px !important;
  text-transform: unset !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 1900px) {
  #chat-widget .rounded-buttons.chips__btn {
    font-size: 12px !important;
  }
}

.service__types>>>em.primaryicon-left-arrow.slick-disabled,
.service__types>>>em.primaryicon-right-arrow.slick-disabled {
  pointer-events: none;
  opacity: 0;
}

.search_bar {
  width: 100%;
  border: 0;
  background: #eee;
  border-radius: 30px;
  margin: auto;
  font-size: 12px;
  display: block;
  padding: 7px;
}

.search_bar:focus {
  outline: none;
}

.search_box_container {
  background: #eee;
  border-radius: 30px;
  padding: 0 10px;
}

.search_box_container em {
  font-size: 12px;
}

div[disabled=true] {
  opacity: 0.6;
  pointer-events: none;
}

#chat-widget .v-slick-slider em[disabled=false] {
  display: none !important;
}
</style>
