<template>
  <div class="service__types w-100 my-2">
    <div v-if="withTopPositionButtons && withTopPositionButtons.length"
      class="d-flex flex-wrap justify-content-center my-3">
      <div v-for="(chip, index) in withTopPositionButtons" :key="index">
        <button :title="chip.displayName" :disabled="isWaitingForResponse"
          class="btn bgcolor-primary mb-2 rounded-buttons mx-1 text-white chips__btn px-4"
          @click="optionclick($event, chip)">
          {{ chip.displayName }}
        </button>
      </div>
    </div>
    <template v-if="withOutPositionButtons != null && withOutPositionButtons.length > 5">
      <slick ref="slick" class="service__types__list px-3 my-3" :options="slickOptions">
        <div v-for="(chip, index) in withOutPositionButtons" :key="index"
          class="service__types__list__item p-1 text-center">
          <div :title="chip.name" :disabled="isWaitingForResponse"
            class="btn bgcolor-primary btn-block w-100 rounded-buttons p-1 text-white chips__btn"
            @click="optionclick($event, chip)">
            {{ chip.displayName }}
          </div>
        </div>
      </slick>
    </template>
    <div v-else class="d-flex flex-wrap justify-content-center my-3">
      <div v-for="(chip, index) in withOutPositionButtons" :key="index">
        <button :title="chip.displayName" :disabled="isWaitingForResponse"
          class="btn bgcolor-primary mb-2 rounded-buttons mx-1 text-white chips__btn px-4"
          @click="optionclick($event, chip)">
          {{ chip.displayName }}
        </button>
      </div>
    </div>
    <div v-if="withBottomPositionButtons && withBottomPositionButtons.length"
      class="d-flex flex-wrap justify-content-center my-3">
      <div v-for="(chip, index) in withBottomPositionButtons" :key="index">
        <button :title="chip.displayName" :disabled="isWaitingForResponse"
          class="btn bgcolor-primary mb-2 rounded-buttons mx-1 text-white chips__btn px-4"
          @click="optionclick($event, chip)">
          {{ chip.displayName }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { chatWidgetSuggestionsInteraction } from "../../../Events/TagManagerServices/TaggingEventForPages.js";
// import { mapState } from "vuex";
// import store from "../../../../../store/store";
// import { SET_CHAT_ACTIONS } from "../../../../../store/StoreConstants/CommonSettingsConstants";
import Slick from "vue-slick";
import { RECENT_VDP_VIN_KEY } from "../../../../../js/constants/constants.js";
import { useChatSettingsStore } from '../../../store/ChatSettingsStore/ChatSettingsStore';
export default {
  components: {
    Slick,
  },
  data() {
    return {
      buttons: [],
      isDisableChat: false,
      themePrimaryColor: window.primaryColor,
      slickOptions: {
        infinite: false,
        rows: 3,
        slidesPerRow: 2,
        dots: false,
        adaptiveHeight: false,
        nextArrow: `<em class="primaryicon-right-arrow bgcolor-primary" style="cursor:pointer;right:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;background:${this.themePrimaryColor} !important"></em>`,
        prevArrow: `<em class="primaryicon-left-arrow bgcolor-primary" style="cursor:pointer;left:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;;background:${this.themePrimaryColor} !important"></em>`,
      },
    };
  },
  computed: {
    // ...mapState({
    //   chatThemeSettings: (state) => {
    //     return state?.commonSettings?.chatThemeSettings;
    //   },
    // }),
    withOutPositionButtons() {
      const normalButtons = this.buttons.filter(button => button.position === undefined || button.position === null);
      return [...normalButtons];
    },
    withTopPositionButtons() {
      const topButtons = this.buttons.filter(button => button.position === 'top');
      return [...topButtons];
    },
    withBottomPositionButtons() {
      const bottomButtons = this.buttons.filter(button => button.position === 'bottom');
      return [...bottomButtons];
    }
  },
  props: ["botResponse", "isWaitingForResponse", "chatThemeSettings"],
  mounted() {
    this.buttons = this.botResponse?.component?.values;
    if (this.chatThemeSettings?.primeColor) {
      this.themePrimaryColor = this.chatThemeSettings.primeColor;
    }
    this.scrollBottom();
  },
  methods: {
    optionclick(event, button) {
      console.log(button)
      // store.commit(SET_CHAT_ACTIONS, {
      //   ModeOfAction: "tvm-assistant-structured-suggestion-button",
      // });

      const storeThemeSettings = useChatSettingsStore();
      storeThemeSettings.tvmChatActions = {
        ModeOfAction: "tvm-assistant-structured-suggestion-button",
      }
      let request = {
        UserResponse: button.displayName,
        Event: null,
        EventLabel: button.displayName,
        SubmitFlow: button.submitFlow,
        SubmitAction: button.submitAction,
        specialObject: { TvmComponentRequest: button },
      };
      if (request) {
        this.$emit("tvm-structured-suggestions", request);
      }
      chatWidgetSuggestionsInteraction(event, {});
    },
    scrollBottom() {
      let container = document.querySelector("#chat__bodyview");
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 800);
      }
    },
  },
};
</script>
<style scoped>
#chat-widget .rounded-buttons.chips__btn {
  font-size: 11px !important;
  font-weight: 400 !important;
  padding: 6px 12px !important;
  min-width: 56px !important;
  text-transform: unset !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 1900px) {
  #chat-widget .rounded-buttons.chips__btn {
    font-size: 12px !important;
  }
}

.service__types>>>em.primaryicon-left-arrow.slick-disabled,
.service__types>>>em.primaryicon-right-arrow.slick-disabled {
  pointer-events: none;
  opacity: 0;
}

.search_bar {
  width: 100%;
  border: 0;
  background: #eee;
  border-radius: 30px;
  margin: auto;
  font-size: 12px;
  display: block;
  padding: 7px;
}

.search_bar:focus {
  outline: none;
}

.search_box_container {
  background: #eee;
  border-radius: 30px;
  padding: 0 10px;
}

.search_box_container em {
  font-size: 12px;
}

div[disabled=true] {
  opacity: 0.6;
  pointer-events: none;
}
</style>
