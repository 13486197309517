<template>
  <div>
    <div>
      <div>
        <div v-if="showtext && !showchat" id="chat_button_enabled" class="chat-con" :class="chatAlignmentPosition()">
          <div class="chat-child">
            <div v-if="!ismobile && !isChatOpenInModal"
              class="d-flex justify-content-center align-items-center nada-text-us-btn _theme_font_bold font-weight-bold theme-bg-color rounded-corners btn-websites button-primary"
              :class="{ 'bgcolor-primary': !textUsButtonColor }" :style="{
                'background-color': textUsButtonColor,
                color: textUsFontColor,
                'border-color': borderColor,
              }" @click.prevent="openchatwidget($event)">
              {{ widgetName }}
            </div>
          </div>
        </div>
        <div v-if="showchat && !ismobile && !isChatOpenInModal" id="chat_button_enabled">
          <chat-assistant @openAssistant="openAssistant($event)" @openService="openService($event)"
            @tileNavigate="assistantParamsDataModal($event)" @modalNavigate="modalNavigate($event)"
            @disableWebBackgroundBlur="disableWebBackgroundBlur($event)" />
        </div>
      </div>

      <div v-if="showwidget" id="chat-widget" :class="[
        chatAlignmentPosition(),
        { textwidgetbox: !ismobile && !isChatOpenInModal },
        { is_tablet_device: isTablet },
      ]" class="pt-0 px-0 pb-0">
        <div>
          <div class="modal-content border-0">
            <div v-if="!isWorkingHoursEnable" class="chat__header">
              <button v-if="showLandingLayout && !ismobile" class="chat__header__btn" @click="hidewidget()">
                <i title="Back" class="header__icon primaryicon-longarrow-left" style="cursor: pointer"
                  :style="{ color: themePrimaryColor }" />
              </button>

              <button v-if="textUsButtonsNavigation" id="textUsFormEnabled" class="chat__header__btn"
                @click="textUsButtonsNavigationClose()">
                <i title="Back" :style="{ color: themePrimaryColor }" class="header__icon primaryicon-longarrow-left" />
              </button>
              <template v-else>
                <button v-if="showChatData" class="chat__header__btn"></button>
                <button v-if="showTextData" class="chat__header__btn">
                  <i v-if="isShowTextUsForm" title="Back" class="header__icon primaryicon-longarrow-left"
                    :style="{ color: themePrimaryColor }" @click="closeTextUsForm()" />
                </button>
              </template>

              <h5>
                <span class="d-flex align-items-center"><assistant-icon v-if="widgetTitle === 'Assistant'" :width="30"
                    :height="30" class="mr-2" />
                  {{ widgetTitle }}</span>
              </h5>
              <button v-if="!ismobile" title="Close" class="chat__header__btn close-animation" style="cursor: pointer"
                @click.prevent="hidewidget()">
                <i class="header__icon primaryicon-times" :style="{ color: themePrimaryColor }" />
              </button>
              <button v-if="ismobile" title="Close" class="chat__header__btn close-animation" style="cursor: pointer"
                :style="{ color: themePrimaryColor }" @click="$emit('close')">
                <i class="header__icon primaryicon-times" />
              </button>
            </div>
            <div v-if="isWorkingHoursEnable" class="chat__header">
              <button class="chat__header__btn" :style="{ color: themePrimaryColor }" @click="
                isWorkingHoursEnable = false;
              scrollAndFocus();
              ">
                <i title="Back" class="header__icon primaryicon-longarrow-left" style="cursor: pointer"
                  :style="{ color: themePrimaryColor }" />
              </button>
              <h5>
                <span>{{ componentNavigationLabel }}</span>
              </h5>
              <button title="Close" class="chat__header__btn close-animation" style="cursor: pointer" @click.prevent="
                isWorkingHoursEnable = false;
              scrollAndFocus();
              ">
                <i class="header__icon primaryicon-times" :style="{ color: themePrimaryColor }" />
              </button>
            </div>
            <div id="chatbody" class="chat__body">
              <div v-if="showChatData" id="chatBodySec2" class="chat__body__section2 position-relative"
                :class="{ 'position-relative': isEnableAssistantParamsDataModal }">
                <div v-if="!isWorkingHoursEnable" id="chat__bodyview" class="chat__subSec"
                  :style="{ height: chatWidgetLayoutHeight }">
                  <!-- flow indicator -->
                  <tvm-flow-name-indicator v-if="processNameIndicator" :flow-indicator="flowIndicator"
                    :process-name-indicator="processNameIndicator" />
                  <!-- end flow indicator -->
                  <!-- flow indicator -->
                  <!-- end flow indicator -->
                  <div class="chat__conversation__block">
                    <div v-for="(item, index) in fullfillmenttextList" :key="index" :id="'tileHeight-' + index">
                      <user-text-response v-if="item.request?.UserResponse" :userinput="item.request"
                        :requesttime="item.requestTime" @reloadDisableChat="reloadDisableChat" :index="index" />

                      <user-text-response v-else-if="item.request?.EventLabel" :userinput="item.request"
                        :requesttime="item.requestTime" @reloadDisableChat="reloadDisableChat" :index="index" />

                      <tvm-full-fillment-text v-if="item && item.responseData" :is-last-index="fullfillmenttextList.length
                        ? fullfillmenttextList.length - 1 === index
                        : false
                        " :full-fill-ment-text="item.fullfillmentText" :request="item.request"
                        :response-data="item.responseData" :response-time="item.responseTime"
                        :header-offset-height="headerOffsetHeight" :index="index" :user-input-text="userInput"
                        :selected-auto-completed-value="selectedAutoCompletedValue"
                        :chat-theme-settings="chatThemeSettings" :is-waiting-for-response="isWaitingForResponse"
                        :item-data="item" @tvm-suggestions="tvmSuggestions" @TvmKbbOffSetValue="tvmKbbOffSetValue"
                        @show-text-us-form="showTextUsForm" @mainMenu="mainMenu"
                        @show-text-us-other-form="showTextUsOtherForm" @selectedInputAddress="selectedInputAddress"
                        @selectedInputYmmt="selectedInputYmmt" @fullFillmentIndex="fullFillmentIndex"
                        @tvm-structured-suggestions="tvmSuggestions" />
                    </div>

                    <tvm-pre-loader v-if="chatResponseDelayLoading || isLoader" />

                    <tvm-api-error-regenerate @showTextUsOtherForm="showTextUsOtherForm"
                      @mainMenu="startMainMenu($event)" @hideTvmApiErrorRegenerate="hideTvmApiErrorRegenerate($event)"
                      v-if="isApiError && !isLoader" :response="isApiError" />

                    <div :style="{
                      height: isTileNavigate ? isTileNavigateHeight - 50 + 'px' : 'auto',
                    }" id="isTileNavigate" v-if="fullfillmenttextList?.length" />
                  </div>

                  <slide-out-panel v-if="!ismobile && !isTablet" :header-offset-height="headerOffsetHeight"
                    :slide-out="slideOutData" :flow-indicator="flowIndicator"
                    :disable-background-blur="disableBackgroundBlur" />
                </div>
                <div v-if="isWorkingHoursEnable" id="chat__bodyview" class="w-100 px-3 pt-3 chat__subSec"
                  :style="{ height: chatWidgetLayoutHeight }">
                  <component :is="componentNavigation" />
                </div>
                <!-- <file-attach v-if="fileAttachment" @close-attachment="fileAttachment = false" /> -->

                <!-- <div>
                  <tvm-help-modal :assistant-modal-code="assitantModalCode" v-if="isEnableAssistantParamsDataModal"
                    @assistant-params-data="assistantParamsConfirmModal" />
                </div> -->

                <!-- <div v-if="enablePrivacyPopUp && privacyText && showchat">
                  <tvm-privacy-modal v-if="!isAcceptedTvmPrivacyPolicy" :privacy-text="privacyText" />
                </div> -->

                <tvm-loader-modal v-if="isSignInNavigationModal" message="Logging you in. Please wait..." />
                <tvm-loader-modal v-if="isSignOut" message="Logging you out. Please wait..." />
                <tvm-loader-modal v-if="isTranslationChange" message="Translating content. Please wait..." />

                <div id="chat_widget_footer_menu" class="w-100">
                  <div v-if="isScrollBottom" class="scroll_bottom_indicator"
                    :class="{ animated_bottom: isScrollBottom }"
                    :style="{ color: themePrimaryColor, bottom: chatFooterHeight + 'px' }" @click="scrollBottom()">
                    <em class="primaryicon-angle-down" />
                  </div>
                  <transition name="slide-up">
                    <div v-show="textLengthValidation" class="text_length_validation">
                      Text must be less than 256 characters.
                    </div>
                  </transition>
                  <div class="chat__footer" :class="{ 'opacity-0': isWorkingHoursEnable }">
                    <input id="MessageBox" v-model="userInput" type="text" :disabled="disable ||
                      disableChat ||
                      isWaitingForResponse ||
                      fullfillmenttextList.length === 0
                      " class="txtInput" name="user__input"
                      :placeholder="disableText ? disableText : 'Type your message...'" autocomplete="off"
                      maxlength="256" @keyup.enter="sendMessage()" @input="selectedAutoCompletedValue = {}" />
                    <button class="chat__footer__btn bgcolor-primary theme-bg-color"
                      :disabled="disable || disableChat || isWaitingForResponse" @click="sendMessage()">
                      <i title="send" class="primaryicon-longarrow-right" />
                    </button>
                  </div>

                  <div class="chat__footer__icon align-items-center">
                    <div class="text-center cursor-pointer flex-1 footer-menu pt-1 pb-1 py-lg-c-2"
                      @mouseover="isHoverMenu = true" @mouseleave="isHoverMenu = false" @click="
                        startMainMenu($event);
                      isWorkingHoursEnable = false;
                      isTileNavigate = false;
                      ">
                      <menu-icon :is-hover-menu="isHoverMenu" />

                      <p class="m-0 ft-10">Menu</p>
                    </div>
                    <div class="text-center cursor-pointer flex-1 footer-menu pt-1 pb-1 py-lg-c-2"
                      @mouseover="isHoverHour = true" @mouseleave="isHoverHour = false"
                      @click="componentNavigate('Hours', $event)">
                      <hours-icon :is-hover-hour="isHoverHour" />
                      <p class="m-0 ft-10">Hours</p>
                    </div>

                    <div v-if="enableTranslationIcon"
                      class="text-center cursor-pointer flex-1 footer-menu pt-1 pb-1 py-lg-c-2"
                      @mouseover="isHoverLanguage = true" @mouseleave="isHoverLanguage = false"
                      @click="languageChangeEvent()">
                      <language-change-icon :icon-plus-text="true">
                        <template #iconPlusText="slotProps">
                          <language-icon :is-hover-language="isHoverLanguage" />
                          <p ref="languageRefContent" class="m-0 ft-10">{{ slotProps.text }}</p>
                        </template>
                      </language-change-icon>
                    </div>

                    <div v-if="!disableSignIn" class="text-center cursor-pointer flex-1 footer-menu pt-1 pb-1 py-lg-c-2"
                      :class="{
                        disableSignIn: isWaitingForResponse || fullfillmenttextList.length === 0,
                      }" @mouseover="isHoverUser = true" @mouseleave="isHoverUser = false" @click="LogoutUser($event)">
                      <sign-in-icon :is-hover-user="isHoverUser" />
                      <p class="m-0 ft-10">
                        {{ isAuthenticated === "True" ? "Sign Out" : "Sign In" }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showTextData" id="chatBodySec3" class="chat__body__section2">
                <div id="chat__bodyview" class="chat__subSec" :style="{ height: chatWidgetBodySec3Height }">
                  <div class="text__conversation__block">
                    <textus-form ref="innerTextusForm" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!disableBackgroundBlur">
      <div @click="hideChatWidgetByOverlay()" :style="slideWidgetHeight()"
        v-if="showwidget && !ismobile && !isHideOverly" class="light-box-area" />
    </template>
  </div>
</template>
<script src="./ChatWidget.js"></script>
<style scoped>
.footer_disclaimer {
  font-size: 9px;
  opacity: 0.7;
}

#chat-widget {
  color: #000;
}

.cursor-pointer {
  cursor: pointer;
}

.main-menu-icon {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1px;
  max-width: 12px;
  cursor: pointer;
  font-size: 22px;
}

#chat-widget>>>ul.list-unstyled.service__types__list {
  margin-bottom: 0 !important;
}

#chat-widget>>>.service__types__list__item:last-child .service__chips__btn {
  margin-bottom: 3px !important;
}

#chat-widget>>>.service_offers .service__chips__btn {
  margin-bottom: 0 !important;
}

#chat-widget>>>#chat__bodyview .df_message .primaryicon-right-arrow {
  width: 16px;
  height: 33px;
  border-radius: 3px 0px 0px 3px;
  margin: 0;
  padding: 5px !important;
  font-size: 10px;
  z-index: 99;
  right: 0 !important;
  position: absolute;
}

#chat-widget>>>#chat__bodyview .df_message .primaryicon-left-arrow {
  width: 16px;
  height: 33px;
  border-radius: 0px 3px 3px 0;
  margin: 0;
  padding: 5px;
  font-size: 10px;
  z-index: 99;
  left: 0 !important;
  position: absolute;
}

#chat-widget :deep(.v-slick-slider) {
  position: inherit;
}



.chat__footer__icon span {
  line-height: 0;
}

span.primaryicon-ellipsis-vertical1 {
  width: 4px;
  height: 4px;
  display: block;
  background: #000;
  border-radius: 50%;
  box-sizing: border-box;
  letter-spacing: 0;
}

.send-icon {
  cursor: pointer;
  font-size: 30px;
  padding: 8px;
  transform: rotate(44deg);
  color: red;
  margin-right: 10px;
}

#chat-footer {
  border-top: 1px solid #ccc;
}

.waiting-message {
  font-style: italic;
}

.chat-text-box {
  box-shadow: 0 0 5pt 0.5pt #d3d3d3;
}

.chat-icon {
  font-size: 25px;
}

textarea:focus,
input:focus {
  outline: none;
}

.df-button {
  background-color: yellowgreen;
}

body {
  background: #eee;
}

.chat-list {
  padding: 0;
  font-size: 1.1rem;
}

.chat-list .chat-img {
  float: left;
  width: 48px;
}

.chat-list .chat-img img {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  width: 100%;
}

.chat-list .chat-message {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  background: #5a99ee;
  display: inline-block;
  padding: 10px 20px;
  position: relative;
}

.chat-list .chat-message:before {
  content: "";
  position: absolute;
  top: 15px;
  width: 0;
  height: 0;
}

.chat-list .chat-message h5 {
  margin: 0 0 5px 0;
  font-weight: 900;
  line-height: 100%;
  font-size: 1.2rem;
}

.chat-list .chat-message p {
  line-height: 18px;
  margin: 0;
  padding: 0;
}

.chat-list .chat-body {
  margin-left: 20px;
  float: left;
  width: 70%;
}

.chat-list .in .chat-message:before {
  left: -12px;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #5a99ee;
}

.chat-list .out .chat-img {
  float: right;
}

.chat-list .out .chat-body {
  float: right;
  margin-right: 20px;
  text-align: right;
}

.chat-list .out .chat-message {
  background: #fc6d4c;
}

.chat-list .out .chat-message:before {
  right: -12px;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #fc6d4c;
}

.card .card-header:first-child {
  -webkit-border-radius: 0.3rem 0.3rem 0 0;
  -moz-border-radius: 0.3rem 0.3rem 0 0;
  border-radius: 0.3rem 0.3rem 0 0;
}

.card .card-header {
  background: #17202b;
  border: 0;
  font-size: 1.2rem;
  padding: 0.65rem 1rem;
  position: relative;
  font-weight: 900;
  color: #ffffff;
}

.content {
  margin-top: 40px;
}

.textwidget-close-icon {
  display: block !important;
  text-align: right;
  font-size: 24px;
  cursor: pointer;
  background-color: #f5f5f5;
}

.chat-image {
  position: absolute;
  width: 50px;
  top: -30px;
  right: 0px;
}

.nada-text-us-btn {
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding: 12px 14px;
  letter-spacing: 2px;
  display: block;
  margin: 0px auto;
  min-width: 100px;
  background: #007cc3;
}

.nada-chat-con {
  padding: 18px;
  border-radius: 5px;
  border: 1px none #000000;
  box-shadow: 1px 1px 3px 2px rgba(2, 2, 2, 0.19);
  color: #555555;
  z-index: 999999999;
  width: 18%;
  position: fixed;
  bottom: 10px;
  right: 5px;
  min-width: 310px;
  box-sizing: border-box;
}

.nada-child {
  box-sizing: border-box;
  margin: 12px 0;
  font-size: 14px;
}

.chat-con {
  position: fixed;
  bottom: 40px;
  z-index: 999;
}

.position-right-alignment {
  right: 0px;
}

.position-left-alignment {
  left: 0px;
}

.chat-con .circle-chat-con {
  background: transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
}

.chat-con .circle-chat-con img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.textwidgetbox {
  position: fixed;
  bottom: 0px;
  width: 25%;
  background: #fff;
  padding: 20px;
  padding-bottom: 0px;
  z-index: 999999999;
  box-shadow: 2px 2px 10px 3px #eeeeee;
}

.cls-3 {
  fill: #fff;
}

@media (max-width: 800px) {
  .textwidgetbox {
    width: 70%;
  }

  .send-btn-con .text-send-btn {
    margin: 5px 0;
  }
}

@media (max-width: 600px) {
  .chat-con {
    bottom: 25px;
    z-index: 1100 !important;
  }

  .chat-con.position-right-alignment {
    right: 4px;
  }

  .chat-con.position-left-alignment {
    left: 4px;
  }

  .nada-text-us-btn {
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    text-align: center;
    padding: 14px;
    letter-spacing: 2px;
    display: block;
    margin: 0px auto;
    min-width: 150px;
    z-index: 3;
    width: 80%;
    position: fixed;
    bottom: 43px;
    right: 0;
    left: 0;
    text-align: center;
    margin: 0 auto;
  }

  .nada-chat-con {
    width: 97%;
    min-width: unset;
    margin: 0 auto;
  }

  .textwidgetbox {
    width: 80%;
  }

  .chat-textarea-con {
    margin: 10px auto;
  }

  .textwidgetbox .text-radio-con .text-radio-box .sales-radio-con .float-left {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .chat-con {
    bottom: 89px;
  }
}

#chat-widget .modal-content {
  box-shadow: 4px 0px 10px 0px #eeeeee;
  border-radius: 0;
  border-left: 1px solid #eee !important;
}

.chat__content.position-right-alignment {
  right: 0;
}

.chat__content.position-left-alignment {
  left: 0;
}

.chat__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  border-bottom: 1px solid #eee;
  border-top: 1px solid rgba(0, 0, 0, 0.095) !important;
  height: 5vh;
}

.chat__header__btn {
  padding: 0;
  background: none;
  border: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  width: 25px;
}

.header__icon {
  font-size: 14px;
  font-weight: bold;
  color: #999;
}

.chat__header h5 {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0;
}

.chat__body {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.chat__body__section {
  width: 100%;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.chat__body__section2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
}

.chat__subSec {
  width: 100%;
  height: 65vh;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
}

#chatBodySec3 .chat__subSec {
  height: 75vh;
}

.chat__body__section2 .headTxt5 {
  font-size: 13px;
  text-align: center;
  margin: 10px auto 10px;
  color: #000;
}

.chat__body__box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 30px 20px;
  cursor: pointer;
}

.chat__body__box:not(:last-child) {
  margin-bottom: 20px;
}

.chat__body__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  margin-bottom: 5px;
  background: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  padding: 0;
}

.icon__cont__box img {
  height: 40px;
  margin-right: 9px;
}

.icon__cont {
  font-size: 12px;
}

#chatBodySec1 .chat__body__btn__title {
  font-size: 20px !important;
  font-weight: 900;
}

.chat__body__para {
  font-size: 9px;
  font-weight: 400;
  text-align: center;
  color: #999;
}

.chat__footer {
  width: 100%;
  height: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  position: relative;
  z-index: 9;
}

.txtInput {
  width: 100%;
  height: 5vh;
  background: #f5f5f5;
  border: 0;
  border-radius: 0;
  outline: none;
  text-align: left;
  padding: 5px 10px;
  font-size: 16px;
  color: #000;
}

.chat__footer__btn {
  width: 2vw;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 0;
  border-radius: 0;
  outline: none;
  cursor: pointer;
}

.chat__footer__icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 9;
}

.chat__conversation__block {
  width: 100%;
}

.chat__conversation__block div:nth-of-type(1) {
  padding: 0;
}

.chat__conversation__block div:nth-of-type(1) div {
  padding: 0;
}

.chat__conversation__block div:not(:first-child) {
  padding: 0;
}

.chat__conversation__block div:not(:first-child)>div {
  padding: 0;
}

.chips__btn {
  font-size: 12px;
  font-weight: 400;
  padding: 6px 12px;
  min-width: 56px;
  height: 32px;
}

#chat-widget .rounded-buttons.chips__btn {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 6px 12px !important;
  min-width: 56px !important;
  height: 32px !important;
  text-transform: unset !important;
}

.chat_loader {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 24px;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes ball-pulse-sync {
  20% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  40% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  60% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  80% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes ball-pulse-sync {
  20% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  40% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  60% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  80% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.ball-pulse-sync>div:nth-child(1) {
  -webkit-animation: ball-pulse-sync 1s -0.36s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.36s infinite ease-in-out;
}

.ball-pulse-sync>div:nth-child(2) {
  -webkit-animation: ball-pulse-sync 1s -0.27s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.27s infinite ease-in-out;
}

.ball-pulse-sync>div:nth-child(3) {
  -webkit-animation: ball-pulse-sync 1s -0.18s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.18s infinite ease-in-out;
}

.ball-pulse-sync>div:nth-child(4) {
  -webkit-animation: ball-pulse-sync 1s -0.09s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.09s infinite ease-in-out;
}

.ball-pulse-sync>div:nth-child(5) {
  -webkit-animation: ball-pulse-sync 1s 0s infinite ease-in-out;
  animation: ball-pulse-sync 1s 0s infinite ease-in-out;
}

.ball-pulse-sync>div {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin: 0px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}

@media all and (max-width: 576px) {
  .chat__content {
    width: 80%;
  }

  .chat__footer__btn {
    width: 12vw;
  }
}

@media all and (min-width: 577px) {
  .chat__content {
    width: 80%;
  }

  .chat__footer__btn {
    width: 12vw;
  }
}

@media all and (min-width: 768px) {
  .chat__content {
    width: 50%;
  }

  .chat__footer__btn {
    width: 8vw;
  }
}

@media all and (min-width: 992px) {
  .chat__content {
    width: 30%;
  }

  .chat__footer__btn {
    width: 4vw;
  }
}

@media all and (min-width: 1200px) {
  .chat__content {
    width: 28%;
  }

  .chat__footer__btn {
    width: 3vw;
  }
}

@media all and (min-width: 1400px) {
  .chat__content {
    width: 24%;
  }
}

@media all and (min-width: 1900px) {
  .footer_disclaimer {
    font-size: 11px;
  }

  .header__icon.primaryicon-times {
    font-size: 11px !important;
    color: #d9d9d9;
  }

  .header__icon.primaryicon-longarrow-left {
    font-size: 14px !important;
    font-weight: bold;
  }

  .chat__body__box {
    padding: 50px 30px;
  }

  .chat__subSec {
    height: 65vh;
  }

  .chat__body__section {
    height: 75vh;
  }

  #chatBodySec3 .chat__subSec {
    height: 75vh;
  }

  .chat__footer {
    height: 6vh;
  }

  .chat__footer__icon {
    height: 6vh;
  }

  .chat__footer__icon svg {
    height: 21px !important;
    margin-bottom: 2px;
  }

  .py-lg-c-2 {
    padding: 0.5rem 0 !important;
  }

  .py-lg-c-2 .ft-10 {
    font-size: 11px !important;
  }

  .txtInput {
    height: 6vh;
    font-size: 16px;
  }

  .chat__footer__btn {
    width: 3vw;
    height: 6vh;
  }

  .chat__footer__btn i {
    font-size: 20px;
  }

  .chat__header h5 {
    font-size: 16px;
  }

  .chat__body__section2 .headTxt5 {
    font-size: 19px;
    font-weight: bold;
    margin-top: 20px;
  }

  .chat__body__para {
    font-size: 11px;
  }

  #chatBodySec1 .icon__cont {
    font-size: 20px;
  }

  #chatBodySec1 .chat__body__para {
    font-size: 14px;
  }

  .header__icon {
    font-size: 11px;
  }

  #chatBodySec1 .chat__body__btn__title {
    font-size: 40px !important;
    font-weight: 900;
  }

  .icon__cont__box img {
    height: 50px;
  }
}

@media all and (min-width: 2500px) {
  .footer_disclaimer {
    font-size: 11px;
  }

  .chat__body__box {
    padding: 70px 30px;
    min-height: 320px;
  }

  .chat__footer {
    height: 4vh;
  }

  .txtInput {
    height: 4vh;
    font-size: 20px;
  }

  .chat__footer__btn {
    width: 3vw;
    height: 4vh;
  }

  .chat__footer__btn i {
    font-size: 24px;
  }

  .chat__header h5 {
    font-size: 20px;
  }

  .chat__body__section2 .headTxt5 {
    font-size: 20px;
  }

  #chatBodySec1 .icon__cont__box {
    width: 48px;
    height: 48px;
    margin-right: 20px;
  }

  #chatBodySec1 .icon__cont {
    font-size: 20px;
  }

  #chatBodySec1 .chat__body__para {
    font-size: 20px;
  }

  .header__icon {
    font-size: 20px;
  }
}

@media all and (min-width: 3800px) {
  .footer_disclaimer {
    font-size: 11px;
  }

  .chat__body__box {
    padding: 50px 30px;
    min-height: 420px;
  }

  .chat__body__box:not(:last-child) {
    margin-bottom: 50px;
  }

  .chat__content {
    width: 22%;
  }

  .chat__header {
    padding: 5px 20px;
  }

  #chatBodySec3 .chat__subSec {
    height: 76vh;
  }

  .chat__footer {
    height: 4vh;
  }

  .txtInput {
    height: 4vh;
    font-size: 24px;
  }

  .chat__footer__btn {
    width: 3vw;
    height: 4vh;
  }

  .chat__footer__btn i {
    font-size: 28px;
  }

  .chat__header h5 {
    font-size: 28px;
  }

  .chat__body__section2 .headTxt5 {
    font-size: 32px;
    margin: 20px auto 30px;
  }

  .chat__body__para {
    font-size: 32px;
  }

  #chatBodySec1 .icon__cont__box {
    width: 82px;
    height: 82px;
    margin-right: 20px;
  }

  #chatBodySec1 .icon__cont {
    font-size: 36px;
  }

  #chatBodySec1 .chat__body__para {
    font-size: 28px;
  }

  .header__icon {
    font-size: 32px;
  }
}

.text__conversation__block {
  width: 100%;
  height: auto;
}

.text__conversation__block>>>.beforechannelsready {
  width: 100%;
  padding: 0 20px;
}

.text__conversation__block>>>.back-button {
  visibility: hidden;
}

.text__conversation__block>>>.channel-list {
  width: 100%;
  padding: 0 20px;
  margin: 0 0 20px 0;
}

.text__conversation__block>>>.channel-form {
  width: 100%;
  height: auto;
  overflow: auto;
  padding: 0 20px;
}

.text__conversation__block>>>.input__contA {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}

.text__conversation__block>>>.mobile-number-box .name-input {
  border: 1px solid #eee;
  border-radius: 5px;
  font-size: 12px;
}

.text__conversation__block>>>.mobile-number-box .input__hold {
  margin: 0 0 10px 0;
}

.text__conversation__block>>>.mobile-number-box .mobile-number {
  color: #999;
  margin: 5px 0;
  font-size: 11px;
}

.text__conversation__block>>>.chat-textarea-con::before,
.text__conversation__block>>>.chat-textarea-con::after {
  display: none;
}

.text__conversation__block>>>.chat-textarea-con textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #eee;
  border-radius: 5px;
}

.text__conversation__block>>>.sales-radio-con {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.text__conversation__block>>>.sales-radio-con>div {
  float: left;
  width: 100%;
}

.text__conversation__block>>>.sales-radio-con .custom-radio-btn .radio-text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.text__conversation__block>>>.sales-radio-con .custom-radio-btn .radio-text .custom-radio-btn-input {
  margin-right: 5px;
}

.text__conversation__block>>>.sales-radio-con .custom-radio-btn .radio-text .custom-radio-btn-label {
  font-size: 10px;
}

.text__conversation__block>>>.note-info-text {
  font-size: 13px;
}

.text__conversation__block>>>.chat-textarea-con {
  width: 100%;
  margin: 20px auto;
  font-size: 12px;
}

.service__types>>>em.primaryicon-left-arrow.slick-arrow {
  background: #007cc3 !important;
}

.service__types>>>em.primaryicon-right-arrow.slick-arrow {
  background: #007cc3 !important;
}

.service__types>>>em.primaryicon-left-arrow.slick-disabled,
.service__types>>>em.primaryicon-right-arrow.slick-disabled {
  pointer-events: none;
  opacity: 0;
}

.chat__footer__icon .footer-menu svg {
  position: relative;
  bottom: 0px;
}

.chat__footer__icon .footer-menu:hover {
  cursor: pointer;
  background: #f5f5f5;
  transition: 0.5s;
}

.chat__footer__icon .footer-menu:hover svg {
  bottom: -2px;
  transition: 0.5s;
}

.chat__footer__icon img {
  height: 12px;
}

.footer-menu svg {
  height: 16px;
  fill: #b3b3b3;
}

.header__icon.primaryicon-times {
  font-size: 11px !important;
  color: #d9d9d9;
}

.header__icon.primaryicon-longarrow-left {
  color: #c7c7c7;
  font-size: 14px;
  font-weight: bold;
}

.chat__subSec::-webkit-scrollbar {
  width: 3px;
}

.chat__subSec::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}

#chat-widget.textwidgetbox {
  box-shadow: none !important;
}

.chat__footer.opacity-0 {
  opacity: 0;
}

.chat-con.position-right-alignment {
  right: 10px;
}

.chat-con.position-left-alignment {
  left: 10px;
}

.chat_widget_footer_menu #MessageBox:active {
  font-size: 16px !important;
}

.txtInput::placeholder {
  color: #0000008c !important;
}

#chat-widget::v-deep .service__types__list__item .service__chips__btn {
  font-weight: 400 !important;
  padding: 6px 12px !important;
  min-width: 56px;
  text-transform: unset !important;
  font-size: 12px !important;
}

#chat-widget::v-deep .chat__subSec .service__chips__btn {
  font-weight: 400 !important;
  padding: 6px 12px !important;
  min-width: 56px;
  text-transform: unset !important;
  font-size: 12px !important;
}

#chat-widget::v-deep .service_offers .service__chips__btn {
  margin-bottom: 0 !important;
  font-weight: 400 !important;
  padding: 6px 12px !important;
  min-width: 56px;
  text-transform: unset !important;
  font-size: 12px !important;
}

#chat-widget::v-deep #Service_Types .service__chips__btn {
  font-size: 9px !important;
  font-weight: 400 !important;
  padding: 2px !important;
  min-width: 56px !important;
  color: #fff !important;
}

.header__icon.primaryicon-times:hover {
  background: #f3f3f3;
  transition: 0.5s ease-in-out;
  transform: rotate(360deg);
  display: block;
  color: #000;
  padding: 5px;
  box-sizing: content-box;
}

.header__icon.primaryicon-longarrow-left:hover {
  background: #f3f3f3;
  transition: 0.4s ease-in-out;
  padding: 6px 8px;
  color: #000;
  font-size: 10px !important;
  box-sizing: content-box;
}

.disableSignIn {
  pointer-events: none;
  opacity: 0.8;
  cursor: not-allowed;
}

.text_length_validation {
  text-align: center;
  background: #eb9c5c;
  color: #fff;
  font-size: 12px;
  padding: 3px;
  position: relative;
  z-index: 1;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.5s;
  transform: translateY(0px);
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(30px);
}

#chat-widget.textwidgetbox.is_tablet_device {
  width: 50% !important;
}

.scroll_bottom_indicator {
  position: absolute;
  right: 10px;
  bottom: 18%;
  width: 20px;
  height: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  background: #fff;
  border: 1px solid #d5cccc;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}

@media (min-width: 1900px) {
  .scroll_bottom_indicator {
    width: 22px;
    height: 22px;
    font-size: 10px;
  }
}

.scroll_bottom_indicator:hover {
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.scroll_bottom_indicator.animated_bottom {
  display: flex;
  animation: animated_bottom 2s;
  transition: 0.5s;
}

@keyframes animated_bottom {
  0% {
    transform: translateY(60px);
  }

  100% {
    transform: translateY(0px);
  }
}

.light-box-area {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 99;
}

.signInNavigationModalLoader {
  position: absolute;
  background: rgba(240, 242, 250, 0.768627451);
  height: 100%;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signInNavigationModalLoader .c_border_r {
  border-radius: 11px;
}

.chat__footer__icon:has(.disableSignIn) {
  cursor: not-allowed;
}
</style>
