<template>
  <div>
    <div v-if="isMobile" class="mobile-section">
      <a
        :href="
          classicTemplate
            ? items[selectedVehicleIndex].inventoryPathClassic
              ? items[selectedVehicleIndex].inventoryPathClassic
              : items[selectedVehicleIndex].inventoryPath
            : items[selectedVehicleIndex].inventoryPath
        "
        class="text-decoration-none row"
      >
        <div class="w-75 mx-auto d-flex justify-content-center align-items-center col">
          <img
            :src="items[selectedVehicleIndex].imageHandlerPath"
            class="img-fluid"
            alt="Mazda Vehicle"
          />
        </div>
        <div class="col">
          <div class="ft-13 font-weight-bold letter-spacing-2 my-2 color-black text-uppercase">
            {{ appendMazda(items[selectedVehicleIndex].model_Name) }}
          </div>
          <div class="ft-13 font-weight-bold mb-2 color-black">
            <span
              v-for="(year, index) of items"
              :key="year.model_Year"
              class="model-year-color"
              :class="[selectedVehicleIndex == index ? 'selected-year' : '']"
              @click.stop.prevent="onVehicleSelect(index)"
            >
              {{ year.model_Year }} <span v-if="index < items.length - 1" class="mx-2">|</span>
            </span>
          </div>
          <div
            v-if="!items[selectedVehicleIndex].baseText"
            class="ft-13 letter-spacing-2 mb-2"
            style="color: #101010"
          >
            Starting at ${{ formatNumber(items[selectedVehicleIndex].baseMSRP) }}
            <Sup
              v-if="items[selectedVehicleIndex].disclaimer"
              class="ft-10"
              @click.prevent="openDisclaimer(items[selectedVehicleIndex].disclaimerText)"
            >
              {{ items[selectedVehicleIndex].disclaimer }}
            </Sup>
          </div>
          <div v-else class="ft-13 letter-spacing-2 mb-2" style="color: #101010">
            {{ items[selectedVehicleIndex].baseText }}
          </div>

          <div
            v-if="items[selectedVehicleIndex].isCaOnly"
            class="ft-13 letter-spacing-2 mb-2"
            style="color: #101010"
          >
            Only Available in CA
          </div>

          <div class="d-flex ft-14 text-uppercase">
            <div class="">
              <a
                v-if="!items[selectedVehicleIndex].hideExploreLink"
                :href="
                  items[selectedVehicleIndex].exploreLink
                    ? items[selectedVehicleIndex].exploreLink
                    : `/research-models/${items[selectedVehicleIndex].model_Year}/mazda/${getUrl(
                        items[selectedVehicleIndex].model_Name
                      )}`
                "
                style="color: #737373"
                class="text-underline"
              >
                Explore
              </a>
            </div>
            <div v-if="!items[selectedVehicleIndex].hideInventory" :class="marginBetweenExploreAndInventoryLinks">
              <a
                :href="`/inventory/new/mazda/${items[selectedVehicleIndex].specificInventoryModels ? items[selectedVehicleIndex].specificInventoryModels :items[selectedVehicleIndex].model_Name}?years=${items[selectedVehicleIndex].model_Year}`"
                style="color: #737373"
                class="text-underline"
              >
                Inventory
              </a>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div v-else>
      <a
        :href="
          classicTemplate
            ? items[selectedVehicleIndex].inventoryPathClassic
              ? items[selectedVehicleIndex].inventoryPathClassic
              : items[selectedVehicleIndex].inventoryPath
            : items[selectedVehicleIndex].inventoryPath
        "
        class="text-decoration-none cursor-pointer"
      >
        <div
          class="text-center ft-13 font-weight-bold letter-spacing-2 my-2 color-black text-uppercase"
        >
          {{ appendMazda(items[selectedVehicleIndex].model_Name) }}
        </div>
        <div class="w-75 mx-auto d-flex justify-content-center align-items-center">
          <img
            :src="items[selectedVehicleIndex].imageHandlerPath"
            class="img-fluid"
            alt="Mazda Vehicle"
          />
        </div>
        <div class="ml-5 mt-2 text-left">
          <div class="ft-13 font-weight-bold mb-2 color-black">
            <span
              v-for="(year, index) of items"
              :key="year.model_Year"
              class="model-year-color"
              :class="[selectedVehicleIndex == index ? 'selected-year' : '']"
              @click.stop.prevent="onVehicleSelect(index)"
            >
              {{ year.model_Year }} <span v-if="index < items.length - 1" class="mx-2">|</span>
            </span>
          </div>
          <div
            v-if="!items[selectedVehicleIndex].baseText"
            class="ft-13 letter-spacing-2 mb-2 font-weight-light"
            style="color: #101010"
          >
            Starting at ${{ formatNumber(items[selectedVehicleIndex].baseMSRP) }}<Sup
              v-if="items[selectedVehicleIndex].disclaimer"
              class="ft-10"
              @click.prevent="openDisclaimer(items[selectedVehicleIndex].disclaimerText)"
              >{{ items[selectedVehicleIndex].disclaimer }}</Sup
            >
          </div>
          <div v-else class="ft-13 letter-spacing-2 mb-2 font-weight-light" style="color: #101010">
            {{ items[selectedVehicleIndex].baseText }}
          </div>

          <div
            v-if="items[selectedVehicleIndex].isCaOnly"
            class="ft-13 letter-spacing-2 mb-2 font-weight-light"
            style="color: #101010"
          >
            Only Available in CA
          </div>

          <div class="d-flex ft-14 text-uppercase font-weight-light">
            <div class="">
              <a
                v-if="!items[selectedVehicleIndex].hideExploreLink"
                :href="
                  items[selectedVehicleIndex].exploreLink
                    ? items[selectedVehicleIndex].exploreLink
                    : `/research-models/${items[selectedVehicleIndex].model_Year}
                    /mazda/${getUrl(items[selectedVehicleIndex].model_Name)}`
                "
                style="color: #737373 !important; padding: 0px !important"
                class="text-underline"
              >
                Explore
              </a>
            </div>
            <div
              :class="marginBetweenExploreAndInventoryLinks"
              v-if="!items[selectedVehicleIndex].hideInventory"
            >
              <a
                :href="`/inventory/new/mazda/${getUrl(items[selectedVehicleIndex].specificInventoryModels ? items[selectedVehicleIndex].specificInventoryModels : items[selectedVehicleIndex].model_Name)}`"
                style="color: #737373 !important; padding: 0px !important"
                class="text-underline"
              >
                Inventory
              </a>
            </div>
          </div>
        </div>
      </a>
    </div>

    <contact-form v-if="contactform" :opencontact="contactform" @hidemodal="contactform = false" />

    <Modal
      v-if="modalShow"
      id="textmodal"
      size="large"
      :hideFooter="true"
      @hideModal="closeModal()"
    >
      <div
        v-if="disclaimer"
        class="px-lg-5 pt-lg-5 pb-lg-4 p-4 disclaimer-content-text text-left font-weight-light ft-16"
      >
        {{ disclaimer }}
      </div>
    </Modal>
  </div>
</template>
<script type="text/javascript" src="./ModelSelectionVehicle.js"></script>
<style>
.yearsactive {
  color: #000;
}
.color-gray {
  color: #bbb;
}
.model-year-color {
  color: #999;
}
.selected-year {
  color: #000;
}
.mazda-inventory-count {
  color: #737373;
  text-transform: uppercase;
}
.mazda-inventory-count:hover {
  color: #737373;
}
</style>
