/**
 *
 * IMPORTANT:
 * This file is used both in vue2 and vue3 project
 * Dont use vue syntax and the store conept
 * FOr clarifications contact Leela
 *
 */
const ExpireFormat = {
  DAYS: 'days',
  MINUTES: 'minutes',
};

export const TvmConstants = {
  expireDate: 1,
  expireFormat: ExpireFormat.DAYS,
  bufferTimeMinitues: 20,
  tvmAccountId: window.baseAccountId,
  tvmCampignId: window.baseCampaignId,
};
