<template>
  <div class="research_vehicle">
    <div v-if="responseData && responseData.length" class="px-4  animated_bottom">
      <div class="d-flex align-items-center justify-content-center py-2 mb-4">
        <div v-for="(item, index) in responseData" :key="index"
          :class="item.year === selectedModels.year ? 'selected_tab' : ''" :style="{ borderColor: primaryColor }"
          class="mx-2 tab_btn" @click="eachTab(item)">
          {{ item.year }}
        </div>
      </div>

      <transition name="slide-up">
        <div v-if="selectedModels && selectedModels.models" :key="selectedModels.year" class="tab_models">
          <div v-for="(model, index) in selectedModels.models" :key="index" class="each_tab_model">
            <h4>{{ model.linkDesc }}</h4>
            <div><img class="img-fluid" :src="model.imageUrl" alt="" loading="lazy" /></div>
            <div>
              <span :id="'modelBtn' + index" :style="{ border: '1px solid' + primaryColor, color: primaryColor }"
                class="chat_btn_outline" @click.once="eachModel(model)" @mouseover="changeColor(true, index)"
                @mouseout="changeColor(false, index)">
                Explore
              </span>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { ChatWidgetService } from '../../../../Services/ChatWidgetService/ChatWidgetService.js';
// import { mapState } from 'vuex';


export default {
  props: {
    responseData: {
      required: true,
      type: Array,
    },
    chatThemeSettings: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      selectedModels: {},
      primaryColor: window.primaryColor,
      animatedBottom: false,
    };
  },
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state.commonSettings.chatThemeSettings;
  //     },
  //   }),
  // },
  watch: {
    responseData: function (val) {
      if (val && val.length) {
        this.selectedModels = val[0];
      }
    }
  },
  mounted() {
    if (this.responseData?.length) {
      this.selectedModels = this.responseData[0];
    }
    if (this.chatThemeSettings?.primeColor) {
      this.primaryColor = this.chatThemeSettings.primeColor
    }
  },
  methods: {
    eachTab(item) {
      this.animatedBottom = true;
      this.selectedModels = item;
    },
    changeColor(isHovering, index) {
      if (isHovering) {
        document.querySelector(`#modelBtn${index}`).style.background = this.primaryColor;
        document.querySelector(`#modelBtn${index}`).style.color = "#fff";
      } else {
        document.querySelector(`#modelBtn${index}`).style.background = "#fff";
        document.querySelector(`#modelBtn${index}`).style.color = this.primaryColor;
      }
    },
    eachModel(item) {
      this.$root.$emit('isWaitingForResponseData', true);
      let specialObject = { TvmComponentRequest: item.linkDesc };
      ChatWidgetService.postTvmMessage(item.userResponse, null, item?.submitFlow, item?.submitAction, specialObject);
    }
  },
};
</script>
<style lang="less" scoped>
.research_vehicle {
  h5 {
    font-size: 1vw;
  }
}

.tab_models {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.each_tab_model {
  padding: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.10) 0px 5px 15px 0px;
  text-align: center;

  h4 {
    font-size: 0.8vw;
    font-weight: bold;
  }

  a {
    text-decoration: none;
  }
}

.chat_btn_outline {
  background: #fff;
  border: 0;
  border-radius: 5px;
  padding: 8px;
  text-transform: uppercase;
  font-size: 0.6vw;
  font-weight: 400;
  display: block;
  width: 100%;
  cursor: pointer;
  outline: none;
}

.each_tab_model img {
  height: 9vh;
  display: block;
  margin: 0 auto;
}

.tab_btn.selected_tab {
  border-bottom: 3px solid;
  opacity: 1;
  font-weight: 600;
}

.tab_btn {
  cursor: pointer;
  font-size: 0.8vw;
  opacity: 0.6;
  text-decoration: none;
}

.animated_bottom {
  animation: animated_bottom 2s;
  transition: 0.5s;
}

@keyframes animated_bottom {
  0% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0px);
  }
}

.slide-up-enter-active {
  animation: animated_bottom 1s;
  transition: 0.5s;
}
</style>
