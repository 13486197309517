<template>
  <div class="chat_assistant_bot">
    <div v-if="iconHeading" class="expand_btn" :style="themeBackground" :class="[
      { show: autoExpandAssistant },
      chatAlignmentPosition(),
      { is_tablet_device: isTablet },
    ]">
      <div v-if="!autoExpandAssistant" class="assistance_box" @click="openAssistant($event)">
        <p class="ft-10 font-weight-bold _theme_font_bold" :style="themeTextColor">
          {{ iconHeading }}
        </p>
        <div>
          <assistant-icon :theme-settings="themeSettings" :width="55" :height="55" :auto-spin="true"
            :spin-duration="spinDuration" />
        </div>
      </div>
    </div>

    <div v-if="autoExpandAssistant" class="content" :class="chatAlignmentPosition()" @mouseover="startHover"
      @mouseleave="endHover" @click.prevent="openChat()">
      <div class="py-c-3 position-relative">
        <div class="assistant_close_icon" @click.stop="closeAssistant()">
          <em class="primaryicon-times" />
        </div>
        <div class="d-flex align-items-center justify-content-center assistant_header">
          <assistant-icon :width="50" :height="50" />
          <h6 class="font-weight-bold _theme_font_bold m-0 ml-3">{{ enlargedIconHeading }}</h6>
        </div>
      </div>
      <div class="services_options_container br-15 py-3 overflow-hidden"
        :style="{ background: assistantSettingsData?.enlargedLaunchBackgroundColor ? assistantSettingsData.enlargedLaunchBackgroundColor : primaryColor }"
        :class="{ 'animate-fade': autoExpandAssistant }">
        <div>
          <div class="mb-4">
            <p :style="textWithOpacity">{{ enlargedIconSubHeading }}</p>
          </div>
          <div v-if="chipButtons && chipButtons.length" class="d-flex flex-wrap justify-content-center">
            <button v-for="(item, index) in chipButtons" :key="index" :style="themeSettingsBackground"
              class="btn mb-2 mx-1 px-3 chips__btn " @click.stop="eachService(item)">
              {{ item.flowName }}
            </button>
            <button v-if="enableTextAssistant" :style="themeSettingsBackground"
              class="btn mb-2 mx-1 px-3 chips__btn  chat_widget" @click.stop="eachService({ name: 'text' })">
              {{ widgetLabel }}
            </button>
          </div>
          <div v-if="!isTilesAvailable" class="d-flex flex-wrap justify-content-center">
            <button v-if="enableTextAssistant" :style="themeSettingsBackground" class="btn mb-2 mx-1 px-3 chips__btn"
              @click.stop="eachService({ name: 'text' })">
              {{ widgetLabel }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./ChatAssistant.js"></script>
<style lang="less" scoped>
.chat_assistant_bot {
  .expand_btn {
    width: 110px;
    height: 105px;
    background: #fff;
    transition: width 0.3s ease-in-out;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border-radius: 15px;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    text-align: center;
    z-index: 9999;

    &.is_tablet_device {
      bottom: 70px !important;
    }

    .assistance_box {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        transform: rotate(-360deg);
        transition: transform 1s ease;
        height: 45px;

        &:hover {
          transform: rotate(360deg);
          transition: transform 1s ease;
        }
      }

      p {
        font-size: 12px;
        margin-bottom: 10px;
        letter-spacing: -0.5px;
        color: #000;
      }
    }

    &.position-left-alignment {
      left: 20px;
    }
  }

  .assistant_header img {
    transform: rotate(-360deg);
    transition: transform 1s ease;
    height: 45px;

    &:hover {
      transform: rotate(360deg);
      transition: transform 1s ease;
    }
  }

  .expand_btn.show {
    width: 500px;
  }

  .content {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 500px;
    background: #fff;
    text-align: center;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    cursor: pointer;
    z-index: 9999;

    &.position-left-alignment {
      left: 20px;
    }

    h6 {
      margin: 0;
      white-space: nowrap;
      font-weight: bold;
      font-size: 1.3rem;
      letter-spacing: -1px;
      color: #000;
    }

    .chips__btn {
      background: rgba(255, 255, 255, 0.1);
      font-size: 13px;
      border-radius: 30px;

      &:hover {
        background: rgba(255, 255, 255, 0.15);
        transition: 0.5s;
        box-shadow: none !important;
      }

      &:active {
        box-shadow: none;
        outline: none;
      }
    }
  }

  .br-15 {
    border-radius: 15px;
  }

  .text-white-opacity {
    color: #ffffffcc;
  }

  .animate-fade {
    position: relative;
    animation: fadeIn 0.8s;
  }

  .assistant_close_icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    em {
      transform: rotate(-360deg);
      transition: transform 1s ease;
      display: block;
      color: #0000005c;
      cursor: pointer;
      font-size: 12px !important;

      &:hover {
        transform: rotate(360deg);
        transition: transform 1s ease;
      }
    }
  }

  .animated_icon {
    animation: rotate 550ms ease;
  }

  .py-c-3 {
    padding: 1.2rem 0;
  }

  @keyframes fadeIn {
    0% {
      right: -300px;
      opacity: 0;
    }

    100% {
      right: 0;
      opacity: 1;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(-360deg);
      transition: transform 550ms ease;
    }

    100% {
      transform: rotate(360deg);
      transition: transform 550ms ease;
    }
  }
}
</style>
