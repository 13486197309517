<template>
  <div>
    <div v-if="!isMobile" class="service__types w-100 my-2">
      <div class="px-3 position-relative" v-if="buttons != null && buttons.length > 5">
        <VSlickCarousel ref="slick" class="service__types__list px-3" v-bind="slickOptions">
          <template #nextArrow="arrowSlotProps">
            <em :disabled="arrowSlotProps.disabled" @click="arrowSlotProps.onClick" class="primaryicon-right-arrow"
              :style="getArrowStyles('right')"></em>
          </template>

          <div v-for="(chip, index) in buttons" :key="index" class="service__types__list__item p-1 text-center">
            <button :id="'chip' + index" :disabled="isWaitingForResponse"
              class="btn btn-block w-100 rounded-buttons p-1 text-white chips__btn"
              :style="{ background: '#' + chip.colorCode + '!important' }" @click.once="optionclick(chip)" />
            <b-popover :ref="'chip' + index" :target="'chip' + index" triggers="hover focus" placement="top">
              <span class="ft-12"> {{ chip.colorDescription }}</span>
            </b-popover>
          </div>

          <template #prevArrow="arrowSlotProps">
            <em :disabled="arrowSlotProps.disabled" @click="arrowSlotProps.onClick" class="primaryicon-left-arrow"
              :style="getArrowStyles('left')"></em>
          </template>
        </VSlickCarousel>
      </div>

      <div v-else class="d-flex flex-wrap justify-content-center">
        <div v-for="(chip, index) in buttons" :key="index">
          <button :id="'chip' + index" :disabled="isWaitingForResponse"
            class="btn mb-2 rounded-buttons mx-2 px-5 text-white chips__btn c_padding flex-1"
            :style="{ background: '#' + chip.colorCode + '!important' }" @click.once="optionclick(chip)" />
          <b-popover :ref="'chip' + index" :target="'chip' + index" triggers="hover focus" placement="top">
            <span class="ft-12"> {{ chip.colorDescription }}</span>
          </b-popover>
        </div>
      </div>
    </div>
    <div v-if="isMobile" class="px-3 mobile_color_selector">
      <div v-for="(chip, index) in buttons" :key="index" :disabled="isWaitingForResponse" :title="chip.colorDescription"
        class="py-1 d-flex align-items-center mb-2 px-2 each_color_selector" @click.once="optionclick(chip)">
        <div class="custom-circle-buttons" :style="{ background: '#' + chip.colorCode + '!important' }" />
        <div class="ft-13 text-dark ml-2">
          {{ chip.colorDescription }}
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./TvmColorSelection.js"></script>
<style scoped>
.rounded-buttons.chips__btn {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 6px 12px !important;
  min-width: 56px !important;
  height: 32px !important;
  text-transform: unset !important;
}

.rounded-buttons.chips__btn.c_padding {
  padding: 6px 42px !important;
}

.service__types>>>em.primaryicon-left-arrow.slick-disabled,
.service__types>>>em.primaryicon-right-arrow.slick-disabled {
  pointer-events: none;
  opacity: 0;
}

div[disabled] {
  opacity: 0.6;
  pointer-events: none;
}

.custom-circle-buttons {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.mobile_color_selector .each_color_selector {
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #eee;
}

#chat-widget .v-slick-slider em[disabled=false] {
  display: none !important;
}
</style>
