// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#chat-widget .my_vehicles .rounded-buttons.chips__btn[data-v-2223fd0e] {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 6px 12px !important;
  min-width: 56px !important;
  height: 32px !important;
  text-transform: unset !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChatWidget/TvmMyVehicles/TvmMyVehicles.vue","webpack://./TvmMyVehicles.vue"],"names":[],"mappings":"AACA;EACE,0BAAA;EACA,2BAAA;EACA,4BAAA;EACA,0BAAA;EACA,uBAAA;EACA,gCAAA;ACAF","sourcesContent":["\n#chat-widget .my_vehicles .rounded-buttons.chips__btn {\n  font-size: 12px !important;\n  font-weight: 400 !important;\n  padding: 6px 12px !important;\n  min-width: 56px !important;\n  height: 32px !important;\n  text-transform: unset !important;\n}\n","#chat-widget .my_vehicles .rounded-buttons.chips__btn {\n  font-size: 12px !important;\n  font-weight: 400 !important;\n  padding: 6px 12px !important;\n  min-width: 56px !important;\n  height: 32px !important;\n  text-transform: unset !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
