<template>
  <div>
    <div v-if="slideOut.panelHeading" ref="overlay_section" class="overlay_section" :style="slideWidgetHeight"
      @click="widgetClose()" />
    <div ref="ValueYourTradeInfo" class="ValueYourTradeInfoSlide" :class="chatAlignmentPosition()"
      :style="slideWidgetHeight" @scroll="handleScroll">
      <div class="nav_header">
        <div />
        <div>
          <h5 class="m-0"><span>{{ slideOut.panelHeading }}</span></h5>
        </div>
        <div class="widget_close_toggle">
          <div :class="chatAlignmentPosition()" class="icon_close_btn" title="Close Summary"
            :style="{ background: primeColor || primaryColor }" @click="widgetClose()">
            <i class="header__icon primaryicon-right-arrow" />
          </div>
        </div>
      </div>
      <!-- dynamic components placed here -->
      <div v-if="slideOut && slideOut.components && slideOut.components.length" class="p-3 mb-5 position-relative">
        <component :is="item.componentName" v-for="(item, index) in slideOut.components" :key="index"
          :response-data="item.value" />
      </div>
      <!-- dynamic components placed here -->
      <div v-if="showScrollButton" @click="scrollBottom()" class="scroll_to_bottom"
        :style="[{ background: primeColor || primaryColor }]">
        <em class="primaryicon-angle-down" />
      </div>
    </div>

    <!-- open widget -->
    <div v-if="slideOut.panelHeading" class="widget_open_toggle">
      <div :class="chatAlignmentPosition()" class="icon_btn" title="View Summary"
        :style="[{ background: primeColor || primaryColor, top: headerOffsetHeight + 'px' }]" @click="widgetOpen()">
        <i class="primaryicon-left-arrow" />
      </div>
    </div>
    <!-- end open widget -->

  </div>
</template>
<script src="./SlideOutPanel.js"></script>
<style lang="less" scoped>
.ValueYourTradeInfoSlide {
  height: 100%;
  width: 0%;
  position: fixed;
  z-index: 1;
  bottom: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: inset -9px -20px 11px 0px #eeeeeefc, -3px -3px 3px 0px #eeeeee52;
  border-top: 1px solid rgba(0, 0, 0, 0.095) !important;
  overflow-y: auto;

  &.position-left-alignment {
    left: 25%;
  }

  &.position-right-alignment {
    right: 25%;
  }

  .nav_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5vh;
    border-bottom: 1px solid #eee;
    position: sticky;
    top: 0;
    z-index: 99;
    background: #fff;

    h5 {
      font-size: 12px;
      font-weight: 600;
    }

    i {
      font-size: 11px !important;
      color: #d9d9d9;
      transition: transform 1s ease;
      display: block;
    }
  }
}

.widget_open_toggle {
  .icon_btn {
    position: fixed;
    top: 30%;
    width: 25px;
    height: 5vh;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0 0 5px;
    cursor: pointer;

    &.position-left-alignment {
      left: 25%;
      transform: rotate(180deg);
    }

    &.position-right-alignment {
      right: 25%;
    }

    i {
      animation: right 1s infinite;
      display: block;
      color: #fff !important;
    }

    @keyframes right {
      0% {
        transform: translateX(5px);
        opacity: 0;
      }

      100% {
        transform: translateX(0px);
        opacity: 1;
      }
    }

    @keyframes left {
      0% {
        transform: translateX(-5px);
        opacity: 0;
      }

      100% {
        transform: translateX(0px);
        opacity: 1;
      }
    }
  }
}

.ValueYourTradeInfoSlide::-webkit-scrollbar {
  width: 3px;
}

.ValueYourTradeInfoSlide::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ValueYourTradeInfoSlide::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.ValueYourTradeInfoSlide::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.overlay_section {
  position: fixed;
  height: 100vh;
  z-index: -1;
  clear: both;
  bottom: 0;
  left: 0;
}

.widget_close_toggle {
  .icon_close_btn {
    width: 25px;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0 0 5px;
    cursor: pointer;

    i {
      font-size: 13px;
      display: block;
      color: #fff !important;
    }

    &.position-left-alignment {
      i {
        transform: rotate(180deg);
      }
    }
  }
}

@media (min-width: 1900px) {
  .ValueYourTradeInfoSlide .nav_header {
    h5 {
      font-size: 16px;
    }
  }
}

@media (min-width: 2500px) {
  .ValueYourTradeInfoSlide .nav_header {
    h5 {
      font-size: 20px;
    }
  }
}

.scroll_to_bottom {
  position: sticky;
  max-width: 50px;
  text-align: center;
  margin: 10px auto;
  bottom: 5px;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
