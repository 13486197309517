/**
 *
 * IMPORTANT:
 * This file is used both in vue2 and vue3
 * Dont use the vue syntax and the store concept in this file.
 * For any clarifications please contact Leela
 *
 */
export class LayoutConstants {
  static WEBSITE_FRAME = '_website_frame';
  static WEBSITE_PRIMARY = '_website_primary';
  static WEBSITE_SIMPLIFY = '_website_simplify';
  static WEBSITE_LUXURY = '_website_luxury';
  static WEBSITE_LUXURY_PORSCHE = '_website_lux_porsche';
  static WEBSITE_NEW = '_Website_New';
  static WEBSITE_MAZDA_OEM = '_website_mazda_oem';
  static WEBSITE_BMW = '_website_bmw';
  static WEBSITE_PARAGON_PRIMARY = '_website_paragon_primary';
  static WEBSITE_LIGHTYEAR = '_website_lightyear';
  static WEBSITE_GEMINI = '_website_gemini';
  static WEBSITE_Mitsubishi = '_website_mitsubishi_oem';
  static WEBSITE_CLASSIC = '_website_classic';

  static isFrameTemplate() {
    return window.masterLayoutName.toLowerCase() === this.WEBSITE_FRAME.toLowerCase();
  }

  static isPrimaryTemplate() {
    return window.masterLayoutName.toLowerCase() === this.WEBSITE_PRIMARY.toLowerCase();
  }

  static isSimplifyTemplate() {
    return window.masterLayoutName.toLowerCase() === this.WEBSITE_SIMPLIFY.toLowerCase();
  }

  static isLuxTemplate() {
    return window.masterLayoutName.toLowerCase() === this.WEBSITE_LUXURY.toLowerCase();
  }
  static isLuxPorscheTemplate() {
    return window.masterLayoutName.toLowerCase() === this.WEBSITE_LUXURY_PORSCHE.toLowerCase();
  }
  static isWebsiteNewTemplate() {
    return window.masterLayoutName.toLowerCase() === this.WEBSITE_NEW.toLowerCase();
  }
  static isMazdaOemTemplate() {
    return window.masterLayoutName.toLowerCase() === this.WEBSITE_MAZDA_OEM.toLowerCase();
  }
  static isBmwTemplate() {
    return window.masterLayoutName.toLowerCase() === this.WEBSITE_BMW.toLowerCase();
  }
  static isParagonprimaryTemplate() {
    return window.masterLayoutName.toLowerCase() === this.WEBSITE_PARAGON_PRIMARY.toLowerCase();
  }
  static isLightYearTemplate() {
    return window.masterLayoutName.toLowerCase() === this.WEBSITE_LIGHTYEAR.toLowerCase();
  }
  static isGeminiTemplate() {
    return window.masterLayoutName.toLowerCase() === this.WEBSITE_GEMINI.toLowerCase();
  }
  static isClassicTemplate() {
    return window.masterLayoutName?.toLowerCase() === this.WEBSITE_CLASSIC?.toLowerCase();
  }
}
