<!--
IMPORTANT:
Used only in Vue3 applications
changes made here should be made in vue2 file also
file Location:wwwroot\js\components\Textus\IntegrateWithText.vue
for clarifications contact Leela

-->
<template>
  <div>
    <div class="integrate-with-text px-4 mt-4">
      <div class="text-center cursor-pointer text-us-btn" @click.prevent="clickChatWithUs()">
        Chat with Us
      </div>
      <div class="text-center cursor-pointer text-us-btn" @click.prevent="clickTextUs()">
        Text Us
      </div>
    </div>
  </div>
</template>
<script>
import * as jQuery from "jquery";
import { fireSonicChatIconClick } from "../../../Events/SonicDataLayer/SonicDataService";
window.jQuery = jQuery;
export default {
  data() {
    return {};
  },

  mounted() {},
  methods: {
    clickChatWithUs() {
      fireSonicChatIconClick("Chat with Us");
      window.CarNowPlugin.launchChat(this, 200);
      return false;
    },
    clickTextUs() {
      fireSonicChatIconClick("Text Us");
      window.CarNowPlugin.showSmsContactForm(this, "cncb11_sms_form");
      return false;
    },
  },
};
</script>
<style scoped>
.integrate-btns {
  font-size: 15px;
  margin: 20px 0;
  padding: 7px 14px;
}

.integrate-with-text .integrate-btns {
  color: #fff !important;
}

.text-us-btn {
  border-radius: 7px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  padding: 10px 20px;
  color: #212529;
  margin-bottom: 15px;
  cursor: pointer;
  height: 55px;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
