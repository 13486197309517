<template>
  <div class="px-3  my-2 tvm_login_vehicles">
    <div v-if="vehicles && vehicles.length" class="d-flex flex-column justify-content-center align-items-center">
      <button v-for="(vehicle, index) in vehicles" :key="index"
        class="btn bgcolor-primary mb-2 rounded-buttons mx-1 text-white chips__btn" @click.once="eachVehicle(vehicle)">
        {{ vehicle.displayText }}
      </button>
    </div>
  </div>
</template>
<script>
import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService.js';

export default {
  props: ["botResponse"],
  data() {
    return {
      vehicles: [],
      primaryColor: window.primaryColor
    }
  },
  mounted() {
    if (this.botResponse?.component?.value?.vehicles?.length) {
      this.vehicles = this.botResponse?.component?.value?.vehicles;
    }
  },
  beforeDestroy() {
    this.$root.$off('isWaitingForResponseData');
  },
  methods: {
    eachVehicle(vehicle) {
      this.$root.$emit('isWaitingForResponseData', true);
      let specialObject = { TvmComponentRequest: vehicle };
      ChatWidgetService.postTvmMessage(vehicle.displayText?.trim(), null, vehicle?.suggestion?.submitFlow, vehicle?.suggestion?.submitAction, specialObject);
    }
  },
}
</script>
<style>
#chat-widget .tvm_login_vehicles .rounded-buttons.chips__btn {
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 6px 12px !important;
  min-width: 56px !important;
  height: 32px !important;
  text-transform: unset !important;
}
</style>
