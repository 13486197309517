
<template>
  <div v-if="isMobile">
    <div v-if="modalShow" class="landing-pop-up-modal">
      <div>
        <div class="landing_text_overlay" />
        <div class="landing_text_modal">
          <div class="h-100 d-flex align-items-center">
            <div class="landing-modal-content">
              <div
                :class="[
                  submenublackcolor ? 'websiteprimarysubmenu' : '',
                  isMobile ? '' : 'position-absolute submenu-navigation'
                ]"
                style="z-index: -1"
              >
                <div class="w-100 bg-white model-section">
                  <div class="text-left _theme_font_bold ft-30">
                    <a class="close p-2 bgcolor-primary w-100" @click="closeModal()">
                      <i class="primaryicon-longarrow-left text-white" />
                    </a>
                  </div>

                  <div class="row">
                    <div class="vehicle-container col-md-6 col-lg-9 col-xl-9 col-xxl-9">
                      <div v-for="(menu, index) in menudata" :key="index" class="pl-5 vehicles-data">
                        <div class="row">
                          <div
                            v-for="(submenu, key) in menu.groupItemsList"
                            :key="key"
                            class="col-md-6 col-lg-4 col-xl-3 col-xxl-3 my-3"
                            v-if="checkModelToLoad(submenu)"
                          >
                            <model-selection-vehicle :items="submenu" />
                          </div>
                        </div>

                        <div class="border-style my-4" />
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-3 col-xl-3 col-xxl-3 p-lg-5 p-4" style="background-color: #f5f5f5">
                      <div class="text-center">
                        <div class="vehicle-btns bgcolor-primary w-100 p-3 rounded-buttons">
                          <a href="/inventory/new" class="text-white text-uppercase">All New Vehicles</a>
                        </div>
                        <div class="vehicle-btns bgcolor-primary w-100 p-3 mt-4 rounded-buttons">
                          <a href="/specials" class="text-white text-uppercase">New Vehicles Specials</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div
    v-else
    :class="[
      submenublackcolor ? 'websiteprimarysubmenu' : '',
      isMobile ? '' : 'position-absolute submenu-navigation'
    ]"
  >
    <div class="w-100 bg-white model-section">
      <div class="w-100 model-filters bgcolor-black model-filters-wrapper-section">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-9 col-xl-9 col-xxl-9">
            <div class="vehicles-data container">
              <ul class="m-0">
                <li>
                  <a
                    href=""
                    :class="{ active: selectedModel === 'all' }"
                    @click.prevent="onModelSelect('all')"
                  >
                    <div>All</div>
                    <div class="model-border" /></a>
                </li>
                <li v-for="(filter, key) of modelFilters" :key="filter">
                  <a
                    href=""
                    :class="{ active: selectedModel === key }"
                    @click.prevent="onModelSelect(key)"
                  ><div>{{ filter }}</div>
                    <div class="model-border" /></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 col-xl-3 col-xxl-3 ft-13">
            <div class="align-items-center mx-3 px-3 preowned-btn-holder">
              <div>
                <a href="/inventory/cpo" class="vehicle-btns preowned-button w-100 d-inline-block text-center">
                  CERTIFIED PREOWNED
                </a>
              </div>
              <div v-if="classicTemplate">
                <a class="close p-2 bgcolor-primary w-100" @click="closeModelSelectionMenu()">
                  <i class="primaryicon-times text-white" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row vehicle-container-row">
        <div
          class="vehicle-container col-md-6 col-lg-9 col-xl-9 col-xxl-9"
          :class="{
            'vehicles-container-hover': hoveredVehicle !== ''
          }"
        >
          <div
            v-for="menu in menudata"
            :key="menu.vehicleTypeName"
            class="pl-5 vehicles-data container"
          >
            <div
              v-if="selectedModel === 'all' || selectedModel === menu.vehicleTypeName"
              class="row"
            >
              <template v-for="(value, key) in menu.groupItemsList">
                <div
                  :key="key"
                  class="col-md-6 col-lg-4 col-xl-3 col-xxl-3 my-3 vehicle-list-item"
                  @mouseenter="onModelMouseEnter(key)"
                  @mouseleave="onModelMouseLeave()"
                  v-if="checkModelToLoad(value)"
                >
                  <model-selection-vehicle v-if="value" :items="value" />
                </div>
              </template>
            </div>
            <div v-if="selectedModel === 'all'" class="border-style my-4" />
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-xl-3 col-xxl-3 p-5" style="background-color: #f5f5f5">
          <div class="text-center">
            <div class="vehicle-btns bgcolor-primary w-100 p-3 rounded-buttons">
              <a href="/inventory/new" class="text-white text-uppercase">All New Vehicles</a>
            </div>
            <div class="vehicle-btns bgcolor-primary w-100 p-3 mt-4 rounded-buttons">
              <a href="/specials" class="text-white text-uppercase">New Vehicle Specials</a>
            </div>

            <div v-if="showMazdaEvModel">
              <div v-for="(evmenu, key) in evmenudata" :key="key" class="row no-gutters justify-content-center mt-3">
                <div style="color: #101010" class="col-12 ft-14 font-weight-bold letter-spacing-2 my-2 color-black text-uppercase">
                  ELECTRIC VEHICLES
                </div>
                <div class="row no-gutters justify-content-center" :class="{'vehicles-container-hover': hoveredVehicle !== ''}">
                  <div
                    v-for="(evmenuItems, key2) in evmenu.groupItemsList"
                    :key="key2"
                    class="col-xl-9 col-12 text-left mt-2 vehicle-list-item" @mouseenter="onModelMouseEnter(key)" @mouseleave="onModelMouseLeave()"
                  >
                    <model-selection-vehicle :items="evmenuItems" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./ModelSelection.js" type="text/javascript"></script>


<style>
.vehicles-data {
  max-width: 1050px;
}
.preowned-button {
  background: #fff;
  border-radius: 0 px;
  margin: 0 px 20 px;
  color: #000;
  letter-spacing: 2px;
}

.model-filters a.active {
  color: #fff;
}

.model-filters .active .model-border {
  border-top: 1px solid #fff;
}
.model-border {
  margin-top: 10px;
}

.preowned-button:hover {
  text-decoration: none;
  color: #000;
}

.model-filters ul {
  list-style: none;
  display: flex;
}



.model-filters ul li a {
  color: #b7b7b7;
  display: inline-block;
  padding: 1.5rem 1rem 1.3rem 1rem;
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
}

.model-filters ul li a:hover {
  text-decoration: none;
}

.model-filters ul li a:hover .model-border {
  border-top: 1px solid #fff;
}

.submenudata {
  width: 200px;
  max-height: 500px;
  overflow-y: auto;
}

.bgcolor-black {
  background-color: #000;
}
.vehicle-btns {
  border-radius: 5px;
}
.vehicle-btns a:hover {
  text-decoration: none;
}
.border-style {
  border: 1px solid #d5d5d5;
  border-bottom: 0;
}
.model-section::-webkit-scrollbar {
  width: 10px;
}

.model-section::-webkit-scrollbar-track {
  background-color: #ccc;
}

.model-section::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.model-section {
  min-height: 90vh;
}

.close {
  opacity: initial;
}
@media (max-width: 700px) {
  .model-section {
    height: initial;
    overflow: hidden;
  }
  .vehicles-data {
    padding-left: 0 !important;
  }
  .vehicle-model-name {
    padding-left: 20px;
  }
  .slick-slider {
    width: 100%;
  }
}
#modalselection-wrapper .modal-body {
  padding: 0px !important;
}
.hovered {
  opacity: 1;
  transition: opacity 0.4s linear 0s;
}

.model-vehicle-box {
  opacity: 0.5;
  transition: opacity 0.4s linear 0s;
}

.vehicles-container-hover .vehicle-list-item {
  opacity: 0.5;
  transition: opacity 0.4s linear 0s;
}
.vehicles-container-hover .vehicle-list-item:hover {
  opacity: 1;
  transition: opacity 0.4s linear 0s;
}
</style>
<style scoped>
.landing-pop-up-modal .landing_text_overlay {
  position: fixed;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
}

.landing_text_modal {
  position: fixed;
  z-index: 2005;
  left: 5vw;
  top: 5vh;
  width: 90vw;
}

.landing_text_modal .position-md-absolute {
  top: 0px;
}

@media (min-height: 1400px) {
  .landing-content {
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .landing_text_modal {
    min-height: 100%;
    top: 0vh;
    overflow: scroll;
    width: 94vw;
    left: 3vw;
  }

  .landing-content {
    border: none;
    height: calc(100% - 80px) !important;
  }
}

.landing-modal-content {
  min-height: 150px;
  background-color: white;
  width: 75%;
  margin: 0 auto;
}

.landing-content {
  max-height: 75vh;
  overflow-y: auto;
}

.website-classic-header .horizontalmenu .preowned-button {
  color: #000 !important;
}

.website-classic-header .horizontalmenu .websiteprimarysubmenu {
  top: 157px !important;
  height: 72vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  box-shadow: rgba(0, 0, 0, 0.75) -1px 11px 18px -9px;
}
.website-classic-header
  .horizontalmenu
  .websiteprimarysubmenu::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .landing_text_modal {
    height: 90vh;
    top: 5vh;
  }
}

@media (min-width: 1400px) {
  .landing_text_modal {
    height: 80vh;
    top: 10vh;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .landing_text_modal {
      height: 100%;
    }
  }
}

@media (max-width: 720px) {
  .landing-modal-content {
    height: 150px;
    background-color: white;
    width: 100%;
    margin: 0 !important;
  }
}

@media (min-width: 1024px) {
  .model-filters-wrapper-section {
    height: 75px;
  }
  .vehicle-container-row {
    max-height: calc(100vh - 160px);
    overflow-y: scroll;
  }
}
.preowned-btn-holder{
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 6px;
}
.preowned-btn-holder .preowned-button{
    padding: 12px;
    border-radius: 3px;
}
.websiteprimarysubmenu {
  top: 125px !important;
  left: 0px;
  width: 100%;
  max-height: 100vh;
}
</style>
