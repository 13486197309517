import TvmVDVIcon from '../TvmVDVIcon/TvmVDVIcon.vue';

export default {
  components: {
    'vdv-button': TvmVDVIcon,
  },
  props: ['botResponse'],
  data() {
    return {
      colorInfo: {},
      imgUrl: '',
      vehicleName: '',
      nextQuestion: '',
      vdvUrl: '',
    };
  },
  mounted() {
    if (this.botResponse?.component?.value?.colorInfo) {
      this.colorInfo = this.botResponse.component.value.colorInfo;
      this.imgUrl = this.botResponse?.component?.value?.imageUri;
      this.vehicleName = this.botResponse?.component?.value?.vehicleName;
      this.vdvUrl = this.botResponse?.component?.value?.vdvUrl;
    }
    if (this.botResponse?.component?.nextQuestion) {
      this.nextQuestion = this.botResponse?.component?.nextQuestion;
    }
  },
  methods: {},
};
