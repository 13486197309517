<template>
  <div class="navigate_to_inventory mb-4">
    <div v-for="(item, index) in inventoryInfo" :key="index" class="d-flex flex-wrap justify-content-center my-2  pt-2"
      style="clear: both;">
      <div class="navigate_inventory_box" :style="{ borderColor: primaryColor }">
        <div class="">
          <a class="d-block py-2" :href="item.uri" style="color: #0000ee;" @click="minimizeChatWidget()">{{
            item.linkDesc
            }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./TvmNavigateToInventory.js"></script>

<style scoped>
.navigate_inventory_box {
  width: 100%;
  text-align: center;
  margin: 0 10px;
  text-decoration: none;
  border-radius: 6px;
  border: 1px solid #eee;
}

.navigate_to_inventory .text-white {
  color: #fff !important;
}

.chips__btn {
  font-size: 12px;
  font-weight: 400;
  padding: 6px 12px;
  min-width: 56px;
  height: 32px;
}
</style>
