<template>
  <div>
    <component
      :desktopsubmenu="desktopsubmenu"
      :sidenavsubmenu="sidenavsubmenu"
      @primaryresearchmodels="closeprimaryResearchModels"
      :is="componentName"
      v-if="componentName"
    ></component>
  </div>
</template>

<script src="./ResearchModelsContainer.js" type="text/javascript"></script>