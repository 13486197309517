// equalHeightsPlugin.js

export default {
  install(app) {
    app.directive('equal-height', {
      mounted(el, binding) {
        // Get the class from the binding value (default to `.box` if not provided)
        const className = binding.value || '.box'; // Default to '.box' if no class is passed

        // Select all elements inside `el` that match the class
        const boxes = Array.from(el.querySelectorAll(className));

        const adjustHeights = () => {
          if (boxes.length === 0) return;

          let maxHeight = 0;

          // Reset the heights of all elements before recalculating
          boxes.forEach((box) => {
            box.style.height = 'auto'; // Reset heights
            const boxHeight = box.offsetHeight;
            if (boxHeight > maxHeight) {
              maxHeight = boxHeight;
            }
          });

          // Set the height of all elements to the tallest one
          boxes.forEach((box) => {
            box.style.height = `${maxHeight}px`;
          });
        };

        // Initial adjustment
        adjustHeights();

        // Recalculate on window resize
        window.addEventListener('resize', adjustHeights);

        // Cleanup listener when the directive is unmounted
        el._adjustHeights = adjustHeights;
      },
      unmounted(el) {
        // Clean up the resize event listener when the directive is unmounted
        window.removeEventListener('resize', el._adjustHeights);
      },
    });
  },
};
