// import Slick from 'vue-slick';
import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService';
// import { mapState } from 'vuex';
import 'v-slick-carousel/style.css';
import { VSlickCarousel } from 'v-slick-carousel';
export default {
  data() {
    return {
      primaryColor: window.primaryColor,
      multiSelectSuggestions: [],
      buttons: [],
      btnDisabled: true,
      selectedItems: [],
      commaSeparated: '',
      selectedIndex: 0,
      slickOptions: {
        infinite: false,
        groupsToShow: 4,
        slidesToScroll: 1,
        // rows: 3,
        slidesPerGroup: 3,
        dots: false,
        // centerPadding: '20px',
        // adaptiveHeight: false,
        // nextArrow: `<em class="primaryicon-right-arrow" style="cursor:pointer;right:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;"></em>`,
        // prevArrow: `<em class="primaryicon-left-arrow" style="cursor:pointer;left:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;"></em>`,
      },
      isDisableBtn: false,
    };
  },
  // computed: {
  //   ...mapState({
  //     chatThemeSettings: (state) => {
  //       return state?.commonSettings?.chatThemeSettings;
  //     },
  //   }),
  // },
  components: {
    // Slick,
    VSlickCarousel,
  },
  props: ['botResponse', 'isLastIndex', 'isWaitingForResponse', 'chatThemeSettings'],
  mounted() {
    if (this.chatThemeSettings?.primeColor) {
      this.primaryColor = this.chatThemeSettings.primeColor;
    }
    // this.slickOptions.nextArrow = `<em class="primaryicon-right-arrow" style="cursor:pointer;right:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;background:${this.primaryColor} !important;color:#fff !important;"></em>`;
    // this.slickOptions.prevArrow = `<em class="primaryicon-left-arrow" style="cursor:pointer;left:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;background:${this.primaryColor} !important;color:#fff !important;"></em>`;
    let payload = this.botResponse?.component?.value;
    this.buttons = payload?.suggestions;
    const years = payload?.data;
    if (years?.length) {
      this.multiSelectSuggestions = years.map((item, index) => ({
        name: item,
        id: index,
      }));
    }
    console.log(this.multiSelectSuggestions);
  },
  methods: {
    onSelectYears(eachSuggestion, index) {
      eachSuggestion.isSelected = !eachSuggestion.isSelected;
      let selectedItems = this.multiSelectSuggestions.filter((item) => {
        return item.isSelected === true;
      });
      this.commaSeparated = '';
      selectedItems.forEach((value) => {
        this.commaSeparated = (
          this.commaSeparated ? `${this.commaSeparated}, ${value.name}` : value.name
        ).replace('</br>,', '</br>');
      });
      this.btnDisabled = selectedItems.length <= 0;
      this.scrollBottom();
    },

    optionclick(button) {
      this.isDisableBtn = true;

      let postObject = {};
      if (this.commaSeparated) {
        postObject.TvmComponentRequest = {
          val1: this.commaSeparated,
          eventname: button.event,
          text: button.name,
        };
      }
      ChatWidgetService.postTvmMessage(
        `${
          this.commaSeparated?.split(',').length > 1 ? 'Selected Years' : 'Selected Year'
        } : ${this.commaSeparated.replace('</br>', ',')}`,
        button.event,
        button.submitFlow,
        button.submitAction,
        postObject
      );
      this.scrollBottom();
    },

    scrollBottom() {
      let container = document.querySelector('#chat__bodyview');
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 500);
      }
    },
    getArrowStyles(direction) {
      const commonStyles = {
        cursor: 'pointer',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: this.primaryColor,
        color: '#fff',
        fontSize: '10px',
        padding: '5px',
        zIndex: 10,
      };
      if (direction === 'left') {
        return {
          ...commonStyles,
          left: '0',
          borderRadius: '0px 3px 3px 0px',
        };
      } else if (direction === 'right') {
        return {
          ...commonStyles,
          right: '0',
          borderRadius: '3px 0px 0px 3px',
        };
      }
      return commonStyles;
    },
  },
};
