// import Vue from 'vue';
import TvmCurrentVehicle from '../SlideOutPanel/TvmCurrentVehicle/TvmCurrentVehicle.vue';
import TvmUpgradeVehicle from '../SlideOutPanel/TvmUpgradeVehicle/TvmUpgradeVehicle.vue';
import TvmSlideOutKbbTradeValue from '../SlideOutPanel/TvmSlideOutKbbTradeValue/TvmSlideOutKbbTradeValue.vue';
import TvmSlideOutBbTradeValue from '../SlideOutPanel/TvmSlideOutBbTradeValue/TvmSlideOutBbTradeValue.vue';
import TvmAppraisalAppointment from '../SlideOutPanel/TvmAppraisalAppointment/TvmAppraisalAppointment.vue';
import TvmSlideOutServiceSpecial from '../SlideOutPanel/TvmSlideOutServiceSpecial/TvmSlideOutServiceSpecial.vue';
import TvmMyVehicle from '../SlideOutPanel/TvmMyVehicle/TvmMyVehicle.vue';
import TvmAppointmentAddress from '../SlideOutPanel/TvmAppointmentAddress/TvmAppointmentAddress.vue';
// Vue.component('tvm-research-models-component', () =>
//   import('../SlideOutPanel/TvmSlideOutResearchModels/TvmSlideOutResearchModels.vue')
// );
import TvmNewVehicle from '../SlideOutPanel/TvmSlideOutBuyVehicle/TvmNewVehicle.vue';
import TvmUserInfo from '../SlideOutPanel/TvmSlideOutBuyVehicle/TvmUserInfo.vue';
import TvmSlideOutResearchModels from '../SlideOutPanel/TvmSlideOutResearchModels/TvmSlideOutResearchModels.vue';
// import { mapState } from 'vuex';

export default {
  props: [
    'headerOffsetHeight',
    'slideOut',
    'flowIndicator',
    'disableBackgroundBlur',
    'chatThemeSettings',
  ],
  data() {
    return {
      primaryColor: window.primaryColor,
      slideInfoComponents: [],
      showScrollButton: false,
      primeColor: '',
    };
  },
  mounted() {
    // this.$root.$on('OpenSlideInfo', (val) => {
    //   if (val) {
    //     this.widgetOpen();
    //   } else {
    //     this.widgetAutoClose();
    //   }
    // });
    // this.$root.$on('main_menu', (val) => {
    //   if (val && document.querySelector('.overlay_section')) {
    //     this.widgetAutoClose();
    //   }
    // });
    this.primeColor = this.chatThemeSettings?.primeColor;
  },
  computed: {
    slideWidgetHeight() {
      return {
        height: `calc(100vh - ${this.headerOffsetHeight}px)`,
      };
    },
    // ...mapState({
    //   primeColor: (state) => {
    //     return state?.commonSettings?.chatThemeSettings?.primeColor;
    //   },
    // }),
  },
  methods: {
    widgetOpen() {
      this.$refs.ValueYourTradeInfo.style.width = '25%';
      this.$refs.overlay_section.style.width = '100% ';
      if (this.disableBackgroundBlur) {
        this.$refs.overlay_section.style.background = 'rgba(0, 0, 0, 0.5)';
      }
    },
    // widget manually close
    widgetClose() {
      this.$refs.ValueYourTradeInfo.style.width = '0';
      this.$refs.overlay_section.style.width = '0';
      sessionStorage.setItem('slideOutClose', true);
    },
    // widget auto close
    widgetAutoClose() {
      this.$refs.ValueYourTradeInfo.style.width = '0';
      if (document.querySelector('.overlay_section')) {
        this.$refs.overlay_section.style.width = '0';
      }
    },
    slideOutReloadAutoClose() {
      let slideOutClose = false;
      if (sessionStorage.getItem('slideOutClose') !== null) {
        slideOutClose = sessionStorage.getItem('slideOutClose');
      }
      if (
        this.flowIndicator !== this.slideOut?.panelHeading &&
        document.querySelector('.overlay_section')
      ) {
        this.widgetAutoClose();
      }
      if (
        this.flowIndicator === this.slideOut?.panelHeading &&
        this.slideOut?.components?.length &&
        !slideOutClose
      ) {
        this.widgetOpen();
      }
    },
    chatAlignmentPosition() {
      return window.textWidgetPosition && window.textWidgetPosition.toLowerCase() === 'bottom left'
        ? 'position-left-alignment'
        : 'position-right-alignment';
    },
    scrollBottom() {
      let container = document.querySelector('.ValueYourTradeInfoSlide');
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
    checkScroll() {
      this.showScrollButton = false;
      const div = document.querySelector('.ValueYourTradeInfoSlide');
      if (div) {
        this.showScrollButton = div.scrollHeight > div.clientHeight;
      }
    },
    handleScroll() {
      const div = this.$refs.ValueYourTradeInfo;
      this.showScrollButton = div.scrollTop + div.clientHeight < div.scrollHeight;
    },
  },
  watch: {
    slideOut: {
      handler(newValue, oldValue) {
        let slideOutClose = false;
        if (sessionStorage.getItem('slideOutClose') !== null) {
          slideOutClose = sessionStorage.getItem('slideOutClose');
        }
        if (this.slideOut?.components?.length) {
          if (!slideOutClose) {
            setTimeout(() => {
              this.widgetOpen();
              this.showScrollButton = false;
            }, 100);
          }
        }
        setTimeout(() => {
          this.checkScroll();
        }, 400);
      },

      deep: true,
    },
    flowIndicator(newVal, oldVal) {
      this.slideOutReloadAutoClose();
    },
  },
  beforeDestroy() {
    this.$root.$off('main_menu');
    this.$root.$off('OpenSlideInfo');
  },
  // don't changes component names here.
  components: {
    'tvm-slideout-current-vehicle': TvmCurrentVehicle,
    'tvm-slideout-upgrade-vehicle': TvmUpgradeVehicle,
    'tvm-slideout-kbb-tradevalue': TvmSlideOutKbbTradeValue,
    'tvm-slideout-bb-tradevalue': TvmSlideOutBbTradeValue,
    'tvm-slideout-appraisal-appointment': TvmAppraisalAppointment,
    'tvm-slideout-service-special': TvmSlideOutServiceSpecial,
    'tvm-slideout-my-vehicle': TvmMyVehicle,
    'tvm-slideout-appointment-address': TvmAppointmentAddress,
    'tvm-slideout-bv-current-vehicle': TvmNewVehicle,
    'tvm-slideout-bv-user-details': TvmUserInfo,
    'tvm-research-models-component': TvmSlideOutResearchModels,
  },
};
