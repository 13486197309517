<!--
Important:
This file is used only in vue3 applications
Changes made here should be made in he vue2 also
file Location: wwwroot\js\components\shared\ChatWidget\usertextresponse\usertextresponse.vue
For Clarifications contact Leela
-->
<template>
  <div>
    <template v-if="userinput.UserResponse || userinput.EventLabel">
      <div class="out my-2" :id="'userResponse' + index">
        <div class="chat__rightPane">
          <small class="d-block text-left mb-1 pl-3 grey-text time__stamp user-time-text">
            <span class="user__icon__box">
              <svg :fill="chatThemeSettings?.borderColor || '#B3B3B3'" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z" />
              </svg>
            </span>
            <span v-html="userinput.FromUser === 'user' ? 'You' : userinput.FromUser" />
            {{ requesttime }}
          </small>
          <div class="chat-body speech-bubble mb-1 flex-fill position-relative user-box"
            style="border: 1px solid #007cc329" :style="{ borderColor: chatThemeSettings?.borderColor }">
            <div class="chat-message">
              <div>
                <p v-if="userinput.customUIUserResponse" class="user_response mb-0">
                  {{
                    userinput.customUIUserResponse
                      ? userinput.customUIUserResponse
                      : userinput.EventLabel
                  }}
                </p>
                <p v-else class="user_response mb-0">
                  {{ userinput.UserResponse ? userinput.UserResponse : userinput.EventLabel }}
                </p>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
        <div style="clear: both"></div>
      </div>
    </template>
  </div>
</template>
<script src="./UserTextResponse.js"></script>
<style scoped>
.chat-message {
  color: #000;
}

.chat__rightPane {
  min-width: 70%;
  float: right;
}

.user-box {
  display: inline-block;
  float: right;
  min-width: 70px;
  max-width: 100%;
}

.user-time-text {
  font-size: 12px;
}

.avatar-img {
  width: 25px;
}

.speech-bubble {
  width: 100%;
  position: relative;
  padding: 10px 30px;
  background-color: #fff;
  color: #000;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.095);
  border-right: 0 !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.speech-bubble:before,
.speech-bubble:after {
  content: "\0020";

  position: absolute;
  top: -12px;
  right: 20px;
  z-index: 2;
  width: 0;
  height: 0;
  overflow: hidden;
  border: solid 14px transparent;
  border-top: 0;
  border-bottom-color: #fff;
  display: none;
}

.speech-bubble:before {
  top: -14px;
  z-index: 1;
  border-bottom-color: inherit;
  display: none;
}

.user_response {
  font-size: 12px;
  color: #000;
}

.time__stamp {
  font-size: 10px;
  color: #000;
}

@media all and (min-width: 1900px) {
  .user_response {
    font-size: 14px;
  }

  .time__stamp {
    font-size: 12px;
  }
}

@media all and (min-width: 2500px) {
  .user_response {
    font-size: 20px;
  }

  .time__stamp {
    font-size: 16px;
  }
}

@media all and (min-width: 3800px) {
  .user_response {
    font-size: 32px;
  }

  .time__stamp {
    font-size: 20px;
  }
}

span.user__icon__box svg {
  height: 15px;
  width: 15px;
  vertical-align: text-bottom;
}
</style>
