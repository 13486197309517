/* eslint-disable no-undef */
/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

import eventBus from '../../../Services/EmitterService/EmitterService';
import UserTextResponse from '../UserTextResponse/UserTextResponse.vue';
import textusform from '../TextusForm/TextusForm.vue';

import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService.js';
// import * as signalR from '@microsoft/signalr';

// import Slick from 'vue-slick';
import { Events } from '../../../Events/ConsumerEvents/Events';

import ShiftDigitalContainer from '../../../Events/ShiftDigital/ShiftDigitalContainer.js';
import DealerWorkingHours from '../DealerWorkingHours/DealerWorkingHours.vue';
// import FileAttach from '@/js/components/shared/ChatWidget/FileAttach/FileAttach.vue';

import OtherOptionTextForm from '../OtherOptionTextForm/OtherOptionTextForm.vue';
import TvmSuggestions from '../TvmSuggestions/TvmSuggestions.vue';

import TvmFullFillmentText from '../TvmFullFillmentText/TvmFullFillmentText.vue';
import ChatAssistant from '../ChatAssistant/ChatAssistant.vue';
import AssistantIcon from '../ChatAssistant/AssistantIcon.vue';

import {
  chatWidgetAscCommEngagement,
  chatWidgetCtaInteraction,
  chatFlowEndEvent,
  fireChatWidgetOpen,
  assistantStartEvent,
  assistantEndEvent,
} from '../../../Events/TagManagerServices/TaggingEventForPages';
import SlideOutPanel from '../SlideOutPanel/SlideOutPanel.vue';
import TvmFlowNameIndicator from '@/js/components/shared/ChatWidget/TvmFlowNameIndicator/TvmFlowNameIndicator.vue';
import moment from 'moment';
import { DecodeHtml } from '@/js/services/DecodeHtml';
import { DetectUserOperatingSystem } from '@/js/services/GetUserAgentDetails';
import { LocalStorageService } from '@/js/components/shared/Services/LocalStorageService';
// import TvmHelpModal from '@/js/components/shared/ChatWidget/TvmHelpModal/TvmHelpModal.vue';
// import TvmPrivacyModal from '@/js/components/shared/ChatWidget/TvmPrivacyModal/TvmPrivacyModal.vue';
// import store from '../../../../store/store';
import TvmPreLoader from '../TvmPreLoader/TvmPreLoader.vue';
import {
  hideOnLoadModalPopups,
  htmlDecode,
  isIPAD,
} from '../../../../../js/services/CommonUtility';
// import { mapState } from 'vuex';
// import {
//   SET_CHAT_ACTIONS,
//   SET_OLD_TID,
// } from '../../../../store/StoreConstants/CommonSettingsConstants.js';
// Vue.component('language-change-icon', () =>
//   import('../../../components/LanguageChange/LanguageChange.vue')
// );

import TvmLoaderModal from '@/js/components/shared/ChatWidget/TvmLoaderModal/TvmLoaderModal.vue';
import TvmApiErrorRegenerate from '../TvmApiErrorRegenerate/TvmApiErrorRegenerate.vue';
import MenuIcon from '../ChatIcons/MenuIcon.vue';
import HoursIcon from '../ChatIcons/HoursIcon.vue';
import SignInIcon from '../ChatIcons/SignInIcon.vue';
import LangIcon from '../ChatIcons/LangIcon.vue';
import LanguageChange from '../../LanguageChange/LanguageChange.vue';
import { useChatSettingsStore } from '../../../store/ChatSettingsStore/ChatSettingsStore.js';
import { mapWritableState } from 'pinia';
export default {
  data() {
    return {
      textUsButtonsNavigation: false,
      showLandingLayout: false,
      showChatData: false,
      showTextData: false,
      isWaitingForResponse: false,
      isTransparentMenu: window.isTransparentMenu ? window.isTransparentMenu : false,
      disable: false,
      count: 0,
      dealerId: accountId.toString(),
      consumerId: window.userDetails.consumerid,
      apolloUserId: null,
      connectionId: null,
      connection: null,
      touserName: null,
      dealerLogo: window.logoImage,
      SessionId: '',
      latestResponse: {},
      conversationTypeId: 1,
      fromuser: 'user',
      touser: 'Virtual Assistant',
      isauthenticated: window.isauthenticated,
      fullfillmenttextList: [],
      chips: [],
      campaignId: window.campaignId,
      request: {},
      sessiontime: new Date().getTime(),
      avatarImg: 'https://bootdey.com/img/Content/avatar/avatar6.png',
      userInput: '',
      showwidget: false,
      ismobile: false,
      openservice: false,
      primarycolor: window.primaryColor,
      buttonColor: '',
      borderColor: '',
      textColor: '',
      isChatEndClicked: false,
      isResponseFromBot: false,
      widgetTitle: '',
      widgetName: '',
      showtext: window.isTextUsEnabled,
      showchat: window.isGoogleChatEnabled,
      disableChat: false,
      disableText: '',
      slickOptions: {
        infinite: false,
        rows: 3,
        slidesPerRow: 2,
        dots: false,
        // centerPadding: "20px",
        // variableWidth: true,
        adaptiveHeight: false,
        nextArrow: `<em class="primaryicon-right-arrow bgcolor-primary" style="cursor:pointer;right:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;"></em>`,
        prevArrow: `<em class="primaryicon-left-arrow bgcolor-primary" style="cursor:pointer;left:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;"></em>`,
      },
      textUsButtonColor: window.textUsButtonColor,
      textUsFontColor: window.textUsFontColor,
      KbbScrollTopPosition: '',
      fileAttachment: false,
      isWorkingHoursEnable: false,
      isAuthenticated: window.isauthenticated,
      isHoverMenu: false,
      isHoverHour: false,
      isHoverUser: false,
      isHoverLanguage: false,
      themePrimaryColor: window.primaryColor,
      componentNavigation: '',
      componentNavigationLabel: '',
      isNavigateTextForm: false,
      chatWidgetLayoutHeight: '',
      chatWidgetBodySec1Height: '',
      chatWidgetBodySec3Height: '',
      textUsCTAButtonLabel: window.textUsCTAButtonLabel,
      textUsMobileCTAButtonLabel: window.textUsMobileCTAButtonLabel,
      isTablet: window.istablet || isIPAD(),
      isChatOpenInModal: false,
      isShowTextUsForm: false,
      submitAction: '',
      submitFlow: '',
      responseType: '',
      headerOffsetHeight: null,
      slideOutData: {},
      currentSlideOutId: 0,
      flowIndicator: '',
      processNameIndicator: false,
      textLengthValidation: false,
      isScrollBottom: false,
      footerDisclaimer: '',
      chatWindowConnectionHub: '',
      uuid: '',
      oldCookieId: '',
      countCoupons: 1,
      mobile_header_offset_height: '',
      masterLayoutName: window.masterLayoutName,
      isMobileAddressBarVisible: false,
      previousHeight: window.previousHeight,
      chatFooterHeight: '',
      isEnableAssistantParamsDataModal: false,
      assitantModalCode: {},
      countTileComponent: 1,
      isHideOverly: false,
      selectedAutoCompletedValue: {},
      chatResponseDelayLoading: false,
      isTileNavigate: false,
      isTileNavigateHeight: 0,
      totalTileHeight: 0,
      disableBackgroundBlur: false,
      mutationObserver: null,
      disableSignIn: false,
      isSignInNavigationModal: false,
      tvmModeOfAction: '',
      isAcceptedTvmPrivacyPolicy: false,
      enablePrivacyPopUp: false,
      privacyText: '',
      isVdp: window.isVdp,
      isSignOut: false,
      isLoader: false,
      isApiError: null,
      isTranslationChange: false,
      enableTranslationIcon: false,
    };
  },
  computed: {
    ...mapWritableState(useChatSettingsStore, ['chatThemeSettings']),
  },
  props: {},
  components: {
    UserTextResponse,
    // 'full-fillment-text': fullfillmenttext,
    // 'black-book-trade-value': blackbooktradevalue,
    'textus-form': textusform,
    // Slick,
    'dealer-working-hours': DealerWorkingHours,
    // 'file-attach': FileAttach,
    'other-option-text-form': OtherOptionTextForm,
    'tvm-suggestions': TvmSuggestions,
    TvmFullFillmentText: TvmFullFillmentText,
    'chat-assistant': ChatAssistant,
    'assistant-icon': AssistantIcon,
    'slide-out-panel': SlideOutPanel,
    'tvm-flow-name-indicator': TvmFlowNameIndicator,
    // 'text-us-layout': textuslayout,
    // 'chat-new-layout': chatnewlayout,
    // 'tvm-help-modal': TvmHelpModal,
    'tvm-pre-loader': TvmPreLoader,
    // 'tvm-privacy-modal': TvmPrivacyModal,
    'tvm-loader-modal': TvmLoaderModal,
    'tvm-api-error-regenerate': TvmApiErrorRegenerate,
    'menu-icon': MenuIcon,
    'hours-icon': HoursIcon,
    'sign-in-icon': SignInIcon,
    'language-icon': LangIcon,
    'language-change-icon': LanguageChange,
  },
  watch: {
    fullfillmenttextList: {
      handler(newValue, oldValue) {
        setTimeout(() => {
          this.tvmSlideOutUpdateByIndex();
          this.flowIndicatorDetection();
        }, 1000);
      },
      deep: true,
    },
    userInput(val) {
      if (val && val.length === 256) {
        this.textLengthValidation = true;
      } else {
        this.textLengthValidation = false;
      }
    },
  },
  mounted() {
    this.uuid = this.generateUUID();
    if (this.showchat) {
      ChatWidgetService.getChatSessionCookie();
    }

    this.slickOptions.nextArrow = `<em class="primaryicon-right-arrow" style="cursor:pointer;right:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;background:${this.themePrimaryColor} !important;color:#fff !important;"></em>`;
    this.slickOptions.prevArrow = `<em class="primaryicon-left-arrow" style="cursor:pointer;left:0;top: 50%;transform: translateY(-50%);display: flex;justify-content: center;align-items: center;background:${this.themePrimaryColor} !important;color:#fff !important;"></em>`;
    let shiftDigitalEvent = ShiftDigitalContainer.getShiftDigitalObject();
    if (shiftDigitalEvent && typeof shiftDigitalEvent.chatImpressionEvent === 'function') {
      shiftDigitalEvent.chatImpressionEvent();
    }
    // this.$root.$on('textUs', () => {
    //   this.textUsButtonsNavigation = true;
    // });
    // this.$root.$on('close', () => {
    //   this.textUsButtonsNavigation = false;
    //   this.widgetTitle = 'Assistant';
    //   this.openchatwidget();
    // });

    window.addEventListener('textUs', () => {
      this.textUsButtonsNavigation = true;
    });
    window.addEventListener('close', () => {
      this.textUsButtonsNavigation = false;
      this.widgetTitle = 'Assistant';
      this.openchatwidget();
    });

    if (document.querySelector('#chat_widget_enabled')) {
      document.querySelector('#chat_button_enabled').style.display = 'none';
    }

    let _myVars = this;
    _myVars.setUserName();
    // if (this.showchat) {
    //   this.connectToChatWindowConnectionHub();
    // }
    if (window.isTextUsEnabled && window.isGoogleChatEnabled)
      this.widgetName = window.textUsCTAButtonLabel || 'Text or Chat';
    else if (window.isTextUsEnabled) this.widgetName = window.textUsCTAButtonLabel || 'Text Us';
    else if (window.isGoogleChatEnabled) this.widgetName = window.textUsCTAButtonLabel || 'Chat';
    else this.widgetName = '';
    this.widgetTitle = 'Assistant';
    let isChatWidgetOpened = ChatWidgetService.checkForWidgetSession();
    if (isChatWidgetOpened) {
      this.openchatwidget();
      if (sessionStorage.getItem('showLayout') === 'showChatLayout') {
        this.showLandingLayout = false;
        this.showChatData = true;
        this.chatWidgetHeight();
        this.widgetTitle = 'Assistant';
      }
      if (sessionStorage.getItem('showLayout') === 'showTextLayout') {
        this.showLandingLayout = false;
        this.showTextData = true;
        this.chatWidgetHeight();
        this.widgetTitle = 'Text Us';
      }
    }
    if (window.innerWidth < 500) {
      this.ismobile = true;
    }

    eventBus.$on('chatWidgetData', (response) => {
      this.submitAction = response?.responseData?.submitAction;
      this.submitFlow = response?.responseData?.submitFlow;
      this.responseType = response?.responseType;
      this.isWaitingForResponse = false;
      eventBus.$emit('disablebuttons', false);
      this.disableChat = false;
      this.disableText = '';
      this.isApiError = null;
      // store.commit(SET_OLD_TID, response?.responseData?.oldTid);
      // chat flow events triggering

      // console.log(response);
      if (response?.responseData?.endResponse?.isEndIntent) {
        let moduleDepartment = response?.responseData?.endResponse?.moduleDepartment;
        let moduleCode = response?.responseData?.endResponse?.moduleCode;
        try {
          chatFlowEndEvent(moduleDepartment, moduleCode);
        } catch (error) {
          console.log(error);
        }
      }

      if (response?.responseData?.events?.length) {
        try {
          let tagEvents = response?.responseData?.events;
          tagEvents.forEach((eachTagEvent) => {
            if (eachTagEvent?.eventType?.toLowerCase() === 'start') {
              assistantStartEvent(eachTagEvent);
              console.log(eachTagEvent, 'dynamic startResponse');
            }
            if (eachTagEvent?.eventType?.toLowerCase() === 'end') {
              eachTagEvent.leadIdentifier = response?.leadIdentifier;
              assistantEndEvent(eachTagEvent);
              console.log(eachTagEvent, 'dynamic endResponse');
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
      // end chat flow events triggering
      // var oldsid = sessionStorage.getItem('oldsid');
      // if (oldsid) {
      //   let cookieId = ChatWidgetService.getChatSessionCookie();
      //   ChatWidgetService.loadChatWidgetDataAfterLogin(cookieId, function (callbackResponse) {
      //     if (callbackResponse) {
      //       sessionStorage.removeItem('oldsid');
      //       var postReloadData = sessionStorage.getItem('postreloadintent');
      //       if (postReloadData) {
      //         sessionStorage.removeItem('postreloadintent');
      //       }
      //     }
      //   });
      // } else {
      if (response?.responseData?.botResponse?.moreActions) {
        this.disableChat = response?.responseData?.botResponse?.moreActions?.disableChat;
        this.disableText = response?.responseData?.botResponse?.moreActions?.disableText;
      }
      _myVars.loadResponse(response);
      //}
    });

    eventBus.$on('chatResponseDelayLoading', (response) => {
      this.chatResponseDelayLoading = response;
    });

    //To disable send message text box and send button
    // eventBus.$on("disablechat", response => {
    //   _myVars.disableChat = true;
    //   console.log(response);
    // });

    eventBus.$on('getChatWidgetData', (response) => {
      this.loadChatData(response.data);
    });

    eventBus.$on('selectedPayloadButtonData', (response) => {
      if (response?.EventLabel) {
        this.flowIndicator = response?.EventLabel;
        ChatWidgetService.setFlowIndicator(this.flowIndicator);
      }
    });

    // this.$root.$on('navigateTextForm', (payload) => {
    //   if (payload) {
    //     this.componentNavigate('other-option-text-form');
    //     this.isNavigateTextForm = payload;
    //   }
    // });
    window.addEventListener('navigateTextForm', (payload) => {
      if (payload) {
        this.componentNavigate('other-option-text-form');
        this.isNavigateTextForm = payload;
      }
    });
    // this.$root.$on('OpenSlideInfo', (data) => {
    //   if (data) {
    //     let val = data.split('-')[1];
    //     if (
    //       this.fullfillmenttextList?.[val]?.responseData?.botResponse?.slideOut?.components?.length
    //     ) {
    //       this.slideOutData = this.fullfillmenttextList[val].responseData?.botResponse?.slideOut;
    //     }
    //   }
    // });
    // for mobile devices
    if ((window.ismobile || this.isTablet) && document.querySelector('#chatTextModal')) {
      this.openchatwidget();
      this.isChatOpenInModal = true;
    }
    // this.$root.$on('isWaitingForResponseData', (data) => {
    //   if (data) {
    //     this.isWaitingForResponse = true;
    //     this.scrollBottom();
    //   }
    // });
    this.chatWidgetButtonColors();
    this.assistantWidgetMessage();
    this.tcpDisclaimer();
    window.addEventListener('resize', this.checkAddressBarVisibility);
    this.$nextTick(() => {
      this.accessBeZindexAdjust(2000);
    });
    // if (
    //   store?.state?.commonSettings?.tvmHelpModalSettings?.assistant &&
    //   (window.ismobile || this.isTablet)
    // ) {
    //   let tvmHelpModalSettings = store.state.commonSettings.tvmHelpModalSettings;
    //   this.modalNavigate(tvmHelpModalSettings);
    // }
    // if (
    //   store?.state?.commonSettings?.tvmNavigateTileSettings?.assistant &&
    //   (window.ismobile || this.isTablet)
    // ) {
    //   let tvmNavigateTileSettings = store.state.commonSettings.tvmNavigateTileSettings;
    //   this.assistantParamsDataModal(tvmNavigateTileSettings);
    // }
    if (this.showchat) {
      this.observeModalPopups();
    }
    window.addEventListener('assistantOpenWithCTA', this.handleCustomClickEvent);
    window.addEventListener('tvmSignInNavigationLoader', this.signInNavigationLoader);
    // this.$root.$on('hideChatWidget', (data) => {
    //   this.hideChatWidgetByOverlay(false);
    // });
    window.addEventListener('tvmLoader', this.tvmLoaderEmitter);
    window.addEventListener('loadErrorComponent', this.loadErrorComponentEmitter);
    window.addEventListener('tvmLanguageLoader', this.tvmLanguageLoader);
  },
  created() {
    window.addEventListener('storage', (event) => {
      if (event.key === 'notify_duplicate_assistant') {
        if (this.uuid !== event.newValue) {
          this.hidewidget();
        }
      }
    });

    if (this.showchat) {
      this.queryParamHasOpenChat();
    }

    //For single options clicks
    // eventBus.$on("onoptionclick", response => {
    //   this.message(response.text, response.eventname);
    // });
    //For service selections and coupon selections
    // eventBus.$on("onselections", response => {
    //   console.log(response, "responseresponseresponseresponseresponseresponse")
    //   this.message(
    //     response.text,
    //     response.eventname,
    //     null,
    //     response.specialObject
    //   );
    // });
  },
  beforeDestroy() {
    eventBus.$off('chatWidgetData');
    eventBus.$off('getChatWidgetData');
    eventBus.$off('selectedPayloadButtonData');
    this.$root.$off('hideChatWidget');

    this.KbbScrollTopPosition = '';
    // this.chatWindowConnectionHub.stop();
    window.removeEventListener('resize', this.checkAddressBarVisibility);
    this.disconnectObserver();
    window.removeEventListener('assistantOpenWithCTA', this.handleCustomClickEvent);
    window.removeEventListener('tvmSignInNavigationLoader', this.signInNavigationLoader);
    window.removeEventListener('storage', this.storageEventListner);
    window.removeEventListener('tvmLoader', this.tvmLoaderEmitter);
    window.removeEventListener('loadErrorComponent', this.loadErrorComponentEmitter);
    window.removeEventListener('tvmLanguageLoader', this.tvmLanguageLoader);
  },
  methods: {
    hideChatWidgetByOverlay(sdEventsIgnore) {
      if (!window.ismobile && !this.ismobile) {
        this.hidewidget(sdEventsIgnore);
      }
      if (window.integrateWithText) {
        try {
          if (document.querySelector('.cn-b13-container .cn-b13-x-chat')) {
            document.querySelector('.cn-b13-container .cn-b13-x-chat').click();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    disableWebBackgroundBlur(val) {
      this.disableBackgroundBlur = val;
    },
    openAssistant(val) {
      if (!val) {
        ChatWidgetService.setAssistantButtonSession();
        this.openchatwidget();
      }
    },
    openService(val) {
      try {
        let value = val;
        let tilesAvailable = JSON.parse(sessionStorage.getItem('tilesAvailable'));
        this.openchatwidget();
        if (value?.name === 'text') {
          if (!tilesAvailable) {
            this.showTextLayout();
          } else {
            this.showTextUsForm();
          }
        } else {
          setTimeout(() => {
            if (value?.flowName) {
              this.message(
                value.flowName,
                value.eventname,
                null,
                null,
                value.submitFlow,
                value.submitAction
              );
            }
          }, 2700);
        }
      } catch (error) {
        console.error('An error occurred in open flowName method', error);
      }
    },
    chatAlignmentPosition() {
      return window.textWidgetPosition && window.textWidgetPosition.toLowerCase() === 'bottom left'
        ? 'position-left-alignment'
        : 'position-right-alignment';
    },
    navigateInventory(value) {
      this.message(value.text, value.eventname, null, null);
    },
    OfflineAgentText(value) {
      this.message(value.text, null, null, null);
    },
    KbbOffsetValue(val) {
      this.KbbScrollTopPosition = val;
    },
    blackBookOffset(val) {
      this.KbbScrollTopPosition = val;
    },
    setUserName() {
      let _myVars = this;
      if (window.userDetails && (window.userDetails.firstname || window.userDetails.lastname)) {
        _myVars.fromuser = `${window.userDetails.firstname} ${window.userDetails.lastname}`;
      }

      if (window.clientName) {
        this.touser = htmlDecode(window.clientName);
      }
    },
    disablechat(value) {
      this.disableChat = value.DisableChat;
      this.disableText = value.DisableText;
    },
    reloadDisableChat(val) {
      if (!val && this.responseType === 'gdf') {
        this.disableChat = false;
        this.disableText = '';
      }
    },
    onoptionclick(value) {
      this.message(value.text, value.eventname);
    },
    onselections(value) {
      this.message(value.text, value.eventname, null, value.specialObject);
    },
    onoffersselections(value) {
      this.message(value.text, value.eventname, null, value.specialObject);
    },
    tvmSuggestions(value) {
      this.message(
        value.UserResponse,
        value.Event,
        value.EventLabel,
        value.specialObject,
        value.SubmitFlow,
        value.SubmitAction
      );
    },
    loadResponse(response) {
      this.isResponseFromBot = true;
      if (response?.responseData) {
        response.responseData.isDirect = true;
        document.querySelector('#chat__bodyview').style.scrollBehavior = 'smooth';
      }
      if (response?.responseData?.botResponse?.component?.componentName === 'tvm-servicecoupons') {
        let count = this.countCoupons++;
        response.responseData.countCoupons = count;
      }

      this.fullfillmenttextList.push(response);
      // remove repeated main-menu tiles
      this.removeRepeatedMainMenuTiles(response, 'isDirect');
      this.latestResponse = response;
      this.chatSdaMessageEventHandler(response?.fullfillmentText, 'agent');
      if (response?.responseData?.botResponse?.slideOut?.components?.length) {
        this.slideOutData = response?.responseData?.botResponse?.slideOut;
      }
      this.filterContextData();
      this.scrollAndFocus();
    },
    loadChatData(response) {
      this.fullfillmenttextList = [];
      let OldTid = '';
      if (response != null && response && response.length > 0) {
        response.forEach((item) => this.BindChatData(item, response?.length));
        this.removeRepeatedMainMenuTiles(this.fullfillmenttextList, 'isReload');
        OldTid = this.fullfillmenttextList.at(-1)?.request?.OldTid || '';
        if (OldTid) {
          // store.commit(SET_OLD_TID, OldTid);
        }
      }
    },
    backbuttonClick() {
      this.showLandingLayout = true;
      this.showChatData = false;
      this.showTextData = false;
      if (this.showLandingLayout) {
        this.widgetTitle = 'Welcome';
        this.chatWidgetHeight();
      }
    },
    showTextLayout() {
      ChatWidgetService.showLayout('showTextLayout');
      this.widgetTitle = 'Text Us';
      this.showLandingLayout = false;
      this.showChatData = false;
      this.showTextData = true;
      this.scrollAndFocus();
      this.chatWidgetHeight();
    },
    showChatLayout() {
      ChatWidgetService.showLayout('showChatLayout');
      this.widgetTitle = window.isGoogleChatEnabled ? 'Assistant' : '';
      this.showLandingLayout = false;
      this.showTextData = false;
      this.showChatData = true;
      this.scrollAndFocus();
      this.chatWidgetHeight();
    },
    backTextbtnclick() {
      this.$refs.innerTextusForm.backbtnclick();
    },
    backToTextBtns() {
      this.backbuttonClick();
      this.backTextbtnclick();
    },
    scrollAndFocus() {
      setTimeout(() => {
        this.scrollBottom();
        this.KbbScrollTopPosition = '';
        if (document.getElementById('MessageBox')) {
          document.getElementById('MessageBox').focus();
          this.isScrollBottom = false;
        }
      }, 500);
    },
    connectToSignalR() {},
    connectToAndInvokeSignalR() {
      this.connection
        .start()
        .then(function () {
          this.connection
            .invoke('GetConnectionId', this.SessionId, this.dealerId)
            .then(function (connectionId) {
              this.connectionId = connectionId;
              // Send the connectionId to controller
            })
            .catch((err) => console.error(err.toString()));
        })
        .catch((err, x) => {
          console.log('Failed to connect with hub', err);
          return Promise.resolve(
            setTimeout(
              () => this.connectToSignalR(),
              //.then(resolve).catch(reject)
              5000
            )
          );
        });
    },

    closeConnectionSR() {
      if (!this.connection) return;
      this.connection = null;
    },
    resetChat(isLoad) {
      sessionStorage.removeItem('chatwidget');
      this.count = 0;
      this.fullfillmenttextList = [];
      this.latestResponse = {};
      this.SessionId = '';
      this.conversationTypeId = 1;
      this.chips = [];
      this.request = {};
      this.disable = false;
      this.sessiontime = new Date().getTime();
      this.submitAction = '';
      this.submitFlow = '';
      ChatWidgetService.setSessionKey(this.sessiontime);
      if (isLoad) {
        this.message(null, 'chat_start');
      }
    },
    hidewidget(sdEventsIgnore = true) {
      if (sdEventsIgnore) {
        let shiftDigitalEvent = ShiftDigitalContainer.getShiftDigitalObject();
        if (shiftDigitalEvent && typeof shiftDigitalEvent.chatClosedsdEvent === 'function') {
          shiftDigitalEvent.chatClosedsdEvent();
        }
      }

      ChatWidgetService.closeShowLayout();
      this.showChatData = false;
      this.showLandingLayout = false;
      this.showTextData = false;
      this.showwidget = false;
      this.isShowTextUsForm = false;
      if (!this.showLandingLayout) {
        this.widgetTitle = 'Assistant';
      }
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
      ChatWidgetService.closeChatWidgetSession();
      this.isEnableAssistantParamsDataModal = false;
    },

    filterContextData() {
      let url = new URL(window.location.href);
      let returnUrl = '';
      if (url.pathname.includes('logout')) {
        returnUrl = `?openchatwidget=true`;
      } else if (url.search) {
        returnUrl = `?openchatwidget=true&returnurl=${url.pathname}${url.search}`;
      } else {
        returnUrl = `?openchatwidget=true&returnurl=${url.pathname}`;
      }

      this.setUserName();
      if (
        this.latestResponse?.diagnosticInfo?.fields?.end_conversation?.boolValue &&
        this.isChatEndClicked
      ) {
        this.showwidget = false;

        //disable
        //conversation ended show message
      }
      if (this.conversationTypeId === 1 && this.latestResponse?.outputContexts?.length) {
        //this.latestResponse.forEach((item) => {
        //this.latestResponse.outputContexts.filter((item) => {
        for (let elementItem of this.latestResponse.outputContexts) {
          let item = elementItem;

          if (item.contextName.contextId === 'dealer-context' && item.parameters) {
            if (
              item.parameters.fields['agent-handoff'] &&
              item.parameters.fields['agent-handoff'].stringValue === 'true'
            ) {
              this.conversationTypeId = 2;
              this.callSignalMethods();
            }
            if (
              (this.consumerId == null || this.consumerId === '') &&
              item.parameters.fields['action'] &&
              item.parameters.fields['action'].stringValue === 'reload' &&
              item.parameters.fields['upin'] &&
              item.parameters.fields['upin'].stringValue !== ''
            ) {
              let postReloadEvent = item.parameters.fields['postreloadintent'].stringValue;
              if (postReloadEvent) {
                sessionStorage.setItem('postreloadintent', postReloadEvent);
              }

              sessionStorage.setItem('oldsid', this.SessionId);

              window.location.href =
                '/landing/autologin/' +
                item.parameters.fields['upin'].stringValue +
                returnUrl.replace('open=false', 'open=true');
            } else if (
              item.parameters.fields['action'] &&
              item.parameters.fields['action'].stringValue === 'resetchat'
            ) {
              this.resetChat(true);
              break;
            } else {
              // No action needed for other cases.
            }
            if (
              this.consumerId == null &&
              item.parameters.fields['cid'] &&
              item.parameters.fields['cid'].stringValue !== ''
            ) {
              this.consumerId = parseInt(item.parameters.fields['cid'].stringValue);
            }
          }
        }
        this.chips = [];
        if (this.latestResponse?.response) {
          this.latestResponse.response.filter((item) => {
            if (item?.suggestions?.suggestions_ != null) {
              this.chips = this.chips.concat(item.suggestions.suggestions_);
              if (this.chips.length > 5) {
                const longestButtonLength = Math.max(
                  ...this.chips.map((item) => item.title.length)
                );
                if (longestButtonLength === 4) {
                  this.reInitSlick();
                  this.slickOptions.slidesPerRow = 4;
                  this.slickOptions.rows = 3;
                } else if (longestButtonLength > 12) {
                  this.reInitSlick();
                  this.slickOptions.slidesPerRow = 2;
                  this.slickOptions.rows = 3;
                } else {
                  this.reInitSlick();
                  this.slickOptions.slidesPerRow = 3;
                  this.slickOptions.rows = 3;
                }
              }
            }
          });
        }
      }
    },
    // connectToChatWindowConnectionHub() {
    //   try {
    //     let _myVars = this;
    //     this.chatWindowConnectionHub = new signalR.HubConnectionBuilder()
    //       .withUrl(`${window.chatSignalRUrl}/operate`, {
    //         skipNegotiation: true,
    //         transport: signalR.HttpTransportType.WebSockets,
    //       })
    //       .withAutomaticReconnect()
    //       .configureLogging(signalR.LogLevel.None)
    //       .build();

    //     this.chatWindowConnectionHub
    //       .start()
    //       .then(() => {
    //         _myVars.joinNotificationGroup();
    //       })
    //       .catch((err) => {
    //         console.log(err, 'Error on starting connection.');
    //       });
    //   } catch (error) {
    //     console.log(error, 'error in connectToChatWindowConnectionHub');
    //   }
    // },
    // joinNotificationGroup() {
    //   try {
    //     let _myVars = this;
    //     let cookieId = ChatWidgetService.getChatSessionCookie();

    //     _myVars.chatWindowConnectionHub.invoke('JoinGroup', cookieId);

    //     _myVars.chatWindowConnectionHub.on('UserAdded', function (response) {});

    //     _myVars.chatWindowConnectionHub.on(
    //       'ChatWindowActivity',
    //       function (uniqueId, accountId, campaignId, data) {
    //         if (_myVars.uuid !== uniqueId) {
    //           _myVars.hidewidget();
    //         }
    //       }
    //     );

    //     _myVars.chatWindowConnectionHub.onreconnected(() => {
    //       _myVars.chatWindowConnectionHub.invoke('JoinGroup', cookieId);
    //     });
    //   } catch (error) {
    //     console.log(error, 'error in joinNotificationGroup');
    //   }
    // },
    generateUUID() {
      let dt = new Date().getTime();
      let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      });
      return uuid;
    },
    callSignalMethods() {
      let sessid = this.SessionId.split('_')[1];
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(window.chatSignalRUrl + '/chathub?username=' + sessid)
        .withAutomaticReconnect()
        .build();
      this.connection.start().then(() =>
        this.connection
          .invoke('GetConnectionId', this.SessionId, this.dealerId)
          .then((response) => {
            this.connectionId = response;
            let consumerid = this.consumerId;
            if (consumerid == null) {
              consumerid = '';
            }
            let that = this;
            this.connection
              .invoke(
                'GetAllMyConversations',
                this.fromuser,
                this.SessionId,
                this.dealerId,
                this.connectionId,
                consumerid,
                ''
              )
              .then((response) => {
                that.conversationTypeId = 3;
              })
              .catch((err) => {
                console.error(err);
              });
          })
      );
      let that = this;
      this.connection.on(
        'ReceiveMessage',
        function (
          Fromuser,
          toUser,
          message,
          sessionId,
          FromUerName,
          ToUserName,
          consumerId,
          apolloAgentId
        ) {
          that.touser = Fromuser;
          that.apolloUserId = apolloAgentId;
          //for binding names
          // that.request.ToUser = Fromuser;
          //for binding names
          that.touserName = FromUerName;
          that.conversationTypeId = 3;
          that.fullfillmenttextList.push(
            that.getSignalRMessageResponse(message, null, null, Fromuser, toUser)
          );
          this.scrollAndFocus();
        }
      );
    },
    sendMessage() {
      if (this.userInput?.trim()) {
        this.chatSdaMessageEventHandler(this.userInput?.trim(), 'user');
        if (this.userInput.trim().length > 256) return false;
        if (Object.keys(this.selectedAutoCompletedValue)?.length !== 0) {
          let specialObject = { TvmComponentRequest: this.selectedAutoCompletedValue };
          // store.commit(SET_CHAT_ACTIONS, { ModeOfAction: 'tvm-assistant-auto-complete-address' });
          this.message(
            this.userInput,
            null,
            null,
            specialObject,
            this.SubmitFlow,
            this.SubmitAction
          );
        } else {
          // store.commit(SET_CHAT_ACTIONS, { ModeOfAction: 'tvm-assistant-user-input' });
          this.message(this.userInput, null, null);
        }
      }
    },
    startOver() {
      this.message('start over', null, null);
    },
    startMainMenu(elementEvent) {
      // store.commit(SET_CHAT_ACTIONS, { ModeOfAction: 'tvm-assistant-start-main-menu' });
      this.submitFlow = '';
      this.submitAction = '';
      this.message('main menu', 'main_menu');
      chatWidgetCtaInteraction({ event: elementEvent, element: 'Menu' });
    },
    getSignalRMessageResponse(stext, event, eventtext, Fromuser, toUser) {
      const date = new Date();

      let typeThreeRequest = {
        outputContexts: [],
        request: {
          ...this.request,
          UserResponse: '',
          EventLabel: '',
          ToUser: Fromuser,
          FromUser: toUser,
        },
        requestTime: ChatWidgetService.formatAMPM(date),
        responseTime: ChatWidgetService.formatAMPM(date),
        response: [
          {
            text: {
              text_: [stext],
            },
          },
        ],
      };
      return typeThreeRequest;
    },

    getConversionTypeThreeResponse(text, event, eventtext) {
      const date = new Date();
      this.request = {
        SessionId: this.SessionId,
        UserResponse: text,
        Event: event,
        FromUser: this.fromuser,
        ToUser: this.touser,
      };
      let typeThreeRequest = {
        outputContexts: [],
        request: this.request,
        requestTime: ChatWidgetService.formatAMPM(date),
        responseTime: ChatWidgetService.formatAMPM(date),
        response: [],
      };
      return typeThreeRequest;
    },
    message(text, event, eventtext, specialobject, submitFlow, submitAction) {
      this.setUserName();
      if (document.getElementById('MessageBox')) {
        document.getElementById('MessageBox').focus();
      }
      // if (this.SessionId == null || this.SessionId === '') {
      //   this.SessionId = ChatWidgetService.getSessionId(this.sessiontime);
      // }
      // sessionStorage.setItem(
      //   'chatwidget',
      //   JSON.stringify({
      //     sessionId: this.SessionId,
      //     sentTimeOut: new Date().toString(),
      //   })
      // );
      ChatWidgetService.getChatSessionCookie();
      if (this.conversationTypeId === 1) {
        if (this.consumerId === '') {
          this.consumerId = null;
        }
        if (text && text.toLowerCase().trim().replace(' ', '') === 'startover') {
          this.resetChat(true);
        } else {
          let oldsid = sessionStorage.getItem('oldsid');
          this.request = {
            // SessionId: this.SessionId,
            UserResponse: text,
            Event: event,
            FromUser: this.fromuser,
            ToUser: this.touser,
            ConsumerId: this.consumerId,
            OldSid: oldsid || '',
            SubmitFlow: submitFlow || this.submitFlow || '',
            SubmitAction: submitAction || this.submitAction || '',
          };

          if (specialobject) {
            this.request.SpecialObject = specialobject;
          }

          this.isWaitingForResponse = true;
          ChatWidgetService.chatWidgetApi(this.request);
        }
      } else if (this.conversationTypeId === 2) {
        if (text && text.toLowerCase().trim().replace(' ', '') === 'startover') {
          this.resetChat(true);
        } else {
          //this.connection.invoke(
          //    'SendToUserGroup',
          //    this.fromuser,
          //    text,
          //    this.dealerId,
          //    this.SessionId,
          //    '',
          //    ''
          let consumerid = this.consumerId;
          if (consumerid === null) {
            consumerid = '';
          }
          let that = this;
          this.connection
            .invoke(
              'GetAllMyConversations',
              this.fromuser,
              this.SessionId,
              this.dealerId,
              this.connectionId,
              consumerid,
              text,
              window.tid
            )
            .then((response) => {
              that.conversationTypeId = 3;
            })
            .catch((err) => {
              console.error(err);
            });

          //);
        }
      } else if (this.conversationTypeId === 3) {
        if (text && text.toLowerCase().trim().replace(' ', '') === 'startover') {
          this.resetChat(true);
        } else {
          //string Fromuser, string toUser, string message, string sessionId, string FromUerName, string ToUserName
          //conversion Type 3 code
          this.fullfillmenttextList.push(
            this.getConversionTypeThreeResponse(text, event, eventtext)
          );
          //conversion Type 3 ends
          let consumerid = this.consumerId;
          if (consumerid == null) {
            consumerid = '';
          }
          this.connection
            .invoke(
              'SendMessage',
              this.fromuser,
              this.touser,
              text,
              this.SessionId,
              this.connectionId,
              this.touserName,
              this.dealerId,
              '',
              consumerid,
              this.apolloUserId,
              window.tid
            )
            .then((response) => {
              this.scrollAndFocus();
            })
            .catch((err) => {
              console.error(err);
            });
        }
      } else {
        // No action needed for other cases.
      }
      this.userInput = '';
      this.scrollAndFocus();
      window.activeTileNavigateIndex = '';
    },

    BindChatData(item, dataLength) {
      let userreq = {
        SessionId: this.SessionId,
        Event: null,
        FromUser: item.fromUser,
        ToUser: item.toUser,
        ConsumerId: this.consumerId,
        OldTid: item.trackingId || '',
      };
      if (item.conversationTypeId === 1) {
        let data = JSON.parse(item.responseJsonString);
        userreq.UserResponse = item.reqMessage;

        let diagnosticInfo = {};
        if (data?.botResponse?.queryResult?.diagnosticInfo) {
          diagnosticInfo = data?.botResponse?.queryResult.diagnosticInfo;
        }

        let fullfillment = {
          request: userreq,
          fullfillmentText: data ? data?.fulfillmentText : '',
          response:
            data?.botResponse && data?.responseType !== 'tvm'
              ? data?.botResponse?.queryResult?.fulfillmentMessages
              : data?.botResponse || '',
          outputContexts: data ? data?.botResponse?.queryResult?.outputContexts : '',
          diagnosticInfo: diagnosticInfo,
          requestTime: this.convertUTCtoLocal(item.dateCreatedUtc),
          responseTime: this.convertUTCtoLocal(item.dateCreatedUtc),
          responseType: data ? data?.responseType : '',
          responseData: data,
          id: item.id,
          feedbacks: item.feedbacks || '',
        };
        if (data?.botResponse?.component?.componentName === 'tvm-servicecoupons') {
          let count = this.countCoupons++;
          fullfillment.responseData.countCoupons = count;
        }

        this.fullfillmenttextList.push(fullfillment);

        if (data?.botResponse?.slideOut?.components?.length) {
          this.slideOutData = data?.botResponse?.slideOut;
          this.tvmSlideOutUpdateByIndex();
          this.flowIndicatorDetection();
        }
        this.latestResponse = fullfillment;
        this.filterContextData();
        this.scrollAndFocus();
      } else if (
        item.conversationTypeId === 3 &&
        item.reqMessage != null &&
        item.reqMessage !== ''
      ) {
        userreq.UserResponse = item.reqMessage;
        let typeThreeRequest = {
          outputContexts: [],
          request: userreq,
          requestTime: ChatWidgetService.formatAMPM(new Date(item.sentDate)),
          responseTime: ChatWidgetService.formatAMPM(new Date(item.recivedDate)),
          response: [],
        };
        this.fullfillmenttextList.push(typeThreeRequest);
        this.scrollAndFocus();
        /* this.fullfillmenttextList.push(this.getConversionTypeThreeResponse(text, event, eventtext)*/
      } else if (
        item.conversationTypeId === 3 &&
        item.resMessage != null &&
        item.resMessage !== ''
      ) {
        let typeThreeRequest = {
          outputContexts: [],
          request: {
            request: userreq,
            UserResponse: '',
            EventLabel: '',
            ToUser: Fromuser,
            FromUser: toUser,
          },
          requestTime: ChatWidgetService.formatAMPM(new Date(item.sentDate)),
          responseTime: ChatWidgetService.formatAMPM(new Date(item.recivedDate)),
          response: [
            {
              text: {
                text_: [item.resMessage],
              },
            },
          ],
        };
        this.fullfillmenttextList.push(typeThreeRequest);
        this.scrollAndFocus();
      } else {
        // No action needed for other cases.
      }
      if (dataLength === this.fullfillmenttextList?.length && item?.responseJsonString) {
        let disableResponseData = JSON.parse(item?.responseJsonString);
        let disableChatObj = disableResponseData?.botResponse?.moreActions;
        console.log(disableResponseData);
        if (disableResponseData && disableChatObj) {
          this.disableChat = disableChatObj.disableChat;
          this.disableText = disableChatObj.disableText;
          console.log(disableResponseData);
        }
        if (disableResponseData !== null && disableResponseData) {
          this.submitAction = disableResponseData?.submitAction
            ? disableResponseData.submitAction
            : '';
          this.submitFlow = disableResponseData?.submitFlow ? disableResponseData.submitFlow : '';
          this.responseType = disableResponseData?.responseType;
        }
      }
    },
    scrollBottom() {
      let container = this.$el.querySelector('#chat__bodyview');
      this.isScrollBottom = false;
      if (container) {
        if (this.KbbScrollTopPosition) {
          container.scrollTo(0, this.KbbScrollTopPosition - 135);
        } else if (window.activeTileNavigateIndex && this.isTileNavigate) {
          setTimeout(() => {
            container.scrollTo(
              0,
              document.querySelector(`#tileHeight-${window.activeTileNavigateIndex}`)?.offsetTop
            );
          }, 300);
        } else {
          container.scrollTop = container.scrollHeight;
        }
      }
    },
    getDifferenceInMinutes(date1, date2) {
      return Math.abs(date2 - date1) / (1000 * 60);
    },
    openchatwidget(event) {
      this.countCoupons = 1;
      this.fullfillmenttextList = [];
      let shiftDigitalEvent = ShiftDigitalContainer.getShiftDigitalObject();
      if (shiftDigitalEvent && typeof shiftDigitalEvent.chatClickEvent === 'function') {
        shiftDigitalEvent.chatClickEvent();
      }
      if (this.showchat) {
        let eventModel = {
          ...Events.consumerEventModel,
          consumerEventId: Events.eventsConstants.openAssistant,
        };
        Events.logConsumerEvent(eventModel);
      }
      this.scrollAndFocus();
      this.textUsButtonsNavigation = false;
      this.showChatData = false;
      this.showTextData = false;
      if (window.isTextUsEnabled && window.isGoogleChatEnabled) {
        let tilesAvailable = JSON.parse(sessionStorage.getItem('tilesAvailable'));
        if (tilesAvailable) {
          this.showTextData = false;
          this.showChatData = true;
          ChatWidgetService.showLayout('showChatLayout');
          this.widgetTitle = 'Assistant';
        } else {
          this.showTextData = true;
          this.showChatData = false;
          ChatWidgetService.showLayout('showTextLayout');
          this.widgetTitle = 'Text Us';
        }
      } else if (window.isTextUsEnabled && !window.isGoogleChatEnabled) {
        this.showTextData = true;
        this.showChatData = false;
        ChatWidgetService.showLayout('showTextLayout');
        this.widgetTitle = 'Text Us';
      } else if (window.isGoogleChatEnabled && !window.isTextUsEnabled) {
        let tilesAvailable = JSON.parse(sessionStorage.getItem('tilesAvailable'));
        if (!tilesAvailable) {
          this.showChatData = true;
          this.widgetTitle = 'Assistant';
          this.showTextData = false;
          ChatWidgetService.showLayout('showChatLayout');
          this.componentNavigate('other-option-text-form');
        } else {
          this.showChatData = true;
          this.widgetTitle = 'Assistant';
          this.showTextData = false;
          ChatWidgetService.showLayout('showChatLayout');
        }
      } else {
        // No action needed for other cases.
      }
      ChatWidgetService.setChatWidgetSession();
      this.chatWidgetHeight();

      if (this.showchat) {
        if (this.isauthenticated) {
          this.isSignInNavigationModal = false;
        }
        let cookieId = ChatWidgetService.getChatSessionCookie();
        let oldsid = sessionStorage.getItem('oldsid');
        if (oldsid && cookieId && oldsid !== cookieId) {
          this.loadChatWithSessionData(cookieId, oldsid);
        } else {
          this.loadChatWithSessionData(cookieId, null);
        }

        if (
          ChatWidgetService.getCookieValueByName('ChatAssistantPrivacyPolicyCookie') !== undefined
        ) {
          this.isAcceptedTvmPrivacyPolicy = true;
        }
      }

      this.count++;
      this.showwidget = true;
      if (!this.ismobile) {
        this.showwidget = true;
      }
      chatWidgetCtaInteraction({
        event: event,
        element: this.widgetName,
        isTextChat: !window.isGoogleChatEnabled,
      });
      chatWidgetAscCommEngagement(
        { ...(event || {}), elementTitle: this.widgetName },
        {},
        window.isGoogleChatEnabled,
        'start'
      );
      if (DetectUserOperatingSystem() === 'Mac/iOS') {
        document.querySelector('html').style.setProperty('overflow', 'hidden', 'important');
      }
      this.accessBeZindexAdjust(1000);

      if (window.masterLayoutName === '_Website_Mazda_OEM') {
        document.querySelector('html').style.setProperty('overflow', 'hidden', 'important');
      }
      if (!this.showchat) {
        fireChatWidgetOpen();
      }

      // setTimeout(() => {
      //   if (this.showchat) {
      //     let cookieId = ChatWidgetService.getChatSessionCookie();
      //     this.oldCookieId = cookieId;

      //     if (this.chatWindowConnectionHub) {
      //       this.chatWindowConnectionHub.invoke(
      //         'SendToUserGroup',
      //         cookieId,
      //         this.uuid,
      //         window.accountId,
      //         window.campaignId,
      //         'send to user group'
      //       );
      //     } else {
      //       console.log(this.chatWindowConnectionHub, 'On SendToUserGroup Invoke');
      //     }
      //   }
      // }, 1000);

      localStorage.setItem('notify_duplicate_assistant', this.uuid);
      setTimeout(() => {
        this.textandAssitantSettings();
      }, 600);
    },
    reInitSlick() {
      if (this.$refs.slick) {
        this.$refs.slick.destroy();
        this.$nextTick(() => {
          this.$refs.slick.create();
        });
      }
    },
    textUsButtonsNavigationClose() {
      if (this.$refs.innerTextusForm.isOtherTextOnlyActive && this.showchat) {
        this.closeTextUsForm();
        this.textUsButtonsNavigation = false;
      } else {
        this.$root.$emit('textUsButtonsNavigation');
        this.textUsButtonsNavigation = false;
      }
    },
    fileAttach() {
      this.fileAttachment = !this.fileAttachment;
    },
    LogoutUser(elementEvent) {
      if (this.isAuthenticated === 'True') {
        chatWidgetCtaInteraction({ event: elementEvent, element: 'Sign Out' });
        window.location.href = `${window.baseUrl}/logout/`;
        sessionStorage.removeItem('activeFlowIndicatorCode');
        this.isSignOut = true;
      } else {
        chatWidgetCtaInteraction({ event: elementEvent, element: 'Sign In' });
        this.isWorkingHoursEnable = false;
        this.submitFlow = '';
        this.submitAction = 'tvm_common_signinprompt';
        this.message('sign in');
      }
    },
    componentNavigate(val, elementEvent) {
      switch (val) {
        case 'Hours':
          if (this.isNavigateTextForm) {
            this.isWorkingHoursEnable = true;
            this.componentNavigation = 'dealer-working-hours';
            this.componentNavigationLabel = 'Hours';
            this.isNavigateTextForm = false;
          } else {
            this.isWorkingHoursEnable = !this.isWorkingHoursEnable;
            this.componentNavigation = 'dealer-working-hours';
            this.componentNavigationLabel = 'Hours';
          }
          break;
        case 'other-option-text-form':
          this.isWorkingHoursEnable = true;
          this.componentNavigation = 'other-option-text-form';
          this.componentNavigationLabel = 'Text Us';
          break;
        default:
          break;
      }
      chatWidgetCtaInteraction({ event: elementEvent, element: 'hours' });
    },
    chatWidgetHeight() {
      setTimeout(() => {
        let headerHeight =
          document.querySelector('#_gemini_simplify_header') ||
          document.querySelector('#website-primary-header.position-fixed.w-100') ||
          document.querySelector(
            '.website-prime-group-header.website-primary-header.position-fixed.w-100'
          ) ||
          document.querySelector('.position-fixed.w-100.bgcolor-primary._theme_background_color') ||
          document.querySelector('.website-classic-header.position-fixed.w-100') ||
          document.querySelector(
            '.paragon-websitenew-header.position-fixed.w-100.bgcolor-primary'
          ) ||
          document.querySelector('.website-luxury-header.bgcolor-primary') ||
          document.querySelector('.header.mazda-oem-navigation') ||
          document.querySelector('.paragon-primary-header-container');
        if (typeof headerHeight !== 'undefined' && headerHeight !== null && !ismobile) {
          let firstWindow = headerHeight.offsetHeight;
          this.headerOffsetHeight = firstWindow;
          let footer_menu = document.querySelector('#chat_widget_footer_menu')
            ? document.querySelector('#chat_widget_footer_menu').offsetHeight
            : '';
          if (document.querySelector('#chatBodySec2 .chat__subSec') && firstWindow && footer_menu) {
            this.chatWidgetLayoutHeight = `calc(95vh - ${firstWindow + footer_menu}px)`;
          }
          if (document.querySelector('#chatBodySec1') && firstWindow) {
            this.chatWidgetBodySec1Height = `calc(95vh - ${firstWindow}px)`;
          }
          if (document.querySelector('#chatBodySec3') && firstWindow) {
            this.chatWidgetBodySec3Height = `calc(95vh - ${firstWindow}px)`;
          }
        }
      }, 600);

      if (ismobile) {
        setTimeout(() => {
          let mobile_header_height =
            document.querySelector('#website-bw-header') ||
            document.querySelector('#paragon-websiteprimary-header') ||
            document.querySelector('#paragon-websitenew-header') ||
            document.querySelector('#website-luxury-mobile-header') ||
            document.querySelector('#simplify-mobile-header') ||
            document.querySelector('#_paragon_primary_header');

          if (typeof mobile_header_height !== 'undefined' && mobile_header_height !== null) {
            this.mobile_header_offset_height = mobile_header_height.offsetHeight;

            let firstWindow = mobile_header_height.offsetHeight;
            this.headerOffsetHeight = firstWindow;
            let footer_menu = document.querySelector('#chat_widget_footer_menu')
              ? document.querySelector('#chat_widget_footer_menu').offsetHeight
              : '';
            let status_bar_height = 0;
            if (DetectUserOperatingSystem() !== 'Mac/iOS')
              status_bar_height = window.innerHeight > this.previousHeight ? 0 : 42;
            else status_bar_height = window.innerHeight > this.previousHeight ? 0 : 100;
            if (
              document.querySelector('#chatBodySec2 .chat__subSec') &&
              firstWindow &&
              footer_menu
            ) {
              this.chatWidgetLayoutHeight = `calc(95vh - ${
                firstWindow + footer_menu + status_bar_height
              }px)`;
            }
            if (document.querySelector('#chatBodySec1') && firstWindow) {
              this.chatWidgetBodySec1Height = `calc(95vh - ${firstWindow + status_bar_height}px)`;
            }
            if (document.querySelector('#chatBodySec3') && firstWindow) {
              this.chatWidgetBodySec3Height = `calc(95vh - ${firstWindow + status_bar_height}px)`;
            }
          }
        }, 500);
      }
    },
    chatWidgetButtonColors() {
      if (
        document.querySelectorAll('#chat-widget .bgcolor-primary') &&
        window.masterLayoutName === '_Website_New'
      ) {
        setTimeout(() => {
          document.querySelectorAll('#chat-widget .bgcolor-primary').forEach((item) => {
            item.style.setProperty('background', window.primaryColor, 'important');
            item.style.setProperty('color', '#fff', 'important');
          });
        }, 1000);
      }
    },
    showTextUsForm(event) {
      if (this.showtext) {
        this.showTextLayout();
        this.isShowTextUsForm = true;
      } else {
        this.componentNavigate('other-option-text-form');
      }
      chatWidgetCtaInteraction({ event: event, element: 'text us' });
    },
    showTextUsOtherForm() {
      this.componentNavigate('other-option-text-form');
    },
    closeTextUsForm() {
      this.showTextData = false;
      this.showChatData = true;
      this.isShowTextUsForm = false;
      this.widgetTitle = 'Assistant';
      this.scrollAndFocus();
    },
    assistantWidgetMessage() {
      try {
        if (sessionStorage.getItem('openchat') !== null) {
          let value = JSON.parse(sessionStorage.getItem('openchat'));
          let tilesAvailable = JSON.parse(sessionStorage.getItem('tilesAvailable'));
          if (value?.name === 'text') {
            if (!tilesAvailable) {
              this.showTextLayout();
            } else {
              this.showTextUsForm();
            }
            sessionStorage.removeItem('openchat');
          } else {
            setTimeout(() => {
              if (value?.flowName) {
                this.message(
                  value.flowName,
                  value.eventname,
                  null,
                  null,
                  value.submitFlow,
                  value.submitAction
                );
                sessionStorage.removeItem('openchat');
              }
            }, 2500);
          }
        }
      } catch (error) {
        console.error('An error occurred in assistantWidgetMessage method:', error);
      }
    },
    tvmKbbOffSetValue(val) {
      this.KbbScrollTopPosition = val;
    },
    tvmSlideOutUpdateByIndex() {
      let list = document.querySelectorAll('.check_fulfillment.confirmed');
      if (list.length) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((item) => {
              if (item.isIntersecting) {
                if (item.target.id) {
                  let id = item.target.id;
                  let val = id.split('-')[1];
                  this.currentSlideOutId = val;
                  if (
                    this.fullfillmenttextList[val]?.responseData?.botResponse?.slideOut?.components
                      ?.length
                  ) {
                    this.slideOutData =
                      this.fullfillmenttextList[val].responseData?.botResponse?.slideOut;
                  }
                }
              }
            });
          },
          { rootMargin: '0px', threshold: 0.75 }
        );
        list.forEach((item) => {
          observer.observe(item);
        });
      }
    },
    flowIndicatorDetection() {
      let list = document.querySelectorAll('.check_fulfillment');
      if (list.length) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((item) => {
              if (item.isIntersecting) {
                if (item.target.id) {
                  let id = item.target.id;
                  let val = id.split('-')[1];
                  this.currentSlideOutId = val;
                  if (this.fullfillmenttextList[val]?.responseData?.indicator) {
                    this.flowIndicator = this.fullfillmenttextList[val].responseData?.indicator;
                  }
                }
              }
            });
          },
          { rootMargin: '0px', threshold: 0.75 }
        );
        list.forEach((item) => {
          observer.observe(item);
        });
      }
    },
    // When user clicks main menu we should clear flow indicator
    mainMenu(val) {
      if (val) {
        this.flowIndicator = '';
      }
    },
    // Check if the scroll bottom is zero inside a specific div
    isDivScrollBottomZero(div) {
      return Math.abs(div.scrollHeight - div.clientHeight - div.scrollTop) < 1;
    },
    tcpDisclaimer() {
      if (window.tCPADisclaimer) {
        this.footerDisclaimer = DecodeHtml.decodeHtml(window.tCPADisclaimer);
        this.chatWidgetHeight();
      }
    },

    async updateOldSession() {
      let searchParams = new URLSearchParams(location.search);
      let oldsid = sessionStorage.getItem('oldsid');
      let cookieId = ChatWidgetService.getChatSessionCookie();
      if (oldsid && cookieId && oldsid !== cookieId) {
        let data = await ChatWidgetService.updateOldSessionId(oldsid, cookieId);
        return Promise.resolve(data);
      } else {
        return Promise.resolve('ok');
      }
    },

    checkAddressBarVisibility() {
      const element = document.querySelector('.chat__header');
      if (element !== null) {
        this.scrollBottom();
        const rect = element.getBoundingClientRect();
        if (rect.top > 0) {
          this.isMobileAddressBarVisible = true;
          this.chatWidgetHeight();
        } else {
          this.isMobileAddressBarVisible = false;
          this.chatWidgetHeight();
        }
      }
    },

    mobileLogoHideChatWidget() {
      let mobile_logo =
        document.querySelector('#website-bw-header img') ||
        document.querySelector('#paragon-websiteprimary-header img') ||
        document.querySelector('#paragon-websitenew-header img') ||
        document.querySelector('#website-luxury-mobile-header img') ||
        document.querySelector('#simplify-mobile-header img') ||
        document.querySelector('#_paragon_primary_header img');

      if (typeof mobile_logo !== 'undefined' && mobile_logo !== null) {
        mobile_logo.addEventListener('click', () => {
          if (sessionStorage.getItem('chatTextModal') !== null) {
            sessionStorage.removeItem('chatTextModal');
          }
        });
      }
    },

    autoPopulateChatContactDetails() {
      let searchParams = new URLSearchParams(location.search);
      let contactDetails = {};
      if (
        localStorage.getItem(window.tid + '-contactForm') !== null &&
        searchParams.get('fname') !== null
      ) {
        localStorage.removeItem(window.tid + '-contactForm');
      }
      if (
        searchParams.get('fname') ||
        searchParams.get('cphone') ||
        searchParams.get('zipcode') ||
        searchParams.get('cityname')
      ) {
        contactDetails.FirstName = searchParams.get('fname');
        contactDetails.LastName = searchParams.get('lname');
        contactDetails.Zip = searchParams.get('zipcode');
        contactDetails.PhoneNumber = searchParams.get('cphone');
        contactDetails.EmailAddress = searchParams.get('email');
        contactDetails.City = searchParams.get('cityname');
        LocalStorageService.setContactdetailsInLocalStorage(contactDetails);
        window.userDetails.zip = window.userDetails.zip
          ? window.userDetails.zip
          : searchParams.get('zipcode');
        window.userDetails.city = window.userDetails.city
          ? window.userDetails.city
          : searchParams.get('cityname');
      }
      if ((searchParams.get('open') !== null) & (searchParams.get('open') === 'false')) {
        this.isHideOverly = true;
      }
    },
    accessBeZindexAdjust(val) {
      setTimeout(() => {
        if (document.querySelector('access-widget-ui')) {
          document.querySelectorAll('access-widget-ui').forEach((item) => {
            const shadowRoot = item.shadowRoot;
            if (shadowRoot.querySelector('button.container')) {
              shadowRoot.querySelector('button.container').style.zIndex = 11;
            }
          });
        }
      }, val);
    },
    async assistantParamsDataModal(value) {
      const data = await ChatWidgetService.getAssistantSettings();
      if (data?.flows?.length) {
        let flowObj = data?.flows.find(({ code }) => code === value?.assistant?.toUpperCase());
        setTimeout(() => {
          let specialObject = {
            TvmComponentRequest: {
              year: value.year || '',
              make: value.make || '',
              model: value.model || '',
              trim: value.trim || '',
              style: value.style || '',
              vin: value.vin || '',
              ActivityTypes: value.ActivityTypes || '',
              aid: window.vdpAccountId || '',
              paymentType: value.paymentType || '',
              DealerLocationId: value.DealerLocationId,
              vehicleType: value.vehicleType || '',
            },
          };
          try {
            let assistantFlowName = '';
            if (this.fullfillmenttextList?.length) {
              assistantFlowName = this.fullfillmenttextList.at(-1).responseData?.indicator || '';
            }
            if (
              flowObj.isActive &&
              (assistantFlowName !== flowObj.flowName ||
                this.tvmModeOfAction === 'tvm-assistant-cta-button')
            ) {
              // store.commit(SET_CHAT_ACTIONS, {
              //   ModeOfAction: this.tvmModeOfAction
              //     ? this.tvmModeOfAction
              //     : 'tvm-assistant-cta-page-loading',
              // });
              this.message(
                flowObj.flowName,
                flowObj.eventname,
                null,
                specialObject,
                flowObj.submitFlow,
                flowObj.submitAction
              );
            }
          } catch (error) {
            console.log(error);
          }
          this.isTileNavigate = true;
          this.tvmModeOfAction = '';
        }, 1000);
      }
    },
    modalNavigate(val) {
      if (val?.assistant) {
        this.isEnableAssistantParamsDataModal = true;
        this.assitantModalCode = val;
      }
    },
    assistantParamsConfirmModal() {
      if (this.showchat) {
        this.assistantParamsDataModal(this.assitantModalCode);
        this.isEnableAssistantParamsDataModal = false;
      }
    },
    removeRepeatedMainMenuTiles(response, type) {
      if (
        response?.responseData?.botResponse?.component?.componentName === 'tvm-startupbuttons' &&
        type === 'isDirect'
      ) {
        let count = this.countTileComponent++;
        let tvmStartUpButtons = [];
        this.fullfillmenttextList?.forEach((element, index) => {
          if (
            element?.responseData?.botResponse?.component?.componentName === 'tvm-startupbuttons'
          ) {
            tvmStartUpButtons.push(index);
            element.responseData.countTileComponent = count;
          }
        });
        if (count !== 1) {
          let firstIndex = tvmStartUpButtons?.at(0);
          this.fullfillmenttextList[firstIndex].responseData.countTileComponent = 1;
        }
      }
      if (type === 'isReload') {
        let tvmStartUpButtons = [];
        response?.forEach((element, index) => {
          if (
            element?.responseData?.botResponse?.component?.componentName === 'tvm-startupbuttons'
          ) {
            tvmStartUpButtons.push(index);
          }
        });
        if (tvmStartUpButtons?.length) {
          let firstIndex = tvmStartUpButtons.at(0);
          let lastIndex = tvmStartUpButtons.at(-1);
          response[firstIndex].responseData.countTileComponent = firstIndex;
          response[lastIndex].responseData.countTileComponent = lastIndex;
          this.countTileComponent = lastIndex + 1;
        }
      }
    },
    queryParamHasOpenChat() {
      let searchParams = new URLSearchParams(location.search);
      if ((searchParams.get('open') !== null) & (searchParams.get('open') === 'false')) {
        this.isHideOverly = true;
        let url = window.location.pathname;
        let pageName = url?.split('/');
        if (pageName?.length && pageName[1] !== 'research-models') {
          this.hidewidget();
          sessionStorage.setItem('slideOutClose', true);
        }
      }
    },
    selectedInputAddress(val) {
      if (val?.description) {
        this.userInput = val.description;
        this.selectedAutoCompletedValue = val;
      }
    },
    selectedInputYmmt(val) {
      if (val?.suggestions) {
        this.userInput = val.suggestions;
        this.selectedAutoCompletedValue = val;
      }
    },
    slideWidgetHeight() {
      if (this.isTransparentMenu) {
        return { height: `100vh` };
      }
      return {
        height: `calc(100vh - ${this.headerOffsetHeight}px)`,
      };
    },
    fullFillmentIndex(val) {
      if (val && val >= window.activeTileNavigateIndex) {
        this.checkModuleHeight(val);
      }
      if (document.querySelector('#MessageBox') && document.querySelector('.modal-dialog div')) {
        document.querySelector('.modal-dialog div').removeAttribute('tabindex');
        document.querySelector('#MessageBox').focus();
      }
    },
    checkModuleHeight(val) {
      if (document.querySelector('#chat__bodyview') && this.isTileNavigate && val) {
        let container = document.querySelector('#chat__bodyview');

        this.totalTileHeight =
          this.totalTileHeight + document.querySelector(`#tileHeight-${val}`)?.clientHeight;
        this.isTileNavigateHeight = container?.clientHeight - this.totalTileHeight;
      }
    },
    observeModalPopups() {
      const targetNode = document.body;
      const config = { attributes: true, childList: true, subtree: true };
      // Callback function to execute when mutations are observed
      const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (this.showChatData && mutation.type === 'childList') {
            let hideModalPopups = hideOnLoadModalPopups();
            hideModalPopups.forEach((element) => {
              const matchedElement = document.querySelector(element) || null;
              if (matchedElement) {
                matchedElement.hidden = true;
              }
            });
          }
          if (
            (!this.showChatData ||
              (document.querySelector('#chatTextModal') === null && this.ismobile)) &&
            mutation.type === 'childList'
          ) {
            let hideModalPopups = hideOnLoadModalPopups();
            hideModalPopups.forEach((element) => {
              const matchedElement = document.querySelector(element) || null;
              if (matchedElement) {
                matchedElement.hidden = false;
              }
            });
          }
        }
      };
      // Create an observer instance linked to the callback function
      this.mutationObserver = new MutationObserver(callback);
      // Start observing the target node for configured mutations
      this.mutationObserver.observe(targetNode, config);
    },
    disconnectObserver() {
      if (this.observer) {
        this.observer.disconnect();
      }
    },
    handleCustomClickEvent(event) {
      this.openchatwidget();
      this.tvmModeOfAction = 'tvm-assistant-cta-button';
      this.assistantParamsDataModal(event.detail);
    },
    convertUTCtoLocal(utcTime) {
      if (utcTime) {
        return moment.utc(utcTime).local().format('hh:mm a');
      }
    },
    async textandAssitantSettings() {
      if (this.showtext || this.showchat) {
        const data = window.assistantSettings;
        if (data?.disableWebBackgroundBlur) {
          this.disableBackgroundBlur = data?.disableWebBackgroundBlur;
        }
        this.disableSignIn = data?.disableSignIn;
        this.enableTranslationIcon = data?.enableTranslation;
        this.enablePrivacyPopUp = data?.enablePrivacyPopUp;
        this.privacyText = data?.privacyText;
      }
    },
    async loadChatWithSessionData(cookieId, oldsid) {
      const response = await ChatWidgetService.loadChatWithSessionId(cookieId, oldsid);
      if (oldsid) {
        sessionStorage.removeItem('oldsid');
      }
      if (response !== null && response.length > 0) {
        this.loadChatData(response);
      } else {
        this.message(null, 'chat_start');
      }
    },
    signInNavigationLoader(event) {
      this.isSignInNavigationModal = event?.detail;
    },
    chatThemeSettingsHandler() {
      if (
        (this.chatThemeSettings?.launchIconColorForAssistant ||
          this.chatThemeSettings?.OemThemeColorForAssistant) &&
        this.chatThemeSettings?.primeColor
      ) {
        const chatWidget = document.querySelector('#chat-widget');
        if (chatWidget && window.themeName !== 'Volkswagen') {
          const items = chatWidget.querySelectorAll('.bgcolor-primary');
          items.forEach((item) => {
            item.style.setProperty(
              'background-color',
              this.chatThemeSettings.primeColor,
              'important'
            );
          });
        }
        if (chatWidget && window.themeName === 'Volkswagen') {
          const items = chatWidget.querySelectorAll('.bgcolor-primary');
          items.forEach((item) => {
            item.style.cssText = `
              border-color: ${this.chatThemeSettings.primeColor} !important;
              color: ${this.chatThemeSettings.primeColor} !important;
          `;
          });
          if (document.querySelector('.chat__footer .bgcolor-primary')) {
            document.querySelector('.chat__footer .bgcolor-primary').style.cssText = `
            background-color : ${this.chatThemeSettings.primeColor} !important;
            color:  #fff !important;
            `;
          }
        }
        if (this.chatThemeSettings?.primeColor) {
          this.themePrimaryColor = this.chatThemeSettings.primeColor;
        }
      }
    },
    chatSdaMessageEventHandler(massage, chatMessageType) {
      let shiftDigitalEvent = ShiftDigitalContainer.getShiftDigitalObject();
      if (shiftDigitalEvent && typeof shiftDigitalEvent.chatMessageEvent === 'function') {
        shiftDigitalEvent.chatMessageEvent(massage, chatMessageType);
      }
    },
    hideTvmApiErrorRegenerate(val) {
      if (val) {
        this.isApiError = null;
      }
    },
    tvmLoaderEmitter(event) {
      this.isLoader = event?.detail;
      this.isWaitingForResponse = true;
    },
    loadErrorComponentEmitter(data) {
      if (data?.detail !== null && data.detail?.TID) {
        this.isApiError = data?.detail;
      }
    },
    languageChangeEvent() {
      this.isTranslationChange = true;
    },
    tvmLanguageLoader(event) {
      if (event?.detail) {
        setTimeout(() => {
          this.isTranslationChange = false;
        }, 1000);
      }
    },
  },
  updated() {
    this.chatWidgetButtonColors();
    if (this.ismobile) {
      this.mobileLogoHideChatWidget();
    }
    if (document.querySelector('#chat__bodyview')) {
      document.querySelector('#chat__bodyview').addEventListener('scroll', () => {
        let container = this.$el.querySelector('#chat__bodyview');
        if (container.scrollTop === 0) {
          this.processNameIndicator = false;
        } else {
          this.processNameIndicator = true;
        }
        // here checking for scroll bottom is true or false
        if (this.isDivScrollBottomZero(container)) {
          this.isScrollBottom = false;
        } else {
          this.isScrollBottom = true;
          if (document.querySelector('#chat_widget_footer_menu')) {
            let footer_menu = document.querySelector('#chat_widget_footer_menu').offsetHeight;
            this.chatFooterHeight = footer_menu + 6;
          }
        }
      });
    }
    this.chatThemeSettingsHandler();
  },
};
