<template>
  <div class="svg_icon">
    <svg :style="{
      fill: isHoverMenu ? themePrimaryColor : chatThemeSettings?.borderColor || themeColor,
      stroke: isHoverMenu ? themePrimaryColor : chatThemeSettings?.borderColor || themeColor,
    }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="#000000" stroke="#B3B3B3" width="50px"
      height="50px">
      <path d="M 0 9 L 0 11 L 50 11 L 50 9 Z M 0 24 L 0 26 L 50 26 L 50 24 Z M 0 39 L 0 41 L 50 41 L 50 39 Z" />
    </svg>
  </div>
</template>

<script>
import { useChatSettingsStore } from '../../../store/ChatSettingsStore/ChatSettingsStore';
import { mapWritableState } from 'pinia';

export default {
  props: {
    themeColor: {
      type: String,
      required: false,
      default: '#B3B3B3',
    },
    isHoverMenu: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      themePrimaryColor: window.primaryColor,
    }
  },
  computed: {
    ...mapWritableState(useChatSettingsStore, ['chatThemeSettings']),
  },
}
</script>
<style scoped>
.svg_icon svg {
  position: relative;
  bottom: 0px;
  height: 16px;
}

.footer-menu:hover svg {
  bottom: -2px;
  transition: 0.5s;
}

@media (min-width: 1900px) {
  .svg_icon svg {
    height: 21px !important;
    margin-bottom: 2px;
  }
}
</style>
