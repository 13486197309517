<template>
  <div>
    <div v-if="componentsData && componentsData.length">
      <div v-for="(item, index) in componentsData" :key="index">
        <div v-if="item.componentType === 'general'">
          <component :is="item.componentName" :bot-response="item" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Vue from "vue";
import TvmKbbTradeValue from "../TvmSellUsYourCar/TvmKbbTradeValue.vue";
import TvmBbTradeValue from "../TvmSellUsYourCar/TvmBbTradeValue.vue";
// Vue.component("tvm_kbb_trade_value", () => import("../TvmSellUsYourCar/TvmKbbTradeValue.vue"));
// Vue.component("tvm_bb_trade_value", () => import("../TvmSellUsYourCar/TvmBbTradeValue.vue"));
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ["componentsData"],
  comments: {
    'tvm_kbb_trade_value': TvmKbbTradeValue,
    'tvm_bb_trade_value': TvmBbTradeValue
  }
};
</script>
