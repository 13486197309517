/**
 *
 * IMPORTANT:
 * This file is used both in vue2 and vue3 project
 * Dont use vue syntax and the store conept
 * FOr clarifications contact Leela
 *
 */

import axios from 'axios';
import axiosRetry from 'axios-retry';
const ChatApi = axios.create({});
import * as Sentry from '@sentry/vue';

axiosRetry(ChatApi, {
  retries: 0,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  retryCondition(error) {
    try {
      let status = axiosRetry.isNetworkError(error) ? 0 : error.response.status;
      let customError = new Error();
      let requestBody;
      switch (status) {
        // case 404:
        // case 500:
        // case 502:
        // case 503:
        // case 504:
        case 0:
          if (error?.config?.data) {
            requestBody = JSON.parse(error.config.data);
          } else {
            requestBody = 'No request body available';
          }
          customError = new Error(
            `Error: ${error.message} , API: ${
              error?.request?.responseURL
            }, guid:UIC93ED1-E854-4807-9CC1-CCE9C220B0UI, Request Body: ${JSON.stringify(
              requestBody
            )}`
          );
          customError.name = `API retry happened`;
          Sentry.captureException(customError);
          return true;
        default:
          return false;
      }
    } catch (error) {
      console.log(error);
    }
  },
});

ChatApi.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ChatApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    let requestBody;
    if (error?.config?.data) {
      requestBody = JSON.parse(error.config.data);
    } else {
      requestBody = 'No request body available';
    }
    const customError = new Error(
      `Error: ${error.message} , API: ${
        error?.request?.responseURL
      }, guid:UIC93ED1-E854-4807-9CC1-CCE9C220B0UI, Request Body: ${JSON.stringify(requestBody)}`
    );
    customError.name = `An error occurred on UI`;
    customError.stack = ` ${JSON.stringify(requestBody)}`;
    Sentry.captureException(customError);
    return Promise.reject(error);
  }
);

export default ChatApi;
