import TvmColorSelection from '../TvmColorSelection/TvmColorSelection.vue';
import TvmNavigateToInventory from '../TvmNavigateToInventory/TvmNavigateToInventory.vue';
import TvmReserveYourVehicleFinalView from '../TvmReserveYourVehicleFinalView/TvmReserveYourVehicleFinalView.vue';
import TvmSuggestions from '../TvmSuggestions/TvmSuggestions.vue';
import TvmSellUsYourCar from '../TvmSellUsYourCar/TvmSellUsYourCar.vue';
import TvmKbbTradeValue from '../TvmSellUsYourCar/TvmKbbTradeValue.vue';
import TvmBbTradeValue from '../TvmSellUsYourCar/TvmBbTradeValue.vue';
import TvmSignInNavigation from '../TvmSignInNavigation/TvmSignInNavigation.vue';
// import SlideOutPanel from '../SlideOutPanel/SlideOutPanel.vue';
import TvmServiceCoupons from '../TvmServiceCoupons/TvmServiceCoupons.vue';
import TvmMyVehicles from '../TvmMyVehicles/TvmMyVehicles.vue';
import TvmServiceTypes from '../TvmServiceTypes/TvmServiceTypes.vue';
import TvmServiceOffers from '../TvmServiceOffers/TvmServiceOffers.vue';
import TvmMyAddress from '../TvmMyAddress/TvmMyAddress.vue';
import TvmStartUpButtons from '../TvmStartUpButtons/TvmStartUpButtons.vue';
import TvmFlowEndItems from '../TvmFlowEndItems/TvmFlowEndItems.vue';
import TvmPageRedirectComponent from '../TvmPageRedirectComponent/TvmPageRedirectComponent.vue';
import TvmColorSwatches from '../TvmColorSwatches/TvmColorSwatches.vue';
import TvmTransportTypes from '../TvmTransportTypes/TvmTransportTypes.vue';
import TvmAutoCompleteAddress from '../TvmAutoCompleteAddress/TvmAutoCompleteAddress.vue';
import TvmYmmtAutoComplete from '../TvmYmmtAutoComplete/TvmYmmtAutoComplete.vue';
import TvmWebIframeRedirect from '../TvmWebIframeRedirect/TvmWebIframeRedirect.vue';
import TvmStructuredSuggestions from '../TvmStructuredSuggestions/TvmStructuredSuggestions.vue';
import TvmLoginVehicles from '../TvmLoginVehicles/TvmLoginVehicles.vue';
import TvmFeedBack from '../TvmFeedBack/TvmFeedBack.vue';
// import { mapState } from 'vuex';
import TvmDealerSpecials from '../TvmDelaerSpecials/TvmDealerSpecials.vue';
import TvmMultiSelectSuggestions from '../TvmMultiSelectSuggestions/TvmMultiSelectSuggestions.vue';

export default {
  data() {
    return {
      clientName: window.clientName || '',
      count: 0,
      // countTileComponent: null,
    };
  },
  components: {
    'tvm-suggestions': TvmSuggestions,
    'tvm-color-selection': TvmColorSelection,
    'tvm-reserve-your-vehicle-final-view': TvmReserveYourVehicleFinalView,
    'tvm-navigate-to-inventory': TvmNavigateToInventory,
    'tvm-sell-us-your-car': TvmSellUsYourCar,
    'tvm-kbb-trade-value': TvmKbbTradeValue,
    'tvm-bb-trade-value': TvmBbTradeValue,
    'tvm-sign-in-navigation': TvmSignInNavigation,
    // 'slide-out-panel': SlideOutPanel,
    'tvm-service-coupons': TvmServiceCoupons,
    'tvm-my-vehicles': TvmMyVehicles,
    'tvm-service-types': TvmServiceTypes,
    'tvm-service-offers': TvmServiceOffers,
    'tvm-my-address': TvmMyAddress,
    'tvm-start-up-buttons': TvmStartUpButtons,
    'tvm-flow-end-items': TvmFlowEndItems,
    'tvm-page-redirect-component': TvmPageRedirectComponent,
    'tvm-show-color-swatches': TvmColorSwatches,
    'tvm-transport-types': TvmTransportTypes,
    'tvm-autocomplete-address': TvmAutoCompleteAddress,
    'tvm-ymmt-auto-complete': TvmYmmtAutoComplete,
    // 'tvm-signin-navigation': TvmSignInNavigation,
    'tvm-webredirect-component': TvmWebIframeRedirect,
    'tvm-structured-suggestions': TvmStructuredSuggestions,
    'tvm-login-vehicles': TvmLoginVehicles,
    'tvm-user-feedback-prompt': TvmFeedBack,
    'tvm-dealer-special-offers': TvmDealerSpecials,
    'tvm-multi-select-suggestions': TvmMultiSelectSuggestions,
  },
  props: {
    fullFillMentText: {
      required: false,
      default: '',
    },
    responseData: {
      required: false,
      default: '',
    },
    responseTime: {
      required: false,
      default: '',
    },
    request: {
      required: false,
      default: '',
    },
    isLastIndex: {
      required: false,
      default: '',
    },
    headerOffsetHeight: {
      required: false,
      default: null,
    },
    index: {
      required: false,
      default: null,
    },
    isWaitingForResponse: {
      required: false,
      default: null,
    },
    userInputText: {
      required: false,
      default: null,
    },
    selectedAutoCompletedValue: {
      required: false,
      default: false,
    },
    itemData: {
      required: false,
      default: null,
    },
    chatThemeSettings: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    if (this.request?.SubmitAction?.toLowerCase() === 'main_menu') {
      this.$root.$emit('main_menu', this.request?.SubmitAction.toLowerCase());
      this.$emit('mainMenu', this.request?.SubmitAction.toLowerCase());
    }
    if (this.index) {
      this.$emit('fullFillmentIndex', this.index);
    }
  },
  methods: {
    getTvmSuggestionsResponse(item, botResponse) {
      return {
        suggestions: item.value,
        suggType: botResponse?.suggType ?? null,
      };
    },
  },
  computed: {
    slideOutLength() {
      if (this.responseData?.botResponse?.slideOut?.components?.length) {
        let components = this.responseData?.botResponse?.slideOut?.components;
        let successComponent = components.find(
          ({ componentName }) => componentName === 'tvm-slideout-appraisal-appointment'
        );
        return !!successComponent?.value?.status;
      } else {
        return false;
      }
    },
    countTileComponent() {
      return this.responseData.countTileComponent || null;
    },
    // ...mapState({
    //   chatThemeSettings: (state) => {
    //     return state.commonSettings.chatThemeSettings;
    //   },
    // }),
    conversationId() {
      if (this.responseData.id || this.itemData.id) {
        return this.responseData.id || this.itemData.id;
      }
    },
  },
};
