<template>
  <div />
</template>
<script>
export default {
  props: ["botResponse"],
  mounted() {
    if (this.botResponse?.component?.value?.uri) {
      window.location.href = `${this.botResponse?.component?.value?.uri}`;
      sessionStorage.setItem("slideOutClose", true);
    }
  },
};
</script>
