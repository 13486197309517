import AssistantIcon from '../ChatAssistant/AssistantIcon.vue';
import axios from 'axios';
import { ChatWidgetService } from '../../../Services/ChatWidgetService/ChatWidgetService';
import {
  chatWidgetCtaInteraction,
  fireChatWidgetOpen,
} from '../../../Events/TagManagerServices/TaggingEventForPages';
import {
  getPageNameFromUrl,
  getPageWithAssistantCode,
  hexToRGBA,
  isIPAD,
} from '@/js/services/CommonUtility';
import { useChatSettingsStore } from '../../../store/ChatSettingsStore/ChatSettingsStore';
// import store from '../../../../../store/store';
// import { SET_CHAT_THEME_SETTINGS } from '../../../../../store/StoreConstants/CommonSettingsConstants';

export default {
  data() {
    return {
      expandAssistant: false,
      primaryColor: window.primaryColor,
      isAnimatedIcon: false,
      isHover: false,
      intervalId: null,
      mouseLeaveIntervalId: null,
      autoExpandAssistant: false,
      INTERVAL: 6000,
      timerId: null,
      timer: 0,
      spinDuration: null,
      d_DurationToEnlargeLaunchIcon: null,
      d_EnlargedLaunchIconStayDuration: null,
      iconHeading: '',
      enlargedIconHeading: '',
      enlargedIconSubHeading: '',
      chipButtons: [],
      widgetPosition: '',
      enableTextAssistant: false,
      widgetLabel: '',
      isAssistantOpened: false,
      isTilesAvailable: false,
      paramValue: {},
      isCheckTvmHelpModalOpened: false,
      isTablet: window.istablet || isIPAD(),
      themeSettings: {
        backgroundColor: '',
        textColor: '',
        iconColor: '',
      },
      assistantSettingsData: null,
    };
  },
  mounted() {
    this.getAssistantSettings();
    this.carNowZindexAdjust();
  },
  methods: {
    openAssistant(event) {
      this.$emit('openAssistant', false);
      this.isAssistantOpened = true;
      chatWidgetCtaInteraction({ event: event, element: this.iconHeading, isTextChat: true });
      fireChatWidgetOpen();
    },
    assistantAutoOpen(val) {
      let count = 0;
      this.intervalId = setInterval(() => {
        count++;
        this.autoExpandAssistant = !this.autoExpandAssistant;
        ChatWidgetService.setAssistantButtonSession();
        if (count === 1) {
          clearInterval(this.intervalId);
        }
      }, val);
    },
    startHover() {
      this.isHover = true;
      this.pause();
    },
    endHover() {
      this.isHover = false;
      this.start();
    },
    openChat() {
      this.$emit('openAssistant', false);
      this.autoExpandAssistant = false;
    },
    closeAssistant() {
      this.$emit('openAssistant', true);
      this.autoExpandAssistant = false;
    },
    mouseLeaveCloseAssistant(val) {
      let count = 0;
      this.mouseLeaveIntervalId = setInterval(() => {
        count++;
        this.autoExpandAssistant = !this.autoExpandAssistant;
        if (count === 1) {
          clearInterval(this.mouseLeaveIntervalId);
        }
      }, val);
    },
    eachService(val) {
      this.$emit('openService', val);
      this.autoExpandAssistant = false;
    },
    start() {
      clearInterval(this.timerId);
      this.timer = this.timer > 0 ? this.timer : this.d_EnlargedLaunchIconStayDuration / 1000;
      this.timerId = setInterval(() => {
        this.timer -= 1;
      }, 1000);
    },
    pause() {
      clearInterval(this.timerId);
    },
    getAssistantSettings() {
      let searchParamsData = new URLSearchParams(location.search);
      this.paramValue.assistant = searchParamsData.get('assistant');
      this.paramValue.utm_medium = searchParamsData.get('utm_medium') || '';
      this.paramValue.vin = searchParamsData.get('vin') || '';

      let url = `${window.chatWidgetApiBaseUrl}/ChatBot/assistantsettings/${window.accountId}/${window.campaignId}`;
      if (window.userDetails?.consumerid) {
        let consumerId = window.userDetails?.consumerid;
        url = url + `?consumerId=${consumerId}`;
      }
      axios
        .get(url)
        .then((response) => {
          if (response?.data) {
            let res = response.data;
            this.assistantSettingsData = res;
            this.spinDuration = res.d_LaunchIconAnimation * 1000 || 0;
            this.d_DurationToEnlargeLaunchIcon = res.d_DurationToEnlargeLaunchIcon * 1000 || 6000;
            this.d_EnlargedLaunchIconStayDuration =
              res.d_EnlargedLaunchIconStayDuration * 1000 || 6000;
            this.iconHeading = res.iconHeading || 'Need Assistance?';
            this.enlargedIconHeading = res.enlargedIconHeading || 'Save Time With Your Assistant';
            this.enlargedIconSubHeading =
              res.enlargedIconSubHeading || 'I can Quickly guide you through the following:';
            this.widgetPosition = res.widgetPosition || 'bottom right';
            this.enableTextAssistant = res.enableTextAssistant;
            this.widgetLabel = res.widgetLabel || 'Text Us';
            sessionStorage.setItem('tilesAvailable', res.tilesAvailable);
            this.isTilesAvailable = res.tilesAvailable;
            window.isEnableTextAssistant = res.enableTextAssistant;
            this.$emit('disableWebBackgroundBlur', res.disableWebBackgroundBlur);
            this.themeSettings.backgroundColor = res.launchIconBackgroundColor;
            this.themeSettings.textColor = res.launchIconTextColor;
            this.themeSettings.iconColor = res.launchIconColor;

            let chatThemeSettings = {};
            if (res.launchIconColorForAssistant && res.launchIconColor) {
              chatThemeSettings.primeColor = res.launchIconColor;
              chatThemeSettings.borderColor = hexToRGBA(
                res.launchIconColor,
                res.themeOpacity || 60
              );
              chatThemeSettings.launchIconColorForAssistant = true;
              chatThemeSettings.backgroundColor = res.launchIconBackgroundColor;
              chatThemeSettings.textColor = res.launchIconTextColor;
              chatThemeSettings.iconColor = res.launchIconColor;
              const storeThemeSettings = useChatSettingsStore();
              storeThemeSettings.chatThemeSettings = chatThemeSettings;
              // store.commit(SET_CHAT_THEME_SETTINGS, chatThemeSettings);
            }
            if (res.oemThemeColorForAssistant) {
              chatThemeSettings.primeColor = window.primaryColor;
              chatThemeSettings.borderColor = hexToRGBA(
                window.primaryColor,
                res.themeOpacity || 60
              );
              chatThemeSettings.oemThemeColorForAssistant = true;
              chatThemeSettings.backgroundColor = res.launchIconBackgroundColor;
              chatThemeSettings.textColor = res.launchIconTextColor;
              chatThemeSettings.iconColor = res.launchIconColor;
              const storeThemeSettings = useChatSettingsStore();
              storeThemeSettings.chatThemeSettings = chatThemeSettings;
              // store.commit(SET_CHAT_THEME_SETTINGS, chatThemeSettings);
            }

            if (res?.flows?.length) {
              res.flows.forEach((item) => {
                if (item.enableOnEnlargedLaunchIcon) {
                  this.chipButtons.push(item);
                }
              });
              this.checkAndLaunchAssistant(res);
              window.tvmFlowSetting = res.flows;
            }
            let isChatOpened = false;
            if (sessionStorage.getItem('isAssistantButtonSession') !== null) {
              isChatOpened = sessionStorage.getItem('isAssistantButtonSession');
            }
            if (!isChatOpened && !res.d_DisableEnlargedLaunchIcon && !this.paramValue?.assistant) {
              this.assistantAutoOpen(this.d_DurationToEnlargeLaunchIcon);
              this.start();
            }
            this.navigateWebBannerToAssistant(res);
            window.assistantSettings = res;
          } else {
            this.spinDuration = 3000;
            this.iconHeading = 'Need Assistance?';
            this.enlargedIconHeading = 'Save Time With Your Assistant';
            this.enlargedIconSubHeading = 'I can Quickly guide you through the following:';
            this.widgetPosition = 'bottom right';
            this.widgetLabel = 'Text Us';
            this.assistantAutoOpen(6000);
            this.start();
          }
        })
        .catch((error) => {
          this.spinDuration = 3000;
          this.iconHeading = 'Need Assistance?';
          this.enlargedIconHeading = 'Save Time With Your Assistant';
          this.enlargedIconSubHeading = 'I can Quickly guide you through the following:';
          this.widgetPosition = 'bottom right';
          this.widgetLabel = 'Text Us';
          this.assistantAutoOpen(6000);
          this.start();
          console.log(error);
        });
    },
    chatAlignmentPosition() {
      return this.widgetPosition && this.widgetPosition.toLowerCase() === 'bottom left'
        ? 'position-left-alignment'
        : 'position-right-alignment';
    },
    carNowZindexAdjust() {
      const elementExist = setInterval(() => {
        let elm = document.querySelector('.master-cn-b13-container');
        if (elm !== null && !window.integrateWithText) {
          elm.style.zIndex = 9;
          clearInterval(elementExist);
        }
      }, 100);
      const elementExist2 = setInterval(() => {
        let elm2 = document.querySelector('#cnpoke');
        if (elm2 !== null) {
          elm2.style.zIndex = 9;
          clearInterval(elementExist2);
        }
      }, 100);
      const elementExist3 = setInterval(() => {
        if (document.querySelector('access-widget-ui')) {
          document.querySelectorAll('access-widget-ui').forEach((item) => {
            const shadowRoot = item.shadowRoot;
            if (shadowRoot.querySelector('button.container') !== null) {
              shadowRoot.querySelector('button.container').style.zIndex = 11;
              clearInterval(elementExist3);
            }
          });
        }
      }, 100);
    },
    navigateWebBannerToAssistant(res) {
      let activeFlowIndicatorCode = sessionStorage.getItem('activeFlowIndicatorCode') || '';
      if (this.paramValue.assistant !== null) {
        if (this.paramValue.assistant.toLowerCase() === 'mainmenu') {
          this.openChat();
        } else {
          this.openChat();
          if (this.paramValue.assistant && !this.paramValue.utm_medium) {
            ChatWidgetService.setAssistantButtonSession();
            let assistantCode = {};
            assistantCode.assistant = this.paramValue.assistant;
            assistantCode.utm_medium = this.paramValue?.utm_medium;
            assistantCode.vin = this.paramValue?.vin;
            assistantCode.ActivityTypes = 'AutoWeb';
            if (res.consumer !== null && window.isauthenticated.toLowerCase() === 'true') {
              assistantCode.consumer = res.consumer;
            }
            if (activeFlowIndicatorCode?.toUpperCase() !== assistantCode?.assistant.toUpperCase()) {
              this.$emit('tileNavigate', assistantCode);
            }
          }
        }
      }
    },
    checkAndLaunchAssistant(res) {
      let pageNames = getPageWithAssistantCode();
      let pageObj = {};
      let activeFlowIndicatorCode = sessionStorage.getItem('activeFlowIndicatorCode') || '';
      if (pageNames?.length && window.location.pathname) {
        pageObj = pageNames.find(
          (item) => item.pageName === getPageNameFromUrl(window.location.pathname)
        );
      }

      if (pageObj?.code) {
        let eachFlowSettings = res.flows.find((item) => item.code === pageObj?.code?.toUpperCase());
        if (
          this.paramValue?.utm_medium === 'email' &&
          eachFlowSettings?.emailTargetEnabled &&
          eachFlowSettings?.isActive
        ) {
          this.openChat();
          ChatWidgetService.setAssistantButtonSession();
          let assistantCode = {};
          assistantCode.assistant = pageObj.code;
          assistantCode.utm_medium = this.paramValue?.utm_medium;
          assistantCode.vin = this.paramValue?.vin;
          assistantCode.ActivityTypes = 'Email';
          if (res.consumer !== null && window.isauthenticated.toLowerCase() === 'true') {
            assistantCode.consumer = res.consumer;
          }
          if (activeFlowIndicatorCode?.toUpperCase() !== pageObj?.code?.toUpperCase()) {
            this.$emit('tileNavigate', assistantCode);
          }
        } else if (eachFlowSettings?.websiteButtonEnabled && eachFlowSettings?.isActive) {
          this.openChat();
          ChatWidgetService.setAssistantButtonSession();
          let assistantCode = {};
          assistantCode.assistant = pageObj.code;
          assistantCode.vin = this.paramValue?.vin;
          assistantCode.ActivityTypes = 'AutoWeb';
          if (res.consumer !== null && window.isauthenticated.toLowerCase() === 'true') {
            assistantCode.consumer = res.consumer;
          }
          if (activeFlowIndicatorCode?.toUpperCase() !== pageObj?.code?.toUpperCase()) {
            this.$emit('tileNavigate', assistantCode);
          } else {
            sessionStorage.removeItem('hideTvmHelpModal');
          }
        } else {
          // No action needed for other cases.
        }
      }
    },
    checkEachModuleSession(assistantCode) {
      let getModalNavigateSession = JSON.parse(sessionStorage.getItem('modalNavigate'));
      if (
        assistantCode?.assistant !== getModalNavigateSession[assistantCode?.assistant]?.flowName
      ) {
        this.$emit('modalNavigate', assistantCode);
        let modalNavigateSession = {
          [assistantCode?.assistant]: {
            isModalPopupSession: true,
            flowName: assistantCode?.assistant,
          },
        };
        let savedData = Object.assign(modalNavigateSession, getModalNavigateSession);
        sessionStorage.setItem('modalNavigate', JSON.stringify(savedData));
      } else {
        this.$emit('tileNavigate', assistantCode);
      }
    },
  },
  watch: {
    isHover(val) {
      if (val) {
        clearInterval(this.intervalId);
        clearInterval(this.mouseLeaveIntervalId);
      } else {
        let timeDiff = this.d_EnlargedLaunchIconStayDuration - this.timer * 1000;
        this.mouseLeaveCloseAssistant(this.d_EnlargedLaunchIconStayDuration - timeDiff);
      }
    },
    timer(newTime) {
      if (newTime === -1) {
        this.start();
      }
    },
    autoExpandAssistant(val) {
      if (val) {
        this.mouseLeaveCloseAssistant(this.d_EnlargedLaunchIconStayDuration);
      }
    },
    isAssistantOpened(val) {
      if (val) {
        clearInterval(this.intervalId);
      }
    },
  },
  components: {
    'assistant-icon': AssistantIcon,
  },
  computed: {
    themeBackground() {
      return {
        background: this.themeSettings?.backgroundColor
          ? this.themeSettings?.backgroundColor
          : '#fff',
      };
    },
    themeTextColor() {
      return {
        color: this.themeSettings?.textColor ? this.themeSettings?.textColor : '#000',
      };
    },
    textWithOpacity() {
      return {
        color: this.assistantSettingsData?.enlargedLaunchSubHeadingFontColor
          ? this.assistantSettingsData?.enlargedLaunchSubHeadingFontColor
          : '#ffffffcc',
      };
    },
    themeSettingsBackground() {
      return {
        background: this.assistantSettingsData?.enlargedLaunchCTAColor
          ? this.assistantSettingsData?.enlargedLaunchCTAColor
          : 'rgba(255, 255, 255, 0.1)',
        color: this.assistantSettingsData?.enlargedLaunchCTAFontColor
          ? this.assistantSettingsData?.enlargedLaunchCTAFontColor
          : '#ffffffcc',
      };
    },
  },
};
