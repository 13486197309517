<template>
  <div class="w-100 my-2 tvm_multi_select_suggestions">
    <div class="px-3 position-relative" v-if="multiSelectSuggestions != null && multiSelectSuggestions.length > 8">
      <VSlickCarousel ref="slick" class="tvm_multi_select_list px-4" v-bind="slickOptions">
        <template #nextArrow="arrowSlotProps">
          <em :disabled="arrowSlotProps.disabled" @click="arrowSlotProps.onClick" class="primaryicon-right-arrow"
            :style="getArrowStyles('right')"></em>
        </template>
        <div v-for="(eachSuggestion, index) in multiSelectSuggestions" :key="index" class="p-2 text-center">
          <div :title="eachSuggestion.name" :class="[{ disabledContent: !isLastIndex }]"
            class="p-2 rounded-buttons suggestions_btn bgcolor-primary theme-text-color cursor-pointer btn"
            @click.prevent="onSelectYears(eachSuggestion, index)">
            <p class="service__types__btn__title ft-12 text-capitalize mb-0 icon_text text-white">
              <span v-if="eachSuggestion.isSelected">
                <em :style="{ backgroundColor: primaryColor }"
                  class="primaryicon-financing d-block font-weight-bold ft-14 text-white rounded-circle p-1 ft-10" />
              </span>
              <span>
                {{ eachSuggestion.name }}
              </span>
            </p>
          </div>
        </div>
        <template #prevArrow="arrowSlotProps">
          <em :disabled="arrowSlotProps.disabled" @click="arrowSlotProps.onClick" class="primaryicon-left-arrow"
            :style="getArrowStyles('left')"></em>
        </template>
      </VSlickCarousel>
    </div>

    <div class="d-flex flex-wrap justify-content-center mb-2"
      v-else-if="multiSelectSuggestions != null && multiSelectSuggestions.length">
      <div v-for="(eachSuggestion, index) in multiSelectSuggestions" :key="index" class="p-2 text-center">
        <div :title="eachSuggestion.name" :class="[{ disabledContent: !isLastIndex }]"
          class="p-2 px-4 rounded-buttons suggestions_btn bgcolor-primary theme-text-color cursor-pointer btn"
          @click.prevent="onSelectYears(eachSuggestion, index)">
          <p class="service__types__btn__title ft-12 text-capitalize mb-0 icon_text text-white">
            <span v-if="eachSuggestion.isSelected">
              <em :style="{ backgroundColor: primaryColor }"
                class="primaryicon-financing d-block font-weight-bold ft-14 text-white rounded-circle p-1 ft-10" />
            </span>
            <span>
              {{ eachSuggestion.name }}
            </span>
          </p>
        </div>
      </div>
    </div>
    <div class="w-100 mt-1">
      <div class="min-40">
        <h6 v-show="commaSeparated" class="list__block pl-2  ft-13 text-capitalize m-0">
          <b>Selected Years : </b> <span v-html="commaSeparated" />
        </h6>
      </div>
      <ul v-if="buttons != null && buttons.length && isLastIndex" class="pt-2 list-unstyled">
        <li v-for="(button, index) in buttons" :key="index" class="service__types__list__item"
          :class="isLastIndex ? 'mb-4' : 'mb-0'">
          <div class="d-flex flex-wrap justify-content-center">
            <button :disabled="!commaSeparated || isDisableBtn"
              class="btn bgcolor-primary mb-2 rounded-buttons mx-2 text-white service__chips__btn"
              @click.once="optionclick(button)">
              {{ button.name }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script src="./TvmMultiSelectSuggestions.js"></script>
<style scoped>
.tvm_multi_select_suggestions>>>em.primaryicon-left-arrow.slick-disabled,
.tvm_multi_select_suggestions>>>em.primaryicon-right-arrow.slick-disabled {
  pointer-events: none;
  opacity: 0;
}

.min-40 {
  min-height: 40px;
}

.suggestions_btn {
  position: relative;
  transition: border 0.1s ease;
}



.suggestions_btn em {
  position: absolute;
  right: -8px;
  bottom: -8px;
  border: 2px solid #fff;
}

.icon_text {
  gap: 4px;
}

.disabledContent {
  pointer-events: none !important;
  opacity: 0.9 !important;
}

#chat-widget .v-slick-slider em[disabled=false] {
  display: none !important;
}
</style>
