<template>
  <div class="svg_icon">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" :style="{
      padding: '1px',
      fill: isHoverLanguage ? themePrimaryColor : chatThemeSettings?.borderColor || themeColor,
      stroke: isHoverLanguage ? themePrimaryColor : chatThemeSettings?.borderColor || themeColor,
    }" width="48" height="48" viewBox="0 0 1024 1024">
      <g id="icomoon-ignore" />
      <path
        d="M512.018 0c-282.362 0-512.018 229.656-512.018 512.018s229.656 512.018 512.018 512.018c282.362 0 512.018-229.656 512.018-512.018s-229.656-512.018-512.018-512.018zM512.018 68.753c245.206 0 443.266 198.060 443.266 443.266s-198.060 443.266-443.266 443.266c-245.206 0-443.266-198.060-443.266-443.266s198.060-443.266 443.266-443.266z" />
      <path
        d="M519.904 703.161c-90.028 0-179.943 3.018-253.659 8.997-36.858 2.989-69.641 6.609-96.818 11.28s-47.728 9.047-66.201 19.068c-10.824 5.932-18.041 17.247-18.041 30.247 0 6.044 1.56 11.724 4.299 16.659l-0.090-0.176c5.956 10.735 17.224 17.881 30.16 17.881 6.025 0 11.689-1.55 16.613-4.273l-0.177 0.090c3.167-1.718 21.065-7.549 45.119-11.683s55.133-7.728 90.641-10.608c71.016-5.76 159.632-8.728 248.154-8.728s177.138 2.968 248.154 8.728c35.508 2.88 66.721 6.474 90.775 10.608s41.952 9.964 45.119 11.683c4.747 2.634 10.411 4.184 16.436 4.184 12.936 0 24.204-7.146 30.070-17.705l0.090-0.176c2.65-4.759 4.21-10.439 4.21-16.483 0-13-7.217-24.315-17.862-30.157l-0.179-0.090c-18.473-10.021-39.159-14.397-66.335-19.068s-59.825-8.29-96.683-11.28c-73.717-5.979-163.765-8.997-253.793-8.997z" />
      <path
        d="M94.558 241.215c-9.026 2.236-16.479 7.806-21.132 15.296l-0.085 0.146c-3.168 5.105-5.046 11.298-5.046 17.929 0 12.359 6.522 23.195 16.313 29.253l0.147 0.085c19.132 11.671 39.917 16.065 67.947 21.082s62.193 9.016 100.712 12.22c77.038 6.407 171.66 9.534 266.416 9.534s189.471-3.127 266.55-9.534c38.54-3.204 72.519-7.203 100.577-12.22s48.949-9.46 68.081-21.082c9.959-6.139 16.498-16.987 16.498-29.362 0-6.567-1.841-12.703-5.036-17.922l0.086 0.15c-6.132-9.993-16.998-16.558-29.398-16.558-6.607 0-12.779 1.864-18.018 5.095l0.148-0.085c-1.58 0.96-19.727 7.666-44.447 12.085s-57.126 8.192-94.266 11.28c-74.281 6.175-167.554 9.4-260.776 9.4s-186.539-3.225-260.776-9.4c-37.118-3.087-69.448-6.861-94.132-11.28s-42.82-11.174-44.313-12.085c-5.093-3.149-11.267-5.014-17.877-5.014-2.902 0-5.719 0.359-8.411 1.036l0.237-0.051z" />
      <path
        d="M519.969 0c-39.183 0-75.138 18.636-104.337 46.999s-53.219 66.677-73.184 112.529c-39.93 91.704-63.381 214.108-63.381 349.134s23.451 257.43 63.381 349.134c19.965 45.852 43.985 84.3 73.184 112.663s65.154 46.999 104.337 46.999c39.183 0 75.138-18.636 104.337-46.999s53.219-66.811 73.184-112.663c39.93-91.704 63.381-214.108 63.381-349.134s-23.451-257.43-63.381-349.134c-19.965-45.852-43.985-84.165-73.184-112.529s-65.154-46.999-104.337-46.999zM519.969 68.753c17.849 0 36.179 7.887 56.399 27.528s40.734 50.791 58.144 90.775c34.82 79.968 57.607 194.67 57.607 321.606s-22.787 241.773-57.607 321.74c-17.41 39.984-37.925 71-58.144 90.641s-38.549 27.662-56.399 27.662c-17.849 0-36.179-8.021-56.399-27.662s-40.734-50.657-58.144-90.641c-34.82-79.968-57.607-194.804-57.607-321.74s22.787-241.638 57.607-321.606c17.41-39.984 37.925-71.134 58.144-90.775s38.549-27.528 56.399-27.528z" />
    </svg>
  </div>
</template>

<script>
import { useChatSettingsStore } from '../../../store/ChatSettingsStore/ChatSettingsStore';
import { mapWritableState } from 'pinia';

export default {
  props: {
    themeColor: {
      type: String,
      required: false,
      default: '#B3B3B3',
    },
    isHoverLanguage: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      themePrimaryColor: window.primaryColor,
    }
  },
  computed: {
    ...mapWritableState(useChatSettingsStore, ['chatThemeSettings']),
  },
}
</script>
<style scoped>
.svg_icon svg {
  position: relative;
  bottom: 0px;
  height: 16px;
}

.footer-menu:hover svg {
  bottom: -2px;
  transition: 0.5s;
}

@media (min-width: 1900px) {
  .svg_icon svg {
    height: 21px !important;
    margin-bottom: 2px;
  }
}
</style>
