<template>
  <div>
    <div class="signInNavigationModalLoader">
      <div class="d-flex align-items-center justify-content-center flex-column  bg-white  p-4 shadow-sm c_border_r">
        <assistant-icon :width="40" :height="40" :auto-spin="true" :spin-duration="1000" />
        <p class="my-2">{{ message }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import AssistantIcon from '../ChatAssistant/AssistantIcon.vue';

export default {
  components: {
    "assistant-icon": AssistantIcon,
  },
  props: {
    message: {
      type: String,
      default: null,
    },
  },
}
</script>
<style>
.signInNavigationModalLoader {
  position: absolute;
  background: rgba(240, 242, 250, .768627451);
  height: 100%;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}

.signInNavigationModalLoader .c_border_r {
  border-radius: 11px;
}
</style>
