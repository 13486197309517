<template>
  <div id="text-layout-section" class="text__conversation__block mt-0 other_Option_Text">
    <div class="channel-form">
      <div v-if="hideTextForm" class="initial-con">
        <div class="text-center custom-padding-t10">
          <p class="m-0 ft-13">Please text us your message below.</p>
        </div>
        <div class="custom-text-area pt-3">
          <div class="placeholder-icon">
            <img class="img-fluid" src="/images/chat-widget/text-area-icon.svg" alt="" />
          </div>
          <textarea
            v-model.trim="textdetails.vTextarea"
            class="salestextareamessage"
            rows="7"
            placeholder="Type your message"
            :class="[{ error: errordetails.textArea }]"
          />
        </div>
        <div class="border-bottom my-custom-margin" />
        <div class="mobile-number-box">
          <!-- first name last name -->
          <div class="input__contA">
            <div class="input__hold">
              <div class="mobile-number">First Name*</div>
              <div class="mobile-input">
                <input
                  v-model.trim="firstName"
                  type="text"
                  class="phonenumberinput name-input rounded-inputs"
                  :class="[{ error: errordetails.firstnameerror }]"
                />
              </div>
            </div>
            <div class="input__hold">
              <div class="mobile-number">Last Name*</div>
              <div class="mobile-input">
                <input
                  v-model.trim="lastName"
                  type="text"
                  class="phonenumberinput name-input rounded-inputs"
                  :class="[{ error: errordetails.lastnameerror }]"
                />
              </div>
            </div>
          </div>
          <!-- first name last name -->
          <div v-if="showEmailField" class="input__hold">
            <div class="mobile-number">Email Address*</div>
            <div class="mobile-input">
              <input
                v-model="textdetails.vEmailAddress"
                type="text"
                name=""
                class="phonenumberinput name-input rounded-inputs"
                :class="[{ error: errordetails.emailerror }]"
              />
            </div>
          </div>
          <div class="input__hold">
            <div class="mobile-number">Mobile Number*</div>
            <div class="mobile-input">
              <input
                v-model="textdetails.vPhonenumber"
                type="text"
                class="phonenumberinput name-input rounded-inputs"
                :class="[{ error: errordetails.phoneerror }]"
                @keypress="isNumberKey"
              />
            </div>
          </div>

          <div class="input__hold" v-if="showZipCodeField">
            <div class="mobile-number">{{ zipDetails.placeHolderText }}</div>
            <div class="mobile-input">
              <input
                v-model="textdetails.Zip"
                type="text"
                class="phonenumberinput name-input rounded-inputs"
                :class="[{ error: errordetails.ziperror }]"
              />
            </div>
          </div>
        </div>

        <div class="mt-2 pt-3">
          <tcpa-disclaimer @checked="ontcpChecked($event)" />
        </div>

        <div class="my-2">
          <show-disclaimer />
        </div>

        <shift-tcpa-disclaimer-checkbox
          v-if="loadShiftSpecialFields"
          :formdata="textdetails"
          :errorobj="errordetails"
        />

        <div class="send-btn-con">
          <button
            id="sendbtn"
            type="button"
            class="_theme_font_bold btn btn-lg btn-block my-3 text-send-btn bgcolor-primary rounded-inputs theme-bg-color btn-websites button-primary"
            @click.prevent="sendtext()"
          >
            Text Us
            <i
              class="primaryicon-longarrow-right float-right"
              style="position: relative; top: 4px"
            />
          </button>
        </div>
      </div>
      <div v-if="isthankyou" class="thank-you-con">
        <div class="success_icon">
          <svg class="success_icon_check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle
              :stroke="themePrimaryColor"
              class="success_icon_checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              :stroke="themePrimaryColor"
              class="success_icon_checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        Thank you, your information has been received and a dealer representative will contact you
        soon!
      </div>
      <div v-if="senderror" class="thank-you-con">Something went wrong,Please try again later;</div>
    </div>
  </div>
</template>
<script type="text/javascript" src="./OtherOptionTextForm.js"></script>
<style scoped>
.chat-image {
  position: absolute;
  width: 50px;
  top: -30px;
  right: 0px;
  z-index: 4;
}

.nada-text-us-btn {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 21px;
  color: #fff;
  text-align: center;
  padding: 14px;
  letter-spacing: 2px;
  display: block;
  margin: 0px auto;
  min-width: 180px;
  border: 3px solid #fff;
  background: #007cc3;
}

.nada-chat-con {
  padding: 18px;
  border-radius: 5px;
  border: 1px none #000000;
  box-shadow: 1px 1px 3px 2px rgba(2, 2, 2, 0.19);
  color: #555555;
  z-index: 999999999;
  width: 18%;
  position: fixed;
  bottom: 10px;
  right: 5px;
  min-width: 310px;
  box-sizing: border-box;
}

.nada-child {
  box-sizing: border-box;
  margin: 12px 0;
  font-size: 14px;
}

.nadaimg {
  max-width: 100%;
  height: auto;
  float: left;
  margin: 4px 10px 0px 0;
  display: block;
  position: relative;
}

.nada-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px;
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;
  border: 1px none #ffffff;
  cursor: pointer;
  min-width: 1em;
  z-index: 9999999999;
  border-radius: 0px;
  box-shadow: 0px 0px 0px 0px rgba(2, 2, 2, 0.23);
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.23);
}

.nada-bgcolor {
  background-color: rgba(0, 144, 178, 1);
}

.nada-color {
  color: rgb(0, 144, 178);
}

.nada-main-text {
  line-height: 19px;
}

.nada-learn-more {
  display: block;
  text-align: center;
  clear: both;
}

.learn-more-btn {
  color: #ffffff;
  margin: 0 auto;
  border-radius: 50px;
  cursor: pointer;
  padding: 12px 16px 10px;
  min-width: 70px;
  display: inline-block;
  font-size: 14px;
}

.chat-con {
  position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 1;
}

.chat-con .circle-chat-con {
  background: transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
}

.chat-con .circle-chat-con img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.textwidgetbox {
  position: fixed;
  bottom: 50px;
  right: 10px;
  width: 20%;
  background: #fff;
  padding: 20px;
  z-index: 999999999;
  border-radius: 5px;
  border: 1px none #000000;
  box-shadow: 1px 1px 3px 2px rgba(2, 2, 2, 0.19);
  background-color: rgba(254, 254, 254, 1);
}

.textwidgetbox .text-radio-con .text-radio-box .sales-radio-con .float-left {
  width: 48%;
}

.textwidgetbox .text-radio-con .text-radio-box .service-radio-con .float-left {
  width: 100%;
}

@media (max-width: 1400px) {
  .textwidgetbox {
    width: 30%;
  }
}

@media (max-width: 800px) {
  .textwidgetbox {
    width: 70%;
  }

  .send-btn-con .text-send-btn {
    margin: 5px 0;
  }

  .textwidgetbox .note-info-text {
    font-size: 11px;
  }

  .text-radio-con {
    margin: 10px 0;
  }

  .textwidgetbox .text-radio-con .text-radio-box .radio-text {
    margin: 5px 0;
    font-size: 11px;
  }
}

@media (max-width: 600px) {
  .chat-con {
    bottom: 25px;
    right: 4px;
    z-index: 1100 !important;
  }

  .nada-text-us-btn {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 20px;
    color: #fff;
    text-align: center;
    padding: 14px;
    letter-spacing: 2px;
    display: block;
    margin: 0px auto;
    z-index: 3;
    width: 80%;
    position: fixed;
    bottom: 43px;
    right: 0;
    left: 0;
    text-align: center;
    margin: 0 auto;
  }

  .nada-chat-con {
    width: 97%;
    min-width: unset;
    margin: 0 auto;
  }

  .textwidgetbox {
    width: 80%;
  }

  .chat-textarea-con {
    margin: 10px auto;
  }

  .textwidgetbox .text-radio-con .text-radio-box .sales-radio-con .float-left {
    width: 50%;
  }
}

.beforechannelsready {
  font-size: 15px;
  color: #000;
}

.f-bold {
  font-weight: bold;
}

.textwidgetbox .mobile-number-box .mobile-input input[type="text"] {
  font-size: 12px;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 15px solid #000;
  margin-right: 5px;
  top: 0;
  position: relative;
}

.chat-buuble-icon {
  color: #8bc53f;
  font-size: 25px;
  position: relative;
  top: 2px;
  right: 10px;
}

.textwidget-close-icon {
  display: block;
  text-align: right;
  font-size: 24px;
  cursor: pointer;
}

.modal-body .speech-bubble-1 {
  background: #8bc53f;
}

.modal-body .speech-bubble-1:after {
  border-top-color: #8bc53f;
}

.modal-body .speech-bubble-2 {
  background: #8bc53f;
}

.modal-body .speech-bubble-2:after {
  border-top-color: #8bc53f;
}

.channel-list-btns-con ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.text-us-title-con .text-us-title {
  color: #8bc53f;
  font-size: 21px;
  font-weight: bold;
  margin-top: 5px;
  position: relative;
}

.text-us-title-con .body-box-chat-icon {
  position: relative;
  margin-right: 25px;
}

.back-btn-con {
  position: absolute;
  right: 15px;
  top: 10px;
}

.back-btn-con .back-btn-box {
  padding: 5px 18px;
  border: 1px solid;
  cursor: pointer;
}

.chat-textarea-con {
  width: 80%;
  margin: 20px auto;
  position: relative;
}

.chat-textarea-con:before {
  content: "";
  position: absolute;
  border: 15px solid #555;
  left: -30px;
  border-color: #555 #555 transparent transparent;
}

.chat-textarea-con:after {
  content: "";
  position: absolute;
  border: 15px solid #555;
  left: -28px;
  border-color: #fff #fff transparent transparent;
  top: 1px;
}

.custom-text-area textarea {
  width: 100%;
  padding: 10px 10px 10px 40px;
  box-sizing: border-box;
  background: #f3f3f3;
  border-radius: 5px;
  font-size: 13px;
  border: 1px solid #f3f3f3;
}

.custom-text-area {
  position: relative;
}

.custom-text-area .placeholder-icon {
  position: absolute;
  top: 25px;
  left: 13px;
}

.custom-text-area .placeholder-icon img {
  height: 16px;
}

.custom-text-area textarea:focus {
  outline: none;
  border-color: #f3f3f3;
  box-shadow: none;
}

.text-radio-con {
  margin: 15px 0;
}

.text-radio-con .intrested-text {
  font-weight: bold;
  color: #000;
  margin-bottom: 0;
}

.text-radio-con .text-radio-box .float-left {
  width: 40%;
}

.text-radio-con .text-radio-box .radio-text {
  margin: 8px 0;
  font-size: 13px;
}

.text-radio-con .text-radio-box .radio-text span {
  position: relative;
  top: -2px;
}

.mobile-number-box .mobile-number {
  font-weight: normal;
  color: #000;
  margin: 5px 0;
}

.mobile-number-box .phonenumberinput {
  width: 100%;
  padding: 10px;
}

.note-info-text {
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
}

.send-btn-con {
  text-align: center;
}

.send-btn-con .text-send-btn {
  background: #8bc53f;
  color: #fff;
  padding: 7px 14px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  font-size: 15px !important;
  min-width: 120px;
  margin: 10px 0;
  border: 0;
}

.other_Option_Text .thank-you-con {
  font-size: 15px;
  text-align: center;
}

.textwidgetbox .textwidget-close-icon {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 28px;
  cursor: pointer;
  display: block;
}

.textwidgetbox .back-btn-con {
  right: 35px;
}

.cls-2 {
  fill: url(#linear-gradient);
}

.cls-3 {
  fill: #fff;
}

.chat-child {
  position: relative;
}

@media (max-width: 767px) {
  .chat-image {
    width: 40px;
  }

  .note-info-text {
    font-size: 10px;
  }
}

@media (max-width: 400px) {
  .chat-image {
    position: absolute;
    width: 35px;
    right: 0px;
    z-index: 4;
  }
}

.mobile-number-box .mobile-input .error {
  border: 1px solid red;
}

.channel-form {
  height: 80vh;
  overflow: auto;
}

.back-button {
  border: 1px solid;
  padding: 0px 10px;
}

.sales-radio-con > div {
  float: left;
  width: 50%;
}

.service-radio-con > div {
  float: left;
  width: 50%;
}

@media (max-width: 500px) {
  .salestextareamessage {
    height: 100px;
  }

  .channel-form {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .radio-text {
    font-size: 10px !important;
  }

  .channel-form {
    font-size: 10px;
  }

  .mobile-number-box .phonenumberinput {
    padding: 4px;
  }

  .text-radio-con .text-radio-box .radio-text {
    margin: 5px 0px;
  }

  .salestextareamessage {
    height: 70px;
  }

  .text-radio-con {
    margin: 0px !important;
  }

  .arrow-left {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 8px solid #000;
    margin-right: 5px;
    top: 2px;
    position: relative;
    font-size: 10px;
  }

  .text-us-title-con .text-us-title {
    margin-top: 0px;
    font-size: 15px;
  }

  .send-btn-con .text-send-btn {
    font-size: 13px !important;
    padding: 5px 14px !important;
    margin: 0px;
  }

  .textus-title {
    font-size: 13px;
  }
}

.name-input {
  border: 1px solid #dedede !important;
}

.name-input.error {
  border: 1px solid red !important;
}

.chat_loader {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 24px;
  align-items: center;
  justify-content: center;
  animation: none;
  border: none;
  margin: 0;
  padding: 0;
}

@-webkit-keyframes ball-pulse-sync {
  20% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  40% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  60% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  80% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes ball-pulse-sync {
  20% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  40% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  60% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  80% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.ball-pulse-sync > div:nth-child(1) {
  -webkit-animation: ball-pulse-sync 1s -0.36s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.36s infinite ease-in-out;
}

.ball-pulse-sync > div:nth-child(2) {
  -webkit-animation: ball-pulse-sync 1s -0.27s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.27s infinite ease-in-out;
}

.ball-pulse-sync > div:nth-child(3) {
  -webkit-animation: ball-pulse-sync 1s -0.18s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.18s infinite ease-in-out;
}

.ball-pulse-sync > div:nth-child(4) {
  -webkit-animation: ball-pulse-sync 1s -0.09s infinite ease-in-out;
  animation: ball-pulse-sync 1s -0.09s infinite ease-in-out;
}

.ball-pulse-sync > div:nth-child(5) {
  -webkit-animation: ball-pulse-sync 1s 0s infinite ease-in-out;
  animation: ball-pulse-sync 1s 0s infinite ease-in-out;
}

.ball-pulse-sync > div {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin: 0px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}

.text-us-btn {
  border-radius: 7px;
  border: solid 1px #e4e4e4;
  background-color: #fff;
  padding: 10px 20px;
  color: #212529;
  margin-bottom: 15px;
  cursor: pointer;
  height: 55px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.radio-button-toolbar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.radio-button-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-button-toolbar label {
  display: block;
  background-color: #fff;
  padding: 13px;
  font-size: 11px;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: 0;
  text-align: center;
  cursor: pointer;
}

.radio-button-toolbar label:hover {
  background-color: #fefefe;
}

.radio-button-toolbar input[type="radio"]:checked + label {
  background-color: #f3f3f3;
  border-color: #333;
  font-weight: 600;
}

.input__hold input:focus {
  outline: none;
}

.note-info-text.info_text {
  font-size: 11px !important;
  color: #999;
}

.salestextareamessage.error {
  border: 1px solid red !important;
}

.my-custom-margin {
  margin: 20px 0 20px 0;
}

.custom-padding-t30-b60 {
  padding: 30px 0 60px 0;
}

.custom-padding-t10 {
  padding-top: 10px;
}

@media (min-width: 1900px) {
  .send-btn-con .text-send-btn {
    height: 50px;
  }
}

.text__conversation__block.other_Option_Text {
  height: 100vh;
  align-items: center;
  display: flex;
}

.text__conversation__block .success_icon_checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.text__conversation__block .success_icon_check {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke-miterlimit: 10;
  margin: 15px auto;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.text__conversation__block .success_icon_checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #fff;
  }
}
</style>
