<template>
  <div
    :class="[desktopsubmenu ? 'website-bw-submenu position-absolute submenu-navigation' : '']"
    style="z-index: -1"
    class="bw-research-models-menu"
  >
    <div
      class="position-fixed w-100 h-100 d-none d-md-inline-block"
      style="z-index: -1"
      @click="closeprimaryResearchModels()"
    ></div>
    <div class="w-100 pb-4 bgcolor-white">
      <div
        class="mr-5 pt-3 text-right cursor-pointer"
        @click="closeprimaryResearchModels()"
        style="position: absolute; right: 0"
        v-if="desktopsubmenu"
      >
        <em class="primaryicon-times ft-16"></em>
      </div>
      <h2 class="text-center pt-4 mb-3 page-sections-title ft-24">Choose a Model</h2>
      <div class="d-flex justify-content-center mb-4 mr-5 pr-3" v-if="!isBMW">
        <a
          class="cursor-pointer text-decoration-none switch-parent position-relative"
          :class="[currentIndex == index ? 'active' : '']"
          @click="selectYear(index, year, true)"
          v-for="(year, index) in yearList"
          :key="index"
        >
          <div class="switch" :class="[currentIndex == index ? 'bgcolor-primary' : '']">
            <span
              class="color-black _theme_font_bold ft-12"
              :class="[currentIndex == index ? 'text-white' : '']"
              >{{ year }}</span
            >
          </div>
        </a>
      </div>
      <div class="container" v-if="menudata">
        <div class="w-100">
          <VSlickCarousel
            ref="slick"
            v-bind="slickOptions"
            @beforeChange="handleBeforeChange"
            class="position-relative"
          >
            <template #prevArrow="arrowSlotProps">
              <div
                @click="arrowSlotProps.onClick"
                :disabled="arrowSlotProps.disabled"
                class="text-dark float-left position-absolute previous-link v-slicl-custom-arrow-position"
              >
                <div
                  class="d-flex align-items-center justify-content-center bg-white shadow border v-slicl-custom-arrow"
                >
                  <span class="primaryicon-left-arrow slick-arrow ft-24"></span>
                </div>
              </div>
            </template>
            <div v-for="(vehicle, index) in getLoopData()" :key="index">
              <a
                :href="baseUrl + vehicle.navigationUrl.toLowerCase()"
                @click="
                  onExploreBoxClickLogging(baseUrl + vehicle.navigationUrl.toLowerCase(), vehicle)
                "
                class="no-hover px-4 text-center d-inline-block img-box"
              >
                <div class="w-75 mx-auto">
                  <div class="my-3 d-flex img-adjustment align-items-center justify-content-center">
                    <img
                      v-if="useoverrides"
                      class="img-fluid"
                      :src="`${vehicle.imageUrl}&type=jpeg&autocrop=true&angle=${angleValue}&width=500&quality=85&useoverrides=${useoverrides}`"
                      alt="BMW vehicle"
                    />
                    <img
                      v-else
                      class="img-fluid"
                      :src="`${vehicle.imageUrl}&type=jpeg&autocrop=true&angle=${angleValue}&width=500&quality=85`"
                      alt="BMW vehicle"
                    />
                  </div>
                  <div class="my-3">
                    <span class="ft-20 font-weight-bold">{{ vehicle.model }}</span>
                  </div>
                </div>
              </a>
            </div>
            <template #nextArrow="arrowSlotProps">
              <div
                @click="arrowSlotProps.onClick"
                :disabled="arrowSlotProps.disabled"
                class="text-dark float-right position-absolute next-link v-slicl-custom-arrow-position"
              >
                <div
                  class="d-flex align-items-center justify-content-center bg-white shadow border v-slicl-custom-arrow"
                >
                  <span class="primaryicon-right-arrow slick-arrow ft-24"></span>
                </div>
              </div>
            </template>
          </VSlickCarousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import ResearchModels from "../ResearchModels";
export default {
  mixins: [ResearchModels],
  data() {
    return {
      useoverrides: window.digiServiceUseOverrides,
      slickOptions: {
        dots: false,
        groupsToShow: 4,
        groupsToScroll: 1,
        slidesPerGroup: 1,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              groupsToShow: 3,
              groupsToScroll: 3,
            },
          },
          {
            breakpoint: 991,
            settings: {
              groupsToShow: 2,
              groupsToScroll: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              groupsToShow: 2,
              groupsToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              groupsToShow: 1,
              groupsToScroll: 1,
            },
          },
        ],
        nextArrow: `<em class="primaryicon-right-arrow" style="color:#cccccc;cursor:pointer;z-index:1"></em>`,
        prevArrow: `<em class="primaryicon-left-arrow" style="cursor:pointer;z-index:1"></em>`,
      },
      isBMW: window.make.toLowerCase() === "bmw",
    };
  },
  methods: {
    getLoopData() {
      if (this.isBMW) {
        return this.menudata;
      } else {
        return this.filterMenuData;
      }
    },
  },
};
</script>

<style scoped>
.switch {
  width: 70px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: #eaeaea;
  border: 1px solid #ccc;
  border-radius: 12px;
  text-align: center;
  left: 16px;
}

.active .switch {
  background-color: #fff;
}

.active {
  position: relative;
  z-index: 1;
}

.website-bw-submenu {
  top: 86px;
  left: 0px;
  width: 100%;
}

.submenudata {
  width: 200px;
  max-height: 500px;
  overflow-y: auto;
}

.img-adjustment {
  height: 160px;
}
.img-adjustment img {
  max-width: 100%;
  max-height: 100%;
}

.slick-arrow.previous-link {
  position: absolute;
  top: 50%;
}

.slick-arrow.next-link {
  position: absolute;
  right: 0px;
  top: 50%;
}

.bgcolor-black {
  background-color: #000;
}
.img-box:hover {
  color: initial !important;
}

:deep(.v-slick-arrow.prev) {
  left: 0px !important;
  z-index: 1000;
  border-radius: 50%;
}
:deep(.v-slick-arrow.next) {
  right: 0px !important;
  border-radius: 50%;
}
:deep(.v-slick-arrow.prev:before),
:deep(.v-slick-arrow.next:before) {
  background-color: #5c5c5c !important;
  border-radius: 50%;
}
:deep(.v-slick-arrow.disabled:before) {
  opacity: 0.1;
  background-color: #000000 !important;
  border-radius: 50%;
}
:deep(.v-slicl-custom-arrow) {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
:deep(.v-slicl-custom-arrow-position) {
  top: 40%;
}

@media (max-width: 768px) {
  .previous-link {
    left: -5px !important;
  }
}
</style>
